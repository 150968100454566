import React, { Component } from 'react';
import MarkerClusterer from '@google/markerclustererplus';
import * as grupoTareasAccion from '../../../store/actions/GrupoTareas';
import cloneDeep from 'lodash/cloneDeep';

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cantidadLocalizaciones: 0,
            localizaciones: [],
            rayos: null,
            detalle: false,
        };
        this.onScriptLoad = this.onScriptLoad.bind(this);
    }

    componentDidMount() {
        // if (!window.google) {
        //     const script = document.createElement('script');
        //     script.type = 'text/javascript';
        //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg`;

        //     const collection = document.getElementsByTagName('script')[0];
        //     collection.parentNode.insertBefore(script, collection);
        //     script.addEventListener('load', (e) => {
        //         this.onScriptLoad();
        //     });
        // } else {
            this.onScriptLoad();
        // }
    }

    _handleClick = (cluster) => {
        try {
            let marcas = cluster.getMarkers();
            let seleccionados = [];
            marcas.forEach((marca) => {
                seleccionados.push(marca.placeName);
            });

            const { dispatch } = this.props;
            let modificarGT = grupoTareasAccion.modificarGT(
                this.props.grupoTareas,
                this.props.tasksTab,
                seleccionados,
                true
            );

            dispatch(modificarGT);
        } catch (error) {}
        // sessionStorage.setItem('clusterMarks', JSON.stringify(seleccionados));
    };

    onScriptLoad() {
        const map = new window.google.maps.Map(document.getElementById(this.props.id), this.props.options);

        if (this.props.detalle) {
            this.onMapLoadDetalle(map);
        } else {
            this.onMapLoad(map);
        }
        //this.getCenter(map);
    }

    showPosition(position) {}

    estadoRayo = (status) => {
        if (status === 1) {
            return 'dot activo';
        } else if (status === 3) {
            return 'dot activo';
        } else {
            return 'dot inactivo';
        }
    };

    onMapLoad = (map) => {
        let markers = [];
        this.props.localizaciones.forEach((coordenadas) => {
            coordenadas.map = map;
            let marker = new window.google.maps.Marker(coordenadas);
            marker.addListener('click', async () => {
                try {
                    const { dispatch } = this.props;
                    let modificarGT = grupoTareasAccion.modificarGT(
                        this.props.grupoTareas,
                        this.props.tasksTab,
                        [coordenadas.placeName],
                        true
                    );
                    dispatch(modificarGT);
                } catch (error) {}
            });
            markers.push(marker);
        });

        let markersRayo = [];

        if (this.props.rayos == null || typeof this.props.rayos == undefined) {
        } else {
            this.props.rayos.forEach((coordenadas, i) => {
                coordenadas.map = map;
                let rayo = cloneDeep(coordenadas.dataRayo);
                let marca = {
                    icon: coordenadas.icon,
                    position: coordenadas.position,
                    map: coordenadas.map,
                    title: coordenadas.title,
                    placeName: coordenadas.placeName,
                };

                let marker = new window.google.maps.Marker(marca);
                const contentString =
                    '<div id="content">' +
                    '<div class="row m-0">' +
                    '<div class="d-inline d-flex align-items-center mb-1 ">' +
                    '<div class="m-0 ' +
                    this.estadoRayo(rayo.status) +
                    '"></div>' +
                    '</div>' +
                    '<label class="text-exo-bold mb-1 pl-2">' +
                    rayo.name +
                    '</label>' +
                    '</div>' +
                    '<div id="bodyContent">' +
                    '<p class="text-roboto-regular mb-0"> ID: ' +
                    rayo.id +
                    '</p>' +
                    '<p class="text-roboto-regular mb-0"> DNI: ' +
                    rayo.dni +
                    '</p>' +
                    '<p class="text-roboto-regular mb-0"> Número de contacto: ' +
                    rayo.phone +
                    '</p>' +
                    '<p class="text-roboto-regular mb-0"> Email: ' +
                    rayo.email +
                    '</p>' +
                    '</div>' +
                    '</div>';

                const infowindow = new window.google.maps.InfoWindow({
                    content: contentString,
                });

                marker.addListener('click', () => {
                    infowindow.open(map, marker);
                });
                marker.setMap(map);
                markersRayo.push(marker);
            });
        }

        let markerClusterer = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });

        if (this.props.asignar !== true) {
            window.google.maps.event.addListener(markerClusterer, 'clusterclick', this._handleClick);
        }
    };

    onMapLoadDetalle = (map) => {
        let markers = [];
        let bounds = new window.google.maps.LatLngBounds();
        this.props.localizaciones.forEach((coordenadas) => {
            let myLatLng = new window.google.maps.LatLng(coordenadas.position.lat, coordenadas.position.lng);
            coordenadas.map = map;
            let marker = new window.google.maps.Marker(coordenadas);

            markers.push(marker);
            bounds.extend(myLatLng);
        });
        map.fitBounds(bounds);

        map.setOptions({
            maxZoom: 18,
        });

        new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.localizaciones.length !== this.state.cantidadLocalizaciones) {
            this.setState({
                cantidadLocalizaciones: this.props.localizaciones.length,
            });
            this.onScriptLoad();
        }
        
        if (this.props.rayos != null && typeof this.props.rayos != undefined && prevProps.rayos != null) {
            if (this.props.rayos.length !== prevProps.rayos.length) this.onScriptLoad(); 
            this.props.rayos.forEach(rayoProps => {
                prevProps.rayos.forEach(rayoPrevProps => {
                    if(rayoProps.dataRayo.id === rayoPrevProps.dataRayo.id){
                        // console.log(rayoProps.dataRayo.name_rayo)
                        // console.log("LGN")
                        // console.log(rayoProps.position.lng)
                        // console.log(rayoPrevProps.position.lng)
                        // console.log("LAT")
                        // console.log(rayoProps.position.lat)
                        // console.log(rayoPrevProps.position.lat)
                        if(rayoProps.position.lat !== rayoPrevProps.position.lat || rayoProps.position.lng !== rayoPrevProps.position.lng){
                            this.onScriptLoad();
                        }
                    }
                });
            });
        }

        if (this.props.rayos !== null && prevProps.rayos === null) {
            this.onScriptLoad();
        }

        if (this.props.detalle !== this.state.detalle) {
            this.setState({
                detalle: this.props.detalle,
            });
            this.onScriptLoad();
        }
    }

    render() {
        return <div className="w-100 h-100" id={this.props.id} />;
    }
}

export default Map;
