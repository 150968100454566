import url from '../../config';
import axios from 'axios';
import Utils from '../../assets/utils/Utils';

export const ERROR_WEBHOOKS = 'ERROR_WEBHOOKS';
export const RES_ERROR = 'RES_ERROR';
export const error = () => {
    return async (dispatch) => {
        dispatch({ type: RES_ERROR });
    };
};

export const OBTENER_WEBHOOKS_TAREAS = 'OBTENER_WEBHOOKS_TAREAS';
export const obtenerWebhooksTareas = () => {
    return async (dispatch) => {
        try {
            let usuario = JSON.parse(sessionStorage.getItem("user"));
            let clientes = Utils.getCustomers();
            await axios({
                method: 'get',
                url: url.host + '/Webhooks/webhooks_task' + clientes,
                //headers: { tenant: usuario.tenant },
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            dispatch({
                                type: OBTENER_WEBHOOKS_TAREAS,
                                webhooksTareas: response.data,
                            });
                            break;

                        default:
                            dispatch({
                                type: ERROR_WEBHOOKS,
                                error:
                                    'Error ' + response.status + ' al realizar la petición obtener webhooks de tareas',
                            });
                            break;
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: ERROR_WEBHOOKS,
                        error: 'Error al realizar la petición obtener webhooks de tareas',
                    });
                });
        } catch (e) {
            dispatch({
                type: ERROR_WEBHOOKS,
                error: 'Error al realizar la petición obtener webhooks de tareas',
            });
        }
    };
};

export const OBTENER_WEBHOOKS_GRUPOS_TAREAS = 'OBTENER_WEBHOOKS_GRUPOS_TAREAS';
export const obtenerWebhooksGruposTareas = () => {
    return async (dispatch) => {
        try {
            let clientes = Utils.getCustomers();
            let usuario = JSON.parse(sessionStorage.getItem("user"));
            await axios({
                method: 'get',
                url: url.host + '/Webhooks/webhooks_tasks_group' + clientes,
                //headers: { tenant: usuario.tenant },
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            dispatch({
                                type: OBTENER_WEBHOOKS_GRUPOS_TAREAS,
                                webhooksGruposTareas: response.data,
                            });
                            break;

                        default:
                            dispatch({
                                type: ERROR_WEBHOOKS,
                                error:
                                    'Error ' +
                                    response.status +
                                    ' al realizar la petición obtener webhooks de grupos de tareas',
                            });
                            break;
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: ERROR_WEBHOOKS,
                        error: 'Error al realizar la petición obtener webhooks de grupos de tareas',
                    });
                });
        } catch (e) {
            dispatch({
                type: ERROR_WEBHOOKS,
                error: 'Error al realizar la petición obtener webhooks de grupos de tareas',
            });
        }
    };
};
