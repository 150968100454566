import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import CopyText from "../../assets/components/custom/CopyText";

import url from "../../config";
import Utils from "../../assets/utils/Utils";
import Cargando from "../../assets/components/cargando/Cargando";
import * as accionComunas from "../../store/actions/Comunas";
import * as accionProveedores from "../../store/actions/Proveedores";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import IconoMapa from "../../assets/icons/icono-map-white.svg";
import IconoCerrar from "../../assets/icons/icono-cerrar-white.svg";
import IconoCerrarBlack from "../../assets/icons/icono-cerrar-red.svg";
import { AMBIENTE } from "./../../assets/utils/Helpers";

import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const IconoUsuario =
  "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/genericImage.png";

const columns = [
  { id: "copiar", label: "Copiar ID", align: "left" },
  { id: "image_rayo", label: "Imagen", align: "left" },
  { id: "name_rayo", label: "Nombre", align: "left" },
  { id: "contact", label: "Contacto", align: "left" },
  { id: "type_vehicles_rayo", label: "Tipos de vehículo", align: "left" },
  { id: "vigencia", label: "Vigencia", align: "left" },
  { id: "customer_rayo", label: "Clientes", align: "left" },
  { id: "massive_pickup", label: "Warehouse", align: "left" },
  { id: "sucursal_autoassign", label: "AutoAsignación", align: "left" },
  { id: "accion", label: "Acción", align: "right" },
];

const ActiveDot = ({ status }) => {
  return (
    <Box display="inline-flex">
      <Box height={12} display="flex" alignItems="center" marginRight={2}>
        <Box
          height={8}
          width={8}
          borderRadius={4}
          bgcolor={status ? "#1fc600" : "#f00"}
        ></Box>
      </Box>
      <Box height={12} display="flex" alignItems="center">
        {status ? "Vigente" : "No vigente"}
      </Box>
    </Box>
  );
};

const ButtonEstado = withStyles(() => ({
  disabled: {
    backgroundColor: "#f2f2f2 !important",
  },
}))(Button);

class Rayos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      page: 0,
      rowsPerPage: 10,

      search: "",
      tipoModal: 1,
      tipoVehiculos: [],
      rayos: null,
      custumers: null,

      form: {
        rayo: "",
        key: "",
        code_rayo: "0",
        image_rayo: IconoUsuario,
        dni_rayo: "",
        name_rayo: "",
        nationality_rayo: "",
        number_rayo: "",
        number_license_rayo: "",
        address_rayo: "",
        status_rayo: "2",
        email_rayo: "",
        type_vehicles_rayo: [],
        gcm_id_rayo: "",
        uuid_rayo: "",
        auto_assign_rayo: false,
        zones_rayo: { communes: [] },
        provider_rayo: "",
        customer_rayo: [],
        password_rayo: "",
        expiration_date: "",
        vigencia: true,
        vigenciaBacklog: true,
        massive_pickup : true,
        sucursal_autoassign : true,

      },
      cargando: false,
    };
  }

  componentDidMount = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    this.setState({ usuario: user });

    this.getRayos();
    this.getComunas();
    this.getProveedores();
    this.getClientes();
  };

  handleClientes = (i) => {
    let newArray = [...this.state.form.customer_rayo];
    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        customer_rayo: newArray,
      },
    });
  };

  handleCargando(value) {
    this.setState({ cargando: value });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let valor = value;
    if (name === "number_rayo") {
      valor = value.replace(/[^0-9.]/g, "");
    } else {
      valor = value;
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: valor,
      },
    });
  };

  handleChangeButton = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        status_rayo: value,
      },
    });
  };

  handleChangeVigencia = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        vigencia: value,
      },
    });
  };

  handleChangeVigenciaBacklog = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        vigenciaBacklog: value,
      },
    });
  };

  handleChangeMassivePickup = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        massive_pickup: value,
      },
    });
  };

  handleChangeAutoAsignacion= (value) => {
    this.setState({
      form: {
        ...this.state.form,
        sucursal_autoassign: value,
      },
    });
  };




  handlePhone = (e) => {
    let name = "number_rayo";
    let value = "+ " + e.replace(/[^0-9.]/g, "");
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleSelectComuna = (e) => {
    e.preventDefault();
    const { value } = e.target;

    let comunasSeleccionadas = cloneDeep(this.state.form.zones_rayo.communes);
    let comunas = cloneDeep(this.props.comunas.comunas);

    const index = comunas.findIndex((element) => element._id === value);

    comunasSeleccionadas.push(comunas[index]);
    comunas.splice(index, 1);

    this.setState({
      form: {
        ...this.state.form,
        zones_rayo: {
          ...this.state.zones_rayo,
          communes: comunasSeleccionadas,
        },
      },
    });

    try {
      const { dispatch } = this.props;
      let modificarArrayComunas = accionComunas.modificarArrayComunas(comunas);
      dispatch(modificarArrayComunas);
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "No se han podido seleccionar las comunas correctamente",
        "Ok"
      );
    }
  };

  handleDeleteSelectComuna = (e) => {
    const value = e;

    let comunasSeleccionadas = cloneDeep(this.state.form.zones_rayo.communes);
    let comunas = cloneDeep(this.props.comunas.comunas);

    const index = comunasSeleccionadas.findIndex(
      (element) => element._id === value
    );

    comunas.push(comunasSeleccionadas[index]);
    comunasSeleccionadas.splice(index, 1);

    this.setState({
      form: {
        ...this.state.form,
        zones_rayo: {
          ...this.state.zones_rayo,
          communes: comunasSeleccionadas,
        },
      },
    });

    try {
      const { dispatch } = this.props;
      let modificarArrayComunas = accionComunas.modificarArrayComunas(comunas);
      dispatch(modificarArrayComunas);
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "No se han podido seleccionar las comunas correctamente",
        "Ok"
      );
    }
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };

      document.getElementById("image_rayo").value = "";
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  handleVehicle = (i) => {
    let newArray = [...this.state.form.type_vehicles_rayo];
    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        type_vehicles_rayo: newArray,
      },
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleModal = async (tipo, rayo) => {
    this.setState({ tipoModal: tipo });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      let array = cloneDeep(this.state.tipoVehiculos);
      array.forEach((v) => {
        rayo.type_vehicles_rayo.forEach((r) => {
          if (v._id === r.idTypeVehicle) {
            v.activo = true;
          }
        });
      });

      let array1 = cloneDeep(this.state.custumers);
      array1.forEach((v) => {
        rayo.customer_rayo.forEach((r) => {
          if (v._id === r.idCustomer) {
            v.activo = true;
          }
        });
      });

      await this.setState({
        form: {
          ...this.state.form,
          key: rayo._id,
          code_rayo: rayo.code_rayo,
          image_rayo: rayo.image_rayo,
          dni_rayo: rayo.dni_rayo,
          number_rayo: rayo.number_rayo.toString(),
          name_rayo: rayo.name_rayo,
          address_rayo: rayo.address_rayo,
          nationality_rayo: rayo.nationality_rayo,
          number_license_rayo: rayo.number_license_rayo,
          status_rayo: rayo.status_rayo,
          email_rayo: rayo.email_rayo,
          gcm_id_rayo: rayo.gcm_id_rayo,
          type_vehicles_rayo: array,
          uuid_rayo: rayo.uuid_rayo,
          auto_assign_rayo: rayo.auto_assign_rayo,
          zones_rayo: {
            communes: rayo.zones_rayo.communes,
          },
          expiration_date: rayo.expiration_date
            ? moment(rayo.expiration_date).utcOffset("+00").format("YYYY-MM-DD")
            : "",
          latitude_rayo: rayo.latitude_rayo,
          longitude_rayo: rayo.longitude_rayo,
          provider_rayo: rayo.provider_rayo,
          customer_rayo: array1,
          vigencia: rayo.vigencia,
          vigenciaBacklog: rayo.backlogActivo,
          massive_pickup : rayo.massive_pickup,
          sucursal_autoassign : rayo.sucursal_autoassign
        },
      });

      if (
        typeof rayo.zones_rayo !== undefined ||
        typeof rayo.zones_rayo.communes !== undefined
      ) {
        let comunasSeleccionadas = cloneDeep(rayo.zones_rayo.communes);
        let comunas = cloneDeep(this.props.comunas.comunas);

        comunasSeleccionadas.forEach((comunaS) => {
          comunas.forEach((comuna, index) => {
            if (comuna._id === comunaS._id) {
              comunas.splice(index, 1);
            }
          });
        });
        try {
          const { dispatch } = this.props;
          let modificarArrayComunas =
            accionComunas.modificarArrayComunas(comunas);
          dispatch(modificarArrayComunas);
        } catch (error) {
          Utils.handleAlert(
            "Error",
            "No se han podido seleccionar las comunas correctamente",
            "Ok"
          );
        }
      }
      $("#modalFormulario").modal("show");
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        key: "",
        code_rayo: "0",
        image_rayo: IconoUsuario,
        dni_rayo: "",
        address_rayo: "",
        number_rayo: "+56",
        name_rayo: "",
        nationality_rayo: "",
        number_license_rayo: "",
        email_rayo: "",
        status_rayo: "2",
        gcm_id_rayo: "",
        uuid_rayo: "",
        auto_assign_rayo: false,
        zones_rayo: { communes: [] },
        vigencia: true,
        vigenciaBacklog: true,
        massive_pickup : true,
        sucursal_autoassign : true,
        expiration_date: "",
        type_vehicles_rayo: [...this.state.tipoVehiculos],
        provider_rayo: "",
        customer_rayo: [...this.state.custumers],
        password_rayo: "",
      },
    });
  };

  getClientes = async () => {
    try {
      let clientes = Utils.getCustomers();
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let customers = data.sort(function (a, b) {
        if (
          a.name_customer.toLowerCase().trim() <
          b.name_customer.toLowerCase().trim()
        )
          return -1;
        if (
          a.name_customer.toLowerCase().trim() >
          b.name_customer.toLowerCase().trim()
        )
          return 1;
        return 0;
      });

      customers.forEach((v) => {
        v.activo = false;
        return v;
      });
      this.setState({
        custumers: customers,
        form: {
          ...this.state.form,
          customer_rayo: customers,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };

  getComunas = () => {
    try {
      const { dispatch } = this.props;
      let obtenerComunas = accionComunas.obtenerComunas();
      dispatch(obtenerComunas);
    } catch (error) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  getProveedores = () => {
    try {
      const { dispatch } = this.props;
      let obtenerProveedores = accionProveedores.obtenerProveedores();
      dispatch(obtenerProveedores);
    } catch (error) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  getTipoVehiculos = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Type-Vehicles/type_vehicles/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((v) => {
        v.activo = false;
        return v;
      });

      this.setState({
        tipoVehiculos: data,
        form: {
          ...this.state.form,
          type_vehicles_rayo: data,
        },
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tipos de vehículo",
        "No se han podido cargar los tipos de vehículo",
        "Ok"
      );
    }
  };

  getRayos = async () => {
    try {
      let clientes = Utils.getCustomers();

      let data = await axios({
        method: "get",
        url: url.host + "/Rayos/rayosBy" + clientes,
        //headers: { tenant: usuario.tenant },
      });

      console.log("********* MPC *******");

      console.log(url.host + "/Rayos/rayosBy" + clientes);

      if (data.status === 200) {
        this.setState({ rayos: data.data });
        this.getTipoVehiculos();
      } else if (data.status === 204) {
        this.setState({ rayos: [] });
        this.getTipoVehiculos();
        this.getClientes();
      } else {
        Utils.handleAlert(
          "Obtener rayos",
          "No se han podido cargar los rayos",
          "Ok"
        );
      }
    } catch (err) {
      Utils.handleAlert(
        "Obtener rayos",
        "No se han podido cargar los rayos",
        "Ok"
      );
    }
  };

  createRayo = async () => {
    try {
      let tiposVehiculo = cloneDeep(this.state.form.type_vehicles_rayo);
      let clientes = cloneDeep(this.state.form.customer_rayo);
      let newTiposVehiculo = [];
      let newClientes = [];
      let vehiculo = false;
      let cliente = false;

      tiposVehiculo.forEach((tipo) => {
        if (tipo.activo) {
          vehiculo = true;
          newTiposVehiculo.push({
            idTypeVehicle: tipo._id,
          });
        }
      });

      clientes.forEach((tipo) => {
        if (tipo.activo) {
          cliente = true;
          newClientes.push({
            idCustomer: tipo._id,
          });
        }
      });

      if (vehiculo && cliente) {
        if (this.state.form.password_rayo === "") {
          this.handleCargando(false);
          Utils.handleAlert(
            "Campos requeridos",
            "Para crear un rayo, debe ingresar una contraseña",
            "Ok"
          );
        } else {
          let comunas = cloneDeep(this.state.form.zones_rayo.communes);

          comunas.forEach((element) => {
            delete element.__v;
            delete element.id;
          });

          let numero = this.state.form.number_rayo.split("+");

          const rayo = {
            code_rayo: this.state.form.code_rayo,
            image_rayo: this.state.form.image_rayo,
            dni_rayo: this.state.form.dni_rayo,
            name_rayo: this.state.form.name_rayo.trim(),
            number_rayo: parseInt(numero.length === 1 ? numero[0] : numero[1]),
            nationality_rayo: this.state.form.nationality_rayo,
            address_rayo: this.state.form.address_rayo,
            number_license_rayo: this.state.form.number_license_rayo,
            status_rayo: parseInt(this.state.form.status_rayo),
            email_rayo: this.state.form.email_rayo
              .toLowerCase()
              .replace(" ", ""),
            gcm_id_rayo: this.state.form.gcm_id_rayo,
            type_vehicles_rayo: newTiposVehiculo,
            uuid_rayo: this.state.form.uuid_rayo,
            auto_assign_rayo: this.state.form.auto_assign_rayo,
            zones_rayo: {
              communes: comunas,
            },
            latitude_rayo: 0,
            longitude_rayo: 0,
            expiration_date: this.state.form.expiration_date,
            provider_rayo: this.state.form.provider_rayo,
            customer_rayo: newClientes,
            password_rayo: this.state.form.password_rayo,
            vigencia: this.state.form.vigencia,
            backlogActivo: this.state.form.vigenciaBacklog,
            massive_pickup :  this.state.form.massive_pickup,
            sucursal_autoassign : this.state.form.sucursal_autoassign,
            tenant: this.state.form.tenant,
            hammer_id: this.state.form.hammer_id,
          };

          await url.api
            .post("/Rayos/rayo/", rayo)
            .then((response) => {
              if (response.status === 201 || response.status === 200) {
                if (typeof response.data.result.code === "undefined") {
                  $("#modalFormulario").modal("hide");
                  this.getRayos();
                  this.handleCargando(false);
                  Utils.handleAlert(
                    "Rayo ingresado",
                    "El rayo se ha ingresado exitosamente",
                    "Ok"
                  );
                  this.emptyForm();
                } else {
                  this.handleCargando(false);
                  Utils.handleAlert(
                    "Error " + response.data.result.code,
                    response.data.result.err.message,
                    "Ok"
                  );
                }
              } else {
                this.handleCargando(false);
                Utils.handleAlert(
                  "Error " + response.status,
                  "Ha ocurrido un error al ingresar el rayo, vuelva a intentarlo",
                  "Ok"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let title = "Error en acción " + error.response.status;
                if (error.response.status === 412) {
                  title = "Precondición fallida";
                }
                this.handleCargando(false);
                Utils.handleAlert(
                  title,
                  error.response.data.error.detail.error_message,
                  "Ok"
                );
              } else {
                this.handleCargando(false);
                Utils.handleAlert(
                  "Error al realizar la peticion",
                  error.message,
                  "Ok"
                );
              }
            });
        }
      } else if (!cliente) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Campos requeridos",
          "Debe seleccionar al menos un cliente",
          "Ok"
        );
      } else if (!vehiculo) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Campos requeridos",
          "Debe seleccionar un tipo de vehiculo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar un rayo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Rayo?",
      text: "Si confirma, no podrá deshacer la acción y el rayo será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteRayo(key);
      }
    });
  };

  deleteRayo = async (key) => {
    await this.handleCargando(true);
    try {
      const rayo = {
        key: key,
        tenant: this.state.form.tenant,
      };
      let res = await url.api.delete("/Rayos/rayo/", {
        data: rayo,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getRayos();
        this.handleCargando(false);
        Utils.handleAlert(
          "Rayo eliminado",
          "El cliente se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el rayo, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el rayo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyRayo = async () => {
    try {
      if (
        this.state.form.password_rayo.length === 0 ||
        this.state.form.password_rayo.length >= 6
      ) {
        let tiposVehiculo = cloneDeep(this.state.form.type_vehicles_rayo);
        let newTiposVehiculo = [];

        tiposVehiculo.forEach((tipo) => {
          if (tipo.activo) {
            newTiposVehiculo.push({
              idTypeVehicle: tipo._id,
            });
          }
        });

        let comunas = cloneDeep(this.state.form.zones_rayo.communes);
        let clientes = cloneDeep(this.state.form.customer_rayo);
        let newClientes = [];

        comunas.forEach((element) => {
          delete element.__v;
          delete element.id;
        });

        clientes.forEach((tipo) => {
          if (tipo.activo) {
            newClientes.push({
              idCustomer: tipo._id,
            });
          }
        });

        let imagen = this.state.form.image_rayo;
        if (imagen === IconoUsuario) {
          imagen = "";
        }

        let numero = this.state.form.number_rayo.split("+");

        const rayo = {
          key: this.state.form.key,
          code_rayo: this.state.form.code_rayo,
          image_rayo: this.state.form.image_rayo,
          dni_rayo: this.state.form.dni_rayo,
          name_rayo: this.state.form.name_rayo.trim(),
          number_rayo: parseInt(numero.length === 1 ? numero[0] : numero[1]),
          address_rayo: this.state.form.address_rayo,
          nationality_rayo: this.state.form.nationality_rayo,
          number_license_rayo: this.state.form.number_license_rayo,
          email_rayo: this.state.form.email_rayo,
          auto_assign_rayo: this.state.form.auto_assign_rayo,
          status_rayo: parseInt(this.state.form.status_rayo),
          gcm_id_rayo: this.state.form.gcm_id_rayo,
          type_vehicles_rayo: newTiposVehiculo,
          uuid_rayo: this.state.form.uuid_rayo,
          latitude_rayo: this.state.latitude_rayo,
          longitude_rayo: this.state.longitude_rayo,
          expiration_date: this.state.form.expiration_date,
          zones_rayo: {
            communes: comunas,
          },
          provider_rayo: this.state.form.provider_rayo,
          customer_rayo: newClientes,
          password_rayo: this.state.form.password_rayo,
          vigencia: this.state.form.vigencia,
          backlogActivo: this.state.form.vigenciaBacklog,
          massive_pickup : this.state.form.massive_pickup,
          sucursal_autoassign :  this.state.form.sucursal_autoassign,
          tenant: this.state.form.tenant,
          hammer_id: this.state.form.hammer_id,
        };

        console.log ("LO QUE ENVIO ")
        console.log (rayo)

        await url.api
          .put("/Rayos/rayo/", rayo)
          .then((res) => {
            if (res.status === 200) {
              this.getRayos();
              $("#modalFormulario").modal("hide");
              this.handleCargando(false);
              Utils.handleAlert(
                "Rayo modificado",
                "El rayo se ha modificado exitosamente",
                "Ok"
              );
              this.emptyForm();
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error " + res.status,
                "Ha ocurrido un error al modificar el rayo, vuelva a intentarlo",
                "Ok"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              let title = "Error en acción " + error.response.status;
              if (error.response.status === 412) {
                title = "Precondición fallida";
              }
              this.handleCargando(false);
              Utils.handleAlert(
                title,
                error.response.data.error.detail.error_message,
                "Ok"
              );
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error al realizar la peticion",
                error.message,
                "Ok"
              );
            }
          });
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Campos requeridos",
          "La contraseña debe tener un largo minimo de 6 caracteres",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el rayo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  descargarQR = async (id) => {
    this.handleCargando(true);
    await axios({
      method: "get",
      url:
        "https://us-central1-rayo-dev.cloudfunctions.net/qrGen/data/?rayoId=" +
        id,
      headers: [],
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            let img = response.data.fileURL;
            let a = $("<a>")
              .attr("href", img)
              .attr("target", "_black")
              .attr("download", id)
              .appendTo("body");
            a[0].click();
            a.remove();
            break;
          default:
            Utils.handleAlert(
              "Error " + response.status,
              "Ha ocurrido un error no controlado al descargar QR, vuelva a intentarlo",
              "Ok"
            );
            break;
        }
      })
      .catch((e) => {
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado al descargar QR, vuelva a intentarlo",
          "Ok"
        );
      });
    this.handleCargando(false);
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;
    const { classes } = this.props;

    const ImagenRayo = ({ imagen, id }) => {
      return (
        <div className="row m-0">
          <div className="col-12 p-0">
            <img
              src={imagen}
              height="35"
              className="icon-table ml-2"
              alt="rayo"
            />
          </div>
          <div className="col-12 p-0">
            <p
              className="link-style txt-carta mb-0"
              onClick={() => this.descargarQR(id)}
            >
              Generar QR
            </p>
          </div>
        </div>
      );
    };

    const SeleccionarVigencia = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={this.state.form.vigencia ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeVigencia(true)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Activo</div>
              </div>
            </Button>
          </div>
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={!this.state.form.vigencia ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeVigencia(false)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot bg-red"></div>
                </div>
                <div className="col-sm-9 px-0">Inactivo</div>
              </div>
            </Button>
          </div>
        </div>
      );
    };

    const SeleccionarBacklog = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={this.state.form.vigenciaBacklog ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeVigenciaBacklog(true)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Activo</div>
              </div>
            </Button>
          </div>
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={!this.state.form.vigenciaBacklog ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeVigenciaBacklog(false)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot bg-red"></div>
                </div>
                <div className="col-sm-9 px-0">Inactivo</div>
              </div>
            </Button>
          </div>
        </div>
      );
    };



    const SeleccionarWarehouse = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={this.state.form.massive_pickup ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeMassivePickup(true)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Activo</div>
              </div>
            </Button>
          </div>
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={!this.state.form.massive_pickup ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeMassivePickup(false)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot bg-red"></div>
                </div>
                <div className="col-sm-9 px-0">Inactivo</div>
              </div>
            </Button>
          </div>
        </div>
      );
    };


    const SeleccionarAutoAsignacion = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={this.state.form.sucursal_autoassign ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeAutoAsignacion(true)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Activo</div>
              </div>
            </Button>
          </div>
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              variant="contained"
              color={!this.state.form.sucursal_autoassign ? "primary" : "default"}
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeAutoAsignacion(false)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot bg-red"></div>
                </div>
                <div className="col-sm-9 px-0">Inactivo</div>
              </div>
            </Button>
          </div>
        </div>
      );
    };



    const SeleccionarEstado = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <ButtonEstado
              type="button"
              disabled={tipoModal === 1 ? true : false}
              variant="contained"
              color={
                parseInt(this.state.form.status_rayo) === 1
                  ? "primary"
                  : "default"
              }
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeButton(1)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Online</div>
              </div>
            </ButtonEstado>
          </div>
          <div className="form-group mb-0 col-md-6">
            <ButtonEstado
              type="button"
              disabled={tipoModal === 1 ? true : false}
              variant="contained"
              color={
                parseInt(this.state.form.status_rayo) === 2
                  ? "primary"
                  : "default"
              }
              className="w-100 text-roboto-regular"
              onClick={() => this.handleChangeButton(2)}
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot inactivo"></div>
                </div>
                <div className="col-sm-9 px-0">Offline</div>
              </div>
            </ButtonEstado>
          </div>
        </div>
      );
    };

    const SeleccionClientes = ({ clientes }) => {
      return clientes.map((client, index) => {
        return (
          <Button
            variant="contained"
            color={client.activo ? "primary" : "default"}
            className="text-capitalize mr-2 mb-2 text-roboto-regular"
            onClick={() => this.handleClientes(index)}
            key={index}
          >
            {client.name_customer}
          </Button>
        );
      });
    };

    const TipoVehiculos = ({ tipoVehiculos }) => {
      let array = cloneDeep(this.state.tipoVehiculos);
      array.forEach((v) => {
        tipoVehiculos.forEach((r) => {
          if (v._id === r.idTypeVehicle) {
            v.activo = true;
          }
        });
      });

      return array.map((tipo, index) => {
        return (
          <Button
            variant="contained"
            disabled
            className={
              tipo.activo
                ? classes.btnTipoVehiculosActive
                : classes.btnTipoVehiculos
            }
            key={index}
          >
            <img
              src={
                tipo.activo
                  ? tipo.active_icon_type_vehicle
                  : tipo.inactive_icon_type_vehicle
              }
              width="25"
              alt="Icono activo"
            />
          </Button>
        );
      });
    };

    const Vehiculos = ({ vehiculos }) => {
      return vehiculos.map((vehiculo, index) => {
        return (
          <Button
            variant="contained"
            className={
              vehiculo.activo
                ? classes.btnTipoVehiculosActive
                : classes.btnTipoVehiculos
            }
            onClick={() => this.handleVehicle(index)}
            key={index}
          >
            <img
              src={
                vehiculo.activo
                  ? vehiculo.active_icon_type_vehicle
                  : vehiculo.inactive_icon_type_vehicle
              }
              width="25"
              alt="Icono activo"
            />
          </Button>
        );
      });
    };

    const Clientes = ({ clientes }) => {
      if (this.state.custumers === null) {
        return false;
      } else {
        let array = cloneDeep(this.state.custumers);
        array.forEach((v) => {
          clientes.forEach((r) => {
            if (v._id === r.idCustomer) {
              v.activo = true;
            }
          });
        });

        return array.map((client, index) => {
          if (client.activo) {
            return (
              <p className="mb-0" key={index}>
                {"- " + client.name_customer}
              </p>
            );
          } else {
            return "";
          }
        });
      }
    };

    const Comunas = ({ comunas }) => {
      return (
        <select
          required
          className="custom-select"
          name="id_comuna"
          onChange={this.handleSelectComuna}
          value={""}
        >
          <option value="" disabled>
            - Seleccionar Comuna -
          </option>
          {comunas.map(({ id, name_commune }, index) => (
            <option value={id} key={index}>
              {name_commune}
            </option>
          ))}
        </select>
      );
    };

    const ComunasSeleccionadas = ({ comunas }) => {
      return comunas.map((comuna, index) => (
        <div
          className={index === 0 ? "row m-0 w-100" : "row m-0 w-100 mt-2"}
          key={index}
        >
          <div className="col-10 pl-0">
            <input
              type="text"
              name="comunas"
              className="form-control light-disabled"
              defaultValue={comuna.name_commune}
              disabled
            />
          </div>
          <div className="col-2 p-0 d-flex justify-content-end align-items-center">
            <div
              className="square-template back-light-grey d-flex justify-content-center align-items-center cursor-pointer"
              onClick={() => this.handleDeleteSelectComuna(comuna._id)}
            >
              <img
                src={IconoCerrarBlack}
                height="25"
                alt="icono"
                className="mb-0"
              />
            </div>
          </div>
        </div>
      ));
    };

    const Proveedores = ({ proveedores }) => {
      return (
        <select
          required
          className="custom-select"
          name="provider_rayo"
          onChange={this.handleChange}
          value={this.state.form.provider_rayo}
        >
          <option value="" disabled>
            - Seleccionar Proveedor -
          </option>
          {proveedores.map(({ _id, name_provider }, index) => (
            <option value={_id} key={index}>
              {name_provider}
            </option>
          ))}
        </select>
      );
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Rayos
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>

            {AMBIENTE === "testing" ? (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.handleModal(1)}
              >
                Crear nuevo
              </Button>

              
              
            ) : this.state.usuario ? (
              this.state.usuario.email === "hackmonkeys@hackmonkeys.com" ||
              this.state.usuario.email === "oswaldo@rayoapp.com" ||
              

              this.state.usuario.email === "francisca@rayoapp.com" ||
              this.state.usuario.email === "yoselyn@rayoapp.com" ||
              this.state.usuario.email === "ana@rayoapp.com" ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => this.handleModal(1)}
                >
                  Crear nuevo
                </Button>
              ) : (
                false
              )
            ) : (
              false
            )}
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Rayos">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rayos ? (
                  this.state.rayos.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen rayos creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.rayos
                      .filter((rayo) => {
                        let bandera = false;
                        if (
                          rayo.name_rayo
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          rayo.email_rayo
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          rayo._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">
                            <ImagenRayo imagen={row.image_rayo} id={row._id} />
                          </TableCell>
                          <TableCell align="left">{row.name_rayo}</TableCell>
                          <TableCell align="left">
                            {row.email_rayo}
                            <br />
                            {"+ " + row.number_rayo}
                          </TableCell>
                          <TableCell align="left">
                            <TipoVehiculos
                              tipoVehiculos={row.type_vehicles_rayo}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.vigencia} />
                          </TableCell>

                          <TableCell align="left">
                            <Clientes clientes={row.customer_rayo} />
                          </TableCell>

                          <TableCell align="left">
                            <ActiveDot status={row.massive_pickup} />
                          </TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.sucursal_autoassign} />
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " rayos";
              }}
              component="div"
              count={
                this.state.rayos
                  ? this.state.rayos.filter((rayo) => {
                      let bandera = false;
                      if (
                        rayo.name_rayo
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        rayo.email_rayo
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        rayo._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1 ? "Crear Rayo" : "Modificar Rayo"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                      this.getComunas();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarRayo}>
                  <div className="modal-body">
                    <div className="w-100 mb-1">
                      <label className=" d-flex justify-content-start">
                        <img
                          src={this.state.form.image_rayo}
                          width="100"
                          height="100"
                          alt="Rayo"
                          className="icon-table"
                        />
                        <span>
                          <input
                            type="file"
                            id="image_rayo"
                            accept="image/*"
                            name="image_rayo"
                            onChange={this.handleImage}
                          />
                        </span>
                      </label>
                    </div>
                    {tipoModal === 1 ? (
                      false
                    ) : (
                      <div className="w-100 mb-3">
                        <p
                          className="link-style-white pb-0 ml-2"
                          onClick={() => this.descargarQR(this.state.form.key)}
                        >
                          Generar QR
                        </p>
                      </div>
                    )}

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">ID</label>
                        <input
                          disabled={true}
                          required={false}
                          type="text"
                          className="form-control"
                          placeholder="ID"
                          value={this.state.form.key}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Dirección</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="address_rayo"
                          className="form-control"
                          placeholder="Dirección"
                          value={this.state.form.address_rayo}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">DNI</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="dni_rayo"
                          maxLength={13}
                          className="form-control"
                          placeholder="DNI"
                          value={this.state.form.dni_rayo}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nombre Rayo</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="name_rayo"
                          className="form-control"
                          placeholder="Nombre rayo"
                          value={this.state.form.name_rayo}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Teléfono</label>
                        <label className="texto-form-red-light"> *</label>
                        <PhoneInput
                          preferredCountries={["cl", "mx"]}
                          country={"cl"}
                          inputProps={{
                            name: "number_rayo",
                            required: true,
                            type: "text",
                            minLength: 9,
                          }}
                          className="form-control"
                          placeholder="989828918"
                          name="number_rayo"
                          value={this.state.form.number_rayo}
                          onChange={this.handlePhone}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Correo electrónico
                        </label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="email_rayo"
                          className="form-control"
                          placeholder="rayo@gmail.com"
                          value={this.state.form.email_rayo}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nacionalidad</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="nationality_rayo"
                          className="form-control"
                          placeholder="Nacionalidad"
                          value={this.state.form.nationality_rayo}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">N° licencia</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          maxLength={6}
                          name="number_license_rayo"
                          className="form-control"
                          placeholder="N° Licencia"
                          value={this.state.form.number_license_rayo.toUpperCase()}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">
                          Fecha fin de contrato
                        </label>
                        {/* <label className="texto-form-red-light"> *</label> */}
                        <input
                          // required
                          onChange={this.handleChange}
                          type="date"
                          name="expiration_date"
                          className="form-control"
                          placeholder="00-00-0000"
                          value={this.state.form.expiration_date}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1 w-100">
                          Proveedor
                          <label className="texto-form-red-light">*</label>
                        </label>
                        {this.props.proveedores.proveedores == null ? (
                          false
                        ) : (
                          <Proveedores
                            proveedores={this.props.proveedores.proveedores}
                          />
                        )}
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1 w-100">
                          Vehículo
                          <label className="texto-form-red-light">*</label>
                        </label>
                        <Vehiculos
                          vehiculos={this.state.form.type_vehicles_rayo}
                        />
                      </div>

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Estado</label>
                        <label className="texto-form-red-light"> *</label>
                        <SeleccionarEstado />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Password</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          type="text"
                          name="password_rayo"
                          className="form-control"
                          placeholder="Password"
                          value={this.state.form.password_rayo}
                        />
                        <label className="txt-carta mb-0 text-right w-100 text-white">
                          Ingresar al menos 6 caracteres
                        </label>
                      </div>

                      <div className="form-group col-md-4 text-center">
                        <label className="texto-form mb-1 w-100"></label>
                        <FormControlLabel
                          className="white-formlabel"
                          control={
                            <Checkbox
                              name="auto_assign_rayo"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    auto_assign_rayo:
                                      !this.state.form.auto_assign_rayo,
                                  },
                                })
                              }
                              checked={this.state.form.auto_assign_rayo}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ color: "white" }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon color="secondary" />}
                            />
                          }
                          label="Tareas auto-asignables"
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Hammer ID</label>
                        <input
                          required={false}
                          onChange={this.handleChange}
                          type="text"
                          name="hammer_id"
                          className="form-control"
                          placeholder="HammerID"
                          value={this.state.form.hammer_id}
                        />
                      </div>

                      {this.state.usuario ? (
                        this.state.usuario.email ===
                          "hackmonkeys@hackmonkeys.com" ||
                        this.state.usuario.email === "francisca@rayoapp.com" ||

                        this.state.usuario.email === "yoselyn@rayoapp.com" ||
                       
                        this.state.usuario.email === "ana@rayoapp.com" ? (
                          <div className="form-group col-md-4">
                            <label className="texto-form mb-1">Vigencia</label>
                            <label className="texto-form-red-light"> *</label>
                            <SeleccionarVigencia />
                          </div>
                        ) : (
                          false
                        )
                      ) : (
                        false
                      )}

                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Backlog App</label>
                        <SeleccionarBacklog />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4"></div>
                      <div className="form-group col-md-4">
                      <label className="texto-form mb-1">Warehouse</label>
                        <SeleccionarWarehouse />
                      </div>
                      <div className="form-group col-md-4">
                      <label className="texto-form mb-1">AutoAsignación</label>
                        <SeleccionarAutoAsignacion />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-12 mb-0">
                        <label className="texto-form mb-1 w-100">
                          Clientes
                          <label className="texto-form-red-light">*</label>
                        </label>

                        <SeleccionClientes
                          clientes={this.state.form.customer_rayo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modalZonas"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalZonas"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <img src={IconoMapa} height="35" alt="Logo Rayo" />
                  <h4
                    className="modal-title title-text-white ml-3"
                    id="editarModalLabel"
                  >
                    Administrar zonas
                  </h4>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => $("#modalZonas").modal("hide")}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label className="texto-form mb-1">
                        Seleccione comunas asignadas al Rayo
                      </label>
                      {this.props.comunas.comunas === null ? (
                        false
                      ) : (
                        <Comunas comunas={this.props.comunas.comunas} />
                      )}
                    </div>
                  </div>
                  <div className="w-100 row m-0 back-light-grey icon-table p-3">
                    {this.state.form.zones_rayo.communes.length === 0 ? (
                      <p className="text-exo-light color-light-grey mb-0 text-center w-100">
                        - No ha seleccionado comunas -
                      </p>
                    ) : (
                      <ComunasSeleccionadas
                        comunas={this.state.form.zones_rayo.communes}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Cargando cargando={this.state.cargando} />
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarRayo = (event) => {
    this.handleCargando(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
      this.handleCargando(false);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createRayo();
      } else {
        this.modifyRayo();
      }
    }
  };
}

const styles = (theme) => ({
  btnTipoVehiculos: {
    marginRight: "0.4rem !important",
    backgroundColor: "#E0E0E0 !important",
  },
  btnTipoVehiculosActive: {
    marginRight: "0.4rem !important",
    backgroundColor: theme.palette.primary.main + " !important",
  },
});

const mapStateToProps = (store) => {
  return {
    comunas: store.comunas,
    proveedores: store.proveedores,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Rayos)
);
