import React from "react";

import axios from "axios";
import "react-phone-input-2/lib/style.css";
import url from "../../config";
import "../../css/global.css";
import moment from "moment";

import DayPicker from "react-day-picker";
import Cargando from "../../assets/components/cargando/Cargando";
import { Box, Grid, IconButton, Container, Typography, Popper, Button, Select, TextField, MenuItem } from "@material-ui/core";
import IconoArrowBottom from "../../assets/icons/icono-down-white.svg";

import { GoogleMap, withScriptjs, withGoogleMap, Polygon, Marker } from 'react-google-maps';
import { LIGHT_MODE_GOOGLE, ID_NO_ASIGNADO, ID_RECEPCIONBODEGA, ID_RETIROCLIENTE, ID_ASIGNADO, ID_ACEPTADO, ID_EN_PROGRESO,
  ID_INICIADO, ID_CANCELADO, ID_RECHAZADO, ID_EXITOSO, ID_FALLIDO, ID_DEVUELTO } from '../../assets/utils/Helpers';

const WrappedMap = withScriptjs(
  withGoogleMap((props) => (
      <GoogleMap
          onTilesLoaded={() => {}}
          defaultZoom={10}
          zoom={props.zoom}
          defaultCenter={{ lat: -33.4473526, lng: -70.6379711 }}
          options={{
              styles: LIGHT_MODE_GOOGLE,
          }}
      >
        {
          props.polygons.map((item) => {
            return (
              <Polygon
                key={'polygon-' + item.zone_id}
                path={item.polygon}
                visible={true}
                options={{
                  strokeColor: "#0000FF",
                  strokeOpacity: 1,
                  strokeWeight: 0.5,
                  fillColor: "#0000FF",
                  fillOpacity: 0.35}}
              >
              </Polygon>
            );
          })
        }
        {
          props.polygons.map((item, index) => {
            return (
              <Marker
                key={'marker-' + item.zone_id}
                position={item.center}
                label={item.count.toString()}
              >
              </Marker>
            );
          })
        }
      </GoogleMap>
  ))
);

class Zones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      polygons: [],
      customers: [],
      allStatus: [
        { id: ID_NO_ASIGNADO, name: "No Asignado", class: "dot back-medium-grey" },
        { id: ID_ASIGNADO, name: "Asignado", class: "dot dot-asignado" },
        { id: ID_ACEPTADO, name: "Aceptado", class: "dot dot-aceptado" },
        { id: ID_INICIADO, name: "Iniciado", class: "dot dot-iniciado" },
        { id: ID_EN_PROGRESO, name: "En Progreso", class: "dot dot-en-progreso" },
        { id: ID_EXITOSO, name: "Exitoso", class: "dot dot-exitoso" },
        { id: ID_FALLIDO, name: "Fallido", class: "dot dot-haFallado" },
        { id: ID_RECHAZADO, name: "Rechazado", class: "dot dot-rechazado" },
        { id: ID_CANCELADO, name: "Cancelado", class: "dot dot-cancelado" },
        { id: ID_DEVUELTO, name: "Devuelto", class: "dot dot-devuelto" },
        { id: ID_RETIROCLIENTE, name: "Retiro Cliente", class: "dot dot-retirocliente" },
        { id: ID_RECEPCIONBODEGA, name: "Recepcionado Rayo", class: "dot dot-bodegarayo" }
      ],
      activeMarker: null,
      initDate: new Date(),
      endDate: new Date(),
      isInitDayPickerOpen: false,
      isEndDayPickerOpen: false,
      initTarget: undefined,
      endTarget: undefined,
      cargando: false,
      selectedCustomer: "SelectAllCustomers",
      selectedStatus: "SelectAllStatus"
    };
  }

  componentDidMount = async () => {

    let initDate = moment(new Date()).format().split("T")[0] + "T00:00:00Z";
    let endDate = moment(new Date().setHours(23, 59, 59, 999)).format("YYYY-MM-DDTHH:mm:ss") + "Z";

    let response = await axios.get(`${url.host}/Zones/tasks?initDate=${initDate}&endDate=${endDate}`)

    let customers = await axios.get(`${url.host}/Customers/customers?includeTest=false&includeInactives=false`)

    this.setState({
      customers: customers.data.map((customer) => { return { id: customer._id, name: customer.name_customer } }),
      polygons: response.data.data
    })
  }

  render() {
    const { initDate, endDate } = this.state;
    
    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Tareas por Zona
          </Typography>

          <Grid container md={12}>
            <Grid item md={2}>
              <Grid md={12}>
                <Typography variant="h6" gutterBottom>
                  Desde:
                </Typography>
              </Grid>
              <Grid container md={12}>
                <Grid item md={8}>
                  <input type="text" style={{ width: "100%", height: 30, padding: 10 }} value={moment(this.state.initDate).format().split("T")[0]} readOnly />
                </Grid>
                <Grid item md={4}>
                  <IconButton 
                    style={{ backgroundColor: "#162335", width: "auto", height: "100%", borderRadius: 2, padding: 2, marginLeft: -2 }}
                    onClick={(ev) => { 
                      this.setState({ 
                        initTarget: ev.currentTarget,
                        isInitDayPickerOpen: !this.state.isInitDayPickerOpen,
                        isEndDayPickerOpen: false
                      }) 
                    }} >
                    <img
                      src={IconoArrowBottom}
                      className="icon-arrow"
                      alt="IconoArrowBottom"
                    />
                  </IconButton>
                  <Popper id="popperInitDate" open={this.state.isInitDayPickerOpen} anchorEl={this.state.initTarget} placement="bottom">
                    <Box style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}>
                      <br />
                      <DayPicker
                        locale="es-CL"
                        mode="single"
                        className="Selectable"
                        selectedDays={[initDate, { from: initDate, to: initDate }]}
                        modifiers={{ start: initDate, end: initDate }}
                        onDayClick={(selectedDate) => { 
                          this.setState({
                            initDate: selectedDate
                          })
                        }}
                      />
                      <br />
                      <br />
                    </Box>
                  </Popper>
                </Grid>
              </Grid>

            </Grid>
            <Grid item md={2}>
              <Grid md={12}>
                <Typography variant="h6" gutterBottom>
                  Hasta:
                </Typography>
              </Grid>
              <Grid container md={12}>
                <Grid item md={8}>
                  <input type="text" style={{ width: "100%", height: 30, padding: 10 }} value={moment(this.state.endDate).format().split("T")[0]} readOnly />
                </Grid>
                <Grid item md={4}>
                  <IconButton 
                    style={{ backgroundColor: "#162335", width: "auto", height: "100%", borderRadius: 2, padding: 2, marginLeft: -2 }}
                    onClick={(ev) => { 
                      this.setState({
                        endTarget: ev.currentTarget, 
                        isEndDayPickerOpen: !this.state.isEndDayPickerOpen,
                        isInitDayPickerOpen: false
                      }) 
                    }} >
                    <img
                      src={IconoArrowBottom}
                      className="icon-arrow"
                      alt="IconoArrowBottom"
                    />
                  </IconButton>
                  <Popper id="popperEndDate" open={this.state.isEndDayPickerOpen} anchorEl={this.state.endTarget} placement="bottom">
                    <Box style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}>
                      <br />
                      <DayPicker
                        locale="es-CL"
                        mode="single"
                        className="Selectable"
                        selectedDays={[endDate, { from: endDate, to: endDate }]}
                        modifiers={{ start: endDate, end: endDate }}
                        onDayClick={(selectedDate) => { 
                          this.setState({
                            endDate: selectedDate
                          })
                        }}
                      />
                      <br />
                      <br />
                    </Box>
                  </Popper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid md={12}>
                <Typography variant="h6" gutterBottom>
                  Customer:
                </Typography>
              </Grid>
              <Grid md={12}>
                <Select 
                  style={{ borderRadius: 2, border: "1px solid #000000", paddingLeft: 10, paddingRight: 10 }}
                  value={this.state.selectedCustomer} 
                  onChange={(event) => {
                    this.setState({
                      selectedCustomer: event.target.value
                    })
                  }}
                >
                  <MenuItem key={"SelectAllCustomers"} value={"SelectAllCustomers"} style={{paddingLeft: 10, paddingRight: 10}}>Todos</MenuItem>
                  {
                    this.state.customers && this.state.customers.map((customer) => {
                      return (<MenuItem key={customer.id} value={customer.id} style={{paddingLeft: 10, paddingRight: 10}}>{customer.name}</MenuItem>)
                    })
                  }
                </Select>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid md={12}>
                <Typography variant="h6" gutterBottom>
                  Estado:
                </Typography>
              </Grid>
              <Grid md={12}>
                <Select 
                  style={{ borderRadius: 2, border: "1px solid #000000", paddingLeft: 10, paddingRight: 10 }}
                  value={this.state.selectedStatus} 
                  onChange={(event) => {
                    this.setState({
                      selectedStatus: event.target.value
                    })
                  }}
                >
                  <MenuItem key={"SelectAllStatus"} value={"SelectAllStatus"} style={{paddingLeft: 10, paddingRight: 10}}>Todos</MenuItem>
                  {
                    this.state.allStatus && this.state.allStatus.map((status) => {
                      return (<MenuItem key={status.id} value={status.id} style={{paddingLeft: 10, paddingRight: 10}}>
                        <Grid container md={12} style={{paddingLeft: 10, paddingRight: 10}}>
                          <Grid item md={2}>
                            <div className={status.class} style={{marginTop: 4, marginRight: 5}}></div>
                          </Grid>
                          <Grid item md={10}>
                            {status.name}
                          </Grid>
                        </Grid>
                      </MenuItem>)
                    })
                  }
                </Select>
              </Grid>
            </Grid>
            <Grid container md={4} alignItems="flex-end">
              <Button 
                color="secondary"
                variant="contained"
                onClick={
                  async () => {
                    let initDate = moment(this.state.initDate).format().split("T")[0] + "T00:00:00Z";
                    let endDate = moment(this.state.endDate.setHours(23, 59, 59, 999)).format("YYYY-MM-DDTHH:mm:ss") + "Z";
                
                    this.setState({ cargando: true })
                    let response = await axios.get(`${url.host}/Zones/tasks?initDate=${initDate}&endDate=${endDate}${this.state.selectedCustomer !== "SelectAllCustomers" ? `&customerId=${this.state.selectedCustomer}`: "" }${this.state.selectedStatus !== "SelectAllStatus" ? `&statusId=${this.state.selectedStatus}`: "" }`)
                    this.setState({
                      polygons: response.data.data,
                      cargando: false
                  })
                }}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          <br/>

          <Box width="100%" display="flex" mb={3}>
            <WrappedMap
              zoom={10}
              context={this}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg`}
              loadingElement={<div style={{ height: `80vh`, width: `100%` }} />}
              containerElement={<div style={{ height: `80vh`, width: `100%` }} />}
              mapElement={<div style={{ height: `80vh`, width: `100%` }} />}
              polygons={this.state.polygons}
              infoWindowIndex={this.state.activeMarker}
            />
          </Box>

        </Container>
        <Cargando cargando={this.state.cargando} />
      </Box>
    );
  }
}

export default Zones;
