import React from "react";

import moment from "moment";
import Swal from "sweetalert2";
import ListaTareasRayo from "../../components/lists/ListaTareasRayo";
import IconoPhone from "../../icons/icono-phone-black.svg";
import EmailIcon from "@material-ui/icons/Email";
import BotonesTareasSeleccionadas from "../home/BotonesTareasSeleccionadas";
import * as rayosAccion from "../../../store/actions/Rayos";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CloseIcon from "@material-ui/icons/Close";

import TextFieldOutlined from "../../components/custom/TextFieldOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RoomIcon from "@material-ui/icons/Room";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BusinessIcon from "@material-ui/icons/Business";

import Utils from "../../utils/Utils";
import ListaRutasRayo from "../lists/ListaRutasRayo";

class DetalleRayo extends React.Component {
  handlePanels = (panel) => {
    this.props.handleState("optimizarBacklog", !this.props.optimizar);
  };

  optimizarTareas = async () => {
    if (this.props.optimizar === false) {
      Swal.fire({
        title: "¿Optimizar tareas?",
        text: "Si confirma, las tareas seleccionadas se optimizarán, por lo que estas serán re-organizadas de forma permanente",
        imageUrl:
          "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
        showCancelButton: true,
        customClass: {
          image: "imageSweetAlert",
          title: "titleSweetAlert",
          content: "textSweetAlert",
          confirmButton: "buttonSweetAlert",
          cancelButton: "btn-secondary",
        },
        confirmButtonText: "Si, optimizar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          this.props.handleCargando(true);
          try {
            const { dispatch } = this.props;
            let optimizar = rayosAccion.tasksOptimization(
              this.props.rayo,
              this.props.tareasRayo
            );
            await dispatch(optimizar);
          } catch (error) { }
          this.handlePanels("optimizar");
          this.props.handleCargando(false);
        }
      });
    } else {
      this.handlePanels("optimizar");
    }
  };

  handleAsignar = (rayos) => {
    this.props.handleAsignarBacklog(rayos);
    this.props.handleState("asignarBacklog", true);
  };

  handleSelectRoute = (item) => {
    this.props.handleState("rutaSeleccionada", item);
    this.props.handleState("detalleRayo", "3");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={Utils.handleVistasVisibles(this.props.panelRayos)}>
        <div
          className={clsx(
            "w-100 alto-dark d-flex align-items-center",
            classes.bgPrimary
          )}
        >
          <div className="row m-0 w-100">
            <div className="col-11 pr-0 d-flex align-items-center">
              <h5
                className={clsx(
                  "mb-0 text-roboto-regular",
                  classes.textBgPrimary
                )}
              >
                RAYOS / {this.props.rayo.code_rayo}
              </h5>
            </div>
            <div className="col-1 pl-0 d-flex align-items-center justify-content-end">
              <IconButton
                aria-label="cerrar"
                className="p-2"
                onClick={() => {
                  this.props.handlePanels("detalleRayos");
                  this.props.handleCenter("cerrar", []);
                  this.props.handlePanels("panelRayos");
                  if (this.props.tareasRayo !== null) {
                    this.props.cleanSelected();
                  }
                  this.props.handleState("detalleRayo", "0");
                  this.props.handleState("asignarBacklog", false);
                  this.props.handleState("rayoCoordenadas", []);
                  this.props.handleState("optimizarBacklog", false);
                  if (this.props.rutaSeleccionada != null && this.props.rutaSeleccionada != undefined) {
                    this.props.handleState("rutaSeleccionada", null);
                  }
                  this.props.vaciarBacklog();
                }}
              >
                <CloseIcon className={classes.textBgPrimary} />
              </IconButton>
            </div>
          </div>
        </div>
        <Tabs
          value={this.props.detalleRayo}
          onChange={(e, newValue) => {
            this.props.handleState("detalleRayo", newValue)
            if (this.props.rutaSeleccionada != null && this.props.rutaSeleccionada != undefined) {
              this.props.handleState("rutaSeleccionada", null);
            }
          }
          }
          aria-label="wrapped label tabs example"
          className="w-100 tabs-task"
        >
          <Tab
            value={"0"}
            label={"Detalles"}
            {...Utils.a11yProps("0")}
            className="tab-rayos-home"
          />
          <Tab
            value={"1"}
            label={"Tareas"}
            {...Utils.a11yProps("1")}
            className="tab-rayos-home"
          />
          <Tab
            value={"2"}
            label={"Rutas"}
            {...Utils.a11yProps("2")}
            className="tab-rayos-home"
          />
        </Tabs>
        <div
          role="tabpanel"
          hidden={this.props.detalleRayo !== "0"}
          id={"wrapped-tabpanel-0"}
          aria-labelledby={"wrapped-tab-0"}
        >
          {this.props.detalleRayo === "0" && (
            <Box className="paper">
              <div
                className="ver-copiado-gt hidden"
                id={"detalle-" + this.props.rayo._id}
              >
                <label>¡Copiado!</label>
              </div>
              <div className="row m-0 py-2 px-3 mb-3">
                <div className="col-5 pr-0">
                  <p className="mb-0 sub-txt-carta text-roboto-regular text-center">
                    {this.props.rayo.status_rayo === 2
                      ? "Fuera de servicio"
                      : "En servicio"}
                  </p>
                </div>
                <div className="col-2 p-0 d-flex justify-content-center">
                  <AntSwitch
                    checked={this.props.rayo.status_rayo === 2 ? false : true}
                    onChange={() =>
                      this.props.statusRayo(
                        this.props.rayo._id,
                        this.props.rayo.status_rayo
                      )
                    }
                    name="checkedC"
                  />
                </div>
                <div className="col-5 pl-0">
                  <p className="mb-0 sub-txt-carta text-roboto-regular text-center">
                    {this.props.rayo.status_rayo === 1
                      ? "Rayo activo"
                      : this.props.rayo.status_rayo === 3
                        ? "Rayo ocupado"
                        : "Rayo inactivo"}
                  </p>
                </div>
              </div>
              <div className="row m-0 py-2 px-3 mb-3">
                <div className="col-3 pr-0 text-right">
                  <img
                    src={this.props.rayo.image_rayo}
                    height="50"
                    alt="user"
                    className="img-rayo"
                  />
                </div>
                <div className="col-9">
                  <h5 className="mb-0 text-left">
                    {this.props.rayo.name_rayo}
                  </h5>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right">
                  <BusinessIcon style={{ color: "black" }} fontSize="small" />
                </div>
                <div className="col-9">
                  <Typography variant="body1">Proveedor</Typography>
                  <Typography
                    variant="body1"
                    className="pl-2"
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                    }}
                  >
                    {this.props.proveedores && this.props.rayo.provider_rayo
                      ? this.props.proveedores.map((element, i) =>
                        element._id === this.props.rayo.provider_rayo
                          ? element.name_provider
                          : false
                      )
                      : false}
                  </Typography>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right">
                  <img src={IconoPhone} height="20" alt="phone" />
                </div>
                <div className="col-9">
                  <Typography variant="body1">Teléfono</Typography>
                  <Typography
                    variant="body1"
                    className="cursor-pointer pl-2"
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                    }}
                    onClick={() =>
                      Utils.justCopyText2(
                        "detalle-" + this.props.rayo._id,
                        this.props.rayo.number_rayo
                      )
                    }
                  >
                    {this.props.rayo.number_rayo}
                  </Typography>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right">
                  <EmailIcon style={{ color: "black" }} fontSize="small" />
                </div>
                <div className="col-9">
                  <Typography variant="body1">Email</Typography>
                  <Typography
                    variant="body1"
                    className="cursor-pointer pl-2"
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                    }}
                    onClick={() =>
                      Utils.justCopyText2(
                        "detalle-" + this.props.rayo._id,
                        this.props.rayo.email_rayo
                      )
                    }
                  >
                    {this.props.rayo.email_rayo}
                  </Typography>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right text-exo-bold">
                  <ContactPhoneIcon
                    style={{ color: "black" }}
                    fontSize="small"
                  />
                </div>
                <div className="col-9">
                  <Typography variant="body1">UUID</Typography>
                  <Typography
                    variant="body1"
                    className="cursor-pointer pl-2 text-truncate"
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: 4,
                    }}
                    onClick={() =>
                      Utils.justCopyText2(
                        "detalle-" + this.props.rayo._id,
                        this.props.rayo.uuid_rayo
                      )
                    }
                  >
                    {this.props.rayo.uuid_rayo}
                  </Typography>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right text-exo-bold">
                  <PhoneAndroidIcon
                    style={{ color: "black" }}
                    fontSize="small"
                  />
                </div>
                <div className="col-9">
                  <Typography variant="body1">App Version</Typography>
                  <Typography
                    variant="body1"
                    className="pl-2"
                    style={{ backgroundColor: "#f2f2f2", borderRadius: 4 }}
                  >
                    {this.props.rayo.app_version ? (
                      this.props.rayo.app_version
                    ) : (
                      <em>-No se encuentra registro-</em>
                    )}
                  </Typography>
                </div>
              </div>

              <div className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right text-exo-bold">
                  <RoomIcon style={{ color: "black" }} fontSize="small" />
                </div>
                <div className="col-9">
                  <Typography variant="body1">Rayo en el mapa</Typography>
                  <Box
                    bgcolor="secondary.main"
                    color="secondary.contrastText"
                    borderRadius="4px"
                    pl="0.5rem"
                    className="cursor-pointer"
                    onClick={this.props.centerMapRayo}
                  >
                    <b>Buscar rayo en el mapa</b>
                  </Box>
                </div>
              </div>

              {/* <div className="d-flex justify-content-center mt-4">
            <Button
              type="button"
              className="btn-primary-rayo"
              onClick={this.props.centerMapRayo}
              variant="contained"
              color="secondary"
            >
              Buscar rayo en mapa{" "}
            </Button>
          </div> */}

              <Box className="row m-0 py-2 px-3">
                <div className="col-3 pr-0 text-right text-exo-bold">
                  <PhoneAndroidIcon
                    style={{ color: "black" }}
                    fontSize="small"
                  />
                </div>
                <div className="col-9">
                  <Typography variant="body1">Inicios de sesión</Typography>
                  <Accordion className={classes.accordion}>
                    {this.props.rayo.device_locations.length === 0 ? (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box>
                          <Typography variant="subtitle2">
                            <b>Último inicio de sesión:</b>
                          </Typography>
                          <Typography variant="body1">
                            <em>- No se han registrado datos -</em>
                          </Typography>
                        </Box>
                      </AccordionSummary>
                    ) : (
                      false
                    )}
                    {this.props.rayo.device_locations.map((element, i) =>
                      i === 0 ? (
                        <AccordionSummary
                          key={"AccordionSummary-" + i.toString()}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box>
                            <Typography variant="subtitle2">
                              <b>Último inicio de sesión:</b>
                            </Typography>
                            <Typography variant="body1">
                              {element.model_cellphone}
                            </Typography>
                            <Typography variant="body1">
                              {element.operating_system}
                            </Typography>
                            <Typography variant="body1">
                              {moment(element.last_location_time)
                                .utcOffset("+00")
                                .format("DD-MM-YYYY HH:mm")}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                      ) : (
                        false
                      )
                    )}

                    <AccordionDetails style={{ padding: "8px 0px 16px 16px" }}>
                      <Box width="100%">
                        {this.props.rayo.device_locations.length !== 0 ? (
                          <>
                            <hr className="mt-0 mb-1 w-100" />
                            <Typography variant="subtitle2">
                              <b>Otros inicios de sesión:</b>
                            </Typography>
                          </>
                        ) : (
                          false
                        )}

                        {this.props.rayo.device_locations.map((element, i) =>
                          i !== 0 ? (
                            <div key={i}>
                              {i === 1 ? (
                                false
                              ) : (
                                <hr className="mt-1 mb-1 w-100" />
                              )}
                              <Typography key={"model_cellphone-" + i.toString()} variant="body1">
                                {element.model_cellphone}
                              </Typography>
                              <Typography key={"operating_system-" + i.toString()} variant="body1">
                                {element.operating_system}
                              </Typography>
                              <Typography key={"last_location_time-" + i.toString()} variant="body1">
                                {moment(element.last_location_time)
                                  .utcOffset("+00")
                                  .format("DD-MM-YYYY HH:mm")}
                              </Typography>
                            </div>
                          ) : (
                            false
                          )
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={this.props.detalleRayo !== "1"}
          id={"wrapped-tabpanel-1"}
          aria-labelledby={"wrapped-tab-1"}
        >
          {this.props.detalleRayo === "1" && (
            <Box
              className={
                this.props.seleccionTareas
                  ? "paper-mini"
                  : "cargando-paneles-rayo"
              }
            >
              {this.props.tareasRayo === null ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <CircularProgress color="primary" />
                </div>
              ) : this.props.tareasRayo.length === 0 ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <div className="w-100">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      No se encuentran tareas en el Backlog del Rayo
                    </p>
                    <p className="w-100 text-center text-exo-light color-light-grey">
                      <strong>{this.props.rayo.name_rayo}</strong>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="p-relative">
                  <div id="info-box-optimizar" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      Guardando nuevo orden de tareas...
                    </p>
                  </div>
                  <div id="info-box-optimizado" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      ¡Cambios guardados!
                    </p>
                  </div>
                  <div className="row m-0 p-2">
                    <div style={{ width: 120, display: "inline-flex" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.seleccionTareas}
                            onClick={() => {
                              if (this.props.seleccionTareas) {
                                this.props.cleanSelected();
                              } else {
                                this.props.checkAll(this.props.filtro);
                              }
                            }}
                            name="checkedA"
                            color="primary"
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#9999FF" }}
                              />
                            }
                          />
                        }
                        className="checkbox-rayo m-0"
                        label="Seleccionar todo"
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 120px)",
                        display: "inline-flex",
                      }}
                    >
                      <TextFieldOutlined
                        size="small"
                        name="filtro_tareas_rayo"
                        placeholder="Buscar tareas"
                        value={this.props.filtro}
                        onChange={this.props.handleChange}
                        variant="outlined"
                        style={{ minWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <ListaTareasRayo
                    clientes={this.props.clientes}
                    tasks={this.props.tareasRayo}
                    filtro_tareas={this.props.filtro}
                    handleCheck={this.props.handleCheck}
                    seleccionTareas={this.props.seleccionTareas}
                    showButtons={this.props.seleccionTareas}
                  />
                </div>
              )}
              {this.props.seleccionTareas ? (
                <BotonesTareasSeleccionadas
                  handleAsignarRayo={this.handleAsignar}
                  handlePanels={this.optimizarTareas}
                  optimizar={this.props.optimizar}
                  backlog={true}
                  rayos={this.props.rayos}
                  vista={"backlog"}
                />
              ) : (
                false
              )}
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={this.props.detalleRayo !== "2"}
          id={"wrapped-tabpanel-2"}
          aria-labelledby={"wrapped-tab-2"}
        >
          {this.props.detalleRayo === "2" && (
            <Box
              className={
                this.props.seleccionTareas
                  ? "paper-mini"
                  : "cargando-paneles-rayo"
              }
            >
              {this.props.rutasRayo === null || this.props.rutasRayo === undefined ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <CircularProgress color="primary" />
                </div>
              ) : this.props.rutasRayo.length == 0 ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <div className="w-100">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      No se encuentran rutas para este usuario.
                    </p>
                    <p className="w-100 text-center text-exo-light color-light-grey">
                      <strong>{this.props.rayo.name_rayo}</strong>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="p-relative">
                  <div id="info-box-optimizar" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      Guardando nuevo orden de tareas...
                    </p>
                  </div>
                  <div id="info-box-optimizado" className="d-none">
                    <p className="w-100 text-center text-exo-light color-light-grey mb-0">
                      ¡Cambios guardados!
                    </p>
                  </div>
                  <div className="row m-0 p-2">
                    <div
                      style={{
                        width: "calc(100%)",
                        display: "inline-flex",
                      }}
                    >
                      <TextFieldOutlined
                        size="small"
                        name="filtro_tareas_rayo"
                        placeholder="Buscar tareas"
                        value={this.props.filtro}
                        onChange={this.props.handleChange}
                        variant="outlined"
                        style={{ minWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <ListaRutasRayo
                    clientes={this.props.clientes}
                    tasks={this.props.tareasRayo}
                    routes={this.props.rutasRayo}
                    filtro_tareas={this.props.filtro}
                    handleCheck={this.props.handleCheck}
                    seleccionTareas={this.props.seleccionTareas}
                    showButtons={this.props.seleccionTareas}
                    handleClick={this.handleSelectRoute}
                  />
                </div>
              )}
            </Box>
          )}
        </div>
        <div
          role="tabpanel"
          hidden={this.props.detalleRayo !== "3"}
          id={"wrapped-tabpanel-3"}
          aria-labelledby={"wrapped-tab-3"}
        >
          {this.props.detalleRayo === "3" && (
            <Box
              className={
                this.props.seleccionTareas
                  ? "paper-mini"
                  : "cargando-paneles-rayo"
              }
            >
              {this.props.rutaSeleccionada === null || this.props.rutaSeleccionada === undefined ? (
                <div className="cargando-paneles-rayo d-flex justify-content-center align-items-center">
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <div className="p-relative">
                  <div className="m-2">
                    <h6>Ruta {this.props.rutaSeleccionada.code_route} ({this.props.rutaSeleccionada.tasks.length})</h6>
                  </div>
                  <div className="row m-0 p-2">
                    <div style={{ width: 120, display: "inline-flex" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.seleccionTareas}
                            onClick={() => {
                              if (this.props.seleccionTareas) {
                                this.props.cleanSelected();
                              } else {
                                this.props.checkAll(this.props.filtro);
                              }
                            }}
                            name="checkedA"
                            color="primary"
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ color: "#9999FF" }}
                              />
                            }
                          />
                        }
                        className="checkbox-rayo m-0"
                        label="Seleccionar todo"
                      />
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 120px)",
                        display: "inline-flex",
                      }}
                    >
                      <TextFieldOutlined
                        size="small"
                        name="filtro_tareas_rayo"
                        placeholder="Buscar tareas"
                        value={this.props.filtro}
                        onChange={this.props.handleChange}
                        variant="outlined"
                        style={{ minWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <ListaTareasRayo
                    clientes={this.props.clientes}
                    tasks={this.props.rutaSeleccionada.tasks}
                    filtro_tareas={this.props.filtro}
                    handleCheck={this.props.handleCheck}
                    seleccionTareas={this.props.seleccionTareas}
                    showButtons={this.props.seleccionTareas}
                  />
                </div>
              )}
            </Box>
          )}
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  bgPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  textBgPrimary: {
    color: theme.palette.primary.contrastText + " !important",
  },
  accordion: {
    boxShadow: "none !important",
    borderTop: "none",
    minHeight: "0px !important",
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px 0.5rem",
      minHeight: "0px !important",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 0",
    },
    "& .MuiButtonBase-root": {
      padding: "0px 7px 0px 0px !important",
      height: "auto !important",
    },
    "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd": {
      padding: "0px 7px 0px 0px !important",
      height: "auto !important",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0 0",
    },
  },
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#1FC600",
        borderColor: "#1FC600",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default withStyles(styles, { withTheme: true })(DetalleRayo);
