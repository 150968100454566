import React from "react";
import DayPicker from "react-day-picker";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";

import "font-awesome/css/font-awesome.min.css";
import "react-day-picker/lib/style.css";
import "../../../css/calendar.css";
import "./Layout.css";

import * as actionGrupoTareas from "../../../store/actions/GrupoTareas";
import * as actionClientes from "../../../store/actions/Clientes";
import * as rayosAccion from "../../../store/actions/Rayos";
import * as proveedoresAccion from "../../../store/actions/Proveedores";

import Cargando from "../../../assets/components/cargando/Cargando";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  CssBaseline,
  Drawer,
} from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { IconButton, Slider, Toolbar, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RayoLogo from "../../../assets/icons/logo-rayo-white.svg";
import IconoMapa from "../../../assets/icons/icono-map-white.svg";
import IconoHome from "../../../assets/icons/icono-home-white.svg";
import IconoMenu from "../../../assets/icons/icono-menu.svg";
import IconoLista from "../../../assets/icons/icono-lista-white.svg";
import IconoArrowBottom from "../../../assets/icons/icono-down-white.svg";
import IconoCalendar from "../../../assets/icons/icono-calendar-white.png";
import IconoUsuario from "../../../assets/icons/icono-usuario-grey.png";
import IconoArrowUp from "../../../assets/icons/icono-up-white.png";
import IconoBag from "../../../assets/icons/icono-bag-white.svg";
import IconoPC from "../../../assets/icons/icono-pc-white.svg";
import IconoLink from "../../../assets/icons/bar-chart.svg";
import Utils from "../../../assets/utils/Utils";
import {
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  MONTHS,
  VERSION,
  EPA,
  ADMIN,
} from "../../../assets/utils/Helpers";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,

      open: false,
      openAdmin: false,
      openLinks: false,
      openUsuario: false,
      openCalendar: false,
      positionCalendar: {},

      mobileMoreAnchorEl: null,
      drawerMobile: false,
      anchorEl: null,

      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      selectedCliente: "",
      selectedProveedor: "",
      selectedRayo: "",

      usuario: null,
      cargando: false,

      from: undefined,
      to: undefined,
    };
    this._isMounted = false;
  }

  componentDidMount = async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      usuario: user,
    });
    this.renderFiltros();
    this.cargaInicial();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  cargaInicial = async () => {
    const { dispatch } = this.props;

    if (
      this.props.data.match.path === "/Home/Mapa" ||
      this.props.data.match.path === "/Home/Lista"
    ) {
      let obtenerRayos = rayosAccion.obtenerRayos([], true);
      let obtenerClientes = actionClientes.obtenerClientes();
      let obtenerProveedores = proveedoresAccion.obtenerProveedores();
      try {
        dispatch(obtenerRayos);
        dispatch(obtenerClientes);
        dispatch(obtenerProveedores);
      } catch (err) {
        console.log(err);
      }
    }
  };

  renderFiltros = () => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
    if (filtrosGlobales === null) {
      let hoy = new Date();
      let hoyFin = new Date().setHours(23, 59, 59, 999);
      hoyFin = moment(hoyFin).format();
      hoy = moment(hoy).subtract(1, "hours").format();

      let fechaFinSplit = hoyFin.split("T");
      let fechaSplit = hoy.split("T");

      let horaFinSplit = fechaFinSplit[1].split(":");
      let horaSplit = fechaSplit[1].split(":");
      this.setState({
        selectedDays: [
          new Date(fechaSplit[0] + "T00:00:00"),
          new Date(fechaFinSplit[0] + "T00:00:00"),
        ],
        selectedDaysOrder: [
          new Date(fechaSplit[0] + "T00:00:00"),
          new Date(fechaFinSplit[0] + "T00:00:00"),
        ],
        selectedHours: [parseInt(horaSplit[0]), parseInt(horaFinSplit[0])],
        from: new Date(fechaSplit[0] + "T00:00:00"),
        to: new Date(fechaFinSplit[0] + "T00:00:00"),
      });
    } else {
      let selectedDays = ["", ""];
      let selectedHours = [0, 0];
      filtrosGlobales[1].forEach((filtro, i) => {
        if (filtro.data !== null) {
          if (filtro.filter === "initDate") {
            selectedDays[0] = new Date(filtro.data.split("T")[0] + "T00:00:00");
            selectedHours[0] = parseInt(
              filtro.data.split("T")[1].split(":")[0]
            );
            this.setState({
              selectedDays: selectedDays,
              selectedDaysOrder: selectedDays,
              selectedHours: selectedHours,
              from: selectedDays[0],
              to: selectedDays[1],
            });
          } else if (filtro.filter === "endDate") {
            selectedDays[1] = new Date(filtro.data.split("T")[0] + "T00:00:00");
            if (filtro.data.split("T")[1] === "23:59:59Z") {
              selectedHours[1] = 24;
            } else {
              selectedHours[1] = parseInt(
                filtro.data.split("T")[1].split(":")[0]
              );
            }
            this.setState({
              selectedDays: selectedDays,
              selectedDaysOrder: selectedDays,
              selectedHours: selectedHours,
              from: selectedDays[0],
              to: selectedDays[1],
            });
          } else if (filtro.filter === "idRayo") {
            this.setState({
              selectedRayo: filtro.data,
            });
          }
        }
      });
    }
  };

  handleDayClick = async (day, { selected }) => {
    const { selectedDays } = this.state;

    if (selected) {
      // const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      const selectedIndex = selectedDays.findIndex(
        (selectedDay) =>
          moment(selectedDay).utcOffset("+00").format("DD-MM-YYYY") ===
          moment(day).utcOffset("+00").format("DD-MM-YYYY")
      );
      if (selectedIndex >= 0) {
        await selectedDays.splice(selectedIndex, 1);
        selectedDays.push(selectedDays[0]);
      } else {
        selectedDays.splice(0, 1);
        selectedDays.push(day);
      }
    } else {
      if (selectedDays.length === 2) {
        selectedDays.splice(0, 1);
      }
      selectedDays.push(day);
    }

    let newFechas = [];
    newFechas.push(new Date(Math.min(...selectedDays)));
    newFechas.push(new Date(Math.max(...selectedDays)));

    let totalDiasSeleccionados =
      moment(newFechas[1]).diff(moment(newFechas[0]), "days") + 1;

    let maxDias = 3;
    if (this.props.data.location.pathname === "/Home/Lista" || this.props.data.location.pathname === "/home/lista") {
      maxDias = 91;
    }

    if (totalDiasSeleccionados <= maxDias) {
      if (selectedDays.length === 2) {
        this.setState({
          selectedDays: selectedDays,
          selectedDaysOrder: newFechas,
          from: newFechas[0],
          to: newFechas[1],
        });
      }
    }
  };

  handleHoursClick = async (event, newValue) => {
    await this.setState({
      selectedHours: newValue,
    });
  };

  juntarFechas = async () => {
    await this.handleCargando(true);
    const selectedDays = this.state.selectedDaysOrder;
    let filtros = [];

    if (selectedDays.length !== 0) {
      this.setState({
        openCalendar: false,
      });
      const selectedDays = this.state.selectedDaysOrder;
      const selectedHours = this.state.selectedHours;
      let newSelectedHours = [];
      let initDate = "";
      let endDate = "";

      selectedHours.forEach((hour) => {
        let hourStr = hour.toString();
        if (hour < 10) {
          hourStr = "0" + hourStr + ":00:00";
        } else if (hour === 24) {
          hourStr = "23:59:59";
        } else {
          hourStr = hourStr + ":00:00";
        }
        newSelectedHours.push(hourStr);
      });

      if (selectedDays.length === 1 || selectedDays.length === 2) {
        initDate =
          moment(selectedDays[0]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[0] +
          "Z";

        filtros.push({
          filter: "initDate",
          data: initDate,
        });
        if (selectedDays.length === 2) {
          endDate =
            moment(selectedDays[1]).format("YYYY-MM-DD") +
            "T" +
            newSelectedHours[1] +
            "Z";
        } else {
          endDate =
            moment(selectedDays[0]).format("YYYY-MM-DD") +
            "T" +
            newSelectedHours[1] +
            "Z";
        }
        filtros.push({
          filter: "endDate",
          data: endDate,
        });
      }
    } else {
      this.setState({
        openCalendar: false,
      });

      filtros = [
        {
          filter: "initDate",
          data: null,
        },
        {
          filter: "endDate",
          data: null,
        },
      ];
    }

    this.filtrar(filtros);
  };

  filtrarRayos = async (filtros) => {
    const { dispatch } = this.props;
    if (
      filtros[0].filter === "idCustomer" &&
      (filtros[0].data === null || filtros[0].data === "null")
    ) {
      let customer = Utils.getCustomerString();
      filtros[0].data = customer;
    }
    let actualizarFiltros = rayosAccion.obtenerRayos(filtros, false);
    try {
      await dispatch(actualizarFiltros);
    } catch (err) {
      console.log(err);
    }
  };

  dispatchTareas = async (actualizarFiltros0, actualizarFiltros1) => {
    const { dispatch } = this.props;
    try {
      dispatch(actualizarFiltros0);
      await dispatch(actualizarFiltros1);
    } catch (err) {
      console.log(err);
    }
  };

  filtrar = async (filtros) => {
    const { dispatch } = this.props;
    await this.handleCargando(true);
    if (this.props.data.location.pathname === "/Home/Mapa" || this.props.data.location.pathname === "/home/mapa") {
      if (
        filtros[0].filter === "idCustomer" &&
        (filtros[0].data === null || filtros[0].data === "null")
      ) {
        let customer = Utils.getCustomerString();
        filtros[0].data = customer;
      }
      let actualizarFiltros0 = actionGrupoTareas.obtenerGTFiltro(
        filtros,
        "0",
        this.props.data.location.pathname,
        false
      );
      let actualizarFiltros1 = actionGrupoTareas.obtenerGTFiltro(
        filtros,
        "1",
        this.props.data.location.pathname,
        false
      );
      try {
        dispatch(actionGrupoTareas.reiniciarEstados());
        await this.dispatchTareas(actualizarFiltros0, actualizarFiltros1);
      } catch (err) {
        console.log(err);
      }
    } else if (this.props.data.location.pathname === "/Home/Lista" || this.props.data.location.pathname === "/home/lista") {
      await this.handleCargando(true);
      if (
        filtros[0].filter === "idCustomer" &&
        (filtros[0].data === null || filtros[0].data === "null")
      ) {
        let customer = Utils.getCustomerString();
        filtros[0].data = customer;
      }
      const { dispatch } = this.props;
      let actualizarFiltros = actionGrupoTareas.obtenerTareas(filtros, false);

      try {
        await dispatch(actualizarFiltros);
      } catch (err) {
        console.log(err);
      }
    }

    await this.handleCargando(false);
  };

  handleCargando = (tipo) => {
    this.setState({
      cargando: tipo,
    });
  };

  handleRayo = async (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "") {
      filtros.push({
        filter: "idRayo",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idRayo",
        data: value._id,
      });
    }
    this.filtrarRayos(filtros);
    this.filtrar(filtros);
  };

  handleProveedor = async (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "") {
      filtros.push({
        filter: "idProvider",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idProvider",
        data: value._id,
      });
    }

    this.filtrarRayos(filtros);
  };

  handleCliente = async (e) => {
    
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    let filtros = [];
    if (value === null || value === "null") {
      filtros.push({
        filter: "idCustomer",
        data: null,
      });
    } else {
      filtros.push({
        filter: "idCustomer",
        data: value._id,
      });
    }

    this.filtrar(filtros);
    this.filtrarRayos(filtros);
  };

  handleDrawer = (value) => {
    this.setState({
      open: value,
    });
  };

  handleDrawerUser = (value) => {
    this.setState({
      openUsuario: value,
    });
  };

  handleColapsed = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleMobileMenuOpen = (event) => {
    this.setState({
      drawerMobile: true,
      mobileMoreAnchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      drawerMobile: false,
      anchorEl: null,
    });
    this.handleMobileMenuClose();
  };

  handleMobileMenuClose = () => {
    this.setState({
      drawerMobile: false,
      mobileMoreAnchorEl: null,
    });
  };

  valuetext = (value) => {
    let num = "";
    if (value < 10) {
      num = "0";
    }
    return `${num}${value}:00`;
  };

  cerrarSesion = () => {
    sessionStorage.setItem("user", null);
    // this.props.data.history.push('/Login');
    window.location.href = "/Login";
  };

  render() {
    const rayos = this.props.rayos.rayos;
    const proveedores = this.props.proveedores.proveedores;
    const clientes = this.props.clientes.clientes;
    const { classes } = this.props;
    const { usuario } = this.state;
    const mobileMenuId = "primary-search-account-menu-mobile";

    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    const MapaIcon = () => {
      return (
        <img
          src={IconoMapa}
          alt="Mapa"
          height="15"
          style={{ marginBottom: 1 }}
        />
      );
    };

    const ListaIcon = () => {
      return (
        <img
          src={IconoLista}
          alt="Lista"
          height="15"
          style={{ marginBottom: 1 }}
        />
      );
    };

    const Usuario = ({ anchor, usuario }) => {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={() => this.handleDrawerUser(true)}
          onKeyDown={() => this.handleDrawerUser(false)}
        >
          <div className="w-100 paper-perfil">
            <div className="row m-0 px-4 pt-4">
              <div className="col-6 p-0">
                <img
                  src={usuario.photoURL}
                  className="icon-user-right"
                  alt="Usuario"
                />
              </div>
              <div className="col-6 p-0"></div>
            </div>
            <div className="row m-0 px-4 pt-4">
              <label className="text-layout">Nombre y apellido</label>
              <input
                disabled
                defaultValue={usuario.name}
                className="form-control input-layout"
              />
            </div>
            <div className="row m-0 px-4 pt-3">
              <label className="text-layout">Correo</label>
              <input
                disabled
                defaultValue={usuario.email}
                className="form-control input-layout"
              />
            </div>
            <div className="row m-0 px-4 pt-3">
              <label className="text-layout">Número de celular</label>
              <input
                disabled
                defaultValue={
                  usuario.phoneNumber === null
                    ? "No ingresado"
                    : usuario.phoneNumber
                }
                className="form-control input-layout"
              />
            </div>
          </div>
          <div className="row m-0 px-4 pt-4 d-flex justify-content-center">
            <button
              type="button"
              className="btn-secondary-white"
              onClick={() => this.cerrarSesion()}
            >
              Cerrar Sesión
            </button>
          </div>
          <p className="w-100 text-center txt-carta text-white mt-1">
            {"v " + VERSION}
          </p>
        </div>
      );
    };

    const Lista = ({ anchor, usuario }) => {
      return (
        <div
          className={classes.list}
          role="presentation"
          onClick={() => this.handleDrawer(true)}
          onKeyDown={() => this.handleDrawer(false)}
        >
          <div className={classes.toolbar}>
            {/* <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon className="text-white" />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton> */}
          </div>
          <div className="row m-0 mb-3 d-flex align-items-center justify-content-center">
            <img src={usuario.photoURL} className="icon-user" alt="Usuario" />
          </div>
          <div className="row m-0 mb-3 px-3 d-flex align-items-center justify-content-center">
            <div>
              <p className="mb-0 text-layout text-center">Administración</p>
              <h4 className="text-center text-white">{usuario.name}</h4>
            </div>
          </div>
          <Box
            alignItems="center"
            justifyContent="center"
            className="row m-0 mb-3"
          >
            <List className={classes.last}>
              <Link to="/Home/Mapa">
                <ListItem button>
                  <ListItemIcon>
                    <img
                      src={IconoPC}
                      className="icon-other mr-3"
                      alt="Panel de operaciónes"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Panel de operaciones"
                    className="text-layout hover-layout"
                  />
                </ListItem>
              </Link>
              {usuario === "null" || !usuario ? (
                false
              ) : usuario.type === EPA ? (
                false
              ) : (
                <ListItem
                  button
                  onClick={() => this.handleColapsed("openAdmin")}
                  className={
                    this.state.openAdmin
                      ? classes.buttonAdmin
                      : classes.buttonAdminInactive
                  }
                >
                  <ListItemIcon>
                    <img
                      src={IconoBag}
                      className="icon-other mr-3"
                      alt="Administración"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Administración"
                    className="hover-layout"
                  />
                  {this.state.openAdmin ? (
                    <img
                      className="icon-arrow"
                      src={IconoArrowUp}
                      alt="Icono subir"
                    />
                  ) : (
                    <img
                      className="icon-arrow"
                      src={IconoArrowBottom}
                      alt="Icono bajar"
                    />
                  )}
                </ListItem>
              )}

              <Collapse in={this.state.openAdmin} timeout="auto">
                <List component="div" disablePadding>
                  <Box
                    position="absolute"
                    height="calc(100% - 18px)"
                    left="10px"
                    width="3px"
                    bgcolor="primary.main"
                  ></Box>
                  
                  <Link to="/Rayos">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Rayos" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Dispatchers">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Dispatchers" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Clientes">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Clientes" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Proveedores">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Proveedores" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Paises">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Paises" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Regiones">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Regiones" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Ciudades">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Ciudades" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Comunas">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Comunas" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/TipoActividades">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Tipo de actividades" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/TipoVehiculos">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Tipo de vehículos" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Templates">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Templates" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Webhooks">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Webhooks" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/Servicios">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Servicios" className="text-white"/>
                    </ListItem>
                  </Link>

                  <Link to="/SubServicios">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="SubServicios" className="text-white"/>
                    </ListItem>
                  </Link>
                  
                  <Link to="/Tenant">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Tenant" className="text-white"/>
                    </ListItem>
                  </Link>
                  
                  <Link to="/Zones">
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Tareas por Zona" className="text-white"/>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>

              {usuario === "null" || !usuario ? (
                false
              ) : usuario.type === EPA ? (
                false
              ) : (
                <ListItem
                  button
                  onClick={() => this.handleColapsed("openLinks")}
                  className={
                    this.state.openLinks
                      ? classes.buttonAdmin
                      : classes.buttonAdminInactive
                  }
                >
                  <ListItemIcon>
                    <img
                      src={IconoLink}
                      className="icon-other mr-3"
                      alt="Links"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Reportes" className="hover-layout" />
                  {this.state.openLinks ? (
                    <img
                      className="icon-arrow"
                      src={IconoArrowUp}
                      alt="Icono subir"
                    />
                  ) : (
                    <img
                      className="icon-arrow"
                      src={IconoArrowBottom}
                      alt="Icono bajar"
                    />
                  )}
                </ListItem>
              )}

              <Collapse in={this.state.openLinks} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Box
                    position="absolute"
                    height="calc(100% - 18px)"
                    left="10px"
                    width="3px"
                    bgcolor="primary.main"
                  ></Box>
                  <a
                    href="https://charts.mongodb.com/charts-fleet-manager-rltbl/public/dashboards/5fbc2b76-8b55-45c3-8fda-bc7d4cd01f31"
                    target="_black"
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemIcon className="d-flex align-items-center">
                        <Box className="sub-admin" bgcolor="primary.main"></Box>
                      </ListItemIcon>
                      <ListItemText primary="Mongo Charts" className="text-white" />
                    </ListItem>
                  </a>
                </List>
              </Collapse>
            </List>
          </Box>
        </div>
      );
    };

    const StringTiempo = () => {
      return this.state.selectedDaysOrder.length === 0 ? (
        <img width="16" src={IconoCalendar} alt="Icono calendario" />
      ) : this.state.selectedDaysOrder.length === 1 ? (
        moment(this.state.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.state.selectedHours[0] +
        ":00" +
        " - " +
        moment(this.state.selectedDaysOrder[0]).format("DD-MM-YYYY") +
        " " +
        this.state.selectedHours[1] +
        ":00"
      ) : (
        this.state.selectedDaysOrder.map((day, i) => {
          if (i === 1) {
            return (
              " - " +
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.state.selectedHours[i] +
              ":00"
            );
          } else {
            return (
              moment(day).format("DD-MM-YYYY") +
              " " +
              this.state.selectedHours[i] +
              ":00"
            );
          }
        })
      );
    };

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{ height: "64px" }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.handleDrawer(true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <img
                src={IconoMenu}
                className="icon-button-menu"
                alt="IconoMenu"
              />
            </IconButton>

            <Typography variant="h6" noWrap>
              <img
                src={RayoLogo}
                width="100"
                className="menu-logo"
                alt="logo"
              />
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className="row m-0">
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  aria-label="contained primary button group"
                  className="mr-2"
                >
                  <Button
                    className={
                      this.props.data.location.pathname === "/Home/Lista"  || this.props.data.location.pathname === "/home/lista"
                        ? classes.buttonVistaSelected
                        : classes.buttonVista
                    }
                    startIcon={<MapaIcon />}
                    onClick={() => this.props.data.history.push("/Home/Mapa")}
                  >
                    MAPA
                  </Button>
                  <Button
                    className={
                      this.props.data.location.pathname === "/Home/Mapa"  || this.props.data.location.pathname === "/home/mapa"
                        ? classes.buttonVistaSelected
                        : classes.buttonVista
                    }
                    startIcon={<ListaIcon />}
                    onClick={() => this.props.data.history.push("/Home/Lista")}
                  >
                    LISTA
                  </Button>
                </ButtonGroup>

                <Button
                  className={classes.buttonVistaDatos}
                  onClick={(e) => {
                    let clickX = 680;
                    if (
                      usuario &&
                      usuario.type === ADMIN &&
                      this.props.data.location.pathname === "/Home/Lista" || this.props.data.location.pathname === "/home/lista"
                    )
                      clickX = 490;
                    if (usuario && usuario.type === EPA) clickX = 50;

                    this.setState({
                      openCalendar: !this.state.openCalendar,
                      positionCalendar: { right: clickX, top: 44 },
                    });
                  }}
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-controls="fade-menu"
                >
                  <StringTiempo />
                </Button>
                <div
                  className={
                    this.state.openCalendar
                      ? "dropdown-menu p-0 calendario-drop show show-drop"
                      : "dropdown-menu p-0 calendario-drop"
                  }
                  style={this.state.positionCalendar}
                  ref={(ref) => {
                    this.calendar = ref;
                  }}
                >
                  <div className="container-calendar">
                    <div className="container-horas row m-0">
                      <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                        <div className="bg-primary-light imagen-titulo  d-flex justify-content-center align-items-center">
                          <img width="23" src={IconoHome} alt="icono" />
                        </div>
                      </div>
                      <div className="col-10 p-0 pl-2 d-flex align-items-center">
                        Intervalo de tiempo
                      </div>
                    </div>
                    <p>
                      {this.state.selectedHours.map((hora, i) => {
                        if (i === 1) {
                          return " - " + hora + ":00";
                        } else {
                          return hora + ":00";
                        }
                      })}
                    </p>
                    <div className="w-100 px-3">
                      <Slider
                        value={this.state.selectedHours}
                        onChange={this.handleHoursClick}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={this.valuetext}
                        valueLabelFormat={this.valuetext}
                        min={0}
                        step={1}
                        max={24}
                      />
                    </div>
                    <DayPicker
                      locale="es-CL"
                      firstDayOfWeek={1}
                      months={MONTHS}
                      weekdaysLong={WEEKDAYS_LONG}
                      weekdaysShort={WEEKDAYS_SHORT}
                      className="Selectable"
                      numberOfMonths={1}
                      selectedDays={[from, { from, to }]}
                      modifiers={modifiers}
                      onDayClick={this.handleDayClick}
                    />

                    <div className="w-100 d-flex justify-content-end px-3">
                      <Button
                        className="button-filtrar"
                        onClick={() => this.juntarFechas()}
                      >
                        Filtrar
                      </Button>
                    </div>
                  </div>
                </div>

                <Button
                  // className="back-dark-grey btn-navbar btn-drop-icon px-1 py-1 mr-2"
                  className={classes.buttonVistaIconos}
                  onClick={(e) => {
                    let clickX = 680;
                    if (
                      usuario &&
                      usuario.type === ADMIN &&
                      this.props.data.location.pathname === "/Home/Lista" || this.props.data.location.pathname === "/home/lista"
                    )
                      clickX = 490;
                    if (usuario && usuario.type === EPA) clickX = 50;

                    this.setState({
                      openCalendar: !this.state.openCalendar,
                      positionCalendar: { right: clickX, top: 44 },
                    });
                  }}
                  // data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-controls="fade-menu"
                  id="button-calendar"
                >
                  <img
                    className="icon-arrow"
                    src={IconoArrowBottom}
                    alt="Icono bajar"
                  />
                </Button>

                {usuario === "null" || !usuario ? (
                  false
                ) : usuario.type === EPA ? (
                  false
                ) : (
                  <div className={classes.sectionDesktop}>
                    {rayos === null ? (
                      false
                    ) : (
                      <Autocomplete
                        id="combo-box-demo"
                        className={clsx(
                          "autocomplete-layout-rayos mr-2",
                          classes.autocomplete
                        )}
                        name="selectedRayo"
                        noOptionsText={"No se encuentran opciones"}
                        value={this.state.selectedRayo}
                        options={rayos}
                        getOptionSelected={(option, value) =>
                          value.value === option.value
                        }
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name_rayo
                        }
                        onChange={(e, newValue) => {
                          let valor = {
                            target: { name: "selectedRayo", value: newValue },
                          };
                          this.handleRayo(valor);
                        }}
                        popupIcon={
                          <img
                            className="icon-arrow"
                            src={IconoArrowBottom}
                            alt="Icono bajar"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="selectedRayo"
                            variant="outlined"
                            placeholder="&#xF0E7;  TODOS LOS RAYOS"
                          />
                        )}
                      />
                    )}

                    {proveedores === null ||
                    this.props.data.location.pathname === "/Home/Lista" || this.props.data.location.pathname === "/home/lista" ? (
                      false
                    ) : (
                      <Autocomplete
                        id="combo-box-proveedores"
                        className={clsx(
                          "autocomplete-layout-proveedores mr-2",
                          classes.autocomplete
                        )}
                        name="selectedProveedor"
                        noOptionsText={"No se encuentran opciones"}
                        value={this.state.selectedProveedor}
                        options={proveedores}
                        getOptionSelected={(option, value) =>
                          value.value === option.value
                        }
                        getOptionLabel={(option) =>
                          typeof option === "string"
                            ? option
                            : option.name_provider
                        }
                        onChange={(e, newValue) => {
                          let valor = {
                            target: {
                              name: "selectedProveedor",
                              value: newValue,
                            },
                          };
                          this.handleProveedor(valor);
                        }}
                        popupIcon={
                          <img
                            className="icon-arrow"
                            src={IconoArrowBottom}
                            alt="Icono bajar"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="selectedProveedor"
                            variant="outlined"
                            placeholder="&#xf2b9;  TODOS LOS PROVEEDORES"
                          />
                        )}
                      />
                    )}

                    {clientes === null ? (
                      false
                    ) : (
                      <Autocomplete
                        id="combo-box-clientes"
                        // className="autocomplete-layout-proveedores autocomplete-layout-clientes mr-2"
                        className={clsx(
                          "autocomplete-layout-proveedores autocomplete-layout-clientes mr-2",
                          classes.autocomplete
                        )}
                        name="selectedCliente"
                        noOptionsText={"No se encuentran opciones"}
                        value={this.state.selectedCliente}
                        options={clientes}
                        getOptionSelected={(option, value) =>
                          value.value === option.value
                        }
                        getOptionLabel={(option) =>
                          typeof option === "string"
                            ? option
                            : option.name_customer
                        }
                        onChange={(e, newValue) => {
                          let valor = {
                            target: {
                              name: "selectedCliente",
                              value: newValue,
                            },
                          };
                          this.handleCliente(valor);
                        }}
                        popupIcon={
                          <img
                            className="icon-arrow"
                            src={IconoArrowBottom}
                            alt="Icono bajar"
                          />
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="selectedCliente"
                            variant="outlined"
                            placeholder="&#xf007;  TODOS LOS CLIENTES"
                          />
                        )}
                      />
                    )}

                    <Button
                      className="mr-2 btn-navbar px-2-5 py-1"
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        this.props.data.history.push("/Home/Tarea/Crear/0")
                      }
                    >
                      &#xf0f6; &nbsp; CREAR TAREAS
                    </Button>
                  </div>
                )}

                <Button
                  className="back-transparent mr-0 btn-navbar p-0 mr-2"
                  onClick={() => this.handleDrawerUser(true)}
                >
                  {usuario === "null" ||
                  usuario === null ||
                  typeof usuario === undefined ? (
                    <img src={IconoUsuario} width="30" alt="Usuario" />
                  ) : (
                    <img
                      src={usuario.photoURL}
                      width="30"
                      className="br-5"
                      alt="Usuario"
                    />
                  )}
                </Button>
              </div>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <img
                  className="icon-arrow"
                  src={IconoArrowBottom}
                  alt="Icono bajar"
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.contenido}
        </main>

        <Drawer
          anchor={"left"}
          open={this.state.open}
          onClose={() => this.handleDrawer(false)}
        >
          {usuario === "null" || !usuario ? (
            false
          ) : (
            <Lista anchor={"left"} usuario={usuario} />
          )}
        </Drawer>

        <Drawer
          anchor={"right"}
          open={this.state.openUsuario}
          onClose={() => this.handleDrawerUser(false)}
        >
          {usuario === "null" || !usuario ? (
            false
          ) : (
            <Usuario anchor={"right"} usuario={usuario} />
          )}
        </Drawer>

        <Cargando cargando={this.state.cargando} />
      </div>
    );
  }
}

const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary,
  },
  autocomplete: {
    backgroundColor: theme.palette.primary.light,
    "& .MuiAutocomplete-popupIndicator": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonAdmin: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText + " !important",
    borderRadius: 5,
    fontFamily: "Exo2-Bold !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonAdminInactive: {
    color: theme.palette.primary.contrastText + " !important",
    fontFamily: "Exo2-Bold !important",
  },
  buttonVista: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    marginRight: "0px !important",
    fontSize: "10px !important",
    height: "30px !important",
    padding: "0px 0.5rem 0px 0.6rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.75,
    },
  },
  buttonVistaSelected: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    marginRight: "0px !important",
    fontSize: "10px !important",
    height: "30px !important",
    padding: "0px 0.5rem 0px 0.6rem !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.75,
    },
  },
  buttonVistaDatos: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    marginRight: "0px !important",
    fontSize: "10px !important",
    height: "30px !important",
    padding: "0px 0.5rem 0px 0.6rem !important",
    borderTopRightRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.75,
    },
  },
  buttonVistaIconos: {
   
    color: theme.palette.primary.contrastText,
    fontSize: "10px !important",
    height: "30px !important",
    padding: "0.25rem !important",
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important",
    right: "0px !important",
    marginRight: "0.5rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.55,
    },
  },
  list: {
    width: 300,
  },
  root: {
    display: "flex",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  last: {
    marginBottom: 64,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    height: 35,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: "-5px",
  },
});

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    clientes: store.clientes,
    grupoTareas: store.grupoTareas,
    proveedores: store.proveedores,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(Layout)
);
