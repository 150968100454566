import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconButton from "@material-ui/core/IconButton";
import CopyText from "../../assets/components/custom/CopyText";
import Utils from "../../assets/utils/Utils";
import url from "../../config";
import "../../css/global.css";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import IconoCerrar from "../../assets/icons/icono-cerrar-white.svg";
import IconoCerrarBlack from "../../assets/icons/icono-cerrar-red.svg";
import IconoLista from "../../assets/icons/icono-lista-black.svg";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const columns = [
  {
    id: "copiar",
    label: "Copiar ID",
    align: "left",
  },
  { id: "code_template", label: "Código", class: "", classtd: "" },
  {
    id: "customer_template",
    label: "Nombre del cliente",
    align: "left",
  },
  {
    id: "type_activity_template",
    label: "Template de actividades",
    align: "left",
  },
  {
    id: "creation_date_template",
    label: "Fecha de creación",
    align: "left",
  },
  { id: "status_template", label: "Estado", align: "left" },
  {
    id: "accion",
    label: "Acción",
    align: "right",
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "rgba(0,0,0,0.2)" : "transparent",
  paddingTop: isDragging ? 4 : 0,
  paddingBottom: isDragging ? 4 : 0,
  ...draggableStyle,
});

// height: 26px;
//     padding: 0.3rem 0.3rem;
//     font-size: 12px !important;

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      tipoModal: 1,
      tipoActividades: [],
      clientes: [],
      templates: null,
      search: "",
      multiscanTypes: [],

      form: {
        template: "",
        key: "",
        code_template: "",
        customer_template: [],
        type_activity_template: [],
        creation_date_template: "",
        status_template: "1",
        multiscan_type: "",

        template_seleccionado: [],
      },
      dropdown_options: [],
      dropdown_text: "",
    };

    this.getTemplates();
    this.getMultiscanTypes();
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.form.template_seleccionado,
      result.source.index,
      result.destination.index
    );

    this.setState({
      form: {
        ...this.state.form,
        template_seleccionado: items,
      },
    });
  }

  //  Maneja textos del formulario
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  //  Maneja textos de template de actividades
  handleTextTemplate = (e, i) => {
    e.preventDefault();
    let newArray = [...this.state.form.template_seleccionado];
    const { name, value } = e.target;

    newArray[i] = {
      ...newArray[i],
      [name]: value,
    };

    this.setState({
      form: {
        ...this.state.form,
        template_seleccionado: newArray,
      },
    });
  };

  //  Maneja botones de estado
  handleChangeButton = (estado) => {
    this.setState({
      form: {
        ...this.state.form,
        status_template: estado,
      },
    });
  };

  handleMultiscanTypeSelect = (e) => {
    const { value } = e.target;

    let indexMT = this.state.multiscanTypes.findIndex(
      (element) => element.code === value
    );
    let indexTemp = this.state.form.template_seleccionado.findIndex(
      (element) => element.name_type_activity === "MultiScan"
    );

    if (indexMT >= 0 && indexTemp >= 0 && value !== "") {
      let newArraySelec = cloneDeep([...this.state.form.template_seleccionado]);
      newArraySelec[indexTemp].descriptionTypeActivity =
        this.state.multiscanTypes[indexMT].description;

      this.setState({
        form: {
          ...this.state.form,
          multiscan_type: value,
          template_seleccionado: newArraySelec,
        },
      });
    } else if (indexTemp >= 0 && value === "") {
      let newArraySelec = cloneDeep([...this.state.form.template_seleccionado]);
      newArraySelec[indexTemp].descriptionTypeActivity = "";

      this.setState({
        form: {
          ...this.state.form,
          multiscan_type: value,
          template_seleccionado: newArraySelec,
        },
      });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleClientes = (e) => {
    let newArray = [...this.state.form.customer_template];

    const i = this.state.form.customer_template.findIndex(
      (element) => element._id === e.target.value
    );

    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        customer_template: newArray,
      },
    });
  };

  handleClientesQuitar = (e) => {
    let newArray = [...this.state.form.customer_template];
    const i = this.state.form.customer_template.findIndex(
      (element) => element._id === e.target.name
    );

    newArray[i] = {
      ...newArray[i],
      activo: !newArray[i].activo,
    };

    this.setState({
      form: {
        ...this.state.form,
        customer_template: newArray,
      },
    });
  };

  handleActividadesQuitar = (i) => {
    let newArray = [...this.state.form.template_seleccionado];

    newArray.splice(i, 1);
    this.setState({
      form: {
        ...this.state.form,
        template_seleccionado: newArray,
      },
    });
  };

  handleSeleecionActividades = (id) => {
    let newArray = [...this.state.form.type_activity_template];
    let newArraySelec = [...this.state.form.template_seleccionado];
    const i = this.state.form.type_activity_template.findIndex(
      (element) => element._id === id
    );

    let haveDropdown = false;
    let haveMultiscan = false;
    newArraySelec.forEach((element) => {
      if (element.dropdown) haveDropdown = true;
      if (element.name_type_activity === "MultiScan") haveMultiscan = true;
    });

    if (haveDropdown && newArray[i].dropdown_activated) {
      Utils.handleAlert(
        "Advertencia",
        "Un template puede tener solo una actividad de tipo Dropdown",
        "Ok"
      );
    }
    if (haveMultiscan && newArray[i].name_type_activity === "MultiScan") {
      Utils.handleAlert(
        "Advertencia",
        "Un template puede tener solo una actividad de tipo Multiscan",
        "Ok"
      );
    } else {
      let seleccionado = {
        _id: newArray[i]._id,
        idTypeActivity: newArray[i]._id,
        image_type_activity: newArray[i].image_type_activity,
        name_type_activity: newArray[i].name_type_activity,
        dropdown: newArray[i].dropdown_activated,
        descriptionTypeActivity: "",
        field: "",
      };

      newArraySelec.push(seleccionado);
      this.setState({
        form: {
          ...this.state.form,
          template_seleccionado: newArraySelec,
        },
      });
    }
  };

  handleDeleteDropdown = (i) => {
    let dropdown = cloneDeep(this.state.dropdown_options);
    dropdown.splice(i, 1);
    this.setState({
      dropdown_options: dropdown,
    });
  };

  handleAddDropdown = () => {
    if (this.state.dropdown_text !== "") {
      let dropdown = cloneDeep(this.state.dropdown_options);
      dropdown.push(this.state.dropdown_text);
      this.setState({
        dropdown_text: "",
        dropdown_options: dropdown,
      });
    }
  };

  handleModal = async (tipo, template) => {
    this.setState({
      tipoModal: tipo,
    });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      let arrayClientes = cloneDeep(this.state.clientes);
      arrayClientes.forEach((v) => {
        template.customer_template.forEach((r) => {
          if (v._id === r.idCustomer) {
            v.activo = true;
          }
        });
      });

      let arrayActividades = cloneDeep(this.state.tipoActividades);
      let actividadesTipo = cloneDeep(this.state.tipoActividades);
      template.type_activity_template.forEach((v) => {
        actividadesTipo.forEach((r) => {
          if (v._id === r._id) {
            r.activo = true;
          }
        });
      });

      await this.setState({
        form: {
          ...this.state.form,
          key: template._id,
          code_template: template.code_template,
          customer_template: arrayClientes,
          type_activity_template: arrayActividades,
          creation_date_template: moment(
            template.creation_date_template
          ).format("YYYY-MM-DD"),
          status_template: template.status_template,
          template_seleccionado: template.type_activity_template,
          multiscan_type: template.multiscan_type,
        },
        dropdown_options: template.dropdown_options
          ? template.dropdown_options
          : [],
      });
      $("#modalFormulario").modal("show");
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        key: "",
        code_template: "",
        customer_template: [...this.state.clientes],
        type_activity_template: [...this.state.tipoActividades],
        creation_date_template: "",
        status_template: "1",
        template_seleccionado: [],
        multiscan_type: "",
      },
      dropdown_options: [],
      dropdown_text: "",
    });
  };

  getMultiscanTypes = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Templates/multiscan_types",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      await this.setState({ multiscanTypes: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tipos de multiscan",
        "No se han podido cargar los tipos de multiscan",
        "Ok"
      );
    }
  };

  getTemplates = async () => {
    try {
      let clientes = Utils.getCustomers();
      let data = await axios({
        method: "get",
        url: url.host + "/Templates/templates" + clientes,
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((element, i) => {
        element.type_activity_template.forEach((e) => {
          e.image_type_activity = "";
          e.name_type_activity = "";
        });
      });
      await this.setState({ templates: data });
      this.getCustumers();
    } catch (err) {
      Utils.handleAlert(
        "Obtener templates",
        "No se han podido cargar los templates",
        "Ok"
      );
    }
  };

  getCustumers = async () => {
    try {
      let clientes = Utils.getCustomers();
      let usuario = JSON.parse(sessionStorage.getItem("user"));
      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let customers = data.sort(function (a, b) {
        if (
          a.name_customer.toLowerCase().trim() <
          b.name_customer.toLowerCase().trim()
        )
          return -1;
        if (
          a.name_customer.toLowerCase().trim() >
          b.name_customer.toLowerCase().trim()
        )
          return 1;
        return 0;
      });
      customers.forEach((v) => {
        v.activo = false;
        return v;
      });
      this.setState({
        clientes: customers,
        form: {
          ...this.state.form,
          customer_template: customers,
        },
      });
      this.getTipoActividades();
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };

  getTipoActividades = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Type-Activities/type_activities/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      data.forEach((v) => {
        v.activo = false;
        return v;
      });

      let newArray = this.state.templates;
      if (newArray) {
        newArray.forEach((template, i) => {
          template.type_activity_template.forEach((tipoActTemplate) => {
            data.forEach((tipoAct) => {
              if (tipoAct._id === tipoActTemplate.idTypeActivity) {
                tipoActTemplate.image_type_activity =
                  tipoAct.image_type_activity;
                tipoActTemplate.name_type_activity = tipoAct.name_type_activity;
                tipoActTemplate.dropdown = tipoAct.dropdown_activated;
              }
            });
          });
        });
      }

      this.setState({
        tipoActividades: data,
        form: {
          ...this.state.form,
          type_activity_template: data,
        },
      });
    } catch (err) {
      console.log(err);
      Utils.handleAlert(
        "Obtener tipos de actividades",
        "No se han podido cargar los tipos de actividades",
        "Ok"
      );
    }
  };

  createTemplate = async () => {
    try {
      let clientes = [];
      this.state.form.customer_template.forEach((element, i) => {
        if (element.activo) {
          let obj = {
            idCustomer: element._id,
          };
          clientes.push(obj);
        }
      });

      let typeDropdown = false;

      let tiposActividades = [];
      this.state.form.template_seleccionado.forEach((element, i) => {
        if (element.dropdown === true) typeDropdown = true;
        let obj = {
          idTypeActivity: element._id,
          descriptionTypeActivity: element.descriptionTypeActivity,
          orderNumber: i,
          field: element.field,
        };
        tiposActividades.push(obj);
      });

      if (typeDropdown === true && this.state.dropdown_options.length === 0) {
        Utils.handleAlert(
          "Opciones no ingresadas",
          "Para ingresar un template con una actividad tipo Dropdown, esta debe tener al menos una opción.",
          "Ok"
        );
      } else {
        const template = {
          code_template: this.state.form.code_template,
          customer_template: clientes,
          type_activity_template: tiposActividades,
          creation_date_template: moment(
            this.state.form.creation_date_template
          ).format("YYYY-MM-DD"),
          status_template: parseInt(this.state.form.status_template),
          dropdown_options: this.state.dropdown_options,
          multiscan_type: this.state.form.multiscan_type,
          tenant: this.state.form.tenant
        };

        let res = await url.api.post("/Templates/template/", template);
        if (res.status === 201 || res.status === 200) {
          $("#modalFormulario").modal("hide");
          this.getTemplates();
          Utils.handleAlert(
            "Template ingresado",
            "El template se ha ingresado exitosamente",
            "Ok"
          );
          this.emptyForm();
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al ingresar el template, vuelva a intentarlo",
            "Ok"
          );
        }
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar el template, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Template?",
      text: "Si confirma, no podrá deshacer la acción y el template será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteTemplate(key);
      }
    });
  };

  deleteTemplate = async (key) => {
    try {
      const template = {
        key: key,
        tenant: this.state.form.tenant
      };
      let res = await url.api.delete("/Templates/template/", {
        data: template,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getTemplates();
        Utils.handleAlert(
          "Template eliminado",
          "El template se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el template, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el template, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyTemplate = async () => {
    try {
      let clientes = [];
      this.state.form.customer_template.forEach((element, i) => {
        if (element.activo) {
          let obj = {
            idCustomer: element._id,
          };
          clientes.push(obj);
        }
      });
      let typeDropdown = false;
      let tiposActividades = [];
      this.state.form.template_seleccionado.forEach((element, i) => {
        if (element.dropdown === true) typeDropdown = true;
        let obj = {
          idTypeActivity: element.idTypeActivity,
          descriptionTypeActivity: element.descriptionTypeActivity,
          orderNumber: i,
          field: element.field,
        };
        tiposActividades.push(obj);
      });
      if (typeDropdown === true && this.state.dropdown_options.length === 0) {
        Utils.handleAlert(
          "Opciones no ingresadas",
          "Para ingresar un template con una actividad tipo Dropdown, esta debe tener al menos una opción.",
          "Ok"
        );
      } else {
        const template = {
          key: this.state.form.key,
          code_template: this.state.form.code_template,
          customer_template: clientes,
          type_activity_template: tiposActividades,
          creation_date_template: moment(
            this.state.form.creation_date_template
          ).format("YYYY-MM-DD"),
          status_template: parseInt(this.state.form.status_template),
          dropdown_options: this.state.dropdown_options,
          multiscan_type: this.state.form.multiscan_type,
          tenant: this.state.form.tenant
        };

        let res = await url.api.put("/Templates/template/", template);
        if (res.status === 200) {
          $("#modalFormulario").modal("hide");
          this.getTemplates();
          Utils.handleAlert(
            "Template modificada",
            "El template se ha modifico exitosamente",
            "Ok"
          );
          this.emptyForm();
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al modificar el template, vuelva a intentarlo",
            "Ok"
          );
        }
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el template, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;

    const SeleccionarEstado = () => {
      return (
        <div className="form-row">
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              onClick={() => this.handleChangeButton(1)}
              variant="contained"
              color={
                parseInt(this.state.form.status_template) === 1
                  ? "primary"
                  : "default"
              }
              className="w-100 text-roboto-regular"
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot activo"></div>
                </div>
                <div className="col-sm-9 px-0">Activo</div>
              </div>
            </Button>
          </div>
          <div className="form-group mb-0 col-md-6">
            <Button
              type="button"
              onClick={() => this.handleChangeButton(2)}
              variant="contained"
              color={
                parseInt(this.state.form.status_template) === 2
                  ? "primary"
                  : "default"
              }
              className="w-100 text-roboto-regular"
            >
              <div className="row m-0 w-100">
                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                  <div className="dot inactivo"></div>
                </div>
                <div className="col-sm-9 px-0">Inactivo</div>
              </div>
            </Button>
          </div>
        </div>
      );
    };

    const Actividades = ({ actividades }) => {
      return actividades.map((actividad, index) => (
        <Button variant="contained" className="mx-1" disabled key={index}>
          <img
            src={actividad.image_type_activity}
            width="25"
            alt={"Icono " + actividad.name_type_activity}
          />
        </Button>
      ));
    };

    const Clientes = ({ clientes }) => {
      let array = cloneDeep(this.state.clientes);
      array.forEach((v) => {
        clientes.forEach((r) => {
          if (v._id === r.idCustomer) {
            v.activo = true;
          }
        });
      });
      return array.map((cliente, index) => {
        return cliente.activo ? (
          <label className="mb-0 w-100" key={index}>
            {"- " + cliente.name_customer}
          </label>
        ) : (
          false
        );
      });
    };

    const SeleccionActividades = ({ actividades }) => {
      let array = cloneDeep(this.state.tipoActividades);
      array.forEach((v) => {
        actividades.forEach((r) => {
          if (v._id === r.idTypeActivity) {
            v.activo = true;
          }
        });
      });

      return (
        <div className="row m-0">
          {array.map((actividad, index) => {
            return (
              <div className="col-md-6 px-1" draggable="false" key={index}>
                <Button
                  id={index}
                  variant="contained"
                  className="w-100 m-0 mb-2"
                  color={actividad.activo ? "primary" : "default"}
                  onClick={() => this.handleSeleecionActividades(actividad._id)}
                  startIcon={
                    <img
                      src={actividad.image_type_activity}
                      height="20"
                      className="mb-1 cursor-pointer"
                      alt="icono"
                    />
                  }
                >
                  {actividad.name_type_activity}
                </Button>
              </div>
            );
          })}
        </div>
      );
    };

    const DropdownIngresados = () => {
      return this.state.dropdown_options.map((drowpdown, i) => (
        <div
          className={i === 0 ? "row m-0 w-100" : "row m-0 w-100 mt-2"}
          key={i}
        >
          <div className="col-10 pl-0">
            <input
              type="text"
              name="comunas"
              className="form-control light-disabled"
              defaultValue={drowpdown}
              disabled
            />
          </div>
          <div className="col-2 p-0 d-flex justify-content-end align-items-center">
            <div
              className="square-template back-light-grey d-flex justify-content-center align-items-center cursor-pointer"
              onClick={() => this.handleDeleteDropdown(i)}
            >
              <img
                src={IconoCerrarBlack}
                height="25"
                alt="icono"
                className="mb-0"
              />
            </div>
          </div>
        </div>
      ));
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Templates
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>

            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearModal"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Templates">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.templates ? (
                  this.state.templates.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen templates creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.templates
                      .filter((template) => {
                        let bandera = false;
                        if (
                          template.code_template
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          template._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">
                            {row.code_template}
                          </TableCell>
                          <TableCell align="left">
                            <Clientes clientes={row.customer_template} />
                          </TableCell>
                          <TableCell align="left">
                            <Actividades
                              actividades={row.type_activity_template}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {moment(row.creation_date_template).format(
                              "DD-MM-YYYY"
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <ActiveDot status={row.status_template} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " templates";
              }}
              component="div"
              count={
                this.state.templates
                  ? this.state.templates.filter((template) => {
                      let bandera = false;
                      if (
                        template.code_template
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        template._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    {tipoModal === 1 ? "Crear Template" : "Modificar Template"}
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarTemplate}>
                  <div className="modal-body">
                    <div className="row m-0">
                      <div className="col-md-8 p-0">
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label className="texto-form mb-1">Código</label>
                            {tipoModal === 1 ? (
                              <label className="texto-form-red-light">*</label>
                            ) : (
                              false
                            )}
                            <input
                              disabled={tipoModal === 1 ? false : true}
                              required={tipoModal === 1 ? true : false}
                              onChange={this.handleChange}
                              type="text"
                              name="code_template"
                              className="form-control"
                              placeholder="Código"
                              value={this.state.form.code_template}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label className="texto-form mb-1">
                              Fecha de creación
                            </label>
                            {tipoModal === 1 ? (
                              <label className="texto-form-red-light">*</label>
                            ) : (
                              false
                            )}
                            <input
                              disabled={tipoModal === 1 ? false : true}
                              required={tipoModal === 1 ? true : false}
                              onChange={this.handleChange}
                              type="date"
                              name="creation_date_template"
                              className="form-control"
                              placeholder="00-00-0000"
                              value={this.state.form.creation_date_template}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label className="texto-form mb-1">Estado</label>
                            <label className="texto-form-red-light">*</label>
                            <SeleccionarEstado />
                          </div>
                          <div className="form-group col-md-6"></div>
                        </div>
                      </div>
                      <div className="col-md-4 pl-2 pr-0">
                        <div className="form-group">
                          <label className="texto-form mb-1">Clientes</label>
                          <label className="texto-form-red-light">*</label>
                          <select
                            className="custom-select mb-2"
                            name="customer_template"
                            onChange={this.handleClientes}
                            value=""
                          >
                            <option value="">- Seleccionar Clientes -</option>
                            {this.state.form.customer_template.map(
                              (cliente, index) =>
                                cliente.activo === false ? (
                                  <option value={cliente._id} key={index}>
                                    {cliente.name_customer}
                                  </option>
                                ) : (
                                  false
                                )
                            )}
                          </select>
                          {this.state.form.customer_template.map(
                            (cliente, index) =>
                              cliente.activo === true ? (
                                <input
                                  value={cliente.name_customer}
                                  name={cliente._id}
                                  className="form-control w-100 mb-1 cursor-pointer"
                                  key={index}
                                  readOnly
                                  onClick={this.handleClientesQuitar}
                                />
                              ) : (
                                false
                              )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* TEMPLATE Y ACTIVIDADES */}
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <div className="back-light-grey icon-table p-3">
                          <label className="text-roboto-regular color-dark-grey w-100 ml-1">
                            Template
                          </label>
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshotD) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {this.state.form.template_seleccionado
                                    //.sort((a, b) => a.orderNumber - b.orderNumber)
                                    .map((item, i) => (
                                      <Draggable
                                        key={item._id + i}
                                        draggableId={item._id + i}
                                        index={i}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="row m-0 px-1 contain-template"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <div
                                              className="w-100 row m-0"
                                              style={{ height: 40 }}
                                            >
                                              <div
                                                style={{
                                                  width: 30,
                                                  height: 40,
                                                }}
                                                className="d-inline-flex align-items-center"
                                              >
                                                <img
                                                  src={IconoLista}
                                                  height="23"
                                                  width="23"
                                                  alt="icono"
                                                />
                                              </div>

                                              <div className="square-template p-relative back-grey-input d-inline-flex justify-content-center align-items-center">
                                                <img
                                                  src={item.image_type_activity}
                                                  height="25"
                                                  className="mb-0"
                                                  alt="icono"
                                                />
                                                {i === 0 ? (
                                                  false
                                                ) : (
                                                  <div
                                                    className={
                                                      snapshotD.isDraggingOver
                                                        ? "borde-plomo-act d-none"
                                                        : "borde-plomo-act d-block"
                                                    }
                                                  ></div>
                                                )}
                                              </div>

                                              <div
                                                style={{
                                                  width: 170,
                                                  paddingLeft: 7,
                                                  height: 40,
                                                }}
                                                className="d-inline-flex align-items-center txt-carta-name"
                                              >
                                                {item.name_type_activity ===
                                                "MultiScan" ? (
                                                  <div className="w-100">
                                                    <div className="w-100">
                                                      {item.name_type_activity}
                                                    </div>
                                                    <div className="w-100">
                                                      <select
                                                        required
                                                        className="custom-select-small"
                                                        name="multiscan_type"
                                                        value={
                                                          this.state.form
                                                            .multiscan_type
                                                        }
                                                        onChange={
                                                          this
                                                            .handleMultiscanTypeSelect
                                                        }
                                                      >
                                                        <option value="">
                                                          - Seleccionar código -
                                                        </option>
                                                        {this.state.multiscanTypes.map(
                                                          (element, i) => (
                                                            <option
                                                              value={
                                                                element.code
                                                              }
                                                              key={i}
                                                            >
                                                              {element.code}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  item.name_type_activity
                                                )}
                                              </div>

                                              <div
                                                style={{
                                                  width: 150,
                                                  paddingLeft: 7,
                                                  height: 40,
                                                }}
                                                className="d-inline-flex align-items-center"
                                              >
                                                <input
                                                  required
                                                  className="form-control template-input"
                                                  placeholder="Nombre del campo"
                                                  name="field"
                                                  value={
                                                    this.state.form
                                                      .template_seleccionado[i]
                                                      .field
                                                  }
                                                  onChange={(e) =>
                                                    this.handleTextTemplate(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  width: !item.dropdown
                                                    ? "calc(100% - 428px)"
                                                    : "calc(100% - 468px)",
                                                  paddingLeft: 7,
                                                  height: 40,
                                                }}
                                                className="d-inline-flex align-items-center"
                                              >
                                                <input
                                                  required
                                                  disabled={
                                                    item.name_type_activity ===
                                                    "MultiScan"
                                                      ? true
                                                      : false
                                                  }
                                                  className="form-control template-input"
                                                  placeholder="Nombre del campo"
                                                  name="descriptionTypeActivity"
                                                  value={
                                                    this.state.form
                                                      .template_seleccionado[i]
                                                      .descriptionTypeActivity
                                                  }
                                                  onChange={(e) =>
                                                    this.handleTextTemplate(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </div>

                                              {item.dropdown ? (
                                                <div
                                                  style={{
                                                    width: 40,
                                                    height: 40,
                                                  }}
                                                  className="d-inline-flex align-items-center justify-content-center"
                                                >
                                                  <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={() =>
                                                      $("#modalOptions").modal(
                                                        "show"
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={IconoCerrarBlack}
                                                      height="20"
                                                      alt="icono"
                                                      className="mb-0 cursor-pointer rotar-icono-45"
                                                    />
                                                  </IconButton>
                                                </div>
                                              ) : (
                                                false
                                              )}

                                              <div
                                                style={{
                                                  width: 40,
                                                  height: 40,
                                                }}
                                                className="d-inline-flex align-items-center justify-content-center"
                                              >
                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  onClick={() =>
                                                    this.handleActividadesQuitar(
                                                      i
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={IconoCerrarBlack}
                                                    height="20"
                                                    alt="icono"
                                                    className="mb-0 cursor-pointer"
                                                  />
                                                </IconButton>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <div className="back-light-grey icon-table p-3">
                          <label className="text-roboto-regular color-dark-grey w-100 ml-1">
                            Actividades
                          </label>
                          <SeleccionActividades
                            actividades={this.state.form.template_seleccionado}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>

                <div
                  className="modal fade"
                  id="modalOptions"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="modalOptions"
                  data-backdrop="static"
                  aria-hidden="true"
                  scrollable="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4
                          className="modal-title title-text-white ml-3"
                          id="editarModalLabel"
                        >
                          Añadir opciones
                        </h4>

                        <button
                          type="button"
                          className="close"
                          data-dismiss="modalOptions"
                          data-toggle="modalOptions"
                          onClick={() => {
                            $("#modalOptions").modal("hide");
                          }}
                          aria-label="Close"
                        >
                          <span aria-hidden="true" className="title-text-white">
                            <img
                              src={IconoCerrar}
                              height="37"
                              alt="Logo Rayo"
                            />
                          </span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {/* {this.state.form.dropdown_activated ? (  <> */}
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label className="texto-form mb-1">
                              Ingrese opciones:
                            </label>
                            <div className="row m-0">
                              <div className="col-11 pl-0">
                                <input
                                  className="form-control"
                                  placeholder="Opción"
                                  name="dropdown_text"
                                  value={this.state.dropdown_text}
                                  onChange={(e) =>
                                    this.setState({
                                      dropdown_text: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-1 p-0">
                                <button
                                  onClick={this.handleAddDropdown}
                                  type="button"
                                  className="btn back-sky-blue br-4 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer"
                                >
                                  <img
                                    src={IconoCerrar}
                                    height="20"
                                    className="rotar-icono-45"
                                    alt="icon"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-100 row m-0 back-light-grey icon-table p-3">
                          <DropdownIngresados />

                          {this.state.dropdown_options.length === 0 ? (
                            <p className="text-exo-light color-light-grey mb-0 text-center w-100">
                              - No se encuentran opciones ingresadas -
                            </p>
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarTemplate = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createTemplate();
      } else {
        this.modifyTemplate();
      }
    }
  };
}

export default Templates;
