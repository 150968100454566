import React, { useEffect } from "react";
import * as Realm from "realm-web";
// import { APPCONFIG, SERVICE_NAME } from "../../assets/utils/Helpers";

const AuthHandler = (props) => {
  useEffect(() => {
    ingresarRealm()
  }, []);

  const ingresarRealm = async () => {
    // this.app = new Realm.App(APPCONFIG);
    try {
      Realm.handleAuthRedirect();
    } catch (error) {
        console.log(error)
    }
  };

  return <div></div>;
};

export default AuthHandler;

