import Swal from "sweetalert2";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { decode } from "@googlemaps/polyline-codec";
import url from "../../config";

import {
  ACT_CODIGO_BARRAS,
  ACT_FIRMA,
  ACT_FOTO,
  ACT_NOTA,
  ACT_CODIGO_QR,
  ACT_DROPDOWN,
  ACT_SECRETO,


} from "./Helpers";

import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_CANCELADO,
} from "./Helpers";

import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_DEVUELTO,
  ID_RETIROCLIENTE,
  ID_BLOQUEADOCLIENTE,
  ID_RECEPCIONBODEGA

} from "./Helpers";

const BSON = require("bson");

class Utils {
  // Color y texto según el tipo de la tarea (LETRAS)
  static colorTipoTareaDetalle = (n, tipo) => {
    let color = "";
    let texto = "";
    switch (n) {
      case 1:
        color = "color-yellow";
        texto = "PICK UP";
        break;
      case 2:
        color = "color-orange";
        texto = "DELIVERY";
        break;
      case 3:
        color = "color-purple";
        texto = "CITA";
        break;
      case 4:
        color = "color-blue";
        texto = "DEVOLUCIÓN";
        break;
      default:
        color = "color-grey";
        texto = "na";
        break;
    }
    if (tipo) {
      return color;
    } else {
      return texto;
    }
  };

  // Color y texto según el tipo de tarea (FONDO)
  static colorTipoTarea = (n, tipo) => {
    let color = "";
    let texto = "";
    switch (n) {
      case 1:
        color = "back-yellow";
        texto = "P";
        break;
      case 2:
        color = "dot-asignado";
        texto = "D";
        break;
      case 3: //bg-purple
        color = "bg-purple";
        texto = "C";
        break;
      case 4: //bg-purple
        color = "dot-iniciado";
        texto = "DV";
        break;
      default:
        color = "inactivo";
        texto = "na";
        break;
    }
    if (tipo) {
      return color;
    } else {
      return texto;
    }
  };

  // Mayuscula en cada palabra de una frase
  static toCapitalize = (text) => {
    text = text.split(" ");
    text.forEach((txt, i) => {
      text[i] = txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
    return text.join(" ");
  };

  // Color y texto según el estado de la tarea
  static colorTarea = (id, tipo) => {

    // console.log ("CONSULTA COLOR", id)
    let color = "";
    let texto = "";

    switch (id) {
      case ID_ASIGNADO:
        color = "dot-asignado";
        texto = "Asignado";
        break;
      case ID_ACEPTADO:
        color = "dot-aceptado";
        texto = "Aceptado";
        break;
      case ID_INICIADO:
        color = "dot-iniciado";
        texto = "Iniciado";
        break;
      case ID_EN_PROGRESO:
        color = "dot-en-progreso";
        texto = "En Progreso";
        break;
      case ID_EXITOSO:
        color = "dot-exitoso";
        texto = "Exitoso";
        break;
      case ID_FALLIDO:
        color = "dot-haFallado";
        texto = "Ha Fallado";
        break;
      case ID_RECHAZADO:
        color = "dot-rechazado";
        texto = "Rechazado";
        break;
      case ID_CANCELADO:
        color = "dot-cancelado";
        texto = "Cancelado";
        break;
      case ID_DEVUELTO:
        color = "dot-devuelto";
        texto = "Devuelto";
        break;
      case ID_RETIROCLIENTE:
        color = "dot-RetiroCliente"
        texto = "Retiro BodegaCliente"
        break;
      case ID_BLOQUEADOCLIENTE:
        color = "dot-Bloqueado"
        texto = "Bloqueado Cliente"
        break;

      case ID_RECEPCIONBODEGA:
        color = "dot-RecepcionadoBodega"
        texto = "Bodega Rayo"
        break;


      default:
        color = "back-medium-grey";
        texto = "No asignado";
        break;
    }

    if (tipo) {
      return color;
    } else {
      return texto;
    }
  };

  static nombreEstado = (n) => {

    console.log("consulta Estado:", n)

    let texto = "";
    switch (n) {
      case ID_NO_ASIGNADO:
        texto = "No asignado";
        break;
      case ID_ASIGNADO:
        texto = "Asignado";
        break;
      case ID_ACEPTADO:
        texto = "Aceptado";
        break;
      case ID_INICIADO:
        texto = "Iniciado";
        break;
      case ID_EN_PROGRESO:
        texto = "En Progreso";
        break;
      case ID_EXITOSO:
        texto = "Exitoso";
        break;
      case ID_FALLIDO:
        texto = "Fallido";
        break;
      case ID_RECHAZADO:
        texto = "Rechazado";
        break;
      case ID_CANCELADO:
        texto = "Cancelado";
        break;
      case ID_DEVUELTO:
        texto = "Devuelto";
        break;
      case ID_RETIROCLIENTE:
        texto = "Retiro Bodega Cliente";
        break;
      case ID_BLOQUEADOCLIENTE:
        texto = "Bloqueado Cliente";
        break;

      case ID_RECEPCIONBODEGA:
        texto = "Recepcionado Bodega Rayo";
        break;


      case null:
        texto = "Todos los estados";
        break;
      default:
        texto = "Personalizado";
        break;
    }

    return texto;
  };

  // Maneja alerta generica
  static handleAlert = (title, text, confirmButtonText) => {
    Swal.fire({
      title: title,
      text: text,
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      confirmButtonText: confirmButtonText,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
      },
    });
  };

  // Re-ordena arreglo
  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Mueve objetos entre arreglos
  static move = (
    source,
    destination,
    droppableSource,
    droppableDestination
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  // Contador de arreglos validado
  static contador(array) {
    let contador = 0;
    if (array === undefined || array === null) {
      contador = 0;
    } else {
      contador = array.length;
    }
    return contador;
  }

  // Contador de Rayos según estado
  static numRayo(state, rayos) {
    let contador = 0;

    if (rayos === undefined || rayos === null) {
      contador = 0;
    } else {
      rayos.forEach((rayo, i) => {
        if (rayo.status_rayo === state) {
          contador = contador + 1;
        }
      });
    }
    return contador;
  }

  // Maneja filtros globales
  static changeFilter = (vista, panel, newFilters, filters) => {
    panel = parseInt(panel);
    if (filters === null || filters === "null") {
      let hoy = new Date().setHours(0, 0, 0, 0);
      let hoyFin = new Date().setHours(23, 59, 59, 999);
      hoyFin = moment(hoyFin).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      hoy = moment(hoy).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      filters = [
        [
          {
            filter: "idStatusTaskgroup",
            data: null,
          },
          {
            filter: "idStatusTaskgroup",
            data: null,
          },
        ],
        [
          {
            filter: "initDate",
            data: hoy,
          },
          {
            filter: "endDate",
            data: hoyFin,
          },
          {
            filter: "idRayo",
            data: null,
          },
          {
            filter: "idCustomer",
            data: null,
          },
        ],
        [
          {
            filter: "idStatusTask",
            data: null,
          },
          {
            filter: "all",
            data: null,
          },
        ],
      ];
    }

    if (vista === "/Mapa/Lista") {
      newFilters.forEach((newFilter) => {
        if (newFilter.filter === "idStatusTask") {
          filters[2][0].data = newFilter.data;
        } else if (
          newFilter.filter === "all" ||
          newFilter.filter === "orderIdTask" ||
          newFilter.filter === "contactNameTask" ||
          newFilter.filter === "addressTask"
        ) {
          filters[2][1].filter = newFilter.filter;
          filters[2][1].data = newFilter.data;
        } else if (
          newFilter.filter !== "idStatusTaskgroup" ||
          newFilter.filter !== "idStatusTask" ||
          newFilter.filter !== "all" ||
          newFilter.filter !== "orderIdTask" ||
          newFilter.filter !== "contactNameTask" ||
          newFilter.filter !== "addressTask"
        ) {
          const i = filters[1].findIndex(
            (element) => element.filter === newFilter.filter
          );
          filters[1][i].data = newFilter.data;
        }
      });
    } else {
      newFilters.forEach((newFilter) => {
        if (newFilter.filter === "idStatusTaskgroup") {
          filters[0][panel].data = newFilter.data;
        } else if (
          newFilter.filter !== "idStatusTask" ||
          newFilter.filter !== "all" ||
          newFilter.filter !== "orderIdTask" ||
          newFilter.filter !== "contactNameTask" ||
          newFilter.filter !== "addressTask"
        ) {
          const i = filters[1].findIndex(
            (element) => element.filter === newFilter.filter
          );
          filters[1][i].data = newFilter.data;
        }
      });
    }

    return filters;
  };

  static changeFilterRayo = (newFilters, filters) => {
    if (filters === null || filters === "null") {
      filters = [
        {
          filter: "idProvider",
          data: null,
        },
        {
          filter: "idCustomer",
          data: null,
        },
        {
          filter: "idRayo",
          data: null,
        },
      ];
    }

    newFilters.forEach((newFilter) => {
      const i = filters.findIndex(
        (element) => element.filter === newFilter.filter
      );
      filters[i].data = newFilter.data;
    });

    return filters;
  };

  static getPolylines = (data) => {
    let result = decode(data, 5).map((item) => {
      return { lat: item[0], lng: item[1] }
    });
    return result;
  }

  // Pasa filtros globales a cadena de caracteres legible para la API
  static stringifyFilters = (vista, panel, filters) => {
    panel = parseInt(panel);
    let stringFilters = "";
    if (vista === "/Home/Mapa") {
      if (filters[0][panel].data !== null) {
        stringFilters =
          stringFilters +
          filters[0][panel].filter +
          "=" +
          filters[0][panel].data;
      }
    } else {
      if (filters[2][0].data !== null) {
        let signo = "&";
        if (stringFilters === "") {
          signo = "";
        }
        stringFilters =
          stringFilters +
          signo +
          filters[2][0].filter +
          "=" +
          filters[2][0].data;
      }
      if (filters[2][1].data !== null) {
        let signo = "&";
        if (stringFilters === "") {
          signo = "";
        }
        stringFilters =
          stringFilters +
          signo +
          filters[2][1].filter +
          "=" +
          filters[2][1].data;
      }
    }

    filters[1].forEach((element, i) => {
      if (element.data !== null) {
        let signo = "&";
        if (stringFilters === "") {
          signo = "";
        }
        stringFilters =
          stringFilters + signo + element.filter + "=" + element.data;
      }
    });
    return stringFilters;
  };

  static findCustommer = (filtros, grupoTareas) => {
    let encontrado = false;

    filtros[1].forEach((element) => {
      if (element.filter === "idCustomer" && element.data === null) {
        // SIN FILTRO POR CLIENTE
        encontrado = true;
      } else if (
        element.filter === "idCustomer" &&
        typeof element.data === "string"
      ) {
        let clientes = element.data.split(",");

        clientes.forEach((element) => {
          if (element === grupoTareas.idcustomer_taskgroup) {
            encontrado = true;
          }
        });
      }
    });

    return encontrado;
  };

  static findCustommerTask = (filtros, tarea) => {
    let encontrado = false;
    filtros[1].forEach((element) => {
      if (element.filter === "idCustomer" && element.data === null) {
        // SIN FILTRO POR CLIENTE
        encontrado = true;
      } else if (
        element.filter === "idCustomer" &&
        typeof element.data === "string"
      ) {
        let clientes = element.data.split(",");
        clientes.forEach((element) => {
          if (element === tarea.idcustomer_task) {
            encontrado = true;
          }
        });
      }
    });
    return encontrado;
  };

  static stringifyFiltersRayo = (filters) => {
    let stringFilters = "";
    filters.forEach((element, i) => {
      if (element.data !== null) {
        let signo = "&";
        if (stringFilters === "") {
          signo = "";
        }
        stringFilters =
          stringFilters + signo + element.filter + "=" + element.data;
      }
    });
    return stringFilters;
  };

  static stringifyFiltersRayoJustProvider = (filters) => {
    let stringFilters = "";
    filters.forEach((element, i) => {
      if (element.filter === "idProvider") {
        if (element.data !== null) {
          stringFilters = element.data;
        }
      }
    });
    return stringFilters;
  };

  static getFilterIdProviderEPA = () => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtrosRayo"));
    let stringifyFiltro =
      Utils.stringifyFiltersRayoJustProvider(filtrosGlobales);
    return "&idProvider=" + stringifyFiltro;
  };

  static getFilterIdProviderEPATask = () => {
    let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtrosRayo"));
    let stringifyFiltro =
      Utils.stringifyFiltersRayoJustProvider(filtrosGlobales);
    return (
      "&idProvider=" +
      stringifyFiltro +
      "&idStatusTask=5f05f3607f5164494c74b966,5f05f3d37f5164494c74b967,5f05f3dc7f5164494c74b968,5f05f3eb7f5164494c74b969,5f05f3f77f5164494c74b96a,5f05f4077f5164494c74b96b,5f05f4147f5164494c74b96c,5f05f4217f5164494c74b96d,5f05f42e7f5164494c74b96e"
    );
  };

  // Proporciona Ally Props a Tabs
  static a11yProps(index) {
    return {
      id: "wrapped-tab-" + index,
      "aria-controls": "wrapped-tabpanel-" + index,
    };
  }

  // Maneja vistas visibles según valor ingresado
  static handleVistasVisibles = (value) => {
    if (value) {
      return "visible";
    } else {
      return "no-visible";
    }
  };

  static handleTaskGroupSelected = (path) => {
    if (
      path === "/Home/Mapa" ||
      path === "/Home/Lista" ||
      path === "/Home/Tarea/Crear/0"
    ) {
      return false;
    } else {
      return true;
    }
  };

  static localizacionesGT = (gruposTareas) => {
    let arreglo = [];
    const icon1 = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-p.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };
    const icon2 = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-d.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };
    const icon3 = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-c.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };

    gruposTareas.forEach((taskGroup) => {
      let lat = taskGroup.latitude_taskgroup;
      let lng = taskGroup.longitude_taskgroup;
      let objeto = {
        icon: icon1,
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        map: null,
        title: taskGroup.orden_id_taskgroup,
        placeName: taskGroup._id,
        type: taskGroup.type_taskgroup,
        contact: taskGroup.contact_name_taskgroup,
      };

      if (taskGroup.type_taskgroup === 2) {
        objeto.icon = icon2;
      } else if (taskGroup.type_taskgroup === 3) {
        objeto.icon = icon3;
      }
      arreglo.push(objeto);
    });

    return arreglo;
  };

  static localizacionesT = (gruposTareas, tipo) => {
    let arreglo = [];
    const icon1 = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-p.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };
    const icon2 = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-d.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };
    const icon3 = {
      url: "https://storage.cloud.google.com/fleet-manager-env.appspot.com/Assets/icono-c-2.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };

    const icon4 = {
      url: "https://storage.cloud.google.com/fleet-manager-env.appspot.com/Assets/icono-dv.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };

    gruposTareas.forEach((taskGroup) => {
      taskGroup.tasks.forEach((element) => {
        let lat = element.latitude_task;
        let lng = element.longitude_task;
        let objeto = {
          icon: icon1,
          position: { lat: parseFloat(lat), lng: parseFloat(lng) },
          map: null,
          title: element.order_id_task,
          placeName: taskGroup._id,
          task: element._id,
          type: element.type_task,
          contact: element.contact_name_task,
        };

        if (element.type_task === 2) {
          objeto.icon = icon2;
        } else if (element.type_task === 3) {
          objeto.icon = icon3;
        } else if (element.type_task === 4) {
          objeto.icon = icon4;
        }
        if (lat && lng) arreglo.push(objeto);
      });

      if (tipo && taskGroup.tasks.length === 0) {
        let lat = taskGroup.latitude_taskgroup;
        let lng = taskGroup.longitude_taskgroup;
        let objeto = {
          icon: icon1,
          position: { lat: parseFloat(lat), lng: parseFloat(lng) },
          map: null,
          title: taskGroup.orden_id_taskgroup,
          placeName: taskGroup._id,
          task: taskGroup._id,
          type: taskGroup.type_taskgroup,
          contact: taskGroup.contact_name_taskgroup,
        };

        if (taskGroup.type_taskgroup === 2) {
          objeto.icon = icon2;
        } else if (taskGroup.type_taskgroup === 3) {
          objeto.icon = icon3;
        }

        if (lat && lng) arreglo.push(objeto);
      }
    });

    return arreglo;
  };

  static getCustomers = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let clientes = "?idCustomer=";
    if (user.customers != null) {
      if (user.customers.length === 0) {
        clientes = "";
      } else {
        user.customers.forEach((element, i) => {
          if (i === 0) {
            clientes = clientes + "" + element;
          } else {
            clientes = clientes + "," + element;
          }
        });
      }
    }
    clientes = clientes + (clientes.length > 0 ? "&" : "?") + "includeInactives=false";
    return clientes;
  };

  static getCustomerString = () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let clientes = "";

    if (user != null) {
      if (user.customers != null) {
        if (user.customers.length === 0) {
          clientes = null;
        } else {
          user.customers.forEach((element, i) => {
            if (i === 0) {
              clientes = clientes + "" + element;
            } else {
              clientes = clientes + "," + element;
            }
          });
        }
      } else {
        clientes = null;
      }
    } else {
      clientes = null;
    }

    return clientes;
  };

  static getBSONtoString = (object) => {
    let objectID = new BSON.ObjectId(object);
    let stringObject = objectID.toString();

    return stringObject;
  };

  static filterTaskGroups = (grupos, seleccionDesdeMapa, filtro_tareas) => {
    let filterGrupos;
    if (grupos == null) {
      filterGrupos = [];
    } else {
      filterGrupos = grupos.filter((grupo) => {
        let data = "true";
        let bandera = false;
        if (seleccionDesdeMapa) {
          if (
            grupo.selected
              .toString()
              .toLowerCase()
              .includes(data.toLowerCase()) &&
            (grupo.contact_name_taskgroup
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()) ||
              grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
              grupo.orden_id_taskgroup
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()))
          ) {
            return true;
          }
        } else {
          if (
            grupo.contact_name_taskgroup
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase()) ||
            grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
            grupo.orden_id_taskgroup
              .toLowerCase()
              .includes(filtro_tareas.toLowerCase())
          ) {
            return true;
          }
        }

        grupo.tasks.forEach((tarea) => {
          if (seleccionDesdeMapa) {
            if (
              grupo.selected
                .toString()
                .toLowerCase()
                .includes(data.toLowerCase()) &&
              (tarea.order_id_task
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()) ||
                tarea.address_task
                  .toLowerCase()
                  .includes(filtro_tareas.toLowerCase()) ||
                tarea._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
                tarea.contact_name_task
                  .toLowerCase()
                  .includes(filtro_tareas.toLowerCase()) ||
                grupo.contact_name_taskgroup
                  .toLowerCase()
                  .includes(filtro_tareas.toLowerCase()) ||
                grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
                //grupo.address_taskgroup.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
                grupo.orden_id_taskgroup
                  .toLowerCase()
                  .includes(filtro_tareas.toLowerCase()))
            ) {
              bandera = true;
              return;
            }
          } else {
            if (
              tarea.order_id_task
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()) ||
              tarea.address_task
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()) ||
              tarea._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
              tarea.contact_name_task
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()) ||
              grupo.contact_name_taskgroup
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase()) ||
              grupo._id.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
              //grupo.address_taskgroup.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
              grupo.orden_id_taskgroup
                .toLowerCase()
                .includes(filtro_tareas.toLowerCase())
            ) {
              bandera = true;
              return;
            }
          }
        });

        return bandera;
      });
    }
    return filterGrupos.length;
  };

  static filterRayos = (rayos, tipo, filtro_rayos) => {
    let filterRayos = [];

    if (rayos) {
      filterRayos = rayos.filter((rayo) => {
        let bandera = false;
        if (
          rayo.status_rayo
            .toString()
            .toLowerCase()
            .includes(tipo.toString().toLowerCase()) &&
          (rayo.name_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) ||
            rayo.dni_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) ||
            rayo._id.toLowerCase().includes(filtro_rayos.toLowerCase()))
        ) {
          bandera = true;
        }
        return bandera;
      });
    }

    return filterRayos.length;
  };

  static downloadCSVClient = (tasks, name) => {
    let fecha = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    let csvContent = "data:text/csv; charset=utf-8,";
    // AñADIR ESTADO
    let data =
      "\uFEFFID;ID Task;Order ID;Tipo;Estado;Nombre;Teléfono;Dirección;Fecha de creación;Fecha finalización;Descripción;ID Customer;Nombre Cliente\r\n";
    tasks.forEach((task) => {
      // let soloFecha = task.Date.split('T')[0];
      // let soloHora = task.Date.split('T')[1].split('.')[0];
      let soloFechaEnd = "";
      let soloHoraEnd = "";
      if (task.End_Date) {
        soloFechaEnd = task.End_Date.split("T")[0];
        soloHoraEnd = task.End_Date.split("T")[1].split(".")[0];
      } else {
        soloFechaEnd = "-";
        soloHoraEnd = "";
      }

      let descripcion = task.Description.split("\n").join("");
      let soloFechaC = task.Creation_Date.split("T")[0];
      let soloHoraC = task.Creation_Date.split("T")[1].split(".")[0];
      data =
        data +
        task.Task_Code +
        ";" +
        task.Task_ID +
        ";" +
        task.Order_ID +
        ";" +
        task.Type +
        ";" +
        task.Status +
        ";" +
        task.Name +
        ";" +
        task.Phone +
        ";" +
        task.Address +
        ";" +
        soloFechaC +
        " " +
        soloHoraC +
        ";" +
        // soloFecha +
        // ' ' +
        // soloHora +
        // ';' +
        soloFechaEnd +
        " " +
        soloHoraEnd +
        ";" +
        descripcion +
        ";" +
        task.Customer_ID +
        ";" +
        task.Customer_Name +
        "\r\n";
    });
    let encodedUri = encodeURIComponent(data);
    let link = document.createElement("a");
    link.setAttribute("href", csvContent + encodedUri);
    link.setAttribute(
      "download",
      "tareas_" + name + "_" + fecha.split("T")[0] + ".csv"
    );
    document.body.appendChild(link);
    link.click();
  };

  static downloadCSV = (tasks, name) => {
    let fecha = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    let csvContent = "data:text/csv; charset=utf-8,";
    // AñADIR ESTADO
    let data =
      "\uFEFFID;ID Task;Order ID;Tipo;Estado;Nombre;Teléfono;Dirección;Fecha de creación;Fecha finalización;Descripción;ID Customer;Nombre Cliente;Rayo\r\n";
    tasks.forEach((task) => {
      // let soloFecha = task.Date.split('T')[0];
      // let soloHora = task.Date.split('T')[1].split('.')[0];
      let soloFechaEnd = "";
      let soloHoraEnd = "";
      if (task.End_Date) {
        soloFechaEnd = task.End_Date.split("T")[0];
        soloHoraEnd = task.End_Date.split("T")[1].split(".")[0];
      } else {
        soloFechaEnd = "-";
        soloHoraEnd = "";
      }

      let descripcion = task.Description.split("\n").join("");
      let soloFechaC = task.Creation_Date.split("T")[0];
      let soloHoraC = task.Creation_Date.split("T")[1].split(".")[0];
      data =
        data +
        task.Task_Code +
        ";" +
        task.Task_ID +
        ";" +
        task.Order_ID +
        ";" +
        task.Type +
        ";" +
        task.Status +
        ";" +
        task.Name +
        ";" +
        task.Phone +
        ";" +
        task.Address +
        ";" +
        soloFechaC +
        " " +
        soloHoraC +
        ";" +
        // soloFecha +
        // ' ' +
        // soloHora +
        // ';' +
        soloFechaEnd +
        " " +
        soloHoraEnd +
        ";" +
        descripcion +
        ";" +
        task.Customer_ID +
        ";" +
        task.Customer_Name +
        ";" +
        task.Rayo +
        "\r\n";
    });
    let encodedUri = encodeURIComponent(data);
    let link = document.createElement("a");
    link.setAttribute("href", csvContent + encodedUri);
    link.setAttribute(
      "download",
      "tareas_" + name + "_" + fecha.split("T")[0] + ".csv"
    );
    document.body.appendChild(link);
    link.click();
  };

  static getOptionTaskGroups = (filtrosGlobales) => {
    let iR = filtrosGlobales[1].findIndex(
      (element) => element.filter === "idRayo"
    );
    let iS = filtrosGlobales[1].findIndex(
      (element) => element.filter === "initDate"
    );
    let iE = filtrosGlobales[1].findIndex(
      (element) => element.filter === "endDate"
    );
    let rayos = filtrosGlobales[1][iR].data;

    let fecha_inicio = filtrosGlobales[1][iS].data;
    let fecha_fin = filtrosGlobales[1][iE].data;
    let customers = [];

    filtrosGlobales[1].forEach((element) => {
      if (element.filter === "idCustomer" && element.data === null) {
        customers = null;
      } else if (
        element.filter === "idCustomer" &&
        typeof element.data === "string"
      ) {
        let clientes = element.data.split(",");

        clientes.forEach((element) => {
          customers.push(new BSON.ObjectId(element));
        });
      }
    });

    let filtersInsertTaskGroups = {
      operationType: "insert",
      "fullDocument.start_date_taskgroup": {
        $gte: new Date(Date.parse(fecha_inicio)),
        $lte: new Date(Date.parse(fecha_fin)),
      },
    };

    let filtersUpdateTaskGroups = {
      operationType: "update",
      $or: [
        {
          "updateDescription.updatedFields.idstatus_taskgroup": {
            $exists: true,
          },
        },
        {
          "updateDescription.updatedFields.idrayo_taskgroup": { $exists: true },
        },
      ],
      "fullDocument.start_date_taskgroup": {
        $gte: new Date(Date.parse(fecha_inicio)),
        $lte: new Date(Date.parse(fecha_fin)),
      },
    };

    if (customers && rayos) {
      filtersInsertTaskGroups = {
        operationType: "insert",
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_taskgroup": {
          $in: customers,
        },
        "fullDocument.idrayo_taskgroup": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };

      filtersUpdateTaskGroups = {
        operationType: "update",
        $or: [
          {
            "updateDescription.updatedFields.idstatus_taskgroup": {
              $exists: true,
            },
          },
          {
            "updateDescription.updatedFields.idrayo_taskgroup": {
              $exists: true,
            },
          },
        ],
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_taskgroup": {
          $in: customers,
        },
        "fullDocument.idrayo_taskgroup": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (!customers && rayos) {
      filtersInsertTaskGroups = {
        operationType: "insert",
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idrayo_taskgroup": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };

      filtersUpdateTaskGroups = {
        operationType: "update",
        $or: [
          {
            "updateDescription.updatedFields.idstatus_taskgroup": {
              $exists: true,
            },
          },
          {
            "updateDescription.updatedFields.idrayo_taskgroup": {
              $exists: true,
            },
          },
        ],
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idrayo_taskgroup": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (customers && !rayos) {
      filtersInsertTaskGroups = {
        operationType: "insert",
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_taskgroup": {
          $in: customers,
        },
      };

      filtersUpdateTaskGroups = {
        operationType: "update",
        $or: [
          {
            "updateDescription.updatedFields.idstatus_taskgroup": {
              $exists: true,
            },
          },
          {
            "updateDescription.updatedFields.idrayo_taskgroup": {
              $exists: true,
            },
          },
        ],
        "fullDocument.start_date_taskgroup": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_taskgroup": {
          $in: customers,
        },
      };
    }

    let optionTaskGroups = {
      filter: {
        $or: [
          {
            operationType: "delete",
          },
          filtersInsertTaskGroups,
          filtersUpdateTaskGroups,
        ],
      },
    };

    return optionTaskGroups;
  };

  static getOptionTasks = (filtrosGlobales) => {
    let iR = filtrosGlobales[1].findIndex(
      (element) => element.filter === "idRayo"
    );
    let iS = filtrosGlobales[1].findIndex(
      (element) => element.filter === "initDate"
    );
    let iE = filtrosGlobales[1].findIndex(
      (element) => element.filter === "endDate"
    );
    let rayos = filtrosGlobales[1][iR].data;

    let fecha_inicio = filtrosGlobales[1][iS].data;
    let fecha_fin = filtrosGlobales[1][iE].data;
    let customers = [];

    filtrosGlobales[1].forEach((element) => {
      if (element.filter === "idCustomer" && element.data === null) {
        customers = null;
      } else if (
        element.filter === "idCustomer" &&
        typeof element.data === "string"
      ) {
        let clientes = element.data.split(",");

        clientes.forEach((element) => {
          customers.push(new BSON.ObjectId(element));
        });
      }
    });

    let filtersTasks = {
      operationType: {
        $in: ["insert"],
      },
      "fullDocument.date_task": {
        $gte: new Date(Date.parse(fecha_inicio)),
        $lte: new Date(Date.parse(fecha_fin)),
      },
    };

    if (customers && rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_task": {
          $in: customers,
        },
        "fullDocument.idrayo_task": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (!customers && rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idrayo_task": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (customers && !rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_task": {
          $in: customers,
        },
      };
    }

    let optionTasks = {
      filter: {
        $or: [filtersTasks],
      },
    };

    return optionTasks;
  };

  static getOptionTasksActions = (filtrosGlobales) => {
    let iR = filtrosGlobales[1].findIndex(
      (element) => element.filter === "idRayo"
    );
    let iS = filtrosGlobales[1].findIndex(
      (element) => element.filter === "initDate"
    );
    let iE = filtrosGlobales[1].findIndex(
      (element) => element.filter === "endDate"
    );
    let rayos = filtrosGlobales[1][iR].data;

    let fecha_inicio = filtrosGlobales[1][iS].data;
    let fecha_fin = filtrosGlobales[1][iE].data;
    let customers = [];

    filtrosGlobales[1].forEach((element) => {
      if (element.filter === "idCustomer" && element.data === null) {
        customers = null;
      } else if (
        element.filter === "idCustomer" &&
        typeof element.data === "string"
      ) {
        let clientes = element.data.split(",");

        clientes.forEach((element) => {
          customers.push(new BSON.ObjectId(element));
        });
      }
    });

    let filtersTasks = {
      operationType: {
        $in: ["insert", "update"],
      },
      "fullDocument.date_task": {
        $gte: new Date(Date.parse(fecha_inicio)),
        $lte: new Date(Date.parse(fecha_fin)),
      },
    };

    if (customers && rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert", "update"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_task": {
          $in: customers,
        },
        "fullDocument.idrayo_task": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (!customers && rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert", "update"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idrayo_task": {
          $in: [new BSON.ObjectId(rayos)],
        },
      };
    } else if (customers && !rayos) {
      filtersTasks = {
        operationType: {
          $in: ["insert", "update"],
        },
        "fullDocument.date_task": {
          $gte: new Date(Date.parse(fecha_inicio)),
          $lte: new Date(Date.parse(fecha_fin)),
        },
        "fullDocument.idcustomer_task": {
          $in: customers,
        },
      };
    }

    let optionTasks = {
      filter: {
        $or: [
          {
            operationType: "delete",
          },
          filtersTasks,
        ],
      },
    };

    return optionTasks;
  };

  static getOptionTaskGroup = (id) => {
    let optionTaskGroups = {
      filter: {
        $or: [
          {
            operationType: "delete",
          },
          {
            operationType: {
              $in: ["update"],
            },
            "fullDocument._id": {
              $in: [new BSON.ObjectId(id)],
            },
          },
        ],
      },
    };

    return optionTaskGroups;
  };

  static getOptionMultipleTasks = (idTaskGroup) => {
    let optionTasks = {
      filter: {
        $or: [
          {
            operationType: "delete",
          },
          {
            operationType: {
              $in: ["update"],
            },
            "fullDocument.idTasksGroup_task": {
              $in: [new BSON.ObjectId(idTaskGroup)],
            },
          },
        ],
      },
    };

    return optionTasks;
  };

  static nameRayo = (id, rayos) => {
    let nombreRayo = "";
    if (id === null || id === "null") {
      nombreRayo = "Sin rayo";
    } else {
      if (rayos) {
        const i = rayos.findIndex((element) => element._id === id);
        if (i >= 0) {
          nombreRayo = rayos[i].name_rayo;
        }
      }
    }

    return nombreRayo;
  };

  static copyText = (id) => {
    console.log("copyText");
    let textArea = document.createElement("textarea");
    textArea.value = id;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      if (successful) {
        document.getElementById(id).classList.remove("hidden");
        setTimeout(() => {
          document.getElementById(id).classList.add("hidden");
        }, 2000);
      }
    } catch (err) { }

    document.body.removeChild(textArea);
  };

  static copyJSON = (copytext) => {
    console.log("copy: ", copytext);
    let textArea = document.createElement("textarea");
    textArea.id = "metadataJson";
    textArea.value = copytext;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");

      console.log("successful", successful);
      document.body.removeChild(textArea);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  static justCopyText = (id) => {
    console.log("justCopyText");
    let textArea = document.createElement("textarea");
    textArea.value = id;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      if (successful) {
        document.getElementById(id).classList.remove("hidden");
        setTimeout(() => {
          if (document.getElementById(id)) {
            document.getElementById(id).classList.add("hidden");
          }
        }, 2000);
      }
    } catch (err) { }

    document.body.removeChild(textArea);
  };

  static justCopyText2 = (id, copytext) => {
    let textArea = document.createElement("textarea");
    textArea.value = copytext;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand("copy");
      if (successful) {
        document.getElementById(id).classList.remove("hidden");
        setTimeout(() => {
          if (document.getElementById(id)) {
            document.getElementById(id).classList.add("hidden");
          }
        }, 2000);
      }
    } catch (err) { }

    document.body.removeChild(textArea);
  };

  static modificarOrdenTareas = (backlog) => {
    let data = cloneDeep(backlog);
    fetch(url.host + "/Backlogs/ordertasks_backlog/", {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => { })
      .catch((error) => { });
  };

  static fechaTerminoTask = (historial) => {
    let fechaTermino = null;

    historial.forEach((element) => {
      if (element.description.toLowerCase().includes("finalizado")) {
        fechaTermino = element.date;
      }
    });

    if (fechaTermino) {
      return {
        color: "",
        texto: moment(fechaTermino).utcOffset("+00").format("DD-MM-YYYY HH:mm"),
      };
    } else {
      return { color: " color-light-grey", texto: "-No definida-" };
    }
  };

  static tareasExitosas = (tasks) => {
    let count = 0;

    tasks.forEach((element) => {
      if (element.idstatus_task === "5f05f4077f5164494c74b96b") {
        count = count + 1;
      }
    });

    return count;
  };

  static stringifyFiltersCliente = (filters) => {
    let stringFilters = "";

    filters[1].forEach((element, i) => {
      if (element.data !== null && element.filter === "idCustomer") {
        let signo = "&";
        stringFilters =
          stringFilters + signo + element.filter + "=" + element.data;
      }
    });
    return stringFilters;
  };

  static nameCustomerSeguimiento = (name) => {
    let newName = "";

    if (name === "Farmacias Cruz Verde") {
      newName = "CruzVerde";
    } else if (name === "Bata Chile") {
      newName = "Bata";
    } else if (name === "Claro Chile") {
      newName = "Claro";
    } else if (name === "Alsea Chile") {
      newName = "Alsea";
    } else {
      newName = name;
    }

    return newName;
  };

  static createObjectActivities = (template) => {
    let newTemplate = [];
    template.forEach((element) => {
      let action = element;
      switch (element.idTypeActivity) {
        case ACT_FIRMA.id:
        case ACT_FOTO.id:
          action.data = null;
          break;

        default:
          action.data = "";
          break;
      }
      newTemplate.push(action);
    });
    return newTemplate;
  };

  static templateData = (templates) => {
    let templateData = [];
    templates.forEach((element) => {
      if (element.data !== "" || element.data !== null || !element.data) {
        switch (element.idTypeActivity) {
          case ACT_CODIGO_BARRAS.id:
            templateData.push({
              [ACT_CODIGO_BARRAS.code]: element.data,
            });
            break;

          case ACT_FIRMA.id:
            templateData.push({
              [ACT_FIRMA.code]: element.data,
            });
            break;

          case ACT_FOTO.id:
            templateData.push({
              [ACT_FOTO.code]: element.data,
            });
            break;

          case ACT_SECRETO.id:
            templateData.push({
              [ACT_SECRETO.code]: element.data,
            });
            break;

          case ACT_CODIGO_QR.id:
            templateData.push({
              [ACT_CODIGO_QR.code]: element.data,
            });
            break;

          case ACT_DROPDOWN.id:
            templateData.push({
              [ACT_DROPDOWN.code]: element.data,
            });
            break;

          default:
            templateData.push({
              [ACT_NOTA.code]: element.data,
            });
            break;
        }
      }
    });

    return templateData;
  };
}

export default Utils;
