import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import CircularProgress from "@material-ui/core/CircularProgress";
import { geocodeByPlaceId } from "react-places-autocomplete";
import "../../../css/global.css";
import * as rayosAccion from "../../../store/actions/Rayos";
import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";
import * as tareasAccion from "../../../store/actions/Tareas";
import { WrappedMapDetalle, WrappedMapRoute } from "../../../assets/components/mapas/MapaTabs";
import RefactorGoogleMapTareas from "../../../assets/components/mapas/RefactorGoogleMapFunctionTareas";
import RefactorGoogleMapTareas1 from "../../../assets/components/mapas/RefactorGoogleMapFunctionTareas1";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { APPCONFIG, SERVICE_NAME } from "../../../assets/utils/Helpers";
import { ID_INICIAL_PERSONALIZADO } from "../../../assets/utils/Helpers";
import {
  N_GRUPOTAREA,
  N_TAREA,
  DATA_EDITAR,
  // THEME_METADATA,
} from "../../../assets/utils/Helpers";
import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_DEVUELTO,
  ID_RETIROCLIENTE
  // TESTING_RAYOS,
} from "../../../assets/utils/Helpers";
import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
  ID_RECEPCIONBODEGA,
} from "../../../assets/utils/Helpers";
import Utils from "../../../assets/utils/Utils";
import Watchs from "../../../assets/utils/Watchs";
import url from "../../../config";
import "../../../css/home.css";

import Cargando from "../../../assets/components/cargando/Cargando";
import CargandoMapa from "../../../assets/components/cargando/CargandoMapa";
import AsignarRayo from "../../../assets/components/home/AsignarRayo";
import CantidadSeleccionados from "../../../assets/components/home/CantidadSeleccionados";
import EditarTareas from "../../../assets/components/tareas/EditarTareas";
import EditarRayo from "../../../assets/components/tareas/EditarRayo";
import EditarEstado from "../../../assets/components/tareas/EditarEstado";
import DuplicarTareas from "../../../assets/components/tareas/DuplicarTareas";
import EliminarTareas from "../../../assets/components/tareas/EliminarTareas";
import DetalleGrupoTareas from "../../../assets/components/home/DetalleGrupoTareas";
import DetalleRayo from "../../../assets/components/home/DetalleRayo";
import ListaRayos from "../../../assets/components/lists/ListaRayos";
import ListaGrupoDeTareas from "../../../assets/components/lists/ListaGrupoDeTareas";
import BotonesTareasSeleccionadas from "../../../assets/components/home/BotonesTareasSeleccionadas";

import IconoReloj from "../../../assets/icons/historia.svg";
import IconoRoute from "../../../assets/icons/gps-route.svg";
import IconoData from "../../../assets/icons/data.svg";
import IconoDuplicar from "../../../assets/icons/icono-duplicar-black.svg";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Popover from "@material-ui/core/Popover";
import SearchIcon from "@material-ui/icons/Search";

import * as Realm from "realm-web";

import MetadataJson from "../../../assets/components/home/MetadataJson";
import { IconButton } from "@material-ui/core";

class VistaMapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      filtrosIniciales: [
        [{ filter: "idStatusTaskgroup", data: ID_NO_ASIGNADO }],
        [{ filter: "idStatusTaskgroup", data: ID_INICIAL_PERSONALIZADO }],
      ],

      filters0: {
        [ID_NO_ASIGNADO]: true,
        [ID_ASIGNADO]: false,
        [ID_ACEPTADO]: false,
        [ID_INICIADO]: false,
        [ID_EN_PROGRESO]: false,
        [ID_EXITOSO]: false,
        [ID_FALLIDO]: false,
        [ID_RECHAZADO]: false,
        [ID_CANCELADO]: false,
        [ID_DEVUELTO]: false,
        [ID_RETIROCLIENTE]: false,
        [ID_RECEPCIONBODEGA]: false
      },

      filters1: {
        [ID_NO_ASIGNADO]: false,
        [ID_ASIGNADO]: true,
        [ID_ACEPTADO]: true,
        [ID_INICIADO]: true,
        [ID_EN_PROGRESO]: true,
        [ID_EXITOSO]: true,
        [ID_FALLIDO]: true,
        [ID_RECHAZADO]: true,
        [ID_CANCELADO]: true,
        [ID_DEVUELTO]: true,
        [ID_RETIROCLIENTE]: true,
        [ID_RECEPCIONBODEGA]: true
      },

      tasksTab: "0",
      rayosTab: "0",
      detalleRayo: "0",

      filtro_rayos: "",
      filtro_tareas: "",
      filtro_tareas_rayo: "",

      panelTareas: true,
      panelRayos: true,
      panelAsignarRayo: false,
      rayos: [],

      detalleRayos: false,
      detalleGT: false,
      cargando: false,
      estados: [],
      failedReason: "",

      grupoTareas: null,
      grupoTareasMover: null,
      rayo: null,
      rayoCoordenadas: [],

      camposEditar: DATA_EDITAR,
      camposEditarSelected: [],
      opcionEditar: "null",
      opcionEditarEstado: "null",

      tipoModal: 1,

      openFiltros: false,
      anchorElFiltros: null,
      contextMenu: { display: "none" },
      contextMenuGT: { display: "none" },
      contextMenuState: false,
      contextMenuGTState: false,
      taskMetadata: null,
      skipGrupoTarea: "",
      historial: false,
      dataHistorial: [],
      tituloHistorial: "",
      template: [],

      optimizar: false,
      wayPoints: [],
      wayPointsId: [],
      optimizarBacklog: false,

      asignarBacklog: false,
      inputBuscarRayo: "",
      abrirInfoBox: false,
      infoBoxInfo: null,
      center: { lat: -33.4473526, lng: -70.6379711 },
      zoom: 10,

      motivoDuplicar: "",
      centerRayo: false,

      rutasRayo: [],
      rutaSeleccionada: null
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  id2List = {
    droppable: "grupoTareas",
    droppable2: "grupoTareasMover",
  };

  handleCenter = (tipo, coordenadas) => {
    if (tipo === "rayo") {
      this.setState({
        center: coordenadas,
        zoom: 18,
      });
    } else if (tipo === "grupoTarea") {
    } else {
      this.setState({
        center: { lat: -33.4473526, lng: -70.6379711 },
        zoom: 10,
      });
    }
  };

  centerMapRayo = async () => {
    try {
      if (this.props.rayos.rayos && this.state.rayo) {
        let i = this.props.rayos.rayos.findIndex(
          (element) => element._id === this.state.rayo._id
        );
        if (i >= 0) {
          let coordenadas = {
            lat: this.props.rayos.rayos[i].latitude_rayo,
            lng: this.props.rayos.rayos[i].longitude_rayo,
          };

          if (this.props.rayos.localidades) {
            const i = this.props.rayos.localidades.findIndex(
              (element) => element.placeName === this.state.rayo._id
            );
            if (i >= 0) {
              let rayoPosition = this.props.rayos.localidades[i];
              rayoPosition.position = coordenadas;

              await this.handleCenter("rayo", coordenadas);
              this.setState(
                {
                  centerRayo: false,
                  abrirInfoBox: false,
                  infoBoxInfo: null,
                },
                () => {
                  this.setState({
                    centerRayo: true,
                    abrirInfoBox: true,
                    infoBoxInfo: rayoPosition,
                  });
                }
              );
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  openInfoBox = (rayo) => {
    if (rayo) {
      this.setState({
        abrirInfoBox: true,
        infoBoxInfo: rayo,
      });
    } else {
      this.setState({
        abrirInfoBox: false,
        infoBoxInfo: null,
      });
    }
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    $("#info-box-optimizar").removeClass("d-none");
    $("#info-box-optimizar").addClass("d-block");

    const { source, destination } = result;
    if (!destination) return;

    if (
      source.droppableId === "droppableRayo" &&
      source.droppableId === destination.droppableId
    ) {
      try {
        const items = Utils.reorder(
          cloneDeep(this.props.rayos.tareas),
          result.source.index,
          result.destination.index
        );
        const { dispatch } = this.props;
        let modificarTareas = rayosAccion.reorderTareas(items);
        dispatch(modificarTareas);

        const tareas = cloneDeep(items);
        let backlogTasks = [];
        tareas.forEach((task, i) => {
          backlogTasks.push({
            id_task: task._id,
            orderNumber_task: i + 1,
          });
        });

        let backlogRayo = {
          idrayo: cloneDeep(this.state.rayo._id),
          tasks: cloneDeep(backlogTasks),
        };

        Utils.modificarOrdenTareas(backlogRayo);

        $("#info-box-optimizar").fadeOut();
        $("#info-box-optimizar").removeClass("d-block");
        $("#info-box-optimizar").addClass("d-none");
        $("#info-box-optimizado").removeClass("d-none");
        $("#info-box-optimizado").addClass("d-block");
        setTimeout(function () {
          $("#info-box-optimizado").fadeOut();
          $("#info-box-optimizado").removeClass("d-block");
          $("#info-box-optimizado").addClass("d-none");
        }, 3000);
      } catch (error) {
        Utils.handleAlert(
          "Error en acción",
          "Se ha podido realizar la acción",
          "Ok"
        );
      }
    } else {
      // const result = Utils.move(
      //     this.getList(source.droppableId).tasks,
      //     this.getList(destination.droppableId).tasks,
      //     source,
      //     destination
      // );
      // this.setState({
      //     [this.id2List[source.droppableId]]: {
      //         ...this.state[this.id2List[source.droppableId]],
      //         tasks: result[source.droppableId],
      //     },
      //     [this.id2List[destination.droppableId]]: {
      //         ...this.state[this.id2List[destination.droppableId]],
      //         tasks: result[destination.droppableId],
      //     },
      // });
    }
  };

  getFechaHoy = () => {
    try {
      let hoy = new Date();
      let hoyFin = new Date().setHours(23, 59, 59, 999);
      hoyFin = moment(hoyFin).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      hoy = moment(hoy).format().split("T")[0] + "T00:00:00Z";
      return [hoy, hoyFin];
    } catch (error) { }
  };

  componentDidMount = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    document.addEventListener("click", this._handleClick);
    document.addEventListener("click", this._handleClickGT);

    const { dispatch } = this.props;
    let fechas = this.getFechaHoy();
    let filtro1 = this.state.filtrosIniciales[0];
    let filtro2 = this.state.filtrosIniciales[1];

    filtro1.push({ filter: "initDate", data: fechas[0] });
    filtro1.push({ filter: "endDate", data: fechas[1] });
    filtro2.push({ filter: "initDate", data: fechas[0] });
    filtro2.push({ filter: "endDate", data: fechas[1] });

    let obtenerGTEstado1 = grupoTareasAccion.obtenerGTFiltro(
      filtro1,
      0,
      "/Home/Mapa",
      true
    );
    let obtenerGTEstado2 = grupoTareasAccion.obtenerGTFiltro(
      filtro2,
      1,
      "/Home/Mapa",
      true
    );

    try {
      dispatch(obtenerGTEstado1);
      dispatch(obtenerGTEstado2);
      this.setState({
        usuario: usuario,
      });
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }

    let IDGrupoTarea = sessionStorage.getItem("taskGroupID");
    if (IDGrupoTarea === null || IDGrupoTarea === "null") {
      await sessionStorage.setItem("taskGroupID", null);
    } else {
      await this.tasksGroup(IDGrupoTarea);
    }

    this.instance_realm(APPCONFIG);

    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.closingRealm();
    });
  };

  closingRealm = async () => {
    try {
      this.watchRayos = null; // UPDATE (solo cambios en: status y lat/lng)
      this.watchBacklog = null; // INSERT, UPDATE
      this.watchTaskGroups = null; // INSERT, UPDATE (solo cambios en: status y rayo), DELETE
      this.watchTasks = null; // INSERT
      this.watchTaskGroupTasks = null; // UPDATE, DELETE (solo cuando esta el detalle de GT abierto)

      await Promise.all(
        this.app.users.map(async (user, index) => {
          await user.logOut();
          await this.app.removeUser(user);
        })
      );
    } catch (error) { }
  };

  instance_realm = async (appCfg) => {
    this.app = new Realm.App(appCfg);
    try {
      if (this.app.users.length > 0) {
        await Promise.all(
          this.app.users.map(async (user, index) => {
            await user.logOut();
            await this.app.removeUser(user);
          })
        );
      }
    } catch (error) { }

    await this.app.logIn(Realm.Credentials.anonymous()).then((user) => {
      this.db = this.app.currentUser
        .mongoClient(SERVICE_NAME)
        .db("fleet_manager");
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

      // ---- WATCH RAYOS UPDATE -----
      let options = {
        filter: {
          operationType: "update",
          $or: [
            {
              "updateDescription.updatedFields.status_rayo": { $exists: true },
            },
            {
              "updateDescription.updatedFields.vigencia": { $exists: true },
            },
            {
              "updateDescription.updatedFields.latitude_rayo": {
                $exists: true,
              },
            },
            {
              "updateDescription.updatedFields.longitude_rayo": {
                $exists: true,
              },
            },
          ],
        },
      };
      this.watchRayos = this.db.collection("rayos").watch(options);
      this.watch_rayo_updated();

      // ---- WATCH BACKLOG INSERT -----
      let optionBacklog = {
        filter: {
          operationType: {
            $in: ["update", "insert"],
          },
        },
      };
      this.watchBacklog = this.db.collection("backlogs").watch(optionBacklog);
      this.watch_backlog();

      // ---- WATCH TASK GROUPS -----
      let optionTaskGroups = Utils.getOptionTaskGroups(filtrosGlobales);
      this.watchTaskGroups = this.db
        .collection("tasks_groups")
        .watch(optionTaskGroups);
      this.watch_taskgroup();

      //---- WATCH TASKS INSERT -----
      let optionTasks = Utils.getOptionTasks(filtrosGlobales);
      this.watchTasks = this.db.collection("tasks").watch(optionTasks);
      this.watch_task_created();

      let IDGrupoTarea = sessionStorage.getItem("taskGroupID");
      if (IDGrupoTarea === "null") IDGrupoTarea = null;
      if (IDGrupoTarea) {
        this.openWatchTasks(IDGrupoTarea);
      }
    });

    sessionStorage.setItem("taskGroupID", null);
  };

  openWatchTasks = (idTaskGroup) => {
    if (this.db) {
      let optionTasks = Utils.getOptionMultipleTasks(idTaskGroup);
      this.watchTaskGroupTasks = this.db.collection("tasks").watch(optionTasks);
      this.watch_taskgrouptasks();
    }
  };

  closeWatchTasks = () => {
    this.watchTaskGroupTasks = null;
  };

  watch_taskgrouptasks = () => {
    if (this.watchTaskGroupTasks) {
      this.watchTaskGroupTasks.next().then((result) => {
        try {
          if (result.value.operationType === "update") {
            let task = Watchs.task_Keys(result.value.fullDocument);
            if (this.state.detalleGT && this.state.grupoTareas) {
              let grupoTareas = this.state.grupoTareas;
              let i = grupoTareas.tasks.findIndex(
                (element) => element._id === task._id
              );

              if (i >= 0) {
                grupoTareas.tasks[i] = task;
                this.setState({ grupoTareas: grupoTareas });
              }
            }
          } else if (result.value.operationType === "delete") {
            let taskID = Utils.getBSONtoString(result.value.documentKey._id);
            if (this.state.detalleGT && this.state.grupoTareas) {
              let grupoTareas = this.state.grupoTareas;
              let i = grupoTareas.tasks.findIndex(
                (element) => element._id === taskID
              );
              if (i >= 0) {
                grupoTareas.tasks.splice(i, 1);
                this.setState({ grupoTareas: grupoTareas });
              }
            }
          }
        } catch (error) { }

        this.watch_taskgrouptasks();
      });
    }
  };

  watch_taskgroup = () => {
    if (this.watchTaskGroups) {
      const { dispatch } = this.props;
      this.watchTaskGroups.next().then((result) => {
        try {
          if (result.value.operationType === "delete") {
            Watchs.taskgroup_deleted(result, dispatch, this.props.grupoTareas);

            if (this.state.detalleGT && this.state.grupoTareas) {
              let id = Utils.getBSONtoString(result.value.documentKey._id);

              if (id === this.state.grupoTareas._id) {
                this.deleteTaskGroupWatch();
              }
            }
          } else if (
            result.value.operationType === "insert" ||
            result.value.operationType === "update"
          ) {
            let taskGroup = this.taskGroup_Keys(result.value.fullDocument);
            if (result.value.operationType === "insert") {
              Watchs.taskgroup_created(
                taskGroup,
                dispatch,
                this.props.grupoTareas,
                this.props.clientes.clientes
              );
            } else if (result.value.operationType === "update") {
              Watchs.taskgroup_updated(
                taskGroup,
                dispatch,
                this.props.grupoTareas,
                this.props.clientes.clientes
              );

              if (this.state.detalleGT && this.state.grupoTareas) {
                if (this.state.grupoTareas._id === taskGroup._id) {
                  this.updateTaskGroupWatch(N_GRUPOTAREA, taskGroup);
                }
              }
            }
          }
        } catch (error) { }
        if (this.watchTaskGroups) this.watch_taskgroup();
      });
    }
  };

  updateTaskGroupWatch = (tipo, data) => {
    let grupoTareas = this.state.grupoTareas;
    if (N_TAREA === tipo) {
    } else if (N_GRUPOTAREA === tipo) {
      grupoTareas.idstatus_taskgroup = data.idstatus_taskgroup;
      grupoTareas.idrayo_taskgroup = data.idrayo_taskgroup;
      this.setState({
        grupoTareas: grupoTareas,
      });
    }
  };

  deleteTaskGroupWatch = () => {
    this.cerrarDetalle();
    Utils.handleAlert(
      "Grupo eliminado",
      "El grupo que estaba visualizado a sido eliminado del sistema",
      "Ok"
    );
  };

  taskGroup_Keys = (document) => {
    let taskGroup = cloneDeep(document);
    taskGroup.id = Utils.getBSONtoString(document._id);
    taskGroup._id = Utils.getBSONtoString(document._id);
    taskGroup.idstatus_taskgroup = Utils.getBSONtoString(
      document.idstatus_taskgroup
    );
    if (document.idrayo_taskgroup == null) {
      taskGroup.idrayo_taskgroup = null;
    } else {
      taskGroup.idrayo_taskgroup = Utils.getBSONtoString(
        document.idrayo_taskgroup
      );
    }
    taskGroup.idcustomer_taskgroup = Utils.getBSONtoString(
      document.idcustomer_taskgroup
    );
    taskGroup.start_date_taskgroup =
      moment(taskGroup.start_date_taskgroup)
        .add(3, "hour")
        .format("YYYY-MM-DDTHH:MM:SS") + ".000Z";

    return taskGroup;
  };

  watch_task_created = () => {
    if (this.watchTasks) {
      const { dispatch } = this.props;
      this.watchTasks.next().then((result) => {
        try {
          let task = Watchs.task_Keys(result.value.fullDocument);
          if (
            this.props.grupoTareas.grupoTareas0 != null ||
            this.props.grupoTareas.grupoTareas1 != null
          ) {
            const i0 = this.props.grupoTareas.grupoTareas0.findIndex(
              (element) => element._id === task.idTasksGroup_task
            );
            const i1 = this.props.grupoTareas.grupoTareas1.findIndex(
              (element) => element._id === task.idTasksGroup_task
            );

            if (i0 >= 0) {
              try {
                let insertTaskInTG = grupoTareasAccion.insertTaskInTG(
                  this.props.grupoTareas.grupoTareas0,
                  task,
                  0,
                  i0
                );
                dispatch(insertTaskInTG);
              } catch (err) {
                Utils.handleAlert(
                  "Actualizar data",
                  "Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo",
                  "Ok"
                );
              }
            }

            if (i1 >= 0) {
              try {
                let insertTaskInTG = grupoTareasAccion.insertTaskInTG(
                  this.props.grupoTareas.grupoTareas1,
                  task,
                  1,
                  i1
                );
                dispatch(insertTaskInTG);
              } catch (err) {
                Utils.handleAlert(
                  "Actualizar data",
                  "Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo",
                  "Ok"
                );
              }
            }
          }

          if (this.state.detalleGT && this.state.grupoTareas) {
            if (this.state.grupoTareas._id === task.idTasksGroup_task) {
              let grupoTareas = this.state.grupoTareas;
              grupoTareas.tasks.push(task);
              this.setState({ grupoTareas: grupoTareas });
            }
          }
        } catch (error) { }
        if (this.watchTasks) this.watch_task_created();
      });
    }
  };

  watch_rayo_updated = () => {
    if (this.watchRayos) {
      const { dispatch } = this.props;
      this.watchRayos.next().then((result) => {
        let stringObject = Utils.getBSONtoString(result.value.documentKey._id);
        let rayo = cloneDeep(result.value.fullDocument);
        rayo.id = stringObject;
        rayo._id = stringObject;
        let updatedFields = result.value.updateDescription.updatedFields;

        try {
          if (updatedFields.vigencia !== undefined) {
            if (updatedFields.vigencia) {
              if (this.props.rayos.rayos) {
                let updateStateRayos = rayosAccion.addRayo(
                  this.props.rayos.rayos,
                  rayo
                );
                dispatch(updateStateRayos);
              }
            } else {
              if (this.props.rayos.rayos) {
                let updateStateRayos = rayosAccion.deleteRayo(
                  this.props.rayos.rayos,
                  rayo.id
                );
                dispatch(updateStateRayos);
              }
            }
          } else {
            if (this.state.rayo != null) {
              if (this.state.rayo._id === stringObject) {
                const icon = {
                  url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
                  scaledSize: new window.google.maps.Size(20, 20),
                  origin: new window.google.maps.Point(0, 0),
                };
                let coordenada = {
                  icon: icon,
                  position: {
                    lat: parseFloat(rayo.latitude_rayo),
                    lng: parseFloat(rayo.longitude_rayo),
                  },
                  map: null,
                  title: rayo.name_rayo,
                  placeName: rayo._id,
                };

                this.handleState("rayoCoordenadas", coordenada);
              }
            }

            if (this.props.rayos.rayos != null) {
              let updateStateRayos = rayosAccion.updateStateRayos(
                this.props.rayos.rayos,
                rayo
              );
              dispatch(updateStateRayos);
            }
          }
        } catch (error) {
          Utils.handleAlert(
            "Actualizar data",
            "Se ha realizado un cambio, pero ha ocurrido un error al momento de mostrarlo, recargue la página para visualizarlo",
            "Ok"
          );
        }
        if (this.watchRayos) this.watch_rayo_updated();
      });
    }
  };

  watch_backlog = () => {
    if (this.watchBacklog) {
      const { dispatch } = this.props;
      this.watchBacklog.next().then((result) => {
        try {
          let idRayo = Utils.getBSONtoString(
            result.value.fullDocument.idrayo_backlog
          );
          let backlog = cloneDeep(result.value.fullDocument);
          backlog.idrayo_backlog = idRayo;

          if (result.value.operationType === "insert") {
            if (this.props.rayos.rayos != null) {
              let createRayos = rayosAccion.createRayos(
                this.props.rayos.rayos,
                backlog
              );
              dispatch(createRayos);
            }
          } else if (result.value.operationType === "update") {
            if (this.props.rayos.rayos != null) {
              let updateRayos = rayosAccion.updateRayos(
                this.props.rayos.rayos,
                backlog
              );
              dispatch(updateRayos);
            }
            if (!this.state.panelRayos && this.state.rayo) {
              if (this.state.rayo._id === idRayo) {
                let obtenerTareasRayo = rayosAccion.obtenerTareasRayo(idRayo);
                try {
                  dispatch(obtenerTareasRayo);
                } catch (error) { }
              }
            }
          }
        } catch (error) { }
        if (this.watchBacklog) this.watch_backlog();
      });
    }
  };

  componentWillUnmount = () => {
    this.setState({ grupoTareas: null });
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("click", this._handleClickGT);
    this.closingRealm();
  };

  resetEditar = () => {
    this.setState({
      camposEditar: DATA_EDITAR,
      camposEditarSelected: [],
      opcionEditar: "null",
    });
  };

  _handleClickMarker = (marker, idTaskGroup) => {
    try {
      const { dispatch } = this.props;
      let modificarGT = grupoTareasAccion.modificarGT(
        this.props.grupoTareas["grupoTareas" + this.state.tasksTab],
        this.state.tasksTab,
        [idTaskGroup],
        true
      );
      dispatch(modificarGT);
    } catch (error) { }
  };

  _handleClickCluster = (cluster) => {
    try {
      let marcas = cluster.getMarkers();
      let seleccionados = [];
      marcas.forEach((marca) => {
        seleccionados.push(marca.placeName);
      });
      seleccionados = seleccionados.filter(
        (thing, index, self) => index === self.findIndex((t) => t === thing)
      );

      const { dispatch } = this.props;
      let modificarGT = grupoTareasAccion.modificarGT(
        this.props.grupoTareas["grupoTareas" + this.state.tasksTab],
        this.state.tasksTab,
        seleccionados,
        true
      );
      dispatch(modificarGT);
    } catch (error) { }
    // sessionStorage.setItem('clusterMarks', JSON.stringify(seleccionados));
  };

  handleAddressSelect = (e, i) => {
    const name = "address_task";
    let camposSelected = cloneDeep(this.state.camposEditarSelected);
    const index = camposSelected.findIndex((campo) => campo.nombre === name);
    if (e === null || typeof e === undefined || e === "") {
      camposSelected[index].data = "";
      this.setState({
        camposEditarSelected: camposSelected,
      });
    } else {
      let value = e.placeId;
      geocodeByPlaceId(value)
        .then((results) => {
          camposSelected[index].data = results[0].formatted_address;
          this.setState({
            camposEditarSelected: camposSelected,
          });
        })
        .catch((error) => {
          Utils.handleAlert(
            "Obtener ubicación",
            "Ha ocurrido un error al obtener datos de ubicación seleccionada, vuelva a intentar",
            "Ok"
          );
        });
    }
  };

  handleChangeEditarAddress = (e) => {
    const name = "address_task";
    let camposSelected = cloneDeep(this.state.camposEditarSelected);

    const index = camposSelected.findIndex((campo) => campo.nombre === name);

    camposSelected[index].data = e;
    this.setState({
      camposEditarSelected: camposSelected,
    });
  };

  handleChangeEditar = (e) => {
    const { name, value } = e.target;
    let camposSelected = cloneDeep(this.state.camposEditarSelected);

    const index = camposSelected.findIndex((campo) => campo.nombre === name);

    let valor = "";
    if (name === "contact_phone_task") {
      valor = value.replace(/[^0-9.]/g, "");
    } else {
      valor = value;
    }

    camposSelected[index].data = valor;
    this.setState({
      camposEditarSelected: camposSelected,
    });
  };

  handleCheck = (e, grupo) => {
    e.preventDefault();
    let id = grupo._id;
    let wayPoints = cloneDeep(this.state.wayPoints);

    let esta = false;
    let i = 0;

    if (esta) {
      wayPoints.splice(i, 1);
    } else {
      let puntos = {
        location: {
          lat: grupo.latitude_taskgroup,
          lng: grupo.longitude_taskgroup,
        },
      };
      wayPoints.push(puntos);
    }

    const { dispatch } = this.props;
    let modificarGT = grupoTareasAccion.modificarGT(
      this.props.grupoTareas["grupoTareas" + this.state.tasksTab],
      this.state.tasksTab,
      [id],
      false
    );

    dispatch(modificarGT);
  };

  handleCheckAsignarRayo = (e, id) => {
    e.preventDefault();
    let rayos = cloneDeep(this.state.rayos);
    rayos.forEach((element) => {
      if (element._id === id) {
        if (element.selected === true) {
          element.selected = false;
        } else {
          element.selected = true;
        }
      } else {
        element.selected = false;
      }
    });
    this.setState({
      rayos: rayos,
    });
  };

  _handleClick = (event) => {
    const { contextMenuState } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && contextMenuState)
      this.setState({
        contextMenuState: false,
        contextMenu: { display: "none" },
      });
  };

  _handleClickGT = (event) => {
    const { contextMenuGTState } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && contextMenuGTState)
      this.setState({
        contextMenuGTState: false,
        contextMenuGT: { display: "none" },
      });
  };

  handleFiltro = async (nombreFiltro, filtro) => {
    this.handleCargando(true);
    let filtros = [{ filter: nombreFiltro, data: filtro }];
    const panel = this.state.tasksTab;
    const { dispatch } = this.props;
    let obtenerGTEstado1 = grupoTareasAccion.obtenerGTFiltro(
      filtros,
      panel,
      this.props.data.location.pathname,
      false
    );

    try {
      await dispatch(obtenerGTEstado1);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
    this.handleCargando(false);
  };

  handleEstados = async (filtro, data) => {
    let tab = this.state.tasksTab;
    let filtros = cloneDeep(this.state["filters" + tab]);

    if (filtro === "todos") {
      this.handleFiltro("idStatusTaskgroup", null);
      this.setState({
        ["filters" + tab]: {
          [ID_NO_ASIGNADO]: true,
          [ID_ASIGNADO]: true,
          [ID_ACEPTADO]: true,
          [ID_INICIADO]: true,
          [ID_EN_PROGRESO]: true,
          [ID_EXITOSO]: true,
          [ID_FALLIDO]: true,
          [ID_RECHAZADO]: true,
          [ID_CANCELADO]: true,
          [ID_DEVUELTO]: true,
          [ID_RETIROCLIENTE]: true
        },
      });
    } else {
      let string = "";
      let contador = 0;
      filtros[filtro] = !filtros[filtro];
      let result = Object.keys(filtros).map((key) => [
        { id: key, data: filtros[key] },
      ]);

      result.forEach((element) => {
        if (element[0].data) {
          contador = contador + 1;
          string =
            contador === 1
              ? string + element[0].id
              : string + "," + element[0].id;
        }
      });

      if (contador !== 0) {
        this.handleFiltro("idStatusTaskgroup", string);
        this.setState({
          ["filters" + tab]: {
            ...this.state["filters" + tab],
            [filtro]: !this.state["filters" + tab][filtro],
          },
        });
      }
    }
  };

  handlePanels = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleState = (campo, valor) => {
    this.setState({
      [campo]: valor,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleCargando = (value) => {
    this.setState({
      cargando: value,
    });
  };

  handleContextMenu = (e, tarea) => {
    e.preventDefault();
    const clickX = e.clientX;
    const clickY = e.clientY;

    this.setState({
      taskMetadata: tarea,
      contextMenuState: true,
      contextMenu: {
        display: "block",
        top: clickY - 64,
        left: clickX,
      },
    });
  };

  cleanSelectedGT = () => {
    const { dispatch } = this.props;
    let modificarGT = grupoTareasAccion.desseleccionarGT(
      this.props.grupoTareas["grupoTareas" + this.state.tasksTab],
      this.state.tasksTab
    );
    dispatch(modificarGT);
  };

  tasksGroup = async (id) => {
    this.handleCargando(true);
    await this.getTasksGroupById(id);
    this.handleState("panelTareas", !this.state.panelTareas);
    this.handleCargando(false);
  };

  handleAsignarRayo = (rayos, vista) => {
    let arrayRayos;
    if (!this.state.panelAsignarRayo) {
      if (rayos === null) {
        arrayRayos = [];
      } else {
        arrayRayos = rayos;
        arrayRayos.forEach((element) => {
          element.selected = false;
        });
      }
    }

    this.setState({
      rayos: arrayRayos,
      panelAsignarRayo: !this.state.panelAsignarRayo,
      asignarBacklog: vista,
    });
  };

  statusRayo = async (id, numero) => {
    await this.handleCargando(true);
    let status = 1;
    if (numero === 1 || numero === 3) {
      status = 2;
    } else {
      status = 1;
    }

    let datos = {
      key: id,
      status_rayo: parseInt(status),
    };
    try {
      let res = await url.api.put("/Rayos/rayo_status/", datos);
      if (res.status === 200) {
        if (this.state.rayo != null) {
          let rayo = cloneDeep(this.state.rayo);
          rayo.status_rayo = status;
          this.setState({
            rayo: rayo,
          });
        }
        this.handleCargando(false);
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Cambiar estado",
          "No se ha podido cambiar el estado del Rayo",
          "Ok"
        );
      }
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Cambiar estado",
        "No se ha podido cambiar el estado del Rayo",
        "Ok"
      );
    }
  };

  handleCheckTaskRayo = (e, tarea) => {
    e.preventDefault();
    let id = tarea._id;
    const { dispatch } = this.props;
    let modificarTareas = rayosAccion.modificarTareasRayo(
      this.props.rayos.tareas,
      [id]
    );
    dispatch(modificarTareas);
  };  

  cleanSelectedTareasRayo = async () => {
    try {
      const { dispatch } = this.props;
      let modificarTareas = rayosAccion.desseleccionarTareas(
        this.props.rayos.tareas
      );
      dispatch(modificarTareas);
    } catch (error) {
      Utils.handleAlert(
        "Error en acción",
        "No se ha podido realizar la acción",
        "Ok"
      );
    }
  };

  vaciarBacklog = async () => {
    try {
      const { dispatch } = this.props;
      let vaciarTareasRayo = rayosAccion.vaciarTareasRayo();
      dispatch(vaciarTareasRayo);
      this.setState({ rayo: null });
    } catch (error) {
      Utils.handleAlert(
        "Error en acción",
        "No se ha podido realizar la acción",
        "Ok"
      );
    }
  };

  checkAllTareasRayo = async (filtro) => {
    try {
      const { dispatch } = this.props;
      let modificarTareas = rayosAccion.seleccionarTodasTareas(
        this.props.rayos.tareas,
        filtro
      );
      dispatch(modificarTareas);
    } catch (error) {
      Utils.handleAlert(
        "Error en acción",
        "No se ha podido realizar la acción",
        "Ok"
      );
    }
  };

  getTasksRayo = async (rayo) => {
    this.handleState("rayo", rayo);
    const icon = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: new window.google.maps.Size(20, 20),
      origin: new window.google.maps.Point(0, 0),
    };
    let coordenada = {
      icon: icon,
      position: {
        lat: parseFloat(rayo.latitude_rayo),
        lng: parseFloat(rayo.longitude_rayo),
      },
      map: null,
      title: rayo.name_rayo,
      placeName: rayo._id,
    };

    this.handleState("rayoCoordenadas", coordenada);

    const { dispatch } = this.props;
    let obtenerTareasRayo = rayosAccion.obtenerTareasRayo(rayo._id);
    try {
      await dispatch(obtenerTareasRayo);
    } catch (error) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  getRoutesRayo = async (rayo) => {
    this.handleState("rayo", rayo);
    const icon = {
      url: "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayo-verde.png",
      scaledSize: new window.google.maps.Size(20, 20),
      origin: new window.google.maps.Point(0, 0),
    };
    let coordenada = {
      icon: icon,
      position: {
        lat: parseFloat(rayo.latitude_rayo),
        lng: parseFloat(rayo.longitude_rayo),
      },
      map: null,
      title: rayo.name_rayo,
      placeName: rayo._id,
    };

    this.handleState("rayoCoordenadas", coordenada);

    const { dispatch } = this.props;
    let obtenerRutasRayo = rayosAccion.obtenerRutasRayo(rayo._id);
    try {
      const res = await dispatch(obtenerRutasRayo);
    } catch (error) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  abrirDetalleRayo = async (rayo) => {
    await this.getRayoById(rayo._id);
    this.handleState("panelRayos", !this.state.panelRayos);
    this.handleState("centerRayo", true);
    this.handleState("detalleRayos", !this.state.detalleRayos);
  };

  getRayoById = async (id) => {
    try {
      await this.handleCargando(true);
      let data = await axios({
        method: "get",
        url: url.host + "/Rayos/rayo/" + id + "/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      let rayo = data;

      if (rayo.device_locations) {
        rayo.device_locations = rayo.device_locations.sort(function (a, b) {
          return (
            new Date(b.last_location_time) - new Date(a.last_location_time)
          );
        });
      }

      if (
        (!rayo.latitude_rayo || !rayo.longitude_rayo) &&
        rayo.location.coordinates
      ) {
        rayo.latitude_rayo = rayo.location.coordinates[1];
        rayo.longitude_rayo = rayo.location.coordinates[0];
      }
      this.setState({
        rayo: data,
      });
      if (this.props.rayos.localidades) {
        const i = this.props.rayos.localidades.findIndex(
          (element) => element.placeName === data._id
        );
        if (i >= 0) this.openInfoBox(this.props.rayos.localidades[i]);
      }

      this.getTasksRayo(rayo);
      this.getRoutesRayo(rayo);
      let centro = { lat: data.latitude_rayo, lng: data.longitude_rayo };
      this.handleCenter("rayo", centro);
      await this.handleCargando(false);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener grupo de tareas",
        "No se ha podido cargar el grupo de tareas",
        "Ok"
      );
    }
  };

  getTasksGroupById = async (id) => {
    await url.api
      .get(
        "/Task-Groups/tasks_group/" + id + "?timestamp=" + new Date().getTime()
      )
      .then(async (res) => {
        let data = cloneDeep(res.data);
        let wayPoints = Utils.localizacionesT([data]);

        let rayo = "";
        if (
          data.idrayo_taskgroup === "null" ||
          data.idrayo_taskgroup === null ||
          this.props.rayos.rayos === null
        ) {
          rayo = "";
        } else {
          const i = this.props.rayos.rayos.findIndex(
            (element) => element._id === data.idrayo_taskgroup
          );
          if (i > 0) {
            rayo = this.props.rayos.rayos[i];
          }
        }
        await this.setState(
          {
            detalleGT: true,
            wayPoints: wayPoints,
            grupoTareas: data,
            rayo_selected: rayo,
            opcionEditarEstado: data.idstatus_taskgroup,
          },
          () => {
            this.openWatchTasks(data._id);
          }
        );
      })
      .catch((error) => {
        Utils.handleAlert(
          "Obtener grupo de tareas",
          "No se ha podido cargar el grupo de tareas",
          "Ok"
        );
      });
  };

  modifyTaskGroupsTask = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    if (this.state.camposEditarSelected.length === 0) {
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar al menos un campo para poder editar las tareas de este grupo, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        let filtros = "";
        let modificar = {
          key: this.state.grupoTareas._id,
          modifier_user: {
            name: usuario.name,
            email: usuario.email,
          },
          tenant: usuario.tenant,
        };

        this.state.camposEditarSelected.forEach((filtro, i) => {
          let signo = "&";
          if (i === 0) {
            signo = "";
          }

          if (filtro.nombre === "date_task") {
            filtros =
              filtros + signo + filtro.nombre + "=" + filtro.data + ":00Z";
          } else {
            filtros = filtros + signo + filtro.nombre + "=" + filtro.data;
          }
        });

        let res = await url.api.put(
          "/Task-Groups/tasks_group_tasks?" + filtros,
          modificar
        );
        if (res.status === 200) {
          $("#modal").modal("hide");
          Utils.handleAlert(
            "Grupo de tareas modificado",
            "Se han modificado los campos ingresados del grupo de tareas exitosamente",
            "Ok"
          );
          this.handleState("camposEditar", DATA_EDITAR);
          this.handleState("camposEditarSelected", []);
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al modificar los campos ingresados del grupo de tareas, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado modificar los campos ingresados del grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  unassignTasksGroup = async () => {
    try {
      this.handleCargando(true);
      if (this.state.asignarBacklog === false) {
        // TASKS GROUPS
      } else {
        // TASKS
        let tareas = [];
        this.props.rayos.tareas.forEach((task) => {
          if (task.selected) {
            tareas.push({ key: task._id });
          }
        });

        let data = {
          tasks: tareas,
          modifier_user: {
            name: this.state.usuario.name,
            email: this.state.usuario.email,
          },
        };

        let res = await url.api.put("/Tasks/unassign_tasks/", data);
        if (res.status === 200) {
          await this.handleCargando(false);
          Utils.handleAlert(
            "Tareas modificadas",
            "Se ha des-asignado el rayo a las tareas seleccionadas exitosamente",
            "Ok"
          );
          this.handleAsignarRayo([], true);
          await this.getTasksRayo(this.state.rayo);
          this.cleanSelectedTareasRayo();
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al des-asignar las tareas seleccionadas, vuelva a intentarlo",
            "Ok"
          );
        }
      }
    } catch (error) {
      this.handleCargando(false);
    }
  };

  modifyTaskGroupsRayo = async () => {
    await this.handleCargando(true);
    let rayos = this.state.rayos;
    let validar = false;
    let rayo = "";
    rayos.forEach((element) => {
      if (element.selected) {
        validar = true;
        rayo = element._id;
      }
    });

    if (validar === false) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar un rayo para realizar esta acción, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        if (this.state.asignarBacklog === false) {
          let newArrayTareas = [];
          this.props.grupoTareas["grupoTareas" + this.state.tasksTab].forEach(
            (element) => {
              if (element.selected === true) {
                newArrayTareas.push({ key: element._id });
              }
            }
          );
          let data = {
            idtask_group: newArrayTareas,
            idrayo_taskgroup: rayo,
            modifier_user: {
              name: this.state.usuario.name,
              email: this.state.usuario.email,
            },
            tenant: this.state.usuario.tenant,
          };
          let res = await url.api.put(
            "/Task-Groups/multiple_tasks_group_rayo/",
            data
          );
          if (res.status === 200) {
            await this.handleCargando(false);
            Utils.handleAlert(
              "Grupos de tareas modificados",
              "Se ha reasignado el rayo a los grupos de tareas exitosamente",
              "Ok"
            );
            this.handleAsignarRayo([], false);

            const { dispatch } = this.props;
            let modificarGT = grupoTareasAccion.desseleccionarGT(
              this.props.grupoTareas["grupoTareas" + this.state.tasksTab],
              this.state.tasksTab
            );
            dispatch(modificarGT);
          } else {
            this.handleCargando(false);
            Utils.handleAlert(
              "Error " + res.status,
              "Ha ocurrido un error al reasignar los grupos de tareas seleccionados, vuelva a intentarlo",
              "Ok"
            );
          }
        } else {
          if (this.state.rayo === null) {
            Utils.handleAlert(
              "Error",
              "Ha ocurrido un error al reasignar las tareas seleccionadas, vuelva a intentarlo",
              "Ok"
            );
          } else {
            let tareas = [];
            this.props.rayos.tareas.forEach((task) => {
              if (task.selected) {
                tareas.push({
                  key: task._id,
                  previous_idrayo_task: task.idrayo_task,
                });
              }
            });

            let data = {
              tasks: tareas,
              idrayo_task: rayo,
              modifier_user: {
                name: this.state.usuario.name,
                email: this.state.usuario.email,
              },
              tenant: this.state.usuario.tenant,
            };

            let res = await url.api.put("/Tasks/tasks_rayo/", data);

            if (res.status === 200) {
              await this.handleCargando(false);
              Utils.handleAlert(
                "Tareas modificadas",
                "Se ha reasignado el rayo a las tareas seleccionadas exitosamente",
                "Ok"
              );
              this.handleAsignarRayo([], true);
              await this.getTasksRayo(this.state.rayo);
              this.cleanSelectedTareasRayo();
            } else {
              this.handleCargando(false);
              Utils.handleAlert(
                "Error " + res.status,
                "Ha ocurrido un error al reasignar las tareas seleccionadas, vuelva a intentarlo",
                "Ok"
              );
            }
          }
        }
      } catch (error) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          validar
            ? "Ha ocurrido un error no controlado al reasignar la/s tareas, vuelva a intentarlo"
            : "Ha ocurrido un error no controlado al reasignar los grupos de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  modifyTaskGroupRayo = async () => {
    this.handleCargando(true);
    if (this.state.rayo_selected === "0" || this.state.rayo_selected === "") {
      this.handleCargando(false);
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar un rayo para realizar esta acción, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        let modificar = {
          key: this.state.grupoTareas._id,
          idrayo_taskgroup: this.state.rayo_selected._id,
          modifier_user: {
            name: this.state.usuario.name,
            email: this.state.usuario.email,
          },
          tenant: this.state.usuario.tenant,
        };

        let res = await url.api.put(
          "/Task-Groups/tasks_group_rayo/",
          modificar, this.state.form.tenant
        );
        if (res.status === 200) {
          if (
            this.props.rayos.tareas !== null &&
            this.state.rayo !== null &&
            typeof this.props.rayos.tareas !== undefined &&
            typeof this.state.rayo !== undefined
          ) {
            this.getTasksRayo(this.state.rayo);
          }
          this.setState({
            rayo_selected: "0",
          });

          $("#modal").modal("hide");
          this.handleCargando(false);
          Utils.handleAlert(
            "Grupo de tareas modificado",
            "Se ha reasignado el rayo al grupo de tareas exitosamente",
            "Ok"
          );
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al reasignar el rayo al grupo de tareas, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado al reasignar el rayo al grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  duplicateTasksGroup = async () => {
    this.handleCargando(true);
    let GrupoDeTareas = cloneDeep(this.state.grupoTareas);
    const fechaHoy = new Date();

    delete GrupoDeTareas._id;
    delete GrupoDeTareas.__v;
    delete GrupoDeTareas.id;
    delete GrupoDeTareas.creation_date_taskgroup;
    delete GrupoDeTareas.history_taskgroup;
    delete GrupoDeTareas.code_tasksgroup;
    GrupoDeTareas.idstatus_taskgroup = "5f05f3607f5164494c74b966";
    GrupoDeTareas.idrayo_taskgroup = null;
    GrupoDeTareas.creation_date_taskgroup =
      moment(fechaHoy).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    GrupoDeTareas.modifier_user = {
      name: this.state.usuario.name,
      email: this.state.usuario.email,
    };

    GrupoDeTareas.tasks.forEach((tarea) => {
      delete tarea._id;
      delete tarea.__v;
      delete tarea.id;
      delete tarea.code_task;
      delete tarea.history_task;
      delete tarea.on;
      delete tarea.idTasksGroup_task;
      delete tarea.skipped_task;
      tarea.idstatus_task = "5f05f3607f5164494c74b966";
      tarea.idrayo_task = null;
      if (tarea.metadata_task === null) {
        tarea.metadata_task = {};
      }
      tarea.modifier_user = {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      };
    });

    try {
      let res = await url.api.post("/Task-Groups/tasks_group/", GrupoDeTareas, this.state.form.tenant);

      if (res.status === 201) {
        this.handleCargando(false);
        $("#modal").modal("hide");
        Utils.handleAlert(
          "Grupo de tareas duplicado",
          "El grupo de tareas se ha duplicado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al duplicar el grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al duplicar el grupo de tareas, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  duplicateTask = async () => {
    this.handleCargando(true);
    const { dispatch } = this.props;
    try {
      let tarea = cloneDeep(this.state.taskMetadata);
      tarea.tags = this.state.motivoDuplicar;
      tarea.modifier_user = {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      };
      let duplicarTareas = tareasAccion.duplicarTareas(tarea);
      await dispatch(duplicarTareas);
      $("#modalDuplicar").modal("hide");
      this.handleCargando(false);
    } catch (error) {
      this.handleCargando(false);
      $("#modalDuplicar").modal("hide");
      Utils.handleAlert(
        "Duplicar Tarea",
        "No se ha podido duplicar la tarea seleccionada",
        "Ok"
      );
    }
  };

  deleteTasksGroup = async (id) => {
    try {
      this.handleCargando(true);
      const grupoTareas = {
        key: id,
        modifier_user: {
          name: this.state.usuario.name,
          email: this.state.usuario.email,
          tenant: this.state.form.tenant,
        },
      };
      let res = await url.api.delete("/Task-Groups/tasks_group/", {
        data: grupoTareas,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        $("#modal").modal("hide");
        this.handleCargando(false);
        this.setState({
          panelTareas: !this.state.panelTareas,
          detalleGT: false,
          localidades: null,
        });
        Utils.handleAlert(
          "Grupo de tareas eliminado",
          "El grupo de tareas se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Eliminar grupo de tareas",
        "No se ha podido eliminar el grupo de tareas",
        "Ok"
      );
    }
  };

  skipedDimensions = (grupo, clickY, clickX) => {
    this.setState({
      skipGrupoTarea: grupo._id,
      contextMenuGTState: true,
      contextMenuGT: {
        display: "block",
        top: clickY - 64,
        left: clickX,
      },
    });
  };

  toggleItemActive = (index) =>
    this.setState((prevState) => {
      const item = prevState.items[index];
      const items = prevState.items.concat();
      items[index] = {
        ...item,
        isActive: !item.isActive,
      };
      return { items };
    });

  cerrarDetalle = (value) => {
    this.closeWatchTasks();
    this.setState({
      detalleGT: false,
      localidades: null,
      historial: false,
      optimizar: false,
      grupoTareas: null,
      rayo_selected: "",
      panelTareas: !this.state.panelTareas,
    });
  };

  cerrarModal = (name) => {
    $(name).modal("hide");
  };

  render() {
    let rayos = this.props.rayos.rayos;
    let allRayos = this.props.rayos.allRayos;
    let tareasRayo = this.props.rayos.tareas;
    let grupoTareas0 = this.props.grupoTareas.grupoTareas0;
    let grupoTareas1 = this.props.grupoTareas.grupoTareas1;
    let { classes } = this.props;

    const openFiltros = Boolean(this.state.anchorElFiltros);
    const idFiltros = openFiltros ? "simple-popover" : undefined;

    let tareasSelectedLength = 0

    if (this.props.rayos.tareas) {
      tareasSelectedLength = this.props.rayos.tareas.filter(t => t.selected).length;
    }

    let localidades;
    let detalle = cloneDeep(this.state.detalleGT);
    if (this.state.detalleGT) {
      localidades = this.state.wayPoints;
    } else {
      localidades = this.props.grupoTareas["localidades" + this.state.tasksTab];
    }

    const MenuContextGT = () => {
      return (
        <div
          style={this.state.contextMenuGT}
          className="position-absolute z-i-300"
          ref={(ref) => {
            this.rootGT = ref;
          }}
        >
          <div
            className="row m-0 opc-drop-gt border"
            onClick={() =>
              window.open(
                "/Tracking/" + N_GRUPOTAREA + "/" + this.state.skipGrupoTarea,
                "_blank"
              )
            }
          >
            <div className="col-md-3 d-flex align-items-center">
              <img src={IconoRoute} height="20" alt="icono" />
            </div>
            <div className="col-md-9 pl-0 txt-carta-name d-flex align-items-center">
              Tracking Link
            </div>
          </div>
        </div>
      );
    };

    const MenuContext = () => {
      return (
        <div
          style={this.state.contextMenu}
          className="position-absolute z-i-300"
          ref={(ref) => {
            this.root = ref;
          }}
        >
          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() => this.handleState("historial", true)}
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoReloj} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Ver Historial
            </div>
          </div>

          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() =>
              window.open(
                "/Tracking/" + N_TAREA + "/" + this.state.taskMetadata._id,
                "_blank"
              )
            }
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoRoute} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Tracking Link
            </div>
          </div>

          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() => {
              $("#modalDuplicar").modal("show");
            }}
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoDuplicar} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Duplicar
            </div>
          </div>
          <div
            className="row m-0 opc-drop border"
            data-toggle="modal"
            data-target="#metadata"
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoData} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Metadata
            </div>
          </div>
        </div>
      );
    };

    const CargandoPanel = () => {
      return (
        <div className="cargando-paneles d-flex justify-content-center align-items-center">
          <CircularProgress />
        </div>
      );
    };

    const SinGT = ({ name }) => {
      return (
        <div className="cargando-paneles d-flex justify-content-center align-items-center">
          <div className="w-100">
            <p className="w-100 text-center text-exo-light color-light-grey mb-0">
              No se encuentran Grupos de Tareas en estado
            </p>
            <p className="w-100 text-center text-exo-light color-light-grey">
              <strong>{name}</strong>
            </p>
          </div>
        </div>
      );
    };

    let newMapRouteKey = "MapaRoutes-" + tareasSelectedLength + "-" + (this.props.rayos.lastUpdate || "");

    return this.state.panelAsignarRayo ? (
      <AsignarRayo
        handleAsignarRayo={this.handleAsignarRayo}
        rayos={this.state.rayos}
        handleCheck={this.handleCheckAsignarRayo}
        modifyTaskGroups={this.modifyTaskGroupsRayo}
        unassignTasksGroup={this.unassignTasksGroup}
        wayPoints={this.props.grupoTareas.wayPoints}
        vista={this.state.asignarBacklog}
        cargando={this.state.cargando}
        inputBuscarRayo={this.state.inputBuscarRayo}
        handleChange={this.handleChange}
      />
    ) : (
      <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
        <Cargando cargando={this.state.cargando} />
        <div className="row m-0 home-screen">
          <div className="col-lg-3 p-0 home-screen plomo">
            <div className={Utils.handleVistasVisibles(this.state.panelTareas)}>
              <div className="row m-0 p-0 back-light-grey">
                <div className="col-2 p-0">
                  <Button
                    className="d-flex align-items-center justify-content-center h-100 w-100"
                    disabled
                  >
                    <SearchIcon />
                  </Button>
                </div>
                <div className="col-9 d-flex align-items-center pl-0">
                  <input
                    value={this.state.filtro_tareas}
                    name="filtro_tareas"
                    onChange={this.handleChange}
                    className="text-roboto-regular input-sup py-2 w-100"
                    type="text"
                    placeholder="GRUPOS DE TAREAS"
                  />
                </div>

                <div className="col-1 p-0">
                  <Button
                    aria-controls="fade-menu"
                    className={classes.btnFilter}
                    aria-describedby={idFiltros}
                    variant="contained"
                    onClick={(e) =>
                      this.handleState("anchorElFiltros", e.currentTarget)
                    }
                  >
                    <MoreVertIcon className={classes.iconFilter} />
                  </Button>

                  <Popover
                    id={idFiltros}
                    open={openFiltros}
                    anchorEl={this.state.anchorElFiltros}
                    onClose={() => this.handleState("anchorElFiltros", null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    getContentAnchorEl={null}
                  >
                    <div
                      className="row m-0 opc-drop border-bottom-0"
                      onClick={() => this.handleEstados("todos")}
                    >
                      <div className="col-12 pl-0 txt-carta-name d-flex align-items-center justify-content-center">
                        Todos los estados
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_NO_ASIGNADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_NO_ASIGNADO]
                              : this.state.filters1[ID_NO_ASIGNADO]
                          }
                          onChange={() => this.handleEstados(ID_NO_ASIGNADO)}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot back-medium-grey"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        No Asignado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_ASIGNADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_ASIGNADO]
                              : this.state.filters1[ID_ASIGNADO]
                          }
                          onChange={() => this.handleEstados(ID_ASIGNADO)}
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-asignado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Asignado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_ACEPTADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_ACEPTADO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_ACEPTADO]
                              : this.state.filters1[ID_ACEPTADO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-aceptado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Aceptado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_INICIADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_INICIADO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_INICIADO]
                              : this.state.filters1[ID_INICIADO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-iniciado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Iniciado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_EN_PROGRESO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_EN_PROGRESO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_EN_PROGRESO]
                              : this.state.filters1[ID_EN_PROGRESO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-en-progreso"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        En Progreso
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_EXITOSO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_EXITOSO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_EXITOSO]
                              : this.state.filters1[ID_EXITOSO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-exitoso"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Exitoso
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_FALLIDO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_FALLIDO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_FALLIDO]
                              : this.state.filters1[ID_FALLIDO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-haFallado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Fallido
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_RECHAZADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_RECHAZADO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_RECHAZADO]
                              : this.state.filters1[ID_RECHAZADO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-rechazado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Rechazado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border-bottom-0">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_CANCELADO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_CANCELADO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_CANCELADO]
                              : this.state.filters1[ID_CANCELADO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-cancelado"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Cancelado
                      </div>
                    </div>
                    <div className="row m-0 opc-drop border">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_DEVUELTO}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_DEVUELTO)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_DEVUELTO]
                              : this.state.filters1[ID_DEVUELTO]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-devuelto"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Devuelto
                      </div>
                    </div>

                    <div className="row m-0 opc-drop border">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_RETIROCLIENTE}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_RETIROCLIENTE)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_RETIROCLIENTE]
                              : this.state.filters1[ID_RETIROCLIENTE]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-retirocliente"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Retiro Bodega Cliente
                      </div>
                    </div>

                    <div className="row m-0 opc-drop border">
                      <div className="col-2 p-0 d-flex align-items-center">
                        <Checkbox
                          name={ID_RECEPCIONBODEGA}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={() => this.handleEstados(ID_RECEPCIONBODEGA)}
                          checked={
                            this.state.tasksTab === "0"
                              ? this.state.filters0[ID_RECEPCIONBODEGA]
                              : this.state.filters1[ID_RECEPCIONBODEGA]
                          }
                        />
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className={"dot dot-bodegarayo"}></div>
                      </div>
                      <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                        Recepcionado Bodega Rayo
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>

              <Tabs
                value={this.state.tasksTab}
                onChange={(e, newValue) =>
                  this.handleState("tasksTab", newValue)
                }
                aria-label="wrapped label tabs example"
                className="w-100 tabs-task"
              >
                <Tab
                  value={"0"}
                  label={
                    this.props.grupoTareas.grupoTareasNombre0 +
                    " " +
                    Utils.filterTaskGroups(
                      grupoTareas0,
                      this.props.grupoTareas.seleccionDesdeMapa,
                      this.state.filtro_tareas
                    )
                  }
                  {...Utils.a11yProps("0")}
                  className="tab-task"
                />
                <Tab
                  value={"1"}
                  label={
                    this.props.grupoTareas.grupoTareasNombre1 +
                    " " +
                    Utils.filterTaskGroups(
                      grupoTareas1,
                      this.props.grupoTareas.seleccionDesdeMapa,
                      this.state.filtro_tareas
                    )
                  }
                  {...Utils.a11yProps("1")}
                  className="tab-task"
                />
              </Tabs>
              {this.state.tasksTab === "0" ? (
                <div>
                  <CantidadSeleccionados
                    seleccionado={this.props.grupoTareas.seleccionado}
                    totalSeleccionado={this.props.grupoTareas.totalSeleccionado}
                    cleanSelectedGT={this.cleanSelectedGT}
                  />
                  {grupoTareas0 === null ? (
                    <CargandoPanel />
                  ) : grupoTareas0.length === 0 ? (
                    <SinGT name={this.props.grupoTareas.grupoTareasNombre0} />
                  ) : (
                    <ListaGrupoDeTareas
                      grupos={grupoTareas0}
                      index={"0"}
                      id={"lista1"}
                      toggleItemActive={this.handleCheck}
                      skipedDimensions={this.skipedDimensions}
                      tasksGroup={this.tasksGroup}
                      width={"100%"}
                      rayos={allRayos}
                      filtro_tareas={this.state.filtro_tareas}
                      seleccionDesdeMapa={
                        this.props.grupoTareas.seleccionDesdeMapa
                      }
                    />
                  )}
                </div>
              ) : (
                <div>
                  <CantidadSeleccionados
                    seleccionado={this.props.grupoTareas.seleccionado}
                    totalSeleccionado={this.props.grupoTareas.totalSeleccionado}
                    cleanSelectedGT={this.cleanSelectedGT}
                  />
                  {grupoTareas1 === null ? (
                    <CargandoPanel />
                  ) : grupoTareas1.length === 0 ? (
                    <SinGT name={this.props.grupoTareas.grupoTareasNombre1} />
                  ) : (
                    <ListaGrupoDeTareas
                      grupos={grupoTareas1}
                      index={"1"}
                      id={"lista2"}
                      toggleItemActive={this.handleCheck}
                      skipedDimensions={this.skipedDimensions}
                      tasksGroup={this.tasksGroup}
                      width={"100%"}
                      rayos={allRayos}
                      filtro_tareas={this.state.filtro_tareas}
                      seleccionDesdeMapa={
                        this.props.grupoTareas.seleccionDesdeMapa
                      }
                    />
                  )}
                </div>
              )}
            </div>

            {this.props.grupoTareas.seleccionado &&
              this.state.grupoTareas === null ? (
              <BotonesTareasSeleccionadas
                handleAsignarRayo={this.handleAsignarRayo}
                handlePanels={this.handlePanels}
                optimizar={this.state.optimizar}
                backlog={false}
                rayos={rayos}
                vista={"taskgroups"}
              />
            ) : (
              false
            )}

            {this.state.grupoTareas && this.state.detalleGT ? (
              <div className={Utils.handleVistasVisibles(this.state.detalleGT)}>
                <DetalleGrupoTareas
                  cerrarDetalle={this.cerrarDetalle}
                  handleState={this.handleState}
                  handleOptimizar={this.handlePanels}
                  optimizar={this.state.optimizar}
                  data={this.props.data}
                  grupoTareas={this.state.grupoTareas}
                  onDragEnd={this.onDragEnd}
                  handleContextMenu={this.handleContextMenu}
                  vista={"mapa"}
                  historialState={this.state.historial}
                  tituloHistorial={this.state.tituloHistorial}
                  dataHistorial={this.state.dataHistorial}
                  rayos={rayos}
                  clientes={this.props.clientes.clientes}
                  template={this.state.template}
                />
              </div>
            ) : (
              false
            )}

            <MenuContext />
            <MenuContextGT />
          </div>
          <div className="col-lg-6 p-0 home-screen">
            {
              this.state.rutaSeleccionada != null || this.state.rutaSeleccionada != undefined ? (
                <div style={{ position: "relative" }}>
                  <CargandoMapa />
                  <WrappedMapRoute
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg`}
                    loadingElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                    containerElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                    mapElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                    rayo={this.state.rayoCoordenadas}
                    geometry={this.state.rutaSeleccionada.polyline}
                    tareasRayo={this.state.rutaSeleccionada.tasks}
                  />
                </div>
              ) : localidades != null &&
                this.state.tasksTab === "0" &&
                !detalle ? (
                <div style={{ position: "relative", minHeight: "100%" }}>
                  <CargandoMapa />
                  <div className="home-screen w-100">
                    <RefactorGoogleMapTareas
                      // Variables para centrar mapa en un rayo
                      detalleRayo={!this.state.panelRayos}
                      centerRayo={this.state.centerRayo}
                      center={this.state.center}
                      rayo={this.state.rayo}
                      // Eventos click
                      _handleClickCluster={this._handleClickCluster}
                      _handleClickMarker={this._handleClickMarker}
                    />
                  </div>
                </div>
              ) : localidades != null &&
                this.state.tasksTab === "1" &&
                !detalle ? (
                <div style={{ position: "relative", minHeight: "100%" }}>
                  <CargandoMapa />
                  <div className="home-screen w-100">
                    <RefactorGoogleMapTareas1
                      // Variables para centrar mapa en un rayo
                      detalleRayo={!this.state.panelRayos}
                      centerRayo={this.state.centerRayo}
                      center={this.state.center}
                      rayo={this.state.rayo}
                      // Eventos click
                      _handleClickCluster={this._handleClickCluster}
                      _handleClickMarker={this._handleClickMarker}
                    />
                  </div>
                </div>
              ) : localidades != null && detalle ? (
                <div style={{ position: "relative" }}>
                  <CargandoMapa />
                  <WrappedMapDetalle
                    isMarkerShown
                    positions={localidades}
                    detalle={detalle}
                    rayos={
                      this.props.rayos.localidades == null
                        ? []
                        : this.props.rayos.localidades
                    }
                    _handleClickCluster={this._handleClickCluster}
                    _handleClickMarker={this._handleClickMarker}
                    openInfoBox={this.openInfoBox}
                    abrirInfoBox={this.state.abrirInfoBox}
                    infoBoxInfo={this.state.infoBoxInfo}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg`}
                    loadingElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                    containerElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                    mapElement={
                      <div style={{ width: `100%` }} className="home-screen" />
                    }
                  />
                </div>
              ) : (
                <div style={{ position: "relative", minHeight: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      position: "absolute",
                      backgroundColor: "#000000",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CircularProgress />
                  </div>
                </div>
              )}
          </div>
          <div className="col-lg-3 p-0 home-screen plomo">
            <div className={Utils.handleVistasVisibles(this.state.panelRayos)}>
              <div className="row m-0 back-light-grey">
                <div className="col-2 p-0">
                  <Button
                    className="d-flex align-items-center justify-content-center h-100 w-100"
                    disabled
                  >
                    <SearchIcon />
                  </Button>
                </div>
                <div className="col-10 pl-0 d-flex align-items-center text-roboto-regular text-div-sup">
                  <input
                    name="filtro_rayos"
                    value={this.state.filtro_rayos}
                    onChange={this.handleChange}
                    className="text-roboto-regular input-sup py-2 w-100"
                    placeholder="RAYOS"
                    type="text"
                  />
                </div>
              </div>
              <Tabs
                value={this.state.rayosTab}
                onChange={(e, newValue) =>
                  this.handleState("rayosTab", newValue)
                }
                aria-label="wrapped label tabs example"
                className="w-100 tabs-task"
              >
                <Tab
                  value={"0"}
                  label={
                    "Activos " +
                    Utils.filterRayos(rayos, 1, this.state.filtro_rayos)
                  }
                  {...Utils.a11yProps("0")}
                  className="tab-rayos-home"
                />
                <Tab
                  value={"1"}
                  label={
                    "Ocupados " +
                    Utils.filterRayos(rayos, 3, this.state.filtro_rayos)
                  }
                  {...Utils.a11yProps("1")}
                  className="tab-rayos-home"
                />
                <Tab
                  value={"2"}
                  label={
                    "Inactivo " +
                    Utils.filterRayos(rayos, 2, this.state.filtro_rayos)
                  }
                  {...Utils.a11yProps("2")}
                  className="tab-rayos-home"
                />
              </Tabs>

              {this.state.rayosTab === "0" ? (
                <div>
                  {rayos === null ? (
                    <div className="cargando-paneles d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <ListaRayos
                      rayos={rayos}
                      width={"100%"}
                      rayo={this.abrirDetalleRayo}
                      tipo={1}
                      filtro_rayos={this.state.filtro_rayos}
                    />
                  )}
                </div>
              ) : this.state.rayosTab === "1" ? (
                <div>
                  {rayos === null ? (
                    <div className="cargando-paneles d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <ListaRayos
                      rayos={rayos}
                      width={"100%"}
                      rayo={this.abrirDetalleRayo}
                      tipo={3}
                      filtro_rayos={this.state.filtro_rayos}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {rayos === null ? (
                    <div className="cargando-paneles d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <ListaRayos
                      rayos={rayos}
                      width={"100%"}
                      rayo={this.abrirDetalleRayo}
                      tipo={2}
                      filtro_rayos={this.state.filtro_rayos}
                    />
                  )}
                </div>
              )}
            </div>

            {this.state.rayo === null ? (
              false
            ) : (
              <DetalleRayo
                key={"DetalleRayo-" + this.state.rayo._id + "-" + tareasSelectedLength}
                clientes={this.props.clientes.clientes}
                rayo={this.state.rayo}
                handleCenter={this.handleCenter}
                rayos={rayos}
                filtro={this.state.filtro_tareas_rayo}
                handleChange={this.handleChange}
                handlePanels={this.handlePanels}
                statusRayo={this.statusRayo}
                detalleRayo={this.state.detalleRayo}
                panelRayos={this.state.detalleRayos}
                handlePanelDetalle={this.handlePanelDetalle}
                handleState={this.handleState}
                tareasRayo={this.props.rayos.tareas}
                rutasRayo={this.props.rayos.rutas}
                handleCheck={this.handleCheckTaskRayo}
                seleccionTareas={this.props.rayos.seleccionadoT}
                cleanSelected={this.cleanSelectedTareasRayo}
                checkAll={this.checkAllTareasRayo}
                asignar={this.state.asignarBacklog}
                handleAsignarBacklog={this.handleAsignarRayo}
                optimizar={this.state.optimizarBacklog}
                vaciarBacklog={this.vaciarBacklog}
                dispatch={this.props.dispatch}
                handleCargando={this.handleCargando}
                centerMapRayo={this.centerMapRayo}
                proveedores={this.props.proveedores.proveedores}
                rutaSeleccionada={this.state.rutaSeleccionada}
              />
            )}
          </div>

          <div
            className="modal fade"
            aria-labelledby="modal"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
            role="dialog"
            id="modal"
          >
            {this.state.tipoModal === 1 ? (
              <EditarTareas
                code={
                  this.state.grupoTareas === null
                    ? []
                    : [
                      {
                        id: this.state.grupoTareas._id,
                        codigo: this.state.grupoTareas.orden_id_taskgroup,
                      },
                    ]
                }
                camposEditar={this.state.camposEditar}
                camposEditarSelected={this.state.camposEditarSelected}
                opcionEditar={this.state.opcionEditar}
                handleChangeEditar={this.handleChangeEditar}
                handleChangeEditarAddress={this.handleChangeEditarAddress}
                handleAddressSelect={this.handleAddressSelect}
                handleChange={this.handleChange}
                handleState={this.handleState}
                resetEditar={this.resetEditar}
                modify={this.modifyTaskGroupsTask}
                vista={"mapa"}
              />
            ) : this.state.tipoModal === 2 ? (
              <DuplicarTareas
                data={this.state.grupoTareas}
                duplicate={this.duplicateTasksGroup}
                vista={"mapa"}
                cerrarModal={this.cerrarModal}
                nameModal={"#modal"}
              />
            ) : this.state.tipoModal === 3 ? (
              <EditarRayo
                code={
                  this.state.grupoTareas === null
                    ? []
                    : [
                      {
                        id: this.state.grupoTareas._id,
                        codigo: this.state.grupoTareas.orden_id_taskgroup,
                      },
                    ]
                }
                data={this.state.grupoTareas}
                rayo_selected={this.state.rayo_selected}
                handleChange={this.handleChange}
                rayos={rayos}
                modify={this.modifyTaskGroupRayo}
                vista={"mapa"}
              />
            ) : this.state.tipoModal === 4 ? (
              <EditarEstado
                data={this.state.grupoTareas}
                opcionEditarEstado={this.state.opcionEditarEstado}
                failedReason={this.state.failedReason}
                handleChange={this.handleChange}
                getTasksGroupById={this.getTasksGroupById}
                vista={"mapa"}
                tareasRayo={tareasRayo}
                rayo={this.state.rayo}
                getTasksRayo={this.getTasksRayo}
                handleCargando={this.handleCargando}
              />
            ) : (
              <EliminarTareas
                code={
                  this.state.grupoTareas === null
                    ? []
                    : [
                      {
                        id: this.state.grupoTareas._id,
                        codigo: this.state.grupoTareas.orden_id_taskgroup,
                      },
                    ]
                }
                data={this.state.grupoTareas}
                delete={this.deleteTasksGroup}
                vista={"mapa"}
              />
            )}
          </div>
        </div>

        <div
          className="modal fade"
          aria-labelledby="modalDuplicar"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modalDuplicar"
        >
          <DuplicarTareas
            data={this.state.taskMetadata}
            duplicate={this.duplicateTask}
            motivoDuplicar={this.state.motivoDuplicar}
            handleChange={this.handleChange}
            handleState={this.handleState}
            vista={"lista"}
            cerrarModal={this.cerrarModal}
            nameModal={"#modalDuplicar"}
          />
        </div>

        <div
          className="modal fade"
          id="metadata"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="metadata"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content modal-white">
              <div className="modal-header">
                <h4 className="h4-modal pl-4 mb-0 text-center w-100">
                  Metadata
                </h4>
                <CopyToClipboard
                  text={
                    this.state.taskMetadata == null
                      ? ""
                      : JSON.stringify(this.state.taskMetadata.metadata_task)
                  }
                >
                  <IconButton>
                    <FileCopyIcon />
                  </IconButton>
                </CopyToClipboard>

                <IconButton onClick={() => $("#metadata").modal("hide")}>
                  <CloseIcon />
                </IconButton>
              </div>

              {this.state.taskMetadata == null ? (
                false
              ) : (
                <div className="modal-body py-0">
                  <MetadataJson
                    metadata={this.state.taskMetadata.metadata_task}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

const styles = (theme) => ({
  btnFilter: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.75,
    },
  },
  iconFilter: {
    color: theme.palette.primary.contrastText,
  },
});

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    grupoTareas: store.grupoTareas,
    clientes: store.clientes,
    proveedores: store.proveedores,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(VistaMapa)
);
