import React, { useState } from "react";
// material
import { Box, Button, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// components
import Mapa from "../../assets/components/zonas/Mapa";
import IconoFlechaAzul from "../../assets/icons/icono-flecha-derecha-blue2.png";

const zone = {
  _id: "ñe",
  rayo_id: "ñe",
  vehicle: 1,
  cost: 3405,
  delivery: [7],
  amount: [7],
  pickup: [0],
  service: 20900,
  duration: 3405,
  waiting_time: 0,
  priority: 0,
  distance: 46345,
  steps: [
    {
      type: "start",
      location: [-70.6085788, -33.4179933],
      load: [7],
      arrival: 0,
      duration: 0,
      distance: 0,
    },
    {
      task_id: "id",
      status_id: "",
      status_name: "",
      type: "job",
      description: "Raulí, 596, Santiago, Región Metropolitana, Chile",
      location: [-70.6361322999999, -33.44948],
      id: 7,
      service: 300,
      waiting_time: 0,
      job: 7,
      load: [6],
      arrival: 408,
      duration: 408,
      distance: 5449,
    },
    {
      type: "job",
      description:
        "Rey Carlos ÍII, Estación Central, Región Metropolitana, Chile",
      location: [-70.7307416, -33.4632321],
      id: 2,
      service: 300,
      waiting_time: 0,
      job: 2,
      load: [5],
      arrival: 1475,
      duration: 1175,
      distance: 15975,
    },
    {
      type: "job",
      description:
        "Joaquín Edwards Bello 736, Maipú, Región Metropolitana, Chile",
      location: [-70.7665224, -33.5323039],
      id: 1,
      service: 300,
      waiting_time: 0,
      job: 1,
      load: [4],
      arrival: 2476,
      duration: 1876,
      distance: 25318,
    },
    {
      type: "job",
      description:
        "Calle 1 y 2 de Octubre 329A, Población Cuadra, Rancagua, Lib. Gral. Bernardo O'Higgins, Chile",
      location: [-70.7487034, -33.5118623],
      id: 19,
      service: 5000,
      waiting_time: 0,
      job: 19,
      load: [3],
      arrival: 3204,
      duration: 2304,
      distance: 29795,
    },
    {
      type: "job",
      description:
        "AV. KENNEDY 1408 DEPTO. 14 POBLACION MARCO SHIAPPONI, Rancagua, Lib. Gral. Bernardo O'Higgins, Chile",
      location: [-70.7487034, -33.5118623],
      id: 18,
      service: 5000,
      waiting_time: 0,
      job: 18,
      load: [2],
      arrival: 8204,
      duration: 2304,
      distance: 29795,
    },
    {
      type: "job",
      description:
        "Demetrio O'Higgins, 967, Rancagua, Lib. Gral. Bernardo O'Higgins, Chile",
      location: [-70.7487034, -33.5118623],
      id: 17,
      service: 5000,
      waiting_time: 0,
      job: 17,
      load: [1],
      arrival: 13204,
      duration: 2304,
      distance: 29795,
    },
    {
      type: "job",
      description: "Los Nibelungos 1662, Maipú, Región Metropolitana, Chile",
      location: [-70.7487034, -33.5118623],
      id: 16,
      service: 5000,
      waiting_time: 0,
      job: 16,
      load: [0],
      arrival: 18204,
      duration: 2304,
      distance: 29795,
    },
    {
      type: "end",
      location: [-70.6128702, -33.4646281],
      load: [0],
      arrival: 24305,
      duration: 3405,
      distance: 46344,
    },
  ],
};

function ZonesList({ id, handleZone }) {
  return (
    <Box className="tarjeta-gt-box">
      <div
        className="row m-0"
        style={{
          backgroundColor: "#e4e9fa",
          borderRadius: 5,
          width: "100%",
          height: 175,
          position: "relative",
        }}
      >
        <div className="check-box d-flex align-items-center justify-content-center">
          <Checkbox
            color="primary"
            value="checkedA"
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            icon={
              <CheckBoxOutlineBlankIcon
                fontSize="small"
                style={{ color: "#9999ff" }}
              />
            }
            className="p-0"
          />
        </div>
        <div
          className={
            "rayo-box d-flex align-items-center justify-content-center"
          }
        >
          <div>
            <div
              style={{
                backgroundColor: "#9999ff",
                borderRadius: 5,
                height: 40,
                width: 40,
                marginBottom: 5,
              }}
            ></div>
            <p className="txt-small">GT sin</p>
            <p className="txt-small">Rayo</p>
          </div>
        </div>

        <div
          className="d-flex align-items-center"
          style={{
            width: "calc(100% - 36px - 40px - 17px)",
            paddingLeft: 15,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 10,
          }}
        >
          <p
            className="order-id-box text-ellipsis"
            style={{ fontSize: 11, lineHeight: 1.2, marginBottom: 0 }}
          >
            {"ZONE-0000" + id}
          </p>

          <div className="w-100">
            <div className="mb-3">
              <p
                className="text-exo-bold"
                style={{ fontSize: 11, lineHeight: 1.2, marginBottom: 0 }}
              >
                {id + "7-01-2022 0" + id + ":19"}
              </p>
              <p style={{ fontSize: 11, lineHeight: 1.2, marginBottom: 0 }}>
                Ruta ...
              </p>
              <p
                style={{ fontSize: 11, lineHeight: 1.2, marginBottom: 0 }}
                className="text-ellipsis color-light-grey"
              >
                .......
              </p>
            </div>
          </div>

          <p
            className="customer-box"
            style={{ fontSize: 11, lineHeight: 1.2, marginBottom: 0 }}
          >
            Cruz verde
          </p>
        </div>
        <div className="d-flex align-items-center" style={{ width: 17 }}>
          <img
            src={IconoFlechaAzul}
            alt="flecha"
            style={{ width: 10, height: 20, cursor: "pointer" }}
            onClick={handleZone}
          />
        </div>
      </div>
    </Box>
  );
}

export default function VistaZonas() {
  const [zonas, setZonas] = useState(null);

  const handleZone = () => {
    setZonas(zone);
  };

  return (
    <Box height="calc(100vh - 64px)" width="100%">
      <Grid container>
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={2} style={{ backgroundColor: "#e5e5e5" }}>
              <Button
                className="d-flex align-items-center justify-content-center h-100 w-100"
                disabled
              >
                <SearchIcon />
              </Button>
            </Grid>
            <Grid item xs={10}>
              <input
                className="text-roboto-regular input-sup py-2 w-100"
                type="text"
                placeholder="FILTRAR ZONAS"
              />
            </Grid>
          </Grid>
          {[1, 2, 3].map((element) => (
            <ZonesList key={element} id={element} handleZone={handleZone} />
          ))}
        </Grid>
        <Grid item xs={6} style={{ height: "100%" }}>
          <Mapa zonas={zonas} />
        </Grid>
        <Grid item xs={3}>
          <Box
            width="100%"
            height="calc(100vh - 64px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <p className="w-100 text-exo-light color-light-grey">
              Seleccione una zona para visualizar el detalle
            </p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
