import React, {
    memo,
    useLayoutEffect,
    useRef,
    useState,
    useEffect,
} from "react";
import moment from "moment";
import memoize from "memoize-one";
import { FixedSizeList as List, areEqual } from "react-window";
import { Droppable } from "react-beautiful-dnd";
import Utils from "../../utils/Utils";

import IconoFlechaAzul from "../../icons/icono-flecha-derecha-blue2.png";

const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
    const combined = {
        ...virtualStyle,
        ...draggableStyle,
    };

    const grid = 0;
    const result = {
        ...combined,
        height: isDragging ? combined.height : combined.height - grid,
        left: isDragging ? combined.left : combined.left + 10,
        background: isDragging ? "transparent" : "transparent",
        width: isDragging
            ? draggableStyle.width
            : `calc(${combined.width} - ${10 * 2}px)`,
        marginBottom: grid,
    };

    return result;
};

const createRayoData = memoize(
    (items, handleCheck, seleccionTareas, clientes, handleClick) => ({
        items,
        handleCheck,
        seleccionTareas,
        clientes,
        handleClick
    })
);

function ListaRutasRayo({
    clientes,
    routes,
    filtro_tareas,
    index,
    handleCheck,
    seleccionTareas,
    showButtons,
    handleClick
}) {
    const [items, changeItems] = useState(routes);
    useEffect(() => {
        changeItems(routes);
    }, [routes]);

    const listRef = useRef();
    useLayoutEffect(() => {
        const list = listRef.current;
        if (list) {
            list.scrollTo(0);
        }
    }, [index]);

    let height = window.innerHeight ;

    let filterTasks = items.filter((task) => {
        let bandera = false;
        if (
            (task.nameResponsable != undefined && task.nameResponsable.toLowerCase().includes(filtro_tareas.toLowerCase())) ||
            (task._id != undefined && task._id.toLowerCase().includes(filtro_tareas.toLowerCase())) ||
            (task.creation_date != undefined && task.creation_date.toLowerCase().includes(filtro_tareas.toLowerCase())) ||
            (task.provider != undefined && task.provider.toString().toLowerCase().includes(filtro_tareas.toLowerCase()))
        ) {
            bandera = true;
        }
        return bandera;
    });
    const itemData = createRayoData(
        filterTasks,
        handleCheck,
        seleccionTareas,
        clientes,
        handleClick
    );

    return (
        <Droppable
            droppableId="droppableRayo"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
                <Item
                    provided={provided}
                    item={filterTasks[rubric.source.index]}
                    handleCheck={handleCheck}
                    handleClick={handleClick}
                    seleccionTareas={seleccionTareas}
                    isDragging={snapshot.isDragging}
                />
            )}
        >
            {(provided, snapshot) => {
                const itemCount = snapshot.isUsingPlaceholder
                    ? filterTasks.length + 1
                    : filterTasks.length;

                return (
                    <List
                        height={height}
                        itemCount={itemCount}
                        itemSize={160}
                        width={"100%"}
                        outerRef={provided.innerRef}
                        itemData={itemData}
                        className="task-list"
                        ref={listRef}
                    >
                        {Row}
                    </List>
                );
            }}
        </Droppable>
    );
}

const Row = memo(({ data, index, style }) => {
    const { items, handleCheck, seleccionTareas, clientes, handleClick } = data;
    const item = items[index];
    if (!item) return null;

    return (
        <Item
            item={item}
            style={style}
            clientes={clientes}
            handleCheck={handleCheck}
            handleClick={handleClick}
            seleccionTareas={seleccionTareas}
        />
    );
}, areEqual);

const Item = ({
    item,
    style,
    handleCheck,
    handleClick
}) => {

    return (
        <div
            style={getStyle({
                virtualStyle: style,
            })}
        >
            <div
                className="d-flex carta-orden bg-white cursor-pointer px-2 py-1"
                style={{ flexDirection: 'row', justifyContent: 'space-between', height: 150 }}
                onClick={() => handleClick(item)}
            >
                <div className="d-flex" style={{ flexDirection: 'row', gap: 30 }}>
                    <div
                        className="text-exo-bold"
                    >
                        <p
                            className={
                                "my-1 "
                            }
                        >
                            Codigo: {item.code_route}
                        </p>
                        <p
                            className="mb-0 sub-txt-carta cursor-pointer"
                            style={{ paddingTop: 1 }}
                        >
                            <span
                                style={{
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: 4,
                                }}
                            >
                                {item.status_route}
                            </span>
                        </p>
                        <p
                            className="mb-0 sub-txt-carta cursor-pointer"
                            style={{ paddingTop: 5 }}
                        >
                            Total: {item?.tasks?.length}
                        </p>
                    </div>
                    <div
                        style={{
                            paddingTop: 7,
                        }}
                    >
                        <p
                            className="mb-0 sub-txt-carta d-flex align-items-center"
                            style={{ height: 20, paddingBottom: 3 }}
                        >
                            {moment(item.creation_date).utcOffset("+00").format("DD-MM-YYYY HH:mm")}
                        </p>
                        <p className="mb-1 txt-carta-name text-ellipsis">
                            {Utils.toCapitalize(item.nameResponsable)}
                        </p>
                        <p className="mb-0 txt-carta-name text-ellipsis d-flex flex-column">
                            {
                                item.resumen.map((item, i) => (
                                    <span key={i}>{item.estado}: {item.cantidad}</span>
                                ))
                            }
                        </p>
                        <p className="mb-1 txt-carta-name text-ellipsis pt-1">
                            Distancia total: {item.distance ? (item.distance / 1000).toFixed(2) : ""}KM
                        </p>
                        <p className="mb-0 txt-carta-name text-ellipsis pt-1">
                            Fecha de termino:
                        </p>
                        <p className="mb-0 txt-carta-name text-ellipsis pt-1">
                            Tiempo restante: 11 horas
                        </p>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-start"
                >
                    <img
                        src={IconoFlechaAzul}
                        className="cursor-pointer"
                        width="6"
                        alt="ir"
                    />
                </div>
            </div>
        </div>
    );
};

export default ListaRutasRayo;