import React from "react";
import moment from "moment";
import Utils from "../../../assets/utils/Utils";
import "../../../css/global.css";
import { connect } from "react-redux";
import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import axios from "axios";
import url from "../../../config";


import Typography from "@material-ui/core/Typography";

import IconoCalendar from "../../../assets/icons/icono-calendar-white.png";

import Cargando from "../../../assets/components/cargando/Cargando";



const columns = [
  {
    id: "store_id",
    label: "ID Tienda",
    align: "left",
  },
  {
    id: "contact_name_task",
    label: "Nombre de la Tienda",
    align: "left",
  },
  {
    id: "date_task",
    label: "Fecha",
    align: "left",
  },
  {
    id: "order_id_task",
    label: "Order Id",
    align: "left",
  },
  {
    id: "idstatus_task",
    label: "Estado",
    align: "center",
  },
  {
    id: "tracking_link",
    label: "Tracking Link",
    align: "center",
  },
  {
    id: "link_seguimiento",
    label: "Seguimiento",
    align: "center",
  },
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 1000,
      openCalendar: false,
      positionCalendar: {},
      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      from: undefined,
      to: undefined,
      client : "",
      charts  : "",

      cargando: false,
    };
  }

  componentDidMount = async () => {
  
    this.ObtieneCliente();
  };

  async ObtieneCliente ()
  
  {

try {
    
    console.log ("Funciona")
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    this.clients = usuario.customers[0]

    console.log (url.host + "/Customers/customers/" + this.clients)

    let data = await axios({
      method: "get",
      url: url.host + "/Customers/customers/" + this.clients,
      //headers: { tenant: usuario.tenant },
    }).then(({ data }) => data);

    this.setState({ charts: data.charts, client : data.name_customer });

    console.log (this.state)

  } catch (err) {
    Utils.handleAlert(
      "Obtener clientes",
      "No se han podido cargar los clientes",
      "Ok"
    );
  }



    
  }



  render() {
    let tasks = this.props.grupoTareas.tareasRecepcion;
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    // const { page, rowsPerPage } = this.state;

  
 
    let totalTareas = tasks ? tasks.length : 0;
    let totalTareasExitosas = tasks ? Utils.tareasExitosas(tasks) : 0;

    const BoxEstado = ({ id }) => {
      return (
        <Button
          className={
            Utils.colorTarea(id, true) +
            " pt-0 pb-0 py-1 w-90-button txt-carta text-capitalize text-roboto-regular button-state"
          }
          disabled
        >
          {Utils.colorTarea(id, false)}
        </Button>
      );
    };

    const StringTiempo = ({ selectedDaysOrder, selectedHours }) => {
      return selectedDaysOrder.length === 0 ? (
        <img width="16" src={IconoCalendar} alt="Icono calendario" />
      ) : selectedDaysOrder.length === 1 ? (
        moment(selectedDaysOrder[0]).format("DD-MM-YYYY")
      ) : (
        // " " +
        // selectedHours[0] +
        // ":00" +
        // " - " +
        // moment(selectedDaysOrder[0]).format("DD-MM-YYYY") +
        // " " +
        // selectedHours[1] +
        // ":00"
        selectedDaysOrder.map((day, i) => {
          if (i === 1) {
            return moment(day).format("DD-MM-YYYY");
          } else {
            return false;
          }
        })
      );
    };

    return (
      <Box pt={4}>
        <Cargando cargando={this.state.cargando} />

            
       
        


          <Typography variant="h1" gutterBottom>
            Dashboard { this.state.client}
          </Typography>

   <iframe src={this.state.charts}
   frameborder="0"
   width="100%"
   height="2000px"
   ></iframe>
      
        
      </Box>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    grupoTareas: store.grupoTareas,
  };
};

export default connect(mapStateToProps)(Dashboard);
