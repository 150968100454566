import React from "react";

import { LIGHT_MODE_GOOGLE } from "../../utils/Helpers";
import Utils from "../../utils/Utils";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Polyline
} from "react-google-maps";

import IconoCerrar from "../../icons/icono-cerrar-grey.svg";
import { iconPickUp, iconRayo } from "./RefactorGoogleMapFunctionTareas";

const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

const WrappedMap0 = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      onTilesLoaded={() => { }}
      defaultZoom={10}
      zoom={props.zoom}
      defaultCenter={{ lat: -33.4473526, lng: -70.6379711 }}
      options={{
        styles: LIGHT_MODE_GOOGLE,
      }}
      center={props.center}
    >
      <MarkerClusterer
        onClick={props._handleClickCluster}
        averageCenter
        enableRetinaIcons
        gridSize={30}
      >
        {props.positions.map((position, i) => {
          return (
            props.isMarkerShown && (
              <Marker
                onClick={() => props._handleClickMarker(position.placeName)}
                icon={position.icon}
                key={position.task}
                title={position.placeName}
                position={{
                  lat: parseFloat(position.position.lat),
                  lng: parseFloat(position.position.lng),
                }}
              ></Marker>
            )
          );
        })}
      </MarkerClusterer>
      {props.rayos.map((rayo, index) => {
        return (
          props.isMarkerShown && (
            <Marker
              onClick={() => props.openInfoBox(rayo)}
              icon={rayo.icon}
              key={rayo.dataRayo.id + index}
              position={{
                lat: parseFloat(rayo.position.lat),
                lng: parseFloat(rayo.position.lng),
              }}
            ></Marker>
          )
        );
      })}

      {props.abrirInfoBox && props.infoBoxInfo != null ? (
        <InfoBox
          position={
            new window.google.maps.LatLng(
              parseFloat(props.infoBoxInfo.position.lat),
              parseFloat(props.infoBoxInfo.position.lng)
            )
          }
          options={{
            closeBoxURL: ``,
            enableEventPropagation: true,
            pixelOffset: new window.google.maps.Size(0, -105),
          }}
        >
          <div id="content-mapa">
            <img
              src={IconoCerrar}
              height="12"
              className="cerrar"
              alt="cerrar"
              onClick={() => props.openInfoBox(null)}
            />

            <div className="row m-0">
              <div className="d-inline d-flex align-items-center mb-1">
                <div className="m-0 dot activo"></div>
              </div>
              <label className="text-exo-bold mb-1 pl-2">
                {props.infoBoxInfo.dataRayo.name}
              </label>
            </div>
            <div id="bodyContent">
              <p className="text-roboto-regular mb-0">
                {"ID: " + props.infoBoxInfo.dataRayo.id}
              </p>
              <p className="text-roboto-regular mb-0">
                {"DNI: " + props.infoBoxInfo.dataRayo.dni}
              </p>
              <p className="text-roboto-regular mb-0">
                {"Número de contacto: " + props.infoBoxInfo.dataRayo.phone}
              </p>
              <p className="text-roboto-regular mb-0">
                {"Email: " + props.infoBoxInfo.dataRayo.email}
              </p>
            </div>
          </div>
        </InfoBox>
      ) : (
        false
      )}
    </GoogleMap>
  ))
);

const WrappedMap1 = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      onTilesLoaded={() => { }}
      defaultZoom={10}
      zoom={props.zoom}
      defaultCenter={{ lat: -33.4473526, lng: -70.6379711 }}
      options={{
        styles: LIGHT_MODE_GOOGLE,
      }}
      center={props.center}
    >
      <MarkerClusterer
        onClick={props._handleClickCluster}
        averageCenter
        enableRetinaIcons
        gridSize={30}
      >
        {props.positions.map((position, i) => {
          return (
            props.isMarkerShown && (
              <Marker
                onClick={() => props._handleClickMarker(position.placeName)}
                icon={position.icon}
                key={position.task}
                title={position.placeName}
                position={{
                  lat: parseFloat(position.position.lat),
                  lng: parseFloat(position.position.lng),
                }}
              ></Marker>
            )
          );
        })}
      </MarkerClusterer>
      {props.rayos.map((rayo, index) => {
        return (
          props.isMarkerShown && (
            <Marker
              onClick={() => props.openInfoBox(rayo)}
              icon={rayo.icon}
              key={rayo.dataRayo.id + index}
              position={{
                lat: parseFloat(rayo.position.lat),
                lng: parseFloat(rayo.position.lng),
              }}
            ></Marker>
          )
        );
      })}

      {props.abrirInfoBox && props.infoBoxInfo != null ? (
        <InfoBox
          defaultPosition={
            new window.google.maps.LatLng(
              parseFloat(props.infoBoxInfo.position.lat),
              parseFloat(props.infoBoxInfo.position.lng)
            )
          }
          options={{
            closeBoxURL: ``,
            enableEventPropagation: true,
            pixelOffset: new window.google.maps.Size(0, -105),
          }}
        >
          <div id="content-mapa">
            <img
              src={IconoCerrar}
              height="12"
              className="cerrar"
              alt="cerrar"
              onClick={() => props.openInfoBox(null)}
            />

            <div className="row m-0">
              <div className="d-inline d-flex align-items-center mb-1">
                <div className="m-0 dot activo"></div>
              </div>
              <label className="text-exo-bold mb-1 pl-2">
                {props.infoBoxInfo.dataRayo.name}
              </label>
            </div>
            <div id="bodyContent">
              <p className="text-roboto-regular mb-0">
                {"ID: " + props.infoBoxInfo.dataRayo.id}
              </p>
              <p className="text-roboto-regular mb-0">
                {"DNI: " + props.infoBoxInfo.dataRayo.dni}
              </p>
              <p className="text-roboto-regular mb-0">
                {"Número de contacto: " + props.infoBoxInfo.dataRayo.phone}
              </p>
              <p className="text-roboto-regular mb-0">
                {"Email: " + props.infoBoxInfo.dataRayo.email}
              </p>
            </div>
          </div>
        </InfoBox>
      ) : (
        false
      )}
    </GoogleMap>
  ))
);

const WrappedMapDetalle = withScriptjs(
  withGoogleMap((props) => {
    return (
      <GoogleMap
        defaultZoom={12}
        options={{
          styles: LIGHT_MODE_GOOGLE,
        }}
        center={
          props.detalle && props.positions
            ? props.positions.lenght === 0
              ? { lat: parseFloat(-33.4473526), lng: parseFloat(-70.6379711) }
              : {
                lat: parseFloat(props.positions[0].position.lat),
                lng: parseFloat(props.positions[0].position.lng),
              }
            : { lat: -33.4473526, lng: -70.6379711 }
        }
      >
        {/* <MarkerClusterer onClick={props._handleClickCluster} averageCenter enableRetinaIcons gridSize={40}> */}
        {props.positions.map((position, i) => {
          return (
            props.isMarkerShown && (
              <Marker
                onClick={() => props._handleClickMarker(position.placeName)}
                icon={position.icon}
                key={position.task}
                title={position.placeName}
                position={{
                  lat: parseFloat(position.position.lat),
                  lng: parseFloat(position.position.lng),
                }}
              ></Marker>
            )
          );
        })}
        {props.rayos.map((rayo, index) => {
          return (
            props.isMarkerShown && (
              <Marker
                onClick={() => props.openInfoBox(rayo)}
                icon={rayo.icon}
                key={rayo.dataRayo.id + index}
                position={{
                  lat: parseFloat(rayo.position.lat),
                  lng: parseFloat(rayo.position.lng),
                }}
              ></Marker>
            )
          );
        })}

        {props.abrirInfoBox && props.infoBoxInfo != null ? (
          <InfoBox
            defaultPosition={
              new window.google.maps.LatLng(
                parseFloat(props.infoBoxInfo.position.lat),
                parseFloat(props.infoBoxInfo.position.lng)
              )
            }
            options={{
              closeBoxURL: ``,
              enableEventPropagation: true,
              pixelOffset: new window.google.maps.Size(0, -105),
            }}
          >
            <div id="content-mapa">
              <img
                src={IconoCerrar}
                height="12"
                className="cerrar"
                alt="cerrar"
                onClick={() => props.openInfoBox(null)}
              />

              <div className="row m-0">
                <div className="d-inline d-flex align-items-center mb-1">
                  <div className="m-0 dot activo"></div>
                </div>
                <label className="text-exo-bold mb-1 pl-2">
                  {props.infoBoxInfo.dataRayo.name}
                </label>
              </div>
              <div id="bodyContent">
                <p className="text-roboto-regular mb-0">
                  {"ID: " + props.infoBoxInfo.dataRayo.id}
                </p>
                <p className="text-roboto-regular mb-0">
                  {"DNI: " + props.infoBoxInfo.dataRayo.dni}
                </p>
                <p className="text-roboto-regular mb-0">
                  {"Número de contacto: " + props.infoBoxInfo.dataRayo.phone}
                </p>
                <p className="text-roboto-regular mb-0">
                  {"Email: " + props.infoBoxInfo.dataRayo.email}
                </p>
              </div>
            </div>
          </InfoBox>
        ) : (
          false
        )}
        {/* </MarkerClusterer> */}
      </GoogleMap>
    );
  })
);

const WrappedMapRoute = withScriptjs(
  withGoogleMap((props) => {
    const pathCoordinates = props.geometry ? Utils.getPolylines(props.geometry) : [];
    return (
      <GoogleMap
        defaultZoom={12}
        options={{ styles: LIGHT_MODE_GOOGLE }}
        // center={}
        defaultCenter={props.tareasRayo.lenght > 0 ? {
          lat: parseFloat(props.tareasRayo[0].latitude_task),
          lng: parseFloat(props.tareasRayo[0].longitude_task),
        } : { lat: -33.4473526, lng: -70.6379711 }}
      >
        {props.rayo != null || props.rayo != undefined &&
          <Marker
            position={
              {
                lat: parseFloat(this.props.rayo.position.lat),
                lng: parseFloat(this.props.rayo.position.lng)
              }
            }
            icon={iconRayo}
          />}
        {props.geometry &&
          <Polyline
            path={pathCoordinates}
            geodesic={true}
            options={{
              strokeColor: "#311DEF",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />
        }
        {props.tareasRayo.map((item, i) => {
          const color = item.status_name == 'Exitoso' ? '1CC501' : item.status_name == 'Fallido' ? 'BA0B11' : '122337';
          return (
            item.latitude_task != undefined && item.longitude_task != undefined &&
            <Marker
              key={i}
              icon={{
                url: `https://marker.nanoka.fr/map_pin-${color}-FFF-${color}-${i + 1}-35.svg`
              }}
              // key={position.task}
              // title={position.placeName}
              position={{
                lat: parseFloat(item.latitude_task),
                lng: parseFloat(item.longitude_task),
              }}
            />
          );
        })}

      </GoogleMap>
    );
  })
);

export { WrappedMap0, WrappedMap1, WrappedMapDetalle, WrappedMapRoute };
