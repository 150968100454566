import React, {
  memo,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from "react";
import moment from "moment";
import memoize from "memoize-one";
import { FixedSizeList as List, areEqual } from "react-window";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Utils from "../../utils/Utils";

import IconoFlechaAzul from "../../icons/icono-flecha-derecha-blue2.png";

const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  const grid = 0;
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + 10,
    background: isDragging ? "transparent" : "transparent",
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${10 * 2}px)`,
    marginBottom: grid,
  };

  return result;
};

const createRayoData = memoize(
  (items, handleCheck, seleccionTareas, clientes) => ({
    items,
    handleCheck,
    seleccionTareas,
    clientes,
  })
);

function ListaTareasRayo({
  clientes,
  tasks,
  filtro_tareas,
  index,
  handleCheck,
  seleccionTareas,
  showButtons,
}) {
  const [items, changeItems] = useState(tasks);
  useEffect(() => {
    changeItems(tasks);
  }, [tasks]);

  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  let height = window.innerHeight - 64 - 99 - 57;
  if (showButtons) {
    height = window.innerHeight - 64 - 99 - 56 - 55;
  }

  let filterTasks = items.filter((task) => {
    let bandera = false;
    if (
      task.contact_name_task.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
      (task._id != undefined && task._id.toLowerCase().includes(filtro_tareas.toLowerCase())) ||
      (task.idTask != undefined && task.idTask.toLowerCase().includes(filtro_tareas.toLowerCase())) ||
      task.order_id_task.toLowerCase().includes(filtro_tareas.toLowerCase()) ||
      task.code_task.toString().toLowerCase().includes(filtro_tareas.toLowerCase()) ||
      task.address_task.toLowerCase().includes(filtro_tareas.toLowerCase())
    ) {
      bandera = true;
    }
    return bandera;
  });
  const itemData = createRayoData(
    filterTasks,
    handleCheck,
    seleccionTareas,
    clientes
  );

  return (
    <Droppable
      droppableId="droppableRayo"
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          item={filterTasks[rubric.source.index]}
          handleCheck={handleCheck}
          seleccionTareas={seleccionTareas}
          isDragging={snapshot.isDragging}
        />
      )}
    >
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder
          ? filterTasks.length + 1
          : filterTasks.length;

        return (
          <List
            height={height}
            itemCount={itemCount}
            itemSize={130}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={itemData}
            className="task-list"
            ref={listRef}
          >
            {Row}
          </List>
        );
      }}
    </Droppable>
  );
}

const Row = memo(({ data, index, style }) => {
  const { items, handleCheck, seleccionTareas, clientes } = data;
  const item = items[index];
  if (!item) return null;

  return (
    <Draggable draggableId={item._id ?? item.idTask} index={index} key={item._id ?? item.idTask}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          clientes={clientes}
          handleCheck={handleCheck}
          seleccionTareas={seleccionTareas}
        />
      )}
    </Draggable>
  );
}, areEqual);

const Item = ({
  provided,
  item,
  style,
  clientes,
  handleCheck,
  seleccionTareas,
  isDragging,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      <div
        className={
          item.optimized === null
            ? "w-100 row m-0 mt-3 carta-orden bg-white"
            : item.optimized === true
              ? "w-100 row m-0 mt-3 carta-orden-green bg-white"
              : "w-100 row m-0 mt-3 carta-orden-red bg-white"
        }
        style={{ height: 110, marginBottom: 4, paddingTop: 10 }}
      >
        <div className="ver-copiado-rayo hidden" id={"rayo-" + item._id}>
          <label>¡Copiado!</label>
        </div>
        <div
          className={
            item.optimized === null
              ? "carta-orden-estado row m-0 py-05 px-05 bg-white"
              : item.optimized === true
                ? "carta-orden-estado-green row m-0 py-05 px-05 bg-white"
                : "carta-orden-estado-red row m-0 py-05 px-05 bg-white"
          }
          style={{ top: 7 }}
        >
          <div className="d-inline d-flex align-items-center">
            <div
              className={"dot " + Utils.colorTarea(item.idstatus_task, true)}
            ></div>
          </div>
          <label className="mb-0 pl-1 txt-carta pr-1">
            {Utils.colorTarea(item.idstatus_task, false) + " "}
            <label className="mb-0 txt-carta color-medium-red">
              {item.skipped_task === 1 ? " / tarea saltada" : false}
            </label>
            <label
              className="mb-0 position-absolute f-right cursor-pointer px-1"
              style={{ backgroundColor: "#f2f2f2", borderRadius: 4 }}
              onClick={() =>
                Utils.justCopyText2("rayo-" + item._id, item.order_id_task)
              }
            >
              {item.order_id_task}
            </label>
          </label>
        </div>
        <div style={{ width: 40, paddingLeft: 2, paddingTop: 2, height: 43 }}>
          <Checkbox
            name="checkedA"
            color="primary"
            checked={item.selected}
            onClick={(e) => handleCheck(e, item)}
            icon={
              <CheckBoxOutlineBlankIcon
                fontSize="small"
                style={{ color: "#9999FF" }}
              />
            }
            checkedIcon={<CheckBoxIcon fontSize="small" />}
          />
        </div>
        <div
          style={{ width: 80, paddingTop: 7, height: 45 }}
          className="text-exo-bold"
        >
          <p
            className={
              "mb-0 " + Utils.colorTipoTareaDetalle(item.type_task, true)
            }
          >
            {Utils.colorTipoTareaDetalle(item.type_task, false)}
          </p>
          <p
            className="mb-0 sub-txt-carta cursor-pointer"
            style={{ paddingTop: 1 }}
            onClick={() =>
              Utils.justCopyText2("rayo-" + item._id, item.code_task)
            }
          >
            <span
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 4,
              }}
              className="px-1"
            >
              {"ID: " + item.code_task}
            </span>
          </p>
        </div>
        <div
          style={{
            width: "calc(100% - 80px - 40px - 10px)",
            paddingTop: 7,
            height: 43,
          }}
        >
          <p
            className="mb-0 sub-txt-carta d-flex align-items-center"
            style={{ height: 20, paddingBottom: 3 }}
          >
            {moment(item.date_task).utcOffset("+00").format("DD-MM-YYYY HH:mm")}
          </p>

          <p className="mb-0 txt-carta-name text-ellipsis">
            {Utils.toCapitalize(item.contact_name_task.split("-")[0])}
          </p>
        </div>

        <div
          className="d-flex align-items-center justify-content-start"
          style={{
            width: 10,
            height: 80,
            position: "absolute",
            top: 23,
            right: 3,
          }}
        >
          <img
            src={IconoFlechaAzul}
            className="cursor-pointer"
            width="6"
            alt="ir"
            onClick={() => {
              const id = item._id ?? item.idTask
              window.location.href = "/Home/Tarea/Editar/" + id;
            }}
          />
        </div>

        <div
          className="sub-txt-carta color-light-grey"
          style={{
            marginLeft: 40,
            height: 32,
            width: "calc(100% - 10px - 40px)",
          }}
        >
          <p className="mb-0">
            <b>
              {clientes
                ? clientes.map((cliente, i) =>
                  cliente._id === item.idcustomer_task
                    ? cliente.name_customer
                    : false
                )
                : false}
            </b>
          </p>
          <p className="mb-0">{item.address_task}</p>
        </div>
      </div>

      {seleccionTareas && item.selected ? (
        <div className="letra-task d-flex justify-content-center align-items-center">
          {item.number}
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default ListaTareasRayo;
