import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import url from "../../config";
import Utils from "../../assets/utils/Utils";
import CopyText from "../../assets/components/custom/CopyText";
import "../../css/global.css";
import cloneDeep from "lodash/cloneDeep";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import IconoDelivery from "../../assets/icons/delivery-man-white.svg";
import IconoCerrar from "../../assets/icons/icono-cerrar-white.svg";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import ActiveDotTrueFalse from "../../assets/components/mantenedores/ActiveDotTrueFalse";

import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

const IconoUsuario =
  "https://storage.googleapis.com/fleet-manager-env.appspot.com/Rayos/genericImage.png";

const columns = [
  {
    id: "copiar",
    label: "Copiar ID",
    align: "left",
  },
  { id: "image_customer", label: "Imagen", align: "left" },
  { id: "name_customer", label: "Nombre", align: "left" },
  { id: "rut_customer", label: "Rut Empresa", align: "left" },
  // { id: "phone_customer", label: "Télefono", class: "", classtd: "text-left" },
  { id: "mail_customer", label: "Correo", align: "left" },
  { id: "vigencia", label: "Vigencia", align: "left" },
  {
    id: "accion",
    label: "Acción",
    align: "right",
  },
];

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      search: "",
      tipoModal: 1,
      customers: null,
      form: {
        key: "",
        code_customer: "",
        name_customer: "",
        business_name_customer: "",
        rut_customer: "",
        representative_customer: "",
        phone_customer: "",
        mail_customer: "",
        status_customer: "1",
        vigencia : true,
        image_customer: IconoUsuario,
        cliente: "",
        search_radius: "",
        maximum_quantity_tasks: "",
        quantity_iterations: "",
        increase_search_radius: "",
        metadata: [],
        metadata_campo: "",
        metadata_valor: "",
        charts : ""
      },
      modal: "",
      tipo_modal: "",
    };
    this.getCustumers();
  }

  handleAddMetadata = () => {
    if (
      this.state.form.metadata_campo !== "" &&
      this.state.form.metadata_valor !== ""
    ) {
      let newArray = [
        this.state.form.metadata_campo,
        this.state.form.metadata_valor,
      ];
      let oldArray = cloneDeep(this.state.form.metadata);
      oldArray.push(newArray);

      this.setState({
        form: {
          ...this.state.form,
          metadata: oldArray,
          metadata_campo: "",
          metadata_valor: "",
        },
      });
    }
  };

  handleRemoveMetadata = (i) => {
    let oldArray = cloneDeep(this.state.form.metadata);
    oldArray.splice(i, 1);

    this.setState({
      form: {
        ...this.state.form,
        metadata: oldArray,
      },
    });
  };

  handlePhone = (e) => {
    let name = "phone_customer";
    let value = "+ " + e.replace(/[^0-9.]/g, "");
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let valor = value;
    if (
      name === "phone_customer" ||
      name === "maximum_quantity_tasks" ||
      name === "quantity_iterations"
    ) {
      valor = value.replace(/[^0-9.]/g, "");
    } else if (name === "search_radius" || name === "increase_search_radius") {
      valor = value.replace(/[^0-9.]|\.(?=.*\.)/g, "");
    } else {
      valor = value;
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: valor,
      },
    });
  };

  handleChangeButton = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        status_customer: value,
      
      },
    });
  };

  handleModal = async (tipo, cliente) => {
    this.setState({
      tipoModal: tipo,
    });
    if (tipo === 1) {
      $("#modalFormulario").modal("show");
    } else {
      let metadata = [];
      if (cliente.metadata) {
        metadata = Object.keys(cliente.metadata).map((key) => [
          key,
          cliente.metadata[key],
        ]);
      }

      await this.setState({
        form: {
          ...this.state.form,
          key: cliente._id,
          code_customer: cliente.code_customer,
          name_customer: cliente.name_customer,
          business_name_customer: cliente.business_name_customer,
          rut_customer: cliente.rut_customer,
          representative_customer: cliente.representative_customer,
          phone_customer: cliente.phone_customer.toString(),
          mail_customer: cliente.mail_customer,
          status_customer: cliente.status_customer,
          vigencia :cliente.vigencia,
          image_customer: cliente.image_customer,
          search_radius: cliente.search_radius,
          vigencia : cliente.vigencia,
          maximum_quantity_tasks: cliente.maximum_quantity_tasks,
          quantity_iterations: cliente.quantity_iterations,
          increase_search_radius: cliente.increase_search_radius,
          charts : cliente.charts,
          metadata: metadata,
          metadata_campo: "",
          metadata_valor: "",
        },
      });
      $("#modalFormulario").modal("show");
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        key: "",
        code_customer: "",
        name_customer: "",
        business_name_customer: "",
        rut_customer: "",
        representative_customer: "",
        phone_customer: "+56",
        vigencia : true,
        mail_customer: "",
        status_customer: "1",
        image_customer: IconoUsuario,
        search_radius: "",
        maximum_quantity_tasks: "",
        quantity_iterations: "",
        increase_search_radius: "",
        metadata: [],
        metadata_campo: "",
        metadata_valor: "",
        charts : ""
      },
    });
  };

  getCustumers = async () => {
    try {
      let clientes = Utils.getCustomers();
      let usuario = JSON.parse(sessionStorage.getItem("user"));

      console.log ("MPC PRUEBAS")

      console.log (url.host + "/Customers/customers" + clientes)

      let data = await axios({
        method: "get",
        url: url.host + "/Customers/customers" + clientes,
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ customers: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener clientes",
        "No se han podido cargar los clientes",
        "Ok"
      );
    }
  };

  createCliente = async () => {
    try {
      let arrayMetadata = this.state.form.metadata;
      let objectMetadata = {};

      arrayMetadata.forEach((element) => {
        console.log(element);
        objectMetadata = {
          ...objectMetadata,
          [element[0]]: element[1],
        };
      });

      let imagen = this.state.form.image_customer;
      if (imagen === IconoUsuario) {
        imagen = "";
      }

      let search_radius = this.state.form.search_radius;
      if (search_radius === "" || search_radius == null) {
        search_radius = "0";
      }

      let maximum_quantity_tasks = this.state.form.maximum_quantity_tasks;
      if (maximum_quantity_tasks === "" || maximum_quantity_tasks == null) {
        maximum_quantity_tasks = "0";
      }

      let quantity_iterations = this.state.form.quantity_iterations;
      if (quantity_iterations === "" || quantity_iterations == null) {
        quantity_iterations = "0";
      }

      let increase_search_radius = this.state.form.increase_search_radius;
      if (increase_search_radius === "" || increase_search_radius == null) {
        increase_search_radius = "0";
      }
    
      let numero = this.state.form.phone_customer.split("+");
      let res = await url.api.post("/Customers/customer/", {
        code_customer: this.state.form.code_customer,
        name_customer: this.state.form.name_customer,
        business_name_customer: this.state.form.business_name_customer,
        rut_customer: this.state.form.rut_customer,
        representative_customer: this.state.form.representative_customer,
        phone_customer: parseInt(numero.length === 1 ? numero[0] : numero[1]),
        mail_customer: this.state.form.mail_customer,
        status_customer: parseInt(this.state.form.status_customer),
        vigencia : this.state.form.vigencia,
        image_customer: imagen,
        search_radius: parseFloat(search_radius),
        maximum_quantity_tasks: parseInt(maximum_quantity_tasks),
        quantity_iterations: parseInt(quantity_iterations),
        increase_search_radius: parseInt(increase_search_radius),
        charts : this.state.form.charts, //MPC agregar Charts
        metadata: objectMetadata,
      });
      if (res.status === 201 || res.status === 200) {
        $("#modalFormulario").modal("hide");
        this.getCustumers();
        Utils.handleAlert(
          "Cliente ingresado",
          "El cliente se ha ingresado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar el cliente, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      console.log(error)
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar el cliente, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Cliente?",
      text: "Si confirma, no podrá deshacer la acción y el cliente será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteCliente(key);
      }
    });
  };

  deleteCliente = async (key) => {
    try {
      const customer = {
        key: key,
        tenant: this.state.form.tenant,
      };
      let res = await url.api.delete("/Customers/customer/", {
        data: customer,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getCustumers();
        Utils.handleAlert(
          "Cliente eliminado",
          "El cliente se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el cliente, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el cliente, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyCliente = async () => {
    try {
      let arrayMetadata = this.state.form.metadata;
      let objectMetadata = {};

      arrayMetadata.forEach((element) => {
        console.log(element);
        objectMetadata = {
          ...objectMetadata,
          [element[0]]: element[1],
        };
      });
      console.log(objectMetadata);

      let imagen = this.state.form.image_customer;
      if (imagen === IconoUsuario) {
        imagen = "";
      }

      let search_radius = this.state.form.search_radius;
      if (search_radius === "" || search_radius == null) {
        search_radius = "0";
      }

      let maximum_quantity_tasks = this.state.form.maximum_quantity_tasks;
      if (maximum_quantity_tasks === "" || maximum_quantity_tasks == null) {
        maximum_quantity_tasks = "0";
      }

      let quantity_iterations = this.state.form.quantity_iterations;
      if (quantity_iterations === "" || quantity_iterations == null) {
        quantity_iterations = "0";
      }

      let increase_search_radius = this.state.form.increase_search_radius;
      if (increase_search_radius === "" || increase_search_radius == null) {
        increase_search_radius = "0";
      }

      let numero = this.state.form.phone_customer.split("+");

      let res = await url.api.put("/Customers/customer/", {
        key: this.state.form.key,
        code_customer: this.state.form.code_customer,
        name_customer: this.state.form.name_customer,
        business_name_customer: this.state.form.business_name_customer,
        rut_customer: this.state.form.rut_customer,
        representative_customer: this.state.form.representative_customer,
        phone_customer: parseInt(numero.length === 1 ? numero[0] : numero[1]),
        mail_customer: this.state.form.mail_customer,
        status_customer: parseInt(this.state.form.status_customer),
        vigencia : this.state.form.vigencia,
        image_customer: imagen,
        search_radius: parseFloat(search_radius),
        maximum_quantity_tasks: parseInt(maximum_quantity_tasks),
        quantity_iterations: parseInt(quantity_iterations),
        increase_search_radius: parseInt(increase_search_radius),
        charts : this.state.form.charts,
        metadata: objectMetadata,
        tenant: this.state.form.tenant,
      });

      if (res.status === 200) {
        this.getCustumers();
        $("#modalFormulario").modal("hide");
        Utils.handleAlert(
          "Cliente modificado",
          "El cliente se ha modificado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al modificar el cliente, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el cliente, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  render() {
    const { page, rowsPerPage, tipoModal } = this.state;

    const ImagenCliente = ({ imagen }) => {
      return (
        <img src={imagen} height="35" className="icon-table" alt="cliente" />
      );
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Clientes
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              data-toggle="modal"
              data-target="#crearModal"
              onClick={() => this.handleModal(1)}
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Customers">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.customers ? (
                  this.state.customers.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen clientes creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.customers
                      .filter((customer) => {
                        let bandera = false;
                        if (
                          customer.business_name_customer
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          customer.name_customer
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          customer._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            <CopyText text={row._id} />
                          </TableCell>
                          <TableCell align="left">
                            <ImagenCliente imagen={row.image_customer} />
                          </TableCell>
                          <TableCell align="left">
                            {row.name_customer}
                          </TableCell>
                          <TableCell align="left">{row.rut_customer}</TableCell>
                          <TableCell align="left">
                            {row.mail_customer}
                          </TableCell>
                          <TableCell align="left">

                            <ActiveDotTrueFalse status={row.vigencia} />
                         
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.handleModal(2, row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return from + " - " + to + " de " + count + " clientes";
              }}
              component="div"
              count={
                this.state.customers
                  ? this.state.customers.filter((customer) => {
                      let bandera = false;
                      if (
                        customer.business_name_customer
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        customer.name_customer
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        customer._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="modalFormulario"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                    style={{ flexGrow: 1 }}
                  >
                    {tipoModal === 1 ? "Crear Cliente" : "Modificar Cliente"}
                  </Typography>

                  <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    className="mr-2"
                    onClick={() => $("#modalMetadata").modal("show")}
                  >
                    Metadata
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => $("#modalAutoAsignacion").modal("show")}
                  >
                    Administrar auto-asignación
                  </Button>
                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modalFormulario").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarCliente}>
                  <div className="modal-body">
                    <div className="w-100 mb-3">
                      <label className="filebutton2 d-flex justify-content-start">
                        <img
                          src={this.state.form.image_customer}
                          width="100"
                          height="100"
                          alt="Cliente"
                          className="icon-table"
                        />
                        <span>
                          <input
                            type="file"
                            accept="image/*"
                            id="image_customer"
                            name="image_customer"
                            onChange={this.handleImage}
                            className="icon-table"
                          />
                        </span>
                      </label>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Código</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="code_customer"
                          className="form-control"
                          placeholder="Código"
                          value={this.state.form.code_customer}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Nombre</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="name_customer"
                          className="form-control"
                          placeholder="Nombre"
                          value={this.state.form.name_customer}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Razon Social</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="business_name_customer"
                          className="form-control"
                          placeholder="Razón social"
                          value={this.state.form.business_name_customer}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Rut Empresa</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          maxLength={13}
                          name="rut_customer"
                          className="form-control"
                          placeholder="Rut empresa"
                          value={this.state.form.rut_customer}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Representante</label>
                        <label className="texto-form-red-light"> *</label>
                        <input  
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="representative_customer"
                          className="form-control"
                          placeholder="Nombre representante"
                          value={this.state.form.representative_customer}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Teléfono</label>
                        <label className="texto-form-red-light"> *</label>

                        <PhoneInput
                          preferredCountries={["cl", "mx"]}
                          country={"cl"}
                          inputProps={{
                            name: "phone_customer",
                            required: true,
                            type: "text",
                            minLength: 9,
                          }}
                          className="form-control"
                          placeholder="989828918"
                          name="phone_customer"
                          value={this.state.form.phone_customer}
                          onChange={this.handlePhone}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Correo</label>
                        <label className="texto-form-red-light"> *</label>
                        <input
                          required
                          onChange={this.handleChange}
                          type="text"
                          name="mail_customer"
                          className="form-control"
                          placeholder="Correo"
                          value={this.state.form.mail_customer}
                        />
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Vigencia</label>
                        <label className="texto-form-red-light"> *</label>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(1)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.vigencia) ===true

                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot activo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Activo</div>
                              </div>
                            </Button>
                          </div>
                          <div className="form-group col-md-6">
                            <Button
                              type="button"
                              onClick={() => this.handleChangeButton(2)}
                              variant="contained"
                              color={
                                parseInt(this.state.form.vigencia) === false
                                  ? "primary"
                                  : "default"
                              }
                              className="w-100 text-roboto-regular"
                            >
                              <div className="row m-0 w-100">
                                <div className="col-sm-3 px-0 d-flex justify-content-center align-items-center">
                                  <div className="dot inactivo"></div>
                                </div>
                                <div className="col-sm-9 px-0">Inactivo</div>
                              </div>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label className="texto-form mb-1">Tenant</label>
                        <input
                          required={false}
                          disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="tenant"
                          className="form-control"
                          placeholder="Tenant"
                          value={this.state.form.tenant}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label className="texto-form mb-1">Charts</label>
                        <input
                          required={false}
                         // disabled={tipoModal === 1 ? false : true}
                          onChange={this.handleChange}
                          type="text"
                          name="charts"
                          className="form-control"
                          placeholder="MongoCharts"
                          value={this.state.form.charts}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      {tipoModal === 1 ? "Crear" : "Modificar"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modalAutoAsignacion"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalAutoAsignacion"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <img src={IconoDelivery} height="35" alt="Logo Rayo" />
                  <h4
                    className="modal-title title-text-white ml-3"
                    id="editarModalLabel"
                  >
                    Administrar auto-asignación
                  </h4>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => $("#modalZonas").modal("hide")}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group input-km">
                    <label className="texto-form mb-1">
                      Kilometros a la redonda
                    </label>
                    <div className="input-group">
                      <input
                        maxLength={9}
                        type="text"
                        className="form-control"
                        placeholder="0"
                        name="search_radius"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.form.search_radius}
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Km
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group input-km">
                    <label className="texto-form mb-1">
                      N° máximo de tareas por Rayo
                    </label>
                    <div className="input-group">
                      <input
                        maxLength={9}
                        type="text"
                        className="form-control"
                        placeholder="0"
                        name="maximum_quantity_tasks"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.form.maximum_quantity_tasks}
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Tareas
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="texto-form mb-1">
                      N° máximo de Intentos
                    </label>
                    <input
                      required
                      onChange={(e) => this.handleChange(e)}
                      type="text"
                      name="quantity_iterations"
                      className="form-control"
                      placeholder="0"
                      value={this.state.form.quantity_iterations}
                    />
                  </div>

                  <div className="form-group input-km">
                    <label className="texto-form mb-1">
                      Kilometros a incrementar
                    </label>
                    <div className="input-group">
                      <input
                        maxLength={9}
                        type="text"
                        className="form-control"
                        placeholder="0"
                        name="increase_search_radius"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.form.increase_search_radius}
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Km
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modalMetadata"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalMetadata"
            data-backdrop="static"
            aria-hidden="true"
            scrollable="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title title-text-white ml-3"
                    id="editarModalLabel"
                  >
                    Metadata
                  </h4>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => $("#modalMetadata").modal("hide")}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="title-text-white">
                      <img src={IconoCerrar} height="37" alt="Logo Rayo" />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row m-0 mb-3">
                    <input
                      required
                      type="text"
                      name="metadata_campo"
                      className="form-control d-inline-flex"
                      style={{ width: "45%" }}
                      placeholder="Ingresar key de objeto"
                      value={this.state.form.metadata_campo}
                      onChange={this.handleChange}
                    />
                    <div
                      style={{ width: 30 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h3
                        className="mb-0 text-secondary"
                        style={{
                          lineHeight: 0,
                        }}
                      >
                        :
                      </h3>
                    </div>
                    <input
                      required
                      type="text"
                      name="metadata_valor"
                      className="form-control d-inline-flex"
                      style={{ width: "45%" }}
                      placeholder="Ingresar valor"
                      value={this.state.form.metadata_valor}
                      onChange={this.handleChange}
                    />
                    <div
                      style={{ width: "calc(100% - 90% - 30px)" }}
                      className="d-flex justify-content-end align-items-center pl-2"
                    >
                      <button
                        onClick={this.handleAddMetadata}
                        className="btn back-sky-blue br-4 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <img
                          src={IconoCerrar}
                          height="20"
                          className="rotar-icono-45"
                          alt="icon"
                        />
                      </button>
                    </div>
                  </div>

                  {this.state.form.metadata
                    ? this.state.form.metadata.map((element, i) => (
                        <div className="row m-0 mb-2" key={i}>
                          <input
                            required
                            disabled
                            type="text"
                            name="quantity_iterations"
                            className="form-control d-inline-flex"
                            style={{ width: "45%" }}
                            placeholder="0"
                            defaultValue={element[0]}
                          />
                          <div
                            style={{ width: 30 }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <h3
                              className="mb-0 text-secondary"
                              style={{
                                lineHeight: 0,
                              }}
                            >
                              :
                            </h3>
                          </div>
                          <input
                            required
                            disabled
                            type="text"
                            name="quantity_iterations"
                            className="form-control d-inline-flex"
                            style={{ width: "45%" }}
                            placeholder="0"
                            defaultValue={element[1]}
                          />
                          <div
                            style={{ width: "calc(100% - 90% - 30px)" }}
                            className="d-flex justify-content-end align-items-center"
                          >
                            <IconButton
                              aria-label="close"
                              className="close"
                              style={{ padding: 7 }}
                              onClick={() => this.handleRemoveMetadata(i)}
                            >
                              <CloseIcon htmlColor="#ffffff" fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      ))
                    : false}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarCliente = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      if (this.state.tipoModal === 1) {
        this.createCliente();
      } else {
        this.modifyCliente();
      }
    }
  };
}

export default Clientes;
