import React from "react";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { withStyles } from "@material-ui/core/styles";
import { geocodeByPlaceId } from "react-places-autocomplete";
// import JSONTree from "react-json-tree";
import "../../../css/global.css";
import url from "../../../config";
import Utils from "../../../assets/utils/Utils";
import Watchs from "../../../assets/utils/Watchs";
import { APPCONFIG, SERVICE_NAME } from "../../../assets/utils/Helpers";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";

import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_DEVUELTO,
} from "../../../assets/utils/Helpers";
import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
  ID_RETIROCLIENTE,
  ID_BLOQUEADOCLIENTE,
  ID_RECEPCIONBODEGA
} from "../../../assets/utils/Helpers";
import {
  DATA_EDITAR,
  N_TAREA,
  N_GRUPOTAREA,
  // THEME_METADATA,
} from "../../../assets/utils/Helpers";

import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";
import EditarRayo from "../../../assets/components/tareas/EditarRayo";
import EditarEstado from "../../../assets/components/tareas/EditarEstado";
import DuplicarTareas from "../../../assets/components/tareas/DuplicarTareas";
import ReAbrirTarea from "../../../assets/components/tareas/ReAbrirTarea";
import CancelarTarea from "../../../assets/components/tareas/CancelarTarea";
import EliminarTareas from "../../../assets/components/tareas/EliminarTareas";
import Cargando from "../../../assets/components/cargando/Cargando";
import DescargarCSV from "../../../assets/components/home/DescargarCSV";
import DetalleGrupoTareas from "../../../assets/components/home/DetalleGrupoTareas";
import EditarTareas from "../../../assets/components/tareas/EditarTareas";
import CustomExpandMore from "../../../assets/components/custom/CustomExpandMore";
import ListaTareas from "../../../assets/components/lists/ListaTareas";

import AssignmentIcon from "@material-ui/icons/Assignment";
import IconoDocumento from "../../../assets/icons/icono-document-download-black.svg";
import IconoRecargar from "../../../assets/icons/icono-cambiar-black.svg";
import IconoLupa from "../../../assets/icons/icono-lupa-white.svg";
import IconoEditar from "../../../assets/icons/icono-pencil-black.svg";
import IconoDuplicar from "../../../assets/icons/icono-duplicar-black.svg";
import IconoReasignar from "../../../assets/icons/icono-user-black.svg";
import IconoCambiar from "../../../assets/icons/icono-cambiar-black.svg";
import IconoEliminar from "../../../assets/icons/icono-trash-black.svg";
import IconoReloj from "../../../assets/icons/historia.svg";
import IconoRoute from "../../../assets/icons/gps-route.svg";
import IconoData from "../../../assets/icons/data.svg";
import IconoFlechaBlanca from "../../../assets/icons/icono-down-white.svg";

import { Button, FormControl, OutlinedInput } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import { Drawer, MenuItem, Select } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/CheckBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import * as Realm from "realm-web";
import MetadataJson from "../../../assets/components/home/MetadataJson";

const columns = [
  {
    id: "check",
    label: "check",
    align: "left",
  },
  {
    id: "order_id_task",
    label: "ID Orden",
    align: "left",
  },
  { id: "code_task", label: "ID Task", align: "left" },
  { id: "type_task", label: "Tipo", align: "center" },
  {
    id: "contact_name_task",
    label: "Nombre de contacto",
    align: "left",
  },
  {
    id: "address_task",
    label: "Dirección",
    align: "left",
  },
  { id: "date_task", label: "Inicio programado", align: "left" },
  { id: "end_date_task", label: "Fecha finalización", align: "left" },
  { id: "idstatus_task", label: "Estado de tarea", align: "left" },
  {
    id: "customer",
    label: "Cliente",
    align: "left",
  },
  { id: "name_rayo", label: "Rayo", align: "left" },
  { id: "route", label: "Codigo Ruta", align: "center" },
  {
    id: "link_seguimiento",
    label: "Seguimiento",
    align: "center",
  },
  {
    id: "accion",
    label: "Acciones",
    align: "center",
  },
];

const CustomOutlinedInput = withStyles((theme) => ({
  root: {
    borderTop: "2px solid " + theme.palette.primary.main + " !important",
    borderBottom: "2px solid " + theme.palette.primary.main + " !important",
    borderLeft: "2px solid " + theme.palette.primary.main + " !important",
    borderRight: "1px solid #9f9f9f !important",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}))(OutlinedInput);

const CustomSelect = withStyles((theme) => ({
  root: {
    borderTop: "2px solid " + theme.palette.primary.main + " !important",
    borderBottom: "2px solid " + theme.palette.primary.main + " !important",
    borderRight: "none !important",
    borderLeft: "none !important",
    borderRadius: "0px !important",
    paddingTop: "8px !important",
    paddingBottom: 8,
  },
}))(Select);

const CustomButton = withStyles((theme) => ({
  root: {
    height: "36.19px !important",
    border: "2px solid " + theme.palette.primary.main + " !important",
    paddingRight: 0,
    "&:focus": {
      border: "2px solid " + theme.palette.primary.main + " !important",
    },
  },
  endIcon: {
    backgroundColor: theme.palette.primary.main,
    padding: 6,
    marginRight: 0,
  },
}))(Button);

class VistaLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      page: 0,
      rowsPerPage: 10,
      tareas: "",
      selectTareas: "all",
      rayo_selected: "0",
      estadoSelected: "0",
      opcionEditarEstado: "",
      anchorElFiltros: null,
      anchorElEstados: null,
      nombreEstado: "Personalizado",
      stringEstados: "",

      filters: {
        [ID_NO_ASIGNADO]: true,
        [ID_ASIGNADO]: true,
        [ID_ACEPTADO]: true,
        [ID_INICIADO]: true,
        [ID_EN_PROGRESO]: true,
        [ID_EXITOSO]: true,
        [ID_FALLIDO]: true,
        [ID_RECHAZADO]: true,
        [ID_CANCELADO]: true,
        [ID_DEVUELTO]: true,
        [ID_RETIROCLIENTE]: true,
        [ID_BLOQUEADOCLIENTE]: true,

        [ID_RECEPCIONBODEGA]: true




      },

      tipoModal: 2,
      tipoModalAccionMasiva: 1,
      estados: [],
      tarea: null,
      grupoTareas: null,

      cargando: false,
      openGT: false,

      taskMetadata: null,
      contextMenuState: false,
      contextMenu: { display: "none" },

      historial: false,
      dataHistorial: [],
      tituloHistorial: "",
      accionMasiva: "null",
      template: [],

      camposEditar: DATA_EDITAR,
      camposEditarSelected: [],
      opcionEditar: "null",
      failedReason: "",
      tareasSelected: [],

      csvCantidadTareas: "todas",
      csvIncluirOrder: false,
      csvRayo: "0",
      csvCliente: "0",

      openCalendar: false,
      positionCalendar: {},
      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      from: undefined,
      to: undefined,

      estadoReabrir: ID_ASIGNADO,
      motivoReabrir: "Error de cierre",
      motivoDuplicar: "", //Segúnda Visita
      motivoCancelar: "Error de cierre",

      templates: {
        successful: null,
        failed: null,
      },
      optionsDropdown: [],
      optionsDropdownSucces: [],
      optionsDropdownFailed: [],
    };
  }

  handleEstados = (filtro) => {
    // let filtros = cloneDeep(this.state.filters);
    let filtrosState = cloneDeep(this.state.filters);
    let filtros = {
      [ID_NO_ASIGNADO]: false,
      [ID_ASIGNADO]: false,
      [ID_ACEPTADO]: false,
      [ID_INICIADO]: false,
      [ID_EN_PROGRESO]: false,
      [ID_EXITOSO]: false,
      [ID_FALLIDO]: false,
      [ID_RECHAZADO]: false,
      [ID_CANCELADO]: false,
      [ID_DEVUELTO]: false,
      [ID_RETIROCLIENTE]: false,

      [ID_BLOQUEADOCLIENTE]: false,

      [ID_RECEPCIONBODEGA]: false,


    };

    if (filtro === "todos") {
      this.handleFiltro("");
      this.setState({
        stringEstados: "",
        nombreEstado: "Personalizado",
        filters: {
          [ID_NO_ASIGNADO]: true,
          [ID_ASIGNADO]: true,
          [ID_ACEPTADO]: true,
          [ID_INICIADO]: true,
          [ID_EN_PROGRESO]: true,
          [ID_EXITOSO]: true,
          [ID_FALLIDO]: true,
          [ID_RECHAZADO]: true,
          [ID_CANCELADO]: true,
          [ID_DEVUELTO]: true,
          [ID_RETIROCLIENTE]: true,
          [ID_BLOQUEADOCLIENTE]: true,
          [ID_RECEPCIONBODEGA]: true,


        },
      });
    } else {
      if (filtrosState[filtro]) {
        this.handleFiltro("");
        this.setState({
          stringEstados: "",
          nombreEstado: "Personalizado",
          filters: filtros,
        });
      } else {
        let string = "";
        let contador = 0;
        filtros[filtro] = !filtros[filtro];
        let result = Object.keys(filtros).map((key) => [
          { id: key, data: filtros[key] },
        ]);
        let saveFilters = {};
        Object.keys(filtros).forEach((key) => {
          saveFilters = { ...saveFilters, [key]: filtros[key] };
        });

        result.forEach((element) => {
          if (element[0].data) {
            contador = contador + 1;
            string =
              contador === 1
                ? string + element[0].id
                : string + "," + element[0].id;
          }
        });
        if (contador !== 0) {
          this.handleFiltro(string);
          this.setState({
            filters: saveFilters,
            stringEstados: string,
            nombreEstado: Utils.nombreEstado(string),
          });
        }
      }
    }
  };

  handleFiltro = async (filtro) => {
    await this.handleCargando(true);
    const { dispatch } = this.props;
    let skip = 0 * this.state.rowsPerPage;
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      skip,
      filtro,
      null,
      this.state.rowsPerPage
    );
    try {
      this.setState({ page: 0 });
      await dispatch(obtenerTareas);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se ha filtrado la data, vuelva a intentarlo",
        "Ok"
      );
    }
    this.handleCargando(false);
  };

  filterOrderId = async () => {
    await this.handleCargando(true);

    const { dispatch } = this.props;



    let data = this.state.tareas;




    if (this.state.tareas === "") {
      data = null;
    }
    let skip = 0 * this.state.rowsPerPage;
    let buscarTexto = { filter: this.state.selectTareas, data: data };



    if (buscarTexto.data != null) {
      //Limita a mostrar solo lo filtrado
      this.setState({ showallitems: false });
    } else {
      this.setState({ showallitems: true });
    }

    let obtenerTareas = await grupoTareasAccion.obtenerTareas(
      [],
      false,
      skip,
      null,
      buscarTexto,
      this.state.rowsPerPage
    );

    // MIGUEL PASTENES console.log ("MPC DISPTACH")
    // console.log (obtenerTareas)

    try {
      this.setState({ page: 0 });
      await dispatch(obtenerTareas);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se ha filtrado la data, vuelva a intentarlo",
        "Ok"
      );
    }
    this.handleCargando(false);
  };

  handleHoursClick = async (event, newValue) => {
    await this.setState({
      selectedHours: newValue,
    });
  };

  handleDayClick = async (day, { selected }) => {
    const { selectedDays } = this.state;

    if (selected) {
      // const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      const selectedIndex = selectedDays.findIndex(
        (selectedDay) =>
          moment(selectedDay).utcOffset("+00").format("DD-MM-YYYY") ===
          moment(day).utcOffset("+00").format("DD-MM-YYYY")
      );
      if (selectedIndex >= 0) {
        await selectedDays.splice(selectedIndex, 1);
        selectedDays.push(selectedDays[0]);
      } else {
        selectedDays.splice(0, 1);
        selectedDays.push(day);
      }
    } else {
      if (selectedDays.length === 2) {
        selectedDays.splice(0, 1);
      }
      selectedDays.push(day);
    }

    let newFechas = [];
    newFechas.push(new Date(Math.min(...selectedDays)));
    newFechas.push(new Date(Math.max(...selectedDays)));

    if (selectedDays.length === 2) {
      this.setState({
        selectedDays: selectedDays,
        selectedDaysOrder: newFechas,
        from: newFechas[0],
        to: newFechas[1],
      });
    }
  };

  fechaFiltro = () => {
    let hoy = new Date();
    let hoyFin = new Date().setHours(23, 59, 59, 999);
    hoyFin = moment(hoyFin).format();
    hoy = moment(hoy).subtract(1, "hours").format();

    let fechaFinSplit = hoyFin.split("T");
    let fechaSplit = hoy.split("T");

    this.setState({
      selectedDays: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedDaysOrder: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedHours: [0, 24],
      from: new Date(fechaSplit[0] + "T00:00:00"),
      to: new Date(fechaFinSplit[0] + "T00:00:00"),
    });
  };

  fechasCalendario = () => {
    this.setState({
      openCalendar: false,
    });
    const selectedDays = this.state.selectedDaysOrder;
    const selectedHours = this.state.selectedHours;
    let newSelectedHours = [];
    let initDate = "";
    let endDate = "";

    selectedHours.forEach((hour) => {
      let hourStr = hour.toString();
      if (hour < 10) {
        hourStr = "0" + hourStr + ":00:00";
      } else if (hour === 24) {
        hourStr = "23:59:59";
      } else {
        hourStr = hourStr + ":00:00";
      }
      newSelectedHours.push(hourStr);
    });

    if (selectedDays.length === 1 || selectedDays.length === 2) {
      initDate =
        moment(selectedDays[0]).format("YYYY-MM-DD") +
        "T" +
        newSelectedHours[0] +
        "Z";

      if (selectedDays.length === 2) {
        endDate =
          moment(selectedDays[1]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      } else {
        endDate =
          moment(selectedDays[0]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      }
    }

    return [initDate, endDate];
  };

  componentDidMount = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    this.setState({
      usuario: usuario,
    });
    document.addEventListener("click", this._handleClick);
    this.getStatus();
    this.cargarTareasIniciales(true);

    this.instance_realm(APPCONFIG);
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.closingRealm();
    });

    this.fechaFiltro();
  };

  closingRealm = async () => {
    try {
      this.watchTasks = null;
      this.watchTaskGroup = null;

      await Promise.all(
        this.app.users.map(async (user, index) => {
          await user.logOut();
          await this.app.removeUser(user);
        })
      );
    } catch (error) { }
  };

  instance_realm = async (appCfg) => {
    try {
      this.app = new Realm.App(appCfg);

      try {
        if (this.app.users.length > 0) {
          await Promise.all(
            this.app.users.map(async (user, index) => {
              await user.logOut();
              await this.app.removeUser(user);
            })
          );
        }
      } catch (error) { }

      await this.app.logIn(Realm.Credentials.anonymous()).then((user) => {
        this.db = this.app.currentUser
          .mongoClient(SERVICE_NAME)
          .db("fleet_manager");
        let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

        // ---- WATCH TASKS -----
        let optionTasks = Utils.getOptionTasksActions(filtrosGlobales);
        this.watchTasks = this.db.collection("tasks").watch(optionTasks);
        this.watch_task();
      });
    } catch (error) { }
  };

  openWatchTaskGroup = (id) => {
    if (this.db) {
      let optionTaskGroup = Utils.getOptionTaskGroup(id);
      this.watchTaskGroup = this.db
        .collection("tasks_groups")
        .watch(optionTaskGroup);
      this.watch_taskgroup();
    }
  };

  closeWatchTaskGroup = () => {
    this.watchTaskGroup = null;
  };

  watch_taskgroup = () => {
    if (this.watchTaskGroup) {
      this.watchTaskGroup.next().then((result) => {
        try {
          if (result.value.operationType === "update") {
            let grupoTareas = this.state.grupoTareas;
            if (grupoTareas) {
              let data = this.taskGroup_Keys(result.value.fullDocument);
              if (data._id === grupoTareas._id) {
                grupoTareas.idstatus_taskgroup = data.idstatus_taskgroup;
                grupoTareas.idrayo_taskgroup = data.idrayo_taskgroup;
                this.setState({
                  grupoTareas: grupoTareas,
                });
              }
            }
          } else if (result.value.operationType === "delete") {
          }
        } catch (error) { }

        this.watch_taskgroup();
      });
    }
  };

  taskGroup_Keys = (document) => {
    let taskGroup = cloneDeep(document);
    taskGroup.id = Utils.getBSONtoString(document._id);
    taskGroup._id = Utils.getBSONtoString(document._id);
    taskGroup.idstatus_taskgroup = Utils.getBSONtoString(
      document.idstatus_taskgroup
    );
    if (document.idrayo_taskgroup == null) {
      taskGroup.idrayo_taskgroup = null;
    } else {
      taskGroup.idrayo_taskgroup = Utils.getBSONtoString(
        document.idrayo_taskgroup
      );
    }
    taskGroup.idcustomer_taskgroup = Utils.getBSONtoString(
      document.idcustomer_taskgroup
    );
    taskGroup.start_date_taskgroup =
      moment(taskGroup.start_date_taskgroup)
        .add(3, "hour")
        .format("YYYY-MM-DDTHH:MM:SS") + ".000Z";

    return taskGroup;
  };

  watch_task = () => {
    if (this.watchTasks) {
      const { dispatch } = this.props;
      this.watchTasks.next().then((result) => {
        try {
          if (result.value.operationType === "delete") {
            Watchs.task_deleted(
              result,
              dispatch,
              this.props.grupoTareas.tareas
            );
            if (this.state.openGT && this.state.grupoTareas) {
              let id = Utils.getBSONtoString(result.value.documentKey._id);

              let i = this.state.grupoTareas.tasks.findIndex(
                (element) => element._id === id
              );
              if (i >= 0) {
                this.deleteTask_TaskGroupWatch(i);
              }
            }
          } else if (
            result.value.operationType === "insert" ||
            result.value.operationType === "update"
          ) {
            let task = Watchs.task_Keys(result.value.fullDocument);

            if (result.value.operationType === "insert") {
              if (this.state.showallitems == undefined) {
                this.setState({ showallitems: true });
              }
              if (this.state.showallitems == true) {
                this.addTasks(this.props.grupoTareas.countTasks);
                if (this.props.grupoTareas.tareas) {
                  if (this.props.grupoTareas.tareas.length < 10) {
                    Watchs.task_created(
                      task,
                      dispatch,
                      this.props.grupoTareas.tareas,
                      this.props.rayos.rayos
                    );
                  }
                }
                if (this.state.openGT && this.state.grupoTareas) {
                  if (this.state.grupoTareas._id === task.idTasksGroup_task) {
                    this.createTask_TaskGroupWatch(task);
                  }
                }
              }
            } else if (result.value.operationType === "update") {
              Watchs.task_updated(
                task,
                dispatch,
                this.props.grupoTareas.tareas,
                this.props.rayos.rayos
              );
              if (this.state.openGT && this.state.grupoTareas) {
                if (this.state.grupoTareas._id === task.idTasksGroup_task) {
                  this.updateTaskGroupWatch(N_TAREA, task);
                }
              }
            }
          }
        } catch (error) { }
        if (this.watchTasks) this.watch_task();
      });
    }
  };

  addTasks = (num) => {
    try {
      let numTotal = num + 1;
      const { dispatch } = this.props;
      let sumarTareas = grupoTareasAccion.actualizarCantidadTareas(numTotal);
      dispatch(sumarTareas);
    } catch (error) { }
  };

  updateTaskGroupWatch = (tipo, data) => {
    let grupoTareas = this.state.grupoTareas;
    if (N_TAREA === tipo) {
      let i = grupoTareas.tasks.findIndex(
        (element) => element._id === data._id
      );
      if (i >= 0) {
        grupoTareas.tasks[i] = data;
        this.setState({
          grupoTareas: grupoTareas,
        });
      }
    } else if (N_GRUPOTAREA === tipo) {
    }
  };

  createTask_TaskGroupWatch = (task) => {
    let grupoTareas = this.state.grupoTareas;
    grupoTareas.tasks.push(task);
    this.setState({
      grupoTareas: grupoTareas,
    });
  };

  deleteTask_TaskGroupWatch = (index) => {
    let grupoTareas = this.state.grupoTareas;
    grupoTareas.tasks.splice(index, 1);
    this.setState({
      grupoTareas: grupoTareas,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this._handleClick);
    this.closingRealm();
  }

  checkAll = () => {
    let tareas = this.props.grupoTareas.tareas.filter((tarea) => {
      let bandera = false;
      if (
        tarea.idstatus_task
          .toLowerCase()
          .includes(this.state.estadoSelected.toLowerCase()) &&
        (tarea.order_id_task
          .toLowerCase()
          .includes(this.state.tareas.toLowerCase()) ||
          tarea.name_rayo
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.code_task
            .toString()
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.address_task
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.contact_name_task
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()))
      ) {
        tarea.selected = true;
        bandera = true;
      } else {
        bandera = true;
      }
      return bandera;
    });

    const { dispatch } = this.props;
    let seleccionarTodasTareas =
      grupoTareasAccion.seleccionarTodasTareas(tareas);
    dispatch(seleccionarTodasTareas);
  };

  contarTareasSeleccionadas = () => {
    let tareas = this.props.grupoTareas.tareas;
    let tareasSeleccioandas = [];
    tareas.forEach((tarea) => {
      if (tarea.selected) {
        tareasSeleccioandas.push({
          id: tarea._id,
          codigo: tarea.order_id_task,
        });
      }
    });
    this.setState({
      tareasSelected: tareasSeleccioandas,
    });
  };

  cargarTareasIniciales = async (tipo) => {
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas([], tipo);
    try {
      dispatch(obtenerTareas);
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  handleAddressSelect = (e, i) => {
    const name = "address_task";
    let camposSelected = cloneDeep(this.state.camposEditarSelected);
    const index = camposSelected.findIndex((campo) => campo.nombre === name);
    if (e === null || typeof e === undefined || e === "") {
      camposSelected[index].data = "";
      this.setState({
        camposEditarSelected: camposSelected,
      });
    } else {
      let value = e.placeId;
      geocodeByPlaceId(value)
        .then((results) => {
          camposSelected[index].data = results[0].formatted_address;
          this.setState({
            camposEditarSelected: camposSelected,
          });
        })
        .catch((error) => {
          Utils.handleAlert(
            "Obtener ubicación",
            "Ha ocurrido un error al obtener datos de ubicación seleccionada, vuelva a intentar",
            "Ok"
          );
        });
    }
  };

  _handleClick = (event) => {
    const { contextMenuState } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && contextMenuState)
      this.setState({
        contextMenuState: false,
        contextMenu: { display: "none" },
      });
  };

  handleChangeEditarAddress = (e) => {
    const name = "address_task";
    let camposSelected = cloneDeep(this.state.camposEditarSelected);

    const index = camposSelected.findIndex((campo) => campo.nombre === name);

    camposSelected[index].data = e;
    this.setState({
      camposEditarSelected: camposSelected,
    });
  };

  handleChangeEditar = (e) => {
    const { name, value } = e.target;
    let camposSelected = cloneDeep(this.state.camposEditarSelected);

    const index = camposSelected.findIndex((campo) => campo.nombre === name);

    let valor = "";
    if (name === "contact_phone_task") {
      valor = value.replace(/[^0-9.]/g, "");
    } else {
      valor = value;
    }

    camposSelected[index].data = valor;
    this.setState({
      camposEditarSelected: camposSelected,
    });
  };

  handleDrawerGT = (value) => {
    if (!value) this.setState({ historial: false });
    this.setState({
      openGT: value,
    });
  };

  handleCargando = (value) => {
    this.setState({
      cargando: value,
    });
  };

  handleChangePage = async (event, newPage) => {
    // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
    await this.handleCargando(true);
    let skip = newPage * this.state.rowsPerPage;
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      skip,
      this.state.stringEstados,
      null,
      this.state.rowsPerPage
    );
    this.setState({ page: newPage });
    try {
      dispatch(obtenerTareas);
      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
    // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================
  };

  handleChangeRowsPerPage = async (event) => {
    await this.handleCargando(true);
    let skip = 0 * +event.target.value;
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      skip,
      this.state.stringEstados === "" ? null : this.state.stringEstados,
      null,
      +event.target.value
    );

    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });

    try {
      dispatch(obtenerTareas);
      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  handleCheck = (e, tarea) => {
    e.preventDefault();
    let id = tarea._id;
    const { dispatch } = this.props;
    let modificarTareas = grupoTareasAccion.modificarTareas(
      this.props.grupoTareas.tareas,
      [id]
    );
    dispatch(modificarTareas);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  cleanSelectedT = () => {
    const { dispatch } = this.props;
    let desseleccionarTareas = grupoTareasAccion.desseleccionarTareas(
      this.props.grupoTareas.tareas
    );
    dispatch(desseleccionarTareas);
  };

  resetEditar = () => {
    this.setState({
      camposEditar: DATA_EDITAR,
      camposEditarSelected: [],
      opcionEditar: "null",
    });
  };

  getStatus = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Status/all_status/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      // MIGUEL PASTENES CONSULTA ESTADOS
      console.log("consulta estados :", JSON.stringify(data))
      this.setState({ estados: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  duplicateTask = async () => {
    await this.handleCargando(true);
    let tarea = cloneDeep(this.state.tarea);

    try {
      let apiGetTask = await url.api.get("/Tasks/task/" + tarea._id);
      let tareaDuplicar = apiGetTask.data;

      delete tareaDuplicar._id;
      delete tareaDuplicar.__v;
      delete tareaDuplicar.id;
      delete tareaDuplicar.code_task;
      delete tareaDuplicar.orderNumber_task;
      delete tareaDuplicar.selected;
      delete tareaDuplicar.history_task;
      tareaDuplicar.idstatus_task = "5f05f3607f5164494c74b966";
      tareaDuplicar.idrayo_task = null;
      tareaDuplicar.tags = this.state.motivoDuplicar;

      tareaDuplicar.modifier_user = {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      };

      let res = await url.api.post("/Tasks/task/", tareaDuplicar);
      if (res.status === 201 || res.status === 200) {
        $("#modal").modal("hide");
        $("#modalAccionMasiva").modal("hide");
        this.handleCargando(false);
        Utils.handleAlert(
          "Tarea duplicada",
          "La tarea se ha duplicado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al duplicar la tarea, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Faltan campos para duplicar la tarea, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  deleteTask = async (id) => {
    await this.handleCargando(true);
    try {
      const tarea = {
        key: id,
        modifier_user: {
          name: this.state.usuario.name,
          email: this.state.usuario.email,
        },
      };
      let res = await url.api.delete("/Tasks/task/", {
        data: tarea,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        $("#modal").modal("hide");
        this.handleCargando(false);
        Utils.handleAlert(
          "Tarea eliminada",
          "La tarea se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar la tarea, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar la tarea, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  deleteTasks = async () => {
    await this.handleCargando(true);
    let requests = [];
    this.state.tareasSelected.forEach((element) => {
      requests.push(
        axios.delete(url.host + "/Tasks/task", {
          data: {
            key: element.id,
            modifier_user: {
              name: this.state.usuario.name,
              email: this.state.usuario.email,
            },
          },
          headers: { Authorization: "***" },
        })
      );
    });

    await Promise.all(requests)
      .then(
        axios.spread((...responses) => {
          $("#modalAccionMasiva").modal("hide");
          this.handleCargando(false);
          this.cleanSelectedT();
          Utils.handleAlert(
            "Tareas eliminadas",
            "Las tareas seleccionadas se han eliminado exitosamente",
            "Ok"
          );
        })
      )
      .catch((error) => {
        $("#modalAccionMasiva").modal("hide");
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Una o más de las tareas seleccionadas no se han eliminado, intente nuevamente",
          "Ok"
        );
      });
  };

  modifyTaskRayo = async () => {
    await this.handleCargando(true);
    let modificar = {
      key: this.state.tarea._id,
      task: {
        key: this.state.tarea._id,
        previous_idrayo_task: this.state.tarea.idrayo_task,
      },
      idrayo_task: this.state.rayo_selected._id,
      modifier_user: {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      },
    };
    if (this.state.rayo_selected === "") {
      this.handleCargando(false);
      Utils.handleAlert(
        "Ingreso de campos",
        "Faltan campos para completar la acción, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        let res = await url.api.put("/Tasks/task_rayo/", modificar);
        if (res.status === 200) {
          $("#modal").modal("hide");
          this.handleCargando(false);
          Utils.handleAlert(
            "Tarea reasignada",
            "La tarea se ha reasignado exitosamente",
            "Ok"
          );
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al reasignar la tarea, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado al reasignar la tarea, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  modifyTasksRayo = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    await this.handleCargando(true);
    if (this.state.rayo_selected === "") {
      this.handleCargando(false);
      Utils.handleAlert(
        "Ingreso de campos",
        "Faltan campos para completar la acción, vuelva a intentarlo",
        "Ok"
      );
    } else {
      let idTareas = [];
      this.state.tareasSelected.forEach((element) => {
        idTareas.push({
          key: element.id,
          previous_idrayo_task: element.idrayo_task,
        });
      });

      let modificar = {
        tasks: idTareas,
        idrayo_task: this.state.rayo_selected._id,
        modifier_user: {
          name: this.state.usuario.name,
          email: this.state.usuario.email,
        },
        tenant: usuario.tenant,
      };

      try {
        let res = await url.api.put("/Tasks/tasks_rayo/", modificar);

        if (res.status === 200) {
          $("#modal").modal("hide");
          this.handleCargando(false);
          Utils.handleAlert(
            "Tareas reasignadas",
            "Las tareas seleccionadas se han reasignado exitosamente",
            "Ok"
          );
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al reasignar la tarea, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado al realizar la acción masiva, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  modifyTasks = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    if (this.state.camposEditarSelected.length === 0) {
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar al menos un campo para poder editar las tareas de este grupo, vuelva a intentarlo",
        "Ok"
      );
    } else {
      await this.handleCargando(true);
      try {
        let filtros = "";
        let tasks = [];

        this.state.tareasSelected.forEach((tarea) => {
          tasks.push({ key: tarea.id });
        });

        this.state.camposEditarSelected.forEach((filtro, i) => {
          let signo = "&";
          if (i === 0) {
            signo = "";
          }
          if (filtro.nombre === "date_task") {
            filtros =
              filtros + signo + filtro.nombre + "=" + filtro.data + ":00Z";
          } else {
            filtros = filtros + signo + filtro.nombre + "=" + filtro.data;
          }
        });

        let modificar = {
          tasks: tasks,
          tenant: usuario.tenant,
        };

        let res = await url.api.put("/Tasks/tasks_by?" + filtros, modificar);
        if (res.status === 200) {
          await this.handleCargando(false);
          $("#modal").modal("hide");
          Utils.handleAlert(
            "Tareas modificadas",
            "Se han modificado los campos ingresados de las tareas exitosamente",
            "Ok"
          );
          this.handleState("camposEditar", DATA_EDITAR);
          this.handleState("camposEditarSelected", []);
        } else {
          await this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al modificar los campos ingresados de las tareas, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        await this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado modificar los campos ingresados de las tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  getTasksGroupById = async (id) => {
    await this.handleCargando(true);
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Task-Groups/tasks_group/" + id + "/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ grupoTareas: data });
      this.openWatchTaskGroup(data._id);
      this.handleDrawerGT(true);

      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Obtener grupo de tareas",
        "No se ha podido cargar el grupo de tareas",
        "Ok"
      );
    }
  };

  cancelTask = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    try {
      await this.handleCargando(true);
      let body = {
        order_ids: [this.state.tarea.order_id_task],
        customer_id: this.state.tarea.idcustomer_task,
        reason: this.state.motivoCancelar,
        tenant: usuario.tenant,
      };
      await url.api
        .post("/Tasks/cancel_tasks", body)
        .then((response) => {
          this.setState({
            motivoCancelar: "",
          });
          $("#modal").modal("hide");
          Utils.handleAlert(
            "Cambio exitoso",
            "Se ha cancelado la tarea exitosamente",
            "Ok"
          );
        })
        .catch((error) => {
          Utils.handleAlert(
            "Re abrir tarea",
            "No se ha podido cancelar la tarea",
            "Ok"
          );
        });
      await this.handleCargando(false);
    } catch (error) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Cancelar tarea",
        "No se ha podido cancelar la tarea",
        "Ok"
      );
    }
  };

  reAbrir = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    await this.handleCargando(true);
    try {
      let body = {
        task_id: this.state.tarea._id,
        status_id: this.state.estadoReabrir,
        reason: this.state.motivoReabrir,
        modifier_user: {
          name: usuario.name,
          email: usuario.email,
        },
        tenant: usuario.tenant,
      };
      await url.api
        .post("/Tasks/reopen", body)
        .then((response) => {
          this.setState({
            motivoReabrir: "",
            estadoReabrir: ID_ASIGNADO,
          });
          $("#modal").modal("hide");
          Utils.handleAlert(
            "Cambio exitoso",
            "Se ha re abierto la tarea exitosamente",
            "Ok"
          );
        })
        .catch((error) => {
          Utils.handleAlert(
            "Re abrir tarea",
            "No se ha podido re abrir la tarea",
            "Ok"
          );
        });

      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Re abrir tarea",
        "No se ha podido re abrir la tarea",
        "Ok"
      );
    }
  };

  downloadTasksCSV = async () => {
    await this.handleCargando(true);
    const allTasks = this.props.grupoTareas.tareas;
    let data = {
      customers_ids: [],
      order_ids: [],
      init_date: "",
      end_date: "",
      rayo_id: "",
      status_id: "",
      data_consistency: 0,
    };

    try {
      let fecha = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      let fechaInicio = fecha.split("T")[0] + "T00:00:00Z";
      let fechaFin = fecha.split("T")[0] + "T23:59:59Z";
      let fechasCalendario = this.fechasCalendario();

      if (this.state.csvCantidadTareas === "todas") {
        data.init_date = fechasCalendario[0];
        data.end_date = fechasCalendario[1];
        if (this.state.csvRayo !== "0") data.rayo_id = this.state.csvRayo;
        if (this.state.csvCliente !== "0")
          data.customers_ids.push(this.state.csvCliente);

        if (this.state.estadoSelected !== "0") {
          data.status_id = this.state.estadoSelected;
        }
      } else if (this.state.csvCantidadTareas === "hoy") {
        data.init_date = fechaInicio;
        data.end_date = fechaFin;
      } else {
        allTasks.forEach((element) => {
          if (element.selected) data.order_ids.push(element.order_id_task);
        });
      }

      if (this.state.csvIncluirOrder) data.data_consistency = 1;

      await url.api
        .post(
          "https://export-thor-2jox3wghaa-uc.a.run.app/Tasks/tasks_for_csv",
          data
        )
        .then((response) => {
          let data = response.data;

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = data;
          a.download = "";
          a.click();
        })
        .catch((error) => {
          Utils.handleAlert("Error al realizar la acción", error.message, "Ok");
        });
    } catch (error) {
      Utils.handleAlert("Error al realizar la acción", error.message, "Ok");
    }

    await this.handleCargando(false);
  };

  handleContextMenu = (e, tarea) => {
    e.preventDefault();
    const clickX = e.clientX;
    const clickY = e.clientY;

    this.setState({
      taskMetadata: tarea,
      contextMenuState: true,
      contextMenu: {
        display: "block",
        top: clickY,
        left: clickX,
      },
    });
  };

  handleModalAccion = (value) => {
    this.setState({ tipoModal: value });
  };

  cerrarModal = (name) => {
    $(name).modal("hide");
  };

  modifyTaskGroupsTask = async () => {
    let usuario = JSON.parse(sessionStorage.getItem("user"));
    if (this.state.camposEditarSelected.length === 0) {
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar al menos un campo para poder editar las tareas de este grupo, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        let filtros = "";
        let modificar = {
          key: this.state.grupoTareas._id,
          modifier_user: {
            name: usuario.name,
            email: usuario.email,
          },
          tenant: usuario.tenant,
        };

        this.state.camposEditarSelected.forEach((filtro, i) => {
          let signo = "&";
          if (i === 0) {
            signo = "";
          }

          if (filtro.nombre === "date_task") {
            filtros =
              filtros + signo + filtro.nombre + "=" + filtro.data + ":00Z";
          } else {
            filtros = filtros + signo + filtro.nombre + "=" + filtro.data;
          }
        });

        let res = await url.api.put(
          "/Task-Groups/tasks_group_tasks?" + filtros,
          modificar
        );
        if (res.status === 200) {
          $("#modal").modal("hide");
          Utils.handleAlert(
            "Grupo de tareas modificado",
            "Se han modificado los campos ingresados del grupo de tareas exitosamente",
            "Ok"
          );
          this.handleState("camposEditar", DATA_EDITAR);
          this.handleState("camposEditarSelected", []);
        } else {
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al modificar los campos ingresados del grupo de tareas, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado modificar los campos ingresados del grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  duplicateTasksGroup = async () => {
    this.handleCargando(true);
    let GrupoDeTareas = cloneDeep(this.state.grupoTareas);
    const fechaHoy = new Date();

    delete GrupoDeTareas._id;
    delete GrupoDeTareas.__v;
    delete GrupoDeTareas.id;
    delete GrupoDeTareas.creation_date_taskgroup;
    delete GrupoDeTareas.history_taskgroup;
    delete GrupoDeTareas.code_tasksgroup;
    GrupoDeTareas.creation_date_taskgroup =
      moment(fechaHoy).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    GrupoDeTareas.modifier_user = {
      name: this.state.usuario.name,
      email: this.state.usuario.email,
    };

    GrupoDeTareas.tasks.forEach((tarea) => {
      delete tarea._id;
      delete tarea.__v;
      delete tarea.id;
      delete tarea.code_task;
      delete tarea.history_task;
      delete tarea.orderNumber_task;
      delete tarea.idTasksGroup_task;
      delete tarea.skipped_task;
      if (tarea.metadata_task === null) {
        tarea.metadata_task = {};
      }
      tarea.modifier_user = {
        name: this.state.usuario.name,
        email: this.state.usuario.email,
      };
    });

    try {
      let res = await url.api.post("/Task-Groups/tasks_groups/", GrupoDeTareas, this.state.form.tenant);
      if (res.status === 201 || res.status === 200) {
        this.handleCargando(false);
        $("#modal").modal("hide");
        Utils.handleAlert(
          "Grupo de tareas duplicado",
          "El grupo de tareas se ha duplicado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al duplicar el grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al duplicar el grupo de tareas, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyTaskGroupRayo = async () => {
    this.handleCargando(true);
    if (this.state.rayo_selected === "0" || this.state.rayo_selected === "") {
      this.handleCargando(false);
      Utils.handleAlert(
        "Faltan campos",
        "Debe seleccionar un rayo para realizar esta acción, vuelva a intentarlo",
        "Ok"
      );
    } else {
      try {
        let modificar = {
          key: this.state.grupoTareas._id,
          idrayo_taskgroup: this.state.rayo_selected._id,
          modifier_user: {
            name: this.state.usuario.name,
            email: this.state.usuario.email,
          },
          tenant: this.state.usuario.tenant,
        };

        let res = await url.api.put(
          "/Task-Groups/tasks_group_rayo/",
          modificar
        );
        if (res.status === 200) {
          if (
            this.props.rayos.tareas !== null &&
            this.state.rayo !== null &&
            typeof this.props.rayos.tareas !== "undefined" &&
            typeof this.state.rayo !== "undefined"
          ) {
            this.getTasksRayo(this.state.rayo);
          }
          this.setState({
            rayo_selected: "0",
          });

          $("#modal").modal("hide");
          this.handleCargando(false);
          Utils.handleAlert(
            "Grupo de tareas modificado",
            "Se ha reasignado el rayo al grupo de tareas exitosamente",
            "Ok"
          );
        } else {
          this.handleCargando(false);
          Utils.handleAlert(
            "Error " + res.status,
            "Ha ocurrido un error al reasignar el rayo al grupo de tareas, vuelva a intentarlo",
            "Ok"
          );
        }
      } catch (error) {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error",
          "Ha ocurrido un error no controlado al reasignar el rayo al grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    }
  };

  deleteTasksGroup = async (id) => {
    try {
      this.handleCargando(true);
      const grupoTareas = {
        key: id,
        tenant: this.state.form.tenant
      };
      let res = await url.api.delete("/Task-Groups/tasks_group/", {
        data: grupoTareas,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        $("#modal").modal("hide");
        this.handleCargando(false);
        this.setState({
          openGT: false,
        });
        Utils.handleAlert(
          "Grupo de tareas eliminado",
          "El grupo de tareas se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        this.handleCargando(false);
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el grupo de tareas, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Eliminar grupo de tareas",
        "No se ha podido eliminar el grupo de tareas",
        "Ok"
      );
    }
  };

  getTemplate = async (tarea) => {
    await url.api
      .get("/Templates/template/" + tarea.template_successful_task.idTemplate)
      .then((response) => {
        let templateSuccess = Utils.createObjectActivities(
          response.data.type_activity_template
        );
        this.setState({
          optionsDropdownSucces: response.data.dropdown_options,
          templates: {
            ...this.state.templates,
            successful: templateSuccess,
          },
        });

        url.api
          .get("/Templates/template/" + tarea.template_failed_task.idTemplate)
          .then((response2) => {
            let templateFail = Utils.createObjectActivities(
              response2.data.type_activity_template
            );
            this.setState({
              optionsDropdownFailed: response2.data.dropdown_options,
              templates: {
                ...this.state.templates,
                failed: templateFail,
              },
            });
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  handleChangeEvidencia = (e, id, type) => {
    e.preventDefault();
    const { value } = e.target;
    let actividades = this.state.templates[type];
    const i = actividades.findIndex((act) => act._id === id);
    if (i >= 0) {
      actividades[i].data = value;
      this.setState({
        evidencia: {
          ...this.state.evidencia,
          [type]: actividades,
        },
      });
    }
  };

  handleChangeEvidenciaImg = (e, id, type) => {
    e.preventDefault();
    const { files } = e.target;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let actividades = this.state.templates[type];
      const i = actividades.findIndex((act) => act._id === id);
      if (i >= 0) {
        actividades[i].data = reader.result;
        this.setState({
          evidencia: {
            ...this.state.evidencia,
            [type]: actividades,
          },
        });
      }
    };
  };

  render() {
    let rayos = this.props.rayos.rayos;
    const { page, rowsPerPage } = this.state;
    // let pagina = this.props.grupoTareas.paginador;
    // let classContainerDot = 'col-3 p-0 d-flex align-items-center justify-content-center';
    const openFiltros = Boolean(this.state.anchorElFiltros);
    let { classes } = this.props;
    const openEstados = Boolean(this.state.anchorElEstados);
    const idFiltrosEstados = openEstados ? "simple-popover" : undefined;

    const MenuContext = () => {
      return (
        <div
          style={this.state.contextMenu}
          className="z-i-300"
          ref={(ref) => {
            this.root = ref;
          }}
        >
          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() => this.handleState("historial", true)}
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoReloj} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Ver Historial
            </div>
          </div>

          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() =>
              window.open(
                "/Tracking/" + N_TAREA + "/" + this.state.taskMetadata._id,
                "_blank"
              )
            }
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoRoute} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Tracking Link
            </div>
          </div>

          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() => {
              this.setState({
                tipoModalAccionMasiva: 2,
                tarea: this.state.taskMetadata,
              });
              $("#modalAccionMasiva").modal("show");
            }}
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoDuplicar} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Duplicar
            </div>
          </div>

          <div
            className="row m-0 opc-drop border"
            data-toggle="modal"
            data-target="#metadata"
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoData} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Metadata
            </div>
          </div>
        </div>
      );
    };

    const AccionMasiva = () => {
      return (
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-white">
            <div className="modal-body">
              <h4 className="h4-modal text-center">Acción masiva</h4>
              <Select
                className="select-crear select-modal p-0 pl-2 w-100"
                value={this.state.accionMasiva}
                name="accionMasiva"
                label="Seleccionar acción"
                IconComponent={CustomExpandMore}
                placeholder="Seleccionar acción"
              >
                <MenuItem value="null">
                  <em>Seleccionar acción</em>
                </MenuItem>
                <MenuItem className="row m-0" value="1">
                  <div className="col-md-2 d-flex align-items-center">
                    <img src={IconoEditar} height="20" alt="icono" />
                  </div>
                  <div className="col-md-10 pl-0 d-flex align-items-center">
                    Edición masiva
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value="3">
                  <div className="col-md-2 d-flex align-items-center">
                    <img src={IconoReasignar} height="20" alt="icono" />
                  </div>
                  <div className="col-md-10 pl-0 d-flex align-items-center">
                    Asignación masiva
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value="4">
                  <div className="col-md-2 d-flex align-items-center">
                    <img src={IconoCambiar} height="20" alt="icono" />
                  </div>
                  <div className="col-md-10 pl-0 d-flex align-items-center">
                    Cambio de estado masivo
                  </div>
                </MenuItem>
                <MenuItem className="row m-0" value="5">
                  <div className="col-md-2 d-flex align-items-center">
                    <img src={IconoEliminar} height="20" alt="icono" />
                  </div>
                  <div className="col-md-10 pl-0 d-flex align-items-center">
                    Eliminación masiva
                  </div>
                </MenuItem>
              </Select>
            </div>
            <div className="modal-footer row m-0">
              <div className="col-6 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn-secondary"
                  onClick={() => {
                    // this.setState({ tipoModalAccionMasiva: 1 })
                    $("#modalAccionMasiva").modal("hide");
                  }}
                >
                  Cancelar
                </button>
              </div>
              <div className="col-6 d-flex justify-content-center">
                <button type="submit" className="btn-primary-rayo">
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="container pt-5 px-0">
        <div className="row m-0 px-0 mb-3">
          <AssignmentIcon fontSize="large" style={{ marginTop: 10 }} />
          <h1 className="title-text ml-2">Lista de Tareas</h1>
        </div>

        <div className="row m-0 mb-2 w-100">
          <div className="col p-0">
            <FormControl
              variant="outlined"
              size="small"
              className="form-height"
              style={{ width: "30ch", height: "36.19px !important" }}
            >
              <CustomOutlinedInput
                type="text"
                name="tareas"
                placeholder="Buscar tareas"
                value={this.state.tareas}
                onChange={(e) => this.handleChange(e)}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className="form-height"
              style={{ width: "30ch", height: "36.19px !important" }}
            >
              <CustomSelect
                label="Age"
                name="selectTareas"
                value={this.state.selectTareas}
                onChange={(e) => this.handleChange(e)}
              >
                <MenuItem value="all">Todos los campos</MenuItem>


                <MenuItem value="orderIdTask">Order ID</MenuItem>
                <MenuItem value="code_task">CodeTask</MenuItem>
                <MenuItem value="contactNameTask">Nombre de contacto</MenuItem>
                <MenuItem value="addressTask">Dirección</MenuItem>
              </CustomSelect>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="button-search-options"
              onClick={() => this.filterOrderId()}
            >
              <img src={IconoLupa} alt="IconoLupa" width="18" />
            </Button>

            <CustomButton
              aria-controls="fade-menu"
              className="ml-4"
              aria-describedby={idFiltrosEstados}
              style={{ height: "36.19px !important" }}
              onClick={(e) =>
                this.handleState("anchorElEstados", e.currentTarget)
              }
              endIcon={<img src={IconoFlechaBlanca} height="24" alt="flecha" />}
            >
              {"Estado " + this.state.nombreEstado}
            </CustomButton>

            <Popover
              id={idFiltrosEstados}
              open={openEstados}
              anchorEl={this.state.anchorElEstados}
              onClose={() => this.handleState("anchorElEstados", null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
            >
              <div
                className="row m-0 opc-drop border-bottom-0"
                onClick={() => this.handleEstados("todos")}
              >
                <div className="col-12 pl-0 txt-carta-name d-flex align-items-center justify-content-center">
                  Todos los estados
                </div>
              </div>

              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_NO_ASIGNADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.state.filters[ID_NO_ASIGNADO]}
                    onChange={() => this.handleEstados(ID_NO_ASIGNADO)}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot back-medium-grey"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  No Asignado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_ASIGNADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.state.filters[ID_ASIGNADO]}
                    onChange={() => this.handleEstados(ID_ASIGNADO)}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-asignado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Asignado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_ACEPTADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_ACEPTADO)}
                    checked={this.state.filters[ID_ACEPTADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-aceptado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Aceptado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_INICIADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_INICIADO)}
                    checked={this.state.filters[ID_INICIADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-iniciado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Iniciado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_EN_PROGRESO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_EN_PROGRESO)}
                    checked={this.state.filters[ID_EN_PROGRESO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-en-progreso"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  En Progreso
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_EXITOSO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_EXITOSO)}
                    checked={this.state.filters[ID_EXITOSO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-exitoso"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Exitoso
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_FALLIDO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_FALLIDO)}
                    checked={this.state.filters[ID_FALLIDO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-haFallado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Fallido
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RECHAZADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RECHAZADO)}
                    checked={this.state.filters[ID_RECHAZADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-rechazado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Rechazado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_CANCELADO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_CANCELADO)}
                    checked={this.state.filters[ID_CANCELADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-cancelado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Cancelado
                </div>
              </div>
              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_DEVUELTO}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_DEVUELTO)}
                    checked={this.state.filters[ID_DEVUELTO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-devuelto"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Devuelto
                </div>
              </div>

              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_BLOQUEADOCLIENTE}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_BLOQUEADOCLIENTE)}
                    checked={this.state.filters[ID_BLOQUEADOCLIENTE]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-Bloqueado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Bloqueado por Cliente
                </div>
              </div>

              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RECEPCIONBODEGA}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RECEPCIONBODEGA)}
                    checked={this.state.filters[ID_RECEPCIONBODEGA]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-RecepcionadoBodega"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Recepcionado en Bodega Rayo
                </div>
              </div>


              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RETIROCLIENTE}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RETIROCLIENTE)}
                    checked={this.state.filters[ID_RETIROCLIENTE]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-RetiroCliente"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Recepcionado en Bodega Rayo
                </div>
              </div>



            </Popover>

            {this.props.grupoTareas.seleccionadoT ? (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className="ml-4"
                style={{ height: 36 }}
                onClick={() => {
                  this.contarTareasSeleccionadas();
                  this.setState({ tipoModal: 1 });
                  $("#modal").modal("show");
                }}
              >
                Edición masiva
              </Button>
            ) : (
              false
            )}

            {this.props.grupoTareas.seleccionadoT ? (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className="ml-4"
                style={{ height: 36 }}
                onClick={() => {
                  this.contarTareasSeleccionadas();
                  this.setState({ tipoModal: 6, rayo_selected: "" });
                  $("#modal").modal("show");
                }}
              >
                Asignación masiva
              </Button>
            ) : (
              false
            )}

            {this.props.grupoTareas.seleccionadoT ? (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className="ml-4"
                style={{ height: 36 }}
                onClick={() => {
                  this.contarTareasSeleccionadas();
                  this.setState({ tipoModalAccionMasiva: 5 });
                  $("#modalAccionMasiva").modal("show");
                }}
              >
                <DeleteOutlineIcon color={classes.iconDelete} />
              </Button>
            ) : (
              false
            )}

            <Button
              variant="contained"
              className="position-absolute px-1 shadow-0"
              style={{ right: 60, height: 40 }}
              onClick={() => {
                this.cargarTareasIniciales(false);
                this.setState({ estadoSelected: "0", tareas: "" });
              }}
            >
              <img src={IconoRecargar} height="30" alt="actualizar" />
            </Button>
            <Button
              variant="contained"
              className="position-absolute px-1 shadow-0"
              style={{ right: 0, height: 40 }}
              onClick={() => $("#modalDescarga").modal("show")}
            >
              <img src={IconoDocumento} height="30" alt="descargar" />
            </Button>
          </div>
        </div>

        {this.props.grupoTareas.tareas === null ? (
          <div className="w-100 p-5 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </div>
        ) : (
          <ListaTareas
            columns={columns}
            tareas={this.props.grupoTareas.tareas}
            grupoTareas={this.props.grupoTareas}
            rayos={rayos}
            page={page}
            rowsPerPage={rowsPerPage}
            openFiltros={openFiltros}
            filtroTareas={this.state.tareas}
            estadoSelected={this.state.estadoSelected}
            anchorElFiltros={this.state.anchorElFiltros}
            cleanSelectedT={this.cleanSelectedT}
            checkAll={this.checkAll}
            handleCheck={this.handleCheck}
            handleState={this.handleState}
            getTasksGroupById={this.getTasksGroupById}
            propsData={this.props.data}
            getTemplate={this.getTemplate}
          />
        )}

        <div className="w-100 mb-3">
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            // rowsPerPageOptions={[rowsPerPage]}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => {
              return from + " - " + to + " de " + count + " tareas";
              // return '';
            }}
            component="div"
            // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
            count={this.props.grupoTareas.countTasks}
            // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================
            // count={this.props.grupoTareas.tareas === null ? 0 : this.props.grupoTareas.tareas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          // onChangePage={this.handleChangePage}
          // onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>

        <div
          className="modal fade"
          aria-labelledby="modal"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modal"
        >
          {this.state.tipoModal === 1 && !this.state.openGT ? (
            <EditarTareas
              code={this.state.tareasSelected}
              camposEditar={this.state.camposEditar}
              camposEditarSelected={this.state.camposEditarSelected}
              opcionEditar={this.state.opcionEditar}
              handleChangeEditar={this.handleChangeEditar}
              handleChangeEditarAddress={this.handleChangeEditarAddress}
              handleAddressSelect={this.handleAddressSelect}
              handleChange={this.handleChange}
              handleState={this.handleState}
              resetEditar={this.resetEditar}
              modify={this.modifyTasks}
              vista={"lista"}
            />
          ) : this.state.tipoModal === 1 && this.state.openGT ? (
            <EditarTareas
              code={
                this.state.grupoTareas === null
                  ? []
                  : [
                    {
                      id: this.state.grupoTareas._id,
                      codigo: this.state.grupoTareas.orden_id_taskgroup,
                    },
                  ]
              }
              camposEditar={this.state.camposEditar}
              camposEditarSelected={this.state.camposEditarSelected}
              opcionEditar={this.state.opcionEditar}
              handleChangeEditar={this.handleChangeEditar}
              handleChangeEditarAddress={this.handleChangeEditarAddress}
              handleAddressSelect={this.handleAddressSelect}
              handleChange={this.handleChange}
              handleState={this.handleState}
              resetEditar={this.resetEditar}
              modify={this.modifyTaskGroupsTask}
              vista={"mapa"}
            />
          ) : this.state.tipoModal === 2 && !this.state.openGT ? (
            <DuplicarTareas
              data={this.state.tarea}
              duplicate={this.duplicateTask}
              motivoDuplicar={this.state.motivoDuplicar}
              handleChange={this.handleChange}
              handleState={this.handleState}
              vista={"lista"}
              cerrarModal={this.cerrarModal}
              nameModal={"#modal"}
            />
          ) : this.state.tipoModal === 2 && this.state.openGT ? (
            <DuplicarTareas
              data={this.state.grupoTareas}
              duplicate={this.duplicateTasksGroup}
              vista={"mapa"}
              cerrarModal={this.cerrarModal}
              nameModal={"#modal"}
            />
          ) : this.state.tipoModal === 3 && !this.state.openGT ? (
            <EditarRayo
              code={
                this.state.tarea === null
                  ? []
                  : [
                    {
                      id: this.state.tarea._id,
                      codigo: this.state.tarea.order_id_task,
                    },
                  ]
              }
              data={this.state.tarea}
              rayo_selected={this.state.rayo_selected}
              handleChange={this.handleChange}
              rayos={rayos}
              modify={this.modifyTaskRayo}
              vista={"lista"}
            />
          ) : this.state.tipoModal === 3 && this.state.openGT ? (
            <EditarRayo
              code={
                this.state.grupoTareas === null
                  ? []
                  : [
                    {
                      id: this.state.grupoTareas._id,
                      codigo: this.state.grupoTareas.orden_id_taskgroup,
                    },
                  ]
              }
              data={this.state.grupoTareas}
              rayo_selected={this.state.rayo_selected}
              handleChange={this.handleChange}
              rayos={rayos}
              modify={this.modifyTaskGroupRayo}
              vista={"mapa"}
            />
          ) : this.state.tipoModal === 4 && !this.state.openGT ? (
            <EditarEstado
              data={this.state.tarea}
              opcionEditarEstado={this.state.opcionEditarEstado}
              failedReason={this.state.failedReason}
              handleChange={this.handleChange}
              cargarTareasIniciales={() => { }}
              vista={"lista"}
              handleCargando={this.handleCargando}
              template={this.state.templates}
              optionsDropdownSucces={this.state.optionsDropdownSucces}
              optionsDropdownFailed={this.state.optionsDropdownFailed}
              handleChangeEvidencia={this.handleChangeEvidencia}
              handleChangeEvidenciaImg={this.handleChangeEvidenciaImg}
            />
          ) : this.state.tipoModal === 4 && this.state.openGT ? (
            <EditarEstado
              data={this.state.grupoTareas}
              opcionEditarEstado={this.state.opcionEditarEstado}
              failedReason={this.state.failedReason}
              handleChange={this.handleChange}
              getTasksGroupById={() => { }}
              vista={"mapa"}
              tareasRayo={null}
              rayo={null}
              cargarTareasIniciales={() => { }}
              handleCargando={this.handleCargando}
            />
          ) : this.state.tipoModal === 5 && !this.state.openGT ? (
            <EliminarTareas
              code={
                this.state.tarea === null
                  ? []
                  : [
                    {
                      id: this.state.tarea._id,
                      codigo: this.state.tarea.order_id_task,
                    },
                  ]
              }
              data={this.state.tarea}
              delete={this.deleteTask}
              vista={"lista"}
            />
          ) : this.state.tipoModal === 5 && this.state.openGT ? (
            <EliminarTareas
              code={
                this.state.grupoTareas === null
                  ? []
                  : [
                    {
                      id: this.state.grupoTareas._id,
                      codigo: this.state.grupoTareas.orden_id_taskgroup,
                    },
                  ]
              }
              data={this.state.grupoTareas}
              delete={this.deleteTasksGroup}
              vista={"mapa"}
            />
          ) : this.state.tipoModal === 7 && !this.state.openGT ? (
            <ReAbrirTarea
              data={this.state.tarea}
              estadoReabrir={this.state.estadoReabrir}
              motivoReabrir={this.state.motivoReabrir}
              handleChange={this.handleChange}
              reAbrir={this.reAbrir}
            />
          ) : this.state.tipoModal === 8 && !this.state.openGT ? (
            <CancelarTarea
              data={this.state.tarea}
              motivoCancelar={this.state.motivoCancelar}
              handleChange={this.handleChange}
              cancelar={this.cancelTask}
            />
          ) : (
            <EditarRayo
              code={this.state.tareasSelected}
              data={this.state.tarea}
              rayo_selected={this.state.rayo_selected}
              handleChange={this.handleChange}
              rayos={rayos}
              modify={this.modifyTasksRayo}
              vista={"multiple"}
            />
          )}
        </div>

        <div
          className="modal fade"
          aria-labelledby="modal"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modalDescarga"
        >
          <DescargarCSV
            disabledPersonalizado={this.props.grupoTareas.seleccionadoT}
            cantidadTareas={this.state.csvCantidadTareas}
            incluirOrder={this.state.csvIncluirOrder}
            descargar={this.downloadTasksCSV}
            handleChange={this.handleChange}
            handleState={this.handleState}
            openCalendar={this.state.openCalendar}
            positionCalendar={this.state.positionCalendar}
            selectedHours={this.state.selectedHours}
            selectedDays={this.state.selectedDays}
            selectedDaysOrder={this.state.selectedDaysOrder}
            from={this.state.from}
            to={this.state.to}
            handleDayClick={this.handleDayClick}
            handleHoursClick={this.handleHoursClick}
            csvRayo={this.state.csvRayo}
            rayos={rayos}
            csvCliente={this.state.csvCliente}
            clientes={this.props.clientes.clientes}
            vista={"dispatcher"}
          />
        </div>

        <div
          className="modal fade"
          aria-labelledby="modal"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modalAccionMasiva"
        >
          {this.state.tipoModalAccionMasiva === 2 && this.state.openGT ? (
            <DuplicarTareas
              data={this.state.tarea}
              duplicate={this.duplicateTask}
              vista={"lista"}
              cerrarModal={this.cerrarModal}
              nameModal={"#modalAccionMasiva"}
            />
          ) : this.state.tipoModalAccionMasiva === 5 && !this.state.openGT ? (
            <EliminarTareas
              code={this.state.tareasSelected}
              data={null}
              delete={this.deleteTasks}
              vista={"multiple"}
            />
          ) : (
            <AccionMasiva />
          )}
        </div>

        <Cargando cargando={this.state.cargando} />

        <Drawer
          anchor={"left"}
          open={this.state.openGT}
          onClose={() => {
            this.closeWatchTaskGroup();
            this.handleDrawerGT(false);
          }}
        >
          <div
            className="panel-detalle"
            role="presentation"
            onKeyDown={() => this.handleDrawerGT(false)}
          >
            <DetalleGrupoTareas
              cerrarDetalle={this.handleDrawerGT}
              estados={this.state.estados}
              handleModal={this.handleModalAccion}
              data={this.props.data}
              grupoTareas={this.state.grupoTareas}
              handleContextMenu={this.handleContextMenu}
              vista={"lista"}
              handleState={this.handleState}
              historialState={this.state.historial}
              tituloHistorial={this.state.tituloHistorial}
              dataHistorial={this.state.dataHistorial}
              rayos={rayos}
              clientes={this.props.clientes.clientes}
              template={this.state.template}
            />
          </div>
          <MenuContext />
        </Drawer>

        <div
          className="modal fade"
          id="metadata"
          data-backdrop="static"
          data-focus="false"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="metadata"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content modal-white">
              <div className="modal-header">
                <h4 className="h4-modal pl-4 mb-0 text-center w-100">
                  Metadata
                </h4>

                <CopyToClipboard
                  text={
                    this.state.taskMetadata == null
                      ? ""
                      : JSON.stringify(this.state.taskMetadata.metadata_task)
                  }
                >
                  <IconButton>
                    <FileCopyIcon />
                  </IconButton>
                </CopyToClipboard>

                <IconButton onClick={() => $("#metadata").modal("hide")}>
                  <CloseIcon />
                </IconButton>
              </div>

              {this.state.taskMetadata == null ? (
                false
              ) : (
                <div className="modal-body py-0">
                  <MetadataJson
                    metadata={this.state.taskMetadata.metadata_task}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  iconDelete: {
    color: theme.palette.secondary.contrastText,
  },
});

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    grupoTareas: store.grupoTareas,
    clientes: store.clientes,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(VistaLista)
);
