import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
import "../../css/global.css";
import Utils from "../../assets/utils/Utils";
import url from "../../config";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import TextFieldOutlined from "../../assets/components/custom/TextFieldOutlined";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";

// import IconoDocumentoBlack from "../../assets/icons/icono-document-upload-black.svg";
// import IconoDocumentoWhite from "../../assets/icons/icono-document-upload-white.svg";
const IconoDocumentoBlack =
  "https://storage.cloud.google.com/poc-rayo-fm.appspot.com/25c020f4-9e00-48f9-8688-745bbe691f44.svg";
const IconoDocumentoWhite =
  "https://storage.cloud.google.com/poc-rayo-fm.appspot.com/fe4cd3ed-a233-41d4-9734-c7c7060b6c98.svg";

const columns = [
  {
    id: "name_type_vehicle",
    label: "Nombre",
    align: "left",
  },
  {
    id: "description_type_vehicle",
    label: "Descripción",
    align: "left",
  },
  {
    id: "inactive_icon_type_vehicle",
    label: "Inactivo",
    align: "left",
  },
  { id: "active_icon_type_vehicle", label: "Activo", classth: "", classtd: "" },
  {
    id: "accion",
    label: "Acción",
    align: "right",
  },
];

class Tipo_Vehiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,

      search: "",
      tipoVehiculos: null,
      form: {
        key: "",
        name_type_vehicle: "",
        description_type_vehicle: "",
        inactive_icon_type_vehicle: IconoDocumentoBlack,
        active_icon_type_vehicle: IconoDocumentoWhite,
        tipoVehiculos: "",
      },
    };
    this.getTipoVehiculos();
  }

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleImage = (e) => {
    try {
      e.preventDefault();
      const estado = this;
      const { name } = e.target;
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        estado.setState({
          form: {
            ...estado.state.form,
            [name]: reader.result,
          },
        });
      };
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error al subir la imagen, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  emptyForm = () => {
    this.setState({
      form: {
        key: "",
        name_type_vehicle: "",
        description_type_vehicle: "",
        inactive_icon_type_vehicle: IconoDocumentoBlack,
        active_icon_type_vehicle: IconoDocumentoWhite,
        tipoVehiculos: "",
      },
    });
  };

  getTipoVehiculos = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Type-Vehicles/type_vehicles/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ tipoVehiculos: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener tipos de vehículo",
        "No se han podido cargar los tipos de vehículo",
        "Ok"
      );
    }
  };

  createVehiculo = async () => {
    try {
      let res = await url.api.post("/Type-Vehicles/type_vehicle/", {
        name_type_vehicle: this.state.form.name_type_vehicle,
        description_type_vehicle: this.state.form.description_type_vehicle,
        inactive_icon_type_vehicle: this.state.form.inactive_icon_type_vehicle,
        active_icon_type_vehicle: this.state.form.active_icon_type_vehicle,
        tenant: this.state.form.tenant,
      });
      if (res.status === 201 || res.status === 200) {
        $("#crearModal").modal("hide");
        this.getTipoVehiculos();
        Utils.handleAlert(
          "Tipo de vehículo ingresada",
          "El tipo de vehículo se ha ingresado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al ingresar el tipo de vehículo, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al ingresar el tipo de vehículo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  confirmDelete = (key) => {
    Swal.fire({
      title: "¿Eliminar Tipo de Vehículo?",
      text: "Si confirma, no podrá deshacer la acción y el tipo de vehículo será eliminado de forma permanente",
      imageUrl:
        "https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg",
      showCancelButton: true,
      customClass: {
        image: "imageSweetAlert",
        title: "titleSweetAlert",
        content: "textSweetAlert",
        confirmButton: "buttonSweetAlert",
        cancelButton: "btn-secondary",
      },
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.deleteVehiculo(key);
      }
    });
  };

  deleteVehiculo = async (key) => {
    try {
      const tipoVehiculo = {
        key: key,
        tenant: this.state.form.tenant,
      };
      let res = await url.api.delete("/Type-Vehicles/type_vehicle/", {
        data: tipoVehiculo,
        headers: { Authorization: "***" },
      });
      if (res.status === 200) {
        this.getTipoVehiculos();
        Utils.handleAlert(
          "Tipo de vehículo eliminado",
          "El tipo de vehículo se ha eliminado exitosamente",
          "Ok"
        );
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al eliminar el tipo de vehículo, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al eliminar el tipo de vehículo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  modifyRegion = async () => {
    try {
      let res = await url.api.put("/Type-Vehicles/type_vehicle/", {
        key: this.state.form.key,
        name_type_vehicle: this.state.form.name_type_vehicle,
        description_type_vehicle: this.state.form.description_type_vehicle,
        inactive_icon_type_vehicle: this.state.form.inactive_icon_type_vehicle,
        active_icon_type_vehicle: this.state.form.active_icon_type_vehicle,
        tenant: this.state.form.tenant,
      });
      if (res.status === 200) {
        this.getTipoVehiculos();
        $("#modificarModal").modal("hide");
        Utils.handleAlert(
          "Tipo de vehículo modificado",
          "El tipo de vehículo se ha modificado exitosamente",
          "Ok"
        );
        this.emptyForm();
      } else {
        Utils.handleAlert(
          "Error " + res.status,
          "Ha ocurrido un error al modificar el tipo de vehículo, vuelva a intentarlo",
          "Ok"
        );
      }
    } catch (error) {
      Utils.handleAlert(
        "Error",
        "Ha ocurrido un error no controlado al modificar el tipo de vehículo, vuelva a intentarlo",
        "Ok"
      );
    }
  };

  editar = async (vehiculo) => {
    await this.setState({
      form: {
        ...this.state.form,
        key: vehiculo._id,
        name_type_vehicle: vehiculo.name_type_vehicle,
        description_type_vehicle: vehiculo.description_type_vehicle,
        inactive_icon_type_vehicle: vehiculo.inactive_icon_type_vehicle,
        active_icon_type_vehicle: vehiculo.active_icon_type_vehicle,
      },
    });
    $("#modificarModal").modal("show");
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { classes } = this.props;

    const IconoActivo = ({ vehiculo }) => {
      return (
        <Button
          variant="contained"
          className={classes.btnTipoVehiculosActive}
          disabled
        >
          <img
            src={vehiculo.active_icon_type_vehicle}
            width="25"
            alt="Icono activo"
          />
        </Button>
      );
    };

    const IconoInactivo = ({ vehiculo }) => {
      return (
        <Button
          variant="contained"
          className={classes.btnTipoVehiculos}
          disabled
        >
          <img
            src={vehiculo.inactive_icon_type_vehicle}
            width="25"
            alt="Icono inactivo"
          />
        </Button>
      );
    };

    return (
      <Box pt={4}>
        <Container>
          <Typography variant="h1" gutterBottom>
            Tipos de vehículos
          </Typography>

          <Box width="100%" display="flex" mb={3}>
            <Box flexGrow="1">
              <TextFieldOutlined
                size="small"
                name="search"
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.page !== 0) this.setState({ page: 0 });
                  this.setState({ search: e.target.value });
                }}
                variant="outlined"
                placeholder="Buscar..."
                style={{ minWidth: 250 }}
              />
            </Box>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              data-toggle="modal"
              data-target="#crearModal"
            >
              Crear nuevo
            </Button>
          </Box>

          <TableContainer className="table-white">
            <Table aria-label="Type vehicles">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell align={column.align} key={index}>
                      <b>{column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tipoVehiculos ? (
                  this.state.tipoVehiculos.length === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1}>
                      <TableCell colSpan={columns.length} align="center">
                        <em>- No existen tipos de vehiculos creados -</em>
                      </TableCell>
                    </TableRow>
                  ) : (
                    this.state.tipoVehiculos
                      .filter((vehiculo) => {
                        let bandera = false;
                        if (
                          vehiculo.name_type_vehicle
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase()) ||
                          vehiculo._id
                            .toLowerCase()
                            .includes(this.state.search.toLowerCase())
                        ) {
                          bandera = true;
                        }
                        return bandera;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow hover key={i}>
                          <TableCell align="left">
                            {row.name_type_vehicle}
                          </TableCell>
                          <TableCell align="left">
                            {row.description_type_vehicle}
                          </TableCell>
                          <TableCell align="left">
                            <IconoInactivo vehiculo={row} />
                          </TableCell>
                          <TableCell align="left">
                            <IconoActivo vehiculo={row} />
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="update"
                              onClick={() => this.editar(row)}
                            >
                              <CreateIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => this.confirmDelete(row._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((e, i) => (
                      <TableCell key={i}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="w-100 mb-4">
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count, page }) => {
                return (
                  from + " - " + to + " de " + count + " tipos de vehículo"
                );
              }}
              component="div"
              count={
                this.state.tipoVehiculos
                  ? this.state.tipoVehiculos.filter((vehiculo) => {
                      let bandera = false;
                      if (
                        vehiculo.name_type_vehicle
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase()) ||
                        vehiculo._id
                          .toLowerCase()
                          .includes(this.state.search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    }).length
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>

          <div
            className="modal fade"
            id="crearModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="crearModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="crearModalLabel"
                  >
                    Crear tipo de vehículo
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#crearModal").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarCrear}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="texto-form mb-1">
                        Nombre del tipo de vehículo
                      </label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="name_type_vehicle"
                        className="form-control"
                        placeholder="Nombre"
                        value={this.state.form.name_type_vehicle}
                      />
                    </div>
                    <div className="form-group">
                      <label className="texto-form mb-1">Descripción</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="description_type_vehicle"
                        className="form-control"
                        placeholder="Descripción"
                        value={this.state.form.description_type_vehicle}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="texto-form mb-1 w-100">
                          Icono activo
                        </label>
                        <label
                          className={clsx(
                            classes.btnTipoVehiculosActive,
                            "filebutton d-flex justify-content-center"
                          )}
                        >
                          <img
                            src={this.state.form.active_icon_type_vehicle}
                            width="25"
                            alt="Icono activo"
                          />
                          <span>
                            <input
                              type="file"
                              id="active_icon_type_vehicle"
                              name="active_icon_type_vehicle"
                              onChange={this.handleImage}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="texto-form mb-1 w-100">
                          Icono inactivo
                        </label>
                        <label
                          className={clsx(
                            classes.btnTipoVehiculosActive,
                            "filebutton d-flex justify-content-center"
                          )}
                        >
                          <img
                            src={this.state.form.inactive_icon_type_vehicle}
                            width="25"
                            alt="Icono activo"
                          />
                          <span>
                            <input
                              type="file"
                              id="inactive_icon_type_vehicle"
                              name="inactive_icon_type_vehicle"
                              onChange={this.handleImage}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="form-group col-md-12">
                      <label className="texto-form mb-1">Tenant</label>
                      <input
                        required={false}
                        disabled={false}
                        onChange={this.handleChange}
                        type="text"
                        name="tenant"
                        className="form-control"
                        placeholder="Tenant"
                        value={this.state.form.tenant}
                      />
                    </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      Crear
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modificarModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editarModalLabel"
            data-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <Typography
                    variant="h5"
                    className="text-white"
                    id="editarModalLabel"
                  >
                    Modificar tipo de vehículo
                  </Typography>

                  <IconButton
                    aria-label="close"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#modificarModal").modal("hide");
                      this.emptyForm();
                    }}
                  >
                    <CloseIcon htmlColor="#ffffff" fontSize="large" />
                  </IconButton>
                </div>
                <form onSubmit={this.validarEditar}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="texto-form mb-1">
                        Nombre del tipo de vehículo
                      </label>
                      <label className="texto-form-red-light">*</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="name_type_vehicle"
                        className="form-control"
                        placeholder="Nombre"
                        value={this.state.form.name_type_vehicle}
                      />
                    </div>
                    <div className="form-group">
                      <label className="texto-form mb-1">Descripción</label>
                      <label className="texto-form-red-light">*</label>
                      <input
                        required
                        onChange={this.handleChange}
                        type="text"
                        name="description_type_vehicle"
                        className="form-control"
                        placeholder="Descripción"
                        value={this.state.form.description_type_vehicle}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label className="texto-form mb-1">Icono activo</label>
                        <label className="texto-form-red-light">*</label>
                        <label className="filebutton d-flex justify-content-center back-light-blue">
                          <img
                            src={this.state.form.active_icon_type_vehicle}
                            width="25"
                            alt="Icono activo"
                          />
                          <span>
                            <input
                              type="file"
                              id="active_icon_type_vehicle"
                              name="active_icon_type_vehicle"
                              accept="image/*"
                              onChange={this.handleImage}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <label className="texto-form mb-1">
                          Icono inactivo
                        </label>
                        <label className="texto-form-red-light">*</label>
                        <label className="filebutton d-flex justify-content-center back-grey">
                          <img
                            src={this.state.form.inactive_icon_type_vehicle}
                            width="25"
                            alt="Icono activo"
                          />
                          <span>
                            <input
                              type="file"
                              id="inactive_icon_type_vehicle"
                              name="inactive_icon_type_vehicle"
                              accept="image/*"
                              onChange={this.handleImage}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="form-group col-md-12">
                      <label className="texto-form mb-1">Tenant</label>
                      <input
                        required={false}
                        disabled={true}
                        onChange={this.handleChange}
                        type="text"
                        name="tenant"
                        className="form-control"
                        placeholder="Tenant"
                        value={this.state.form.tenant}
                      />
                    </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button color="secondary" variant="contained" type="submit">
                      Modificar
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    );
  }

  validar = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  validarCrear = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.createVehiculo();
    }
  };

  validarEditar = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      this.validar(event);
    } else {
      this.validar(event);
      this.modifyRegion();
    }
  };
}

const styles = (theme) => ({
  btnTipoVehiculos: {
    marginRight: "0.4rem !important",
    backgroundColor: "#E0E0E0 !important",
  },
  btnTipoVehiculosActive: {
    marginRight: "0.4rem !important",
    backgroundColor: theme.palette.primary.main + " !important",
  },
});

export default withStyles(styles, { withTheme: true })(Tipo_Vehiculos);
