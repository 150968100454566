import React from "react";

import Box from "@material-ui/core/Box";



const ActiveDotTrueFalse = ({ status }) => {
  return (
    <Box display="inline-flex">
      <Box height={12} display="flex" alignItems="center" marginRight={2}>
        <Box height={8} width={8} borderRadius={4} bgcolor={status === true ? "#1fc600" : "#f00"}></Box>
      </Box>
      <Box height={12} display="flex" alignItems="center">{status === true ? "Vigente" : "Inactivo"}</Box>
    </Box>
  );
};
export default  ActiveDotTrueFalse;
