import url from '../../config';
import axios from 'axios';

export const ERROR_ESTADOS = 'ERROR_ESTADOS';
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const OBTENER_ESTADOS = 'OBTENER_ESTADOS';
export const obtenerEstados = () => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: url.host + '/Status/all_status/',
                //headers: { tenant: usuario.tenant },
            }).then((response) => {
                switch (response.status) {
                    case 200:
                        dispatch({
                            type: OBTENER_ESTADOS,
                            estados: response.data,
                        });
                        break;

                    default:
                        dispatch({
                            type: ERROR_ESTADOS,
                            error: 'Error ' + response.status + ' al realizar la petición obtener estados',
                        });
                        break;
                }
            }).catch((e)=>{
                dispatch({
                    type: ERROR_ESTADOS,
                    error: 'Error al realizar la petición obtener estados',
                });
            })
        } catch (e) {
            dispatch({
                type: ERROR_ESTADOS,
                error: 'Error al realizar la petición obtener estados',
            });
        }
    };
};