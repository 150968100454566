import url from '../../config';
import axios from 'axios';

export const ERROR_COMUNAS = 'ERROR_COMUNAS';
export const RES_ERROR = 'RES_ERROR';
export const error = () => {
    return async (dispatch) => {
        dispatch({ type: RES_ERROR });
    };
};

export const OBTENER_COMUNAS = 'OBTENER_COMUNAS';
export const obtenerComunas = () => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: url.host + '/Communes/communes/',
                //headers: { tenant: usuario.tenant },
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            dispatch({
                                type: OBTENER_COMUNAS,
                                comunas: response.data,
                            });
                            break;

                        default:
                            dispatch({
                                type: ERROR_COMUNAS,
                                error: 'Error ' + response.status + ' al realizar la petición obtener comunas',
                            });
                            break;
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: ERROR_COMUNAS,
                        error: 'Error al realizar la petición obtener comunas',
                    });
                });
        } catch (e) {
            dispatch({
                type: ERROR_COMUNAS,
                error: 'Error al realizar la petición obtener comunas',
            });
        }
    };
};

export const MODIFICAR_ARRAY_COMUNAS = 'MODIFICAR_ARRAY_COMUNAS';
export const modificarArrayComunas = (comunas) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: MODIFICAR_ARRAY_COMUNAS,
                comunas: comunas,
            });
        } catch (e) {
            dispatch({
                type: ERROR_COMUNAS,
                error: 'Error al seleccionar comunas',
            });
        }
    };
};
