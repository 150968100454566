import url from '../../config';
import axios from 'axios';
import Utils from '../../assets/utils/Utils';
import { ID_NO_ASIGNADO } from '../../assets/utils/Helpers';

export const ERROR_TAREAS = 'ERROR_TAREAS';
export const RES_ERROR = 'RES_ERROR';
export const error = () => {
    return async (dispatch) => {
        dispatch({ type: RES_ERROR });
    };
};

export const OBTENER_ZONAS = 'OBTENER_ZONAS';
export const obtenerZonas = (initDate, endDate) => {
    return async (dispatch) => {

        await axios.get(`${url.host}/Zones/tasks?initDate=${initDate}&endDate=${endDate}`)
        .then((response) => {
            switch (response.status) {
                case 200:
                    dispatch({ 
                        type: OBTENER_ZONAS,
                        zones: response.data.data
                    })
                    break;
                default:
                    dispatch({
                        type: OBTENER_ZONAS,
                        zones: []
                    });
                    break;
            }
        });
    }
}

export const OBTENER_TAREAS = 'OBTENER_TAREAS';
export const obtenerTareas = () => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: url.host + '/Tasks/tasks/',
                headers: [],
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                            let tareas = response.data;
                            tareas.forEach((tarea) => {
                                tarea.selected = false;
                            });

                            dispatch({
                                type: OBTENER_TAREAS,
                                tareas: response.data,
                            });
                            break;

                        default:
                            dispatch({
                                type: ERROR_TAREAS,
                                error: 'Error ' + response.status + ' al realizar la petición de obtener tareas',
                            });
                            break;
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: ERROR_TAREAS,
                        error: 'Error al realizar la petición de obtener tareas',
                    });
                });
        } catch (e) {
            dispatch({
                type: ERROR_TAREAS,
                error: 'Error al realizar la petición de obtener tareas',
            });
        }
    };
};

export const MODIFICAR_T = 'MODIFICAR_T';
export const modificarTareas = (tareas, seleccionados) => {
    return async (dispatch) => {
        let estado = false;
        tareas.forEach((tarea) => {
            seleccionados.forEach((seleccionado) => {
                if (tarea._id === seleccionado) {
                    tarea.selected = !tarea.selected;
                }

                if (tarea.selected === true) {
                    estado = true;
                }
            });
        });

        dispatch({
            type: MODIFICAR_T,
            tareas: tareas,
            seleccionado: estado,
        });
    };
};

export const DES_SELECCIONAR_T = 'DES_SELECCIONAR_T';
export const desseleccionarTareas = (tareas) => {
    return async (dispatch) => {
        let estado = false;
        tareas.forEach((tarea) => {
            tarea.selected = false;
        });

        dispatch({
            type: DES_SELECCIONAR_T,
            tareas: tareas,
            seleccionado: estado,
        });
    };
};

export const DUPLICAR_TAREAS = 'DUPLICAR_TAREAS';
export const duplicarTareas = (tarea) => {
    return async (dispatch) => {
        try {
            delete tarea._id;
            delete tarea.__v;
            delete tarea.id;
            delete tarea.code_task;
            delete tarea.history_task;
            delete tarea.orderNumber_task;
            tarea.idstatus_task = ID_NO_ASIGNADO;
            tarea.idrayo_task = null;
            tarea.name_rayo = 'Sin rayo';

            let res = await url.api.post('/Tasks/task/', tarea);
            if (res.status === 201 || res.status === 200) {
                Utils.handleAlert('Tarea duplicada', 'La tarea se ha duplicado exitosamente', 'Ok');
                dispatch({ type: DUPLICAR_TAREAS });
            } else {
                Utils.handleAlert(
                    'Error ' + res.status,
                    'Ha ocurrido un error al duplicar la tarea, intentelo nuevamente',
                    'Ok'
                );
            }

            // await axios({
            //     method: 'post',
            //     url: url.host + '/task/',
            //     headers: [],
            //     data: tarea,
            // })
            //     .then((response) => {
            //         switch (response.status) {
            //             case 201:
            //                 Utils.handleAlert('Tarea duplicada', 'La tarea se ha duplicado exitosamente', 'Ok');
            //                 dispatch({ type: DUPLICAR_TAREAS });
            //                 break;

            //             default:
            //                 Utils.handleAlert(
            //                     'Error ' + response.status,
            //                     'Ha ocurrido un error al duplicar la tarea, intentelo nuevamente',
            //                     'Ok'
            //                 );
            //                 break;
            //         }
            //     })
            //     .catch((e) => {
            //         Utils.handleAlert('Error', 'Ha ocurrido un error al duplicar la tarea, intentelo nuevamente', 'Ok');
            //     });
        } catch (e) {
            Utils.handleAlert('Error', 'Ha ocurrido un error al duplicar la tarea, intentelo nuevamente', 'Ok');
        }
    };
};
