require("dotenv").config();
const axios = require("axios");

// AMBIENTE PRODUCTIVO
// const config = {
//     api: axios.create({ baseURL: 'https://thor-fm.uc.r.appspot.com' }),
//     mode: 'CORS',
//     headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
//     host: 'https://thor-fm.uc.r.appspot.com',
//     hostSocket: 'https://thor-fm.uc.r.appspot.com',
// };

// AMBIENTE LOCAL
// const config = {
//     api: axios.create({ baseURL: 'http://localhost:8080/' }),
//     mode:'CORS',
//     headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
//     host: "http://localhost:8080",
//     hostSocket: 'http://localhost:8080',
// };

// // AMBIENTE DE DESARROLLO
// const config = {
//     api: axios.create({ baseURL: 'https://thor-fm-test.uc.r.appspot.com' }),
//     mode: 'CORS',
//     headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
//     host: 'https://thor-fm-test.uc.r.appspot.com',
//     hostSocket: 'https://thor-fm-test.uc.r.appspot.com',
// };

// AMBIENTE CLOUD TESTING
// const config = {
//     api: axios.create({ baseURL: 'https://backendservices-test.rayoapp.com' }),
//     mode: 'CORS',
//     headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' },
//     host: 'https://backendservices-test.rayoapp.com',
//     hostSocket: 'https://backendservices-test.rayoapp.com',
// };

// AMBIENTE CLOUD PRODUCCION
const config = {
  api: axios.create({ baseURL: "https://backendservices.rayoapp.com" }),
  mode: "CORS",
  headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" },
  host: "https://backendservices.rayoapp.com",
  hostSocket: "https://backendservices.rayoapp.com",
};


// AMBIENTE CLOUD PRODUCCION
/*
const config = {
  api: axios.create({ baseURL: "https://backendservices.entregas.cl" }),
  mode: "CORS",
  headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" },
  host: "https://backendservices.entregas.cl",
  hostSocket: "https://backendservices.entregas.cl",
};
*/

module.exports = config;
