import React from "react";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
// import JSONTree from "react-json-tree";
import "../../../css/global.css";
import { connect } from "react-redux";
import url from "../../../config";
import Watchs from "../../../assets/utils/Watchs";
import Utils from "../../../assets/utils/Utils";
import {
  N_TAREA,
  N_GRUPOTAREA,
  // THEME_METADATA,
  APPCONFIG,
  SERVICE_NAME,
} from "../../../assets/utils/Helpers";
import {
  ID_NO_ASIGNADO,
  ID_ASIGNADO,
  ID_ACEPTADO,
  ID_INICIADO,
  ID_DEVUELTO,
  ID_RETIROCLIENTE,
  ID_BLOQUEADOCLIENTE,
  ID_RECEPCIONBODEGA
} from "../../../assets/utils/Helpers";
import {
  ID_EN_PROGRESO,
  ID_EXITOSO,
  ID_FALLIDO,
  ID_RECHAZADO,
  ID_CANCELADO,
} from "../../../assets/utils/Helpers";
import DescargarCSV from "../../../assets/components/home/DescargarCSV";
import Cargando from "../../../assets/components/cargando/Cargando";
import DetalleGrupoTareas from "../../../assets/components/home/DetalleGrupoTareas";
import ListaTareas from "../../../assets/components/lists/ListaTareas";

import * as Realm from "realm-web";
import * as grupoTareasAccion from "../../../store/actions/GrupoTareas";

import TablePagination from "@material-ui/core/TablePagination";
import {
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Button, Drawer } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Popover from "@material-ui/core/Popover";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/CheckBox";

import IconoDocumento from "../../../assets/icons/icono-document-download-black.svg";
import IconoLupa from "../../../assets/icons/icono-lupa-white.svg";
import IconoRoute from "../../../assets/icons/gps-route.svg";
import IconoData from "../../../assets/icons/data.svg";
import IconoCerrar from "../../../assets/icons/icono-cerrar-grey.svg";
import IconoReloj from "../../../assets/icons/historia.svg";
import IconoRecargar from "../../../assets/icons/icono-cambiar-black.svg";
import IconoFlechaBlanca from "../../../assets/icons/icono-down-white.svg";

import MetadataJson from "../../../assets/components/home/MetadataJson";

const columns = [
  {
    id: "check",
    label: "check",
    align: "left",
  },
  {
    id: "order_id_task",
    label: "ID Orden",
    align: "left",
  },
  { id: "code_task", label: "ID Task", align: "left" },
  { id: "type_task", label: "Tipo de Tarea", align: "center" },
  {
    id: "contact_name_task",
    label: "Nombre de contacto",
    align: "left",
  },
  {
    id: "contact_phone_task",
    label: "Número de contacto",
    align: "left",
  },
  {
    id: "address_task",
    label: "Dirección",
    align: "left",
  },
  { id: "date_task", label: "Inicio programado", align: "left" },
  { id: "end_date_task", label: "Fecha finalización", align: "left" },
  { id: "idstatus_task", label: "Estado de tarea", align: "left" },
  { id: "code_route", label: "ID Ruta", align: "center" },
  {
    id: "link_seguimiento",
    label: "Seguimiento",
    align: "center",
  },
  {
    id: "metadata_task",
    label: "Etiqueta",
    align: "center",
  },
];

class VistaLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openGT: false,
      taskMetadata: null,
      contextMenuState: false,
      contextMenu: { display: "none" },
      anchorElEstados: null,
      nombreEstado: "Personalizado",
      stringEstados: "",

      filters: {
        [ID_NO_ASIGNADO]: true,
        [ID_ASIGNADO]: true,
        [ID_ACEPTADO]: true,
        [ID_INICIADO]: true,
        [ID_EN_PROGRESO]: true,
        [ID_EXITOSO]: true,
        [ID_FALLIDO]: true,
        [ID_RECHAZADO]: true,
        [ID_CANCELADO]: true,
        [ID_DEVUELTO]: true,
        [ID_RETIROCLIENTE]: true,
        [ID_BLOQUEADOCLIENTE]: true,
        [ID_RECEPCIONBODEGA]: true,


      },

      page: 0,
      rowsPerPage: 10,

      tareas: "",
      selectTareas: "all",
      estadoSelected: "0",
      estados: [],

      csvCantidadTareas: "todas",
      csvIncluirOrder: false,

      template: [],
      tituloHistorial: "Historial tarea",
      dataHistorial: [],
      historial: false,
      cargando: false,

      openCalendar: false,
      positionCalendar: {},
      selectedHours: [1, 19],
      selectedDays: [],
      selectedDaysOrder: [],
      from: undefined,
      to: undefined,
    };
  }

  handleEstados = (filtro) => {
    // let filtros = cloneDeep(this.state.filters);
    let filtrosState = cloneDeep(this.state.filters);
    let filtros = {
      [ID_NO_ASIGNADO]: false,
      [ID_ASIGNADO]: false,
      [ID_ACEPTADO]: false,
      [ID_INICIADO]: false,
      [ID_EN_PROGRESO]: false,
      [ID_EXITOSO]: false,
      [ID_FALLIDO]: false,
      [ID_RECHAZADO]: false,
      [ID_CANCELADO]: false,
      [ID_DEVUELTO]: false,
      [ID_RETIROCLIENTE]: false,
      [ID_BLOQUEADOCLIENTE]: false,

      [ID_RECEPCIONBODEGA]: false,
    };

    if (filtro === "todos") {
      this.handleFiltro("");
      this.setState({
        stringEstados: "",
        nombreEstado: "Personalizado",
        filters: {
          [ID_NO_ASIGNADO]: true,
          [ID_ASIGNADO]: true,
          [ID_ACEPTADO]: true,
          [ID_INICIADO]: true,
          [ID_EN_PROGRESO]: true,
          [ID_EXITOSO]: true,
          [ID_FALLIDO]: true,
          [ID_RECHAZADO]: true,
          [ID_CANCELADO]: true,
          [ID_DEVUELTO]: true,
          [ID_RETIROCLIENTE]: true,
          [ID_BLOQUEADOCLIENTE]: true,
          [ID_RECEPCIONBODEGA]: true,

        },
      });
    } else {
      if (filtrosState[filtro]) {
        this.handleFiltro("");
        this.setState({
          stringEstados: "",
          nombreEstado: "Personalizado",
          filters: filtros,
        });
      } else {
        let string = "";
        let contador = 0;
        filtros[filtro] = !filtros[filtro];
        let result = Object.keys(filtros).map((key) => [
          { id: key, data: filtros[key] },
        ]);
        let saveFilters = {};
        Object.keys(filtros).forEach((key) => {
          saveFilters = { ...saveFilters, [key]: filtros[key] };
        });

        result.forEach((element) => {
          if (element[0].data) {
            contador = contador + 1;
            string =
              contador === 1
                ? string + element[0].id
                : string + "," + element[0].id;
          }
        });
        if (contador !== 0) {
          this.handleFiltro(string);
          this.setState({
            filters: saveFilters,
            stringEstados: string,
            nombreEstado: Utils.nombreEstado(string),
          });
        }
      }
    }
  };

  handleFiltro = async (filtro) => {
    await this.handleCargando(true);
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      null,
      filtro
    );
    try {
      await dispatch(obtenerTareas);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se ha filtrado la data, vuelva a intentarlo",
        "Ok"
      );
    }
    this.handleCargando(false);
  };

  filterOrderId = async () => {
    await this.handleCargando(true);
    const { dispatch } = this.props;
    let data = this.state.tareas;
    if (this.state.tareas === "") {
      data = null;
    }

    let buscarTexto = { filter: this.state.selectTareas, data: data };
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      null,
      null,
      buscarTexto
    );
    try {
      this.setState({ page: 0 });
      await dispatch(obtenerTareas);
    } catch (err) {
      this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se ha filtrado la data, vuelva a intentarlo",
        "Ok"
      );
    }
    this.handleCargando(false);
  };

  handleHoursClick = async (event, newValue) => {
    await this.setState({
      selectedHours: newValue,
    });
  };

  handleDayClick = async (day, { selected }) => {
    const { selectedDays } = this.state;

    if (selected) {
      // const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      const selectedIndex = selectedDays.findIndex(
        (selectedDay) =>
          moment(selectedDay).utcOffset("+00").format("DD-MM-YYYY") ===
          moment(day).utcOffset("+00").format("DD-MM-YYYY")
      );
      if (selectedIndex >= 0) {
        await selectedDays.splice(selectedIndex, 1);
        selectedDays.push(selectedDays[0]);
      } else {
        selectedDays.splice(0, 1);
        selectedDays.push(day);
      }
    } else {
      if (selectedDays.length === 2) {
        selectedDays.splice(0, 1);
      }
      selectedDays.push(day);
    }

    let newFechas = [];
    newFechas.push(new Date(Math.min(...selectedDays)));
    newFechas.push(new Date(Math.max(...selectedDays)));

    if (selectedDays.length === 2) {
      this.setState({
        selectedDays: selectedDays,
        selectedDaysOrder: newFechas,
        from: newFechas[0],
        to: newFechas[1],
      });
    }
  };

  fechaFiltro = () => {
    let hoy = new Date();
    let hoyFin = new Date().setHours(23, 59, 59, 999);
    hoyFin = moment(hoyFin).format();
    hoy = moment(hoy).subtract(1, "hours").format();

    let fechaFinSplit = hoyFin.split("T");
    let fechaSplit = hoy.split("T");

    this.setState({
      selectedDays: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedDaysOrder: [
        new Date(fechaSplit[0] + "T00:00:00"),
        new Date(fechaFinSplit[0] + "T00:00:00"),
      ],
      selectedHours: [0, 24],
      from: new Date(fechaSplit[0] + "T00:00:00"),
      to: new Date(fechaFinSplit[0] + "T00:00:00"),
    });
  };

  fechasCalendario = () => {
    this.setState({
      openCalendar: false,
    });
    const selectedDays = this.state.selectedDaysOrder;
    const selectedHours = this.state.selectedHours;
    let newSelectedHours = [];
    let initDate = "";
    let endDate = "";

    selectedHours.forEach((hour) => {
      let hourStr = hour.toString();
      if (hour < 10) {
        hourStr = "0" + hourStr + ":00:00";
      } else if (hour === 24) {
        hourStr = "23:59:59";
      } else {
        hourStr = hourStr + ":00:00";
      }
      newSelectedHours.push(hourStr);
    });

    if (selectedDays.length === 1 || selectedDays.length === 2) {
      initDate =
        moment(selectedDays[0]).format("YYYY-MM-DD") +
        "T" +
        newSelectedHours[0] +
        "Z";

      if (selectedDays.length === 2) {
        endDate =
          moment(selectedDays[1]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      } else {
        endDate =
          moment(selectedDays[0]).format("YYYY-MM-DD") +
          "T" +
          newSelectedHours[1] +
          "Z";
      }
    }

    return [initDate, endDate];
  };

  _handleClick = (event) => {
    const { contextMenuState } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && contextMenuState)
      this.setState({
        contextMenuState: false,
        contextMenu: { display: "none" },
      });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this._handleClick);
    this.closingRealm();
  }

  closingRealm = async () => {
    try {
      this.watchTasks = null;
      this.watchTaskGroup = null;

      await Promise.all(
        this.app.users.map(async (user, index) => {
          await user.logOut();
          await this.app.removeUser(user);
        })
      );
    } catch (error) { }
  };

  componentDidMount() {
    this.getStatus();
    document.addEventListener("click", this._handleClick);
    this.cargarTareasIniciales(true);
    this.instance_realm(APPCONFIG);
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.closingRealm();
    });
    this.fechaFiltro();
  }

  cargarTareasIniciales = async (tipo) => {
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas([], tipo);
    try {
      dispatch(obtenerTareas);
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  instance_realm = async (appCfg) => {
    try {
      this.app = new Realm.App(appCfg);

      try {
        if (this.app.users.length > 0) {
          await Promise.all(
            this.app.users.map(async (user, index) => {
              await user.logOut();
              await this.app.removeUser(user);
            })
          );
        }
      } catch (error) { }

      await this.app.logIn(Realm.Credentials.anonymous()).then((user) => {
        this.db = this.app.currentUser
          .mongoClient(SERVICE_NAME)
          .db("fleet_manager");
        let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));

        // ---- WATCH TASKS -----
        let optionTasks = Utils.getOptionTasksActions(filtrosGlobales);
        this.watchTasks = this.db.collection("tasks").watch(optionTasks);
        this.watch_task();
      });
    } catch (error) { }
  };

  openWatchTaskGroup = (id) => {
    if (this.db) {
      let optionTaskGroup = Utils.getOptionTaskGroup(id);
      this.watchTaskGroup = this.db
        .collection("tasks_groups")
        .watch(optionTaskGroup);
      this.watch_taskgroup();
    }
  };

  closeWatchTaskGroup = () => {
    this.watchTaskGroup = null;
  };

  watch_taskgroup = () => {
    if (this.watchTaskGroup) {
      this.watchTaskGroup.next().then((result) => {
        try {
          if (result.value.operationType === "update") {
            let grupoTareas = this.state.grupoTareas;
            if (grupoTareas) {
              let data = this.taskGroup_Keys(result.value.fullDocument);
              if (data._id === grupoTareas._id) {
                grupoTareas.idstatus_taskgroup = data.idstatus_taskgroup;
                grupoTareas.idrayo_taskgroup = data.idrayo_taskgroup;
                this.setState({
                  grupoTareas: grupoTareas,
                });
              }
            }
          } else if (result.value.operationType === "delete") {
          }
        } catch (error) { }

        this.watch_taskgroup();
      });
    }
  };

  taskGroup_Keys = (document) => {
    let taskGroup = cloneDeep(document);
    taskGroup.id = Utils.getBSONtoString(document._id);
    taskGroup._id = Utils.getBSONtoString(document._id);
    taskGroup.idstatus_taskgroup = Utils.getBSONtoString(
      document.idstatus_taskgroup
    );
    if (document.idrayo_taskgroup == null) {
      taskGroup.idrayo_taskgroup = null;
    } else {
      taskGroup.idrayo_taskgroup = Utils.getBSONtoString(
        document.idrayo_taskgroup
      );
    }
    taskGroup.idcustomer_taskgroup = Utils.getBSONtoString(
      document.idcustomer_taskgroup
    );
    taskGroup.start_date_taskgroup =
      moment(taskGroup.start_date_taskgroup)
        .add(3, "hour")
        .format("YYYY-MM-DDTHH:MM:SS") + ".000Z";

    return taskGroup;
  };

  watch_task = () => {
    if (this.watchTasks) {
      const { dispatch } = this.props;
      this.watchTasks.next().then((result) => {
        try {
          if (result.value.operationType === "delete") {
            Watchs.task_deleted(
              result,
              dispatch,
              this.props.grupoTareas.tareas
            );

            if (this.state.openGT && this.state.grupoTareas) {
              let id = Utils.getBSONtoString(result.value.documentKey._id);

              let i = this.state.grupoTareas.tasks.findIndex(
                (element) => element._id === id
              );
              if (i >= 0) {
                this.deleteTask_TaskGroupWatch(i);
              }
            }
          } else if (
            result.value.operationType === "insert" ||
            result.value.operationType === "update"
          ) {
            let task = Watchs.task_Keys(result.value.fullDocument);
            if (result.value.operationType === "insert") {
              this.addTasks(this.props.grupoTareas.countTasks);

              if (this.props.grupoTareas.tareas) {
                if (this.props.grupoTareas.tareas.length < 10) {
                  Watchs.task_created(
                    task,
                    dispatch,
                    this.props.grupoTareas.tareas,
                    this.props.rayos.rayos
                  );
                }
              }
              if (this.state.openGT && this.state.grupoTareas) {
                if (this.state.grupoTareas._id === task.idTasksGroup_task) {
                  this.createTask_TaskGroupWatch(task);
                }
              }
            } else if (result.value.operationType === "update") {
              Watchs.task_updated(
                task,
                dispatch,
                this.props.grupoTareas.tareas,
                this.props.rayos.rayos
              );

              if (this.state.openGT && this.state.grupoTareas) {
                if (this.state.grupoTareas._id === task.idTasksGroup_task) {
                  this.updateTaskGroupWatch(N_TAREA, task);
                }
              }
            }
          }
        } catch (error) { }
        this.watch_task();
      });
    } else {
    }
  };

  addTasks = (num) => {
    try {
      let numTotal = num + 1;
      const { dispatch } = this.props;
      let sumarTareas = grupoTareasAccion.actualizarCantidadTareas(numTotal);
      dispatch(sumarTareas);
    } catch (error) { }
  };

  updateTaskGroupWatch = (tipo, data) => {
    let grupoTareas = this.state.grupoTareas;
    if (N_TAREA === tipo) {
      let i = grupoTareas.tasks.findIndex(
        (element) => element._id === data._id
      );
      if (i >= 0) {
        grupoTareas.tasks[i] = data;
        this.setState({
          grupoTareas: grupoTareas,
        });
      }
    } else if (N_GRUPOTAREA === tipo) {
    }
  };

  createTask_TaskGroupWatch = (task) => {
    let grupoTareas = this.state.grupoTareas;
    grupoTareas.tasks.push(task);
    this.setState({
      grupoTareas: grupoTareas,
    });
  };

  deleteTask_TaskGroupWatch = (index) => {
    let grupoTareas = this.state.grupoTareas;
    grupoTareas.tasks.splice(index, 1);
    this.setState({
      grupoTareas: grupoTareas,
    });
  };

  cleanSelectedT = () => {
    const { dispatch } = this.props;
    let desseleccionarTareas = grupoTareasAccion.desseleccionarTareas(
      this.props.grupoTareas.tareas
    );
    dispatch(desseleccionarTareas);
  };

  checkAll = () => {
    let tareas = this.props.grupoTareas.tareas.filter((tarea) => {
      let bandera = false;
      if (
        tarea.idstatus_task
          .toLowerCase()
          .includes(this.state.estadoSelected.toLowerCase()) &&
        (tarea.order_id_task
          .toLowerCase()
          .includes(this.state.tareas.toLowerCase()) ||
          tarea.name_rayo
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.code_task
            .toString()
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.address_task
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()) ||
          tarea.contact_name_task
            .toLowerCase()
            .includes(this.state.tareas.toLowerCase()))
      ) {
        tarea.selected = true;
        bandera = true;
      } else {
        bandera = true;
      }
      return bandera;
    });

    const { dispatch } = this.props;
    let seleccionarTodasTareas = grupoTareasAccion.seleccionarTodasTareas(
      tareas
    );
    dispatch(seleccionarTodasTareas);
  };

  getStatus = async () => {
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Status/all_status/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ estados: data });
    } catch (err) {
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  handleCargando = (tipo) => {
    this.setState({
      cargando: tipo,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleChangePage = async (event, newPage) => {
    await this.handleCargando(true);
    let skip = newPage * 10;

    this.setState({ page: newPage });
    const { dispatch } = this.props;
    let obtenerTareas = grupoTareasAccion.obtenerTareas(
      [],
      false,
      skip,
      this.state.stringEstados
    );
    try {
      dispatch(obtenerTareas);
      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Obtener data",
        "No se han podido cargar todos los elementos de la página",
        "Ok"
      );
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleDrawerGT = (value) => {
    this.setState({
      openGT: value,
    });
  };

  handleCheck = (e, tarea) => {
    e.preventDefault();
    let id = tarea._id;
    const { dispatch } = this.props;
    let modificarTareas = grupoTareasAccion.modificarTareas(
      this.props.grupoTareas.tareas,
      [id]
    );
    dispatch(modificarTareas);
  };

  handleContextMenu = (e, tarea) => {
    e.preventDefault();
    const clickX = e.clientX;
    const clickY = e.clientY;

    this.setState({
      taskMetadata: tarea,
      contextMenuState: true,
      contextMenu: {
        display: "block",
        top: clickY,
        left: clickX,
      },
    });
  };

  getTasksGroupById = async (id) => {
    await this.handleCargando(true);
    try {
      let data = await axios({
        method: "get",
        url: url.host + "/Task-Groups/tasks_group/" + id + "/",
        //headers: { tenant: usuario.tenant },
      }).then(({ data }) => data);
      this.setState({ grupoTareas: data });
      this.handleDrawerGT(true);
      this.openWatchTaskGroup(data._id);
      await this.handleCargando(false);
    } catch (err) {
      await this.handleCargando(false);
      Utils.handleAlert(
        "Obtener grupo de tareas",
        "No se ha podido cargar el grupo de tareas",
        "Ok"
      );
    }
  };

  downloadTasksCSV = async () => {
    await this.handleCargando(true);
    const allTasks = this.props.grupoTareas.tareas;
    let data = {
      customers_ids: [],
      order_ids: [],
      init_date: "",
      end_date: "",
      rayo_id: "",
      status_id: "",
      data_consistency: 0,
    };

    try {
      let fecha = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss") + "Z";
      let fechaInicio = fecha.split("T")[0] + "T00:00:00Z";
      let fechaFin = fecha.split("T")[0] + "T23:59:59Z";
      let filtrosGlobales = JSON.parse(sessionStorage.getItem("filtros"));
      let fechasCalendario = this.fechasCalendario();

      filtrosGlobales[1].forEach((element) => {
        if (element.filter === "idCustomer" && element.data != null) {
          let clientes = element.data.split(",");
          clientes.forEach((cliente) => {
            data.customers_ids.push(cliente);
          });
        }
      });

      if (this.state.csvCantidadTareas === "todas") {
        filtrosGlobales[1].forEach((element) => {
          if (element.filter === "initDate" && element.data != null) {
            data.init_date = fechasCalendario[0];
          } else if (element.filter === "endDate" && element.data != null) {
            data.end_date = fechasCalendario[1];
          } else if (element.filter === "idRayo" && element.data != null) {
            data.rayo_id = element.data;
          }
        });
        if (this.state.estadoSelected !== "0") {
          data.status_id = this.state.estadoSelected;
        }
      } else if (this.state.csvCantidadTareas === "hoy") {
        data.init_date = fechaInicio;
        data.end_date = fechaFin;
      } else {
        allTasks.forEach((element) => {
          if (element.selected) data.order_ids.push(element.order_id_task);
        });
      }

      if (this.state.csvIncluirOrder) data.data_consistency = 1;

      await url.api
        .post(
          "https://export-thor-2jox3wghaa-uc.a.run.app/Tasks/tasks_for_csv",
          data
        )
        .then((response) => {
          let data = response.data;
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = data;
          a.download = "";
          a.click();
        })
        .catch((error) => {
          Utils.handleAlert("Error al realizar la acción", error.message, "Ok");
        });

      // const todayTasks = await url.api.post("/Tasks/tasks_for_csv", data);
      // if (todayTasks.data.length === 0) {
      //   Utils.handleAlert(
      //     "Sin tareas",
      //     "No se encuentran tareas según los parametros ingresados",
      //     "Ok"
      //   );
      // } else {
      //   Utils.downloadCSVClient(todayTasks.data, this.state.csvCantidadTareas);
      // }
    } catch (error) {
      Utils.handleAlert("Error al realizar la acción", error.message, "Ok");
    }

    await this.handleCargando(false);
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const openEstados = Boolean(this.state.anchorElEstados);
    // let pagina = this.props.grupoTareas.paginador;
    const idFiltrosEstados = openEstados ? "simple-popover" : undefined;

    const MenuContext = () => {
      return (
        <div
          style={this.state.contextMenu}
          className="z-i-300"
          ref={(ref) => {
            this.root = ref;
          }}
        >
          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() => this.handleState("historial", true)}
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoReloj} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Ver Historial
            </div>
          </div>
          <div
            className="row m-0 opc-drop border-bottom-0"
            onClick={() =>
              window.open(
                "/Tracking/" + N_TAREA + "/" + this.state.taskMetadata._id,
                "_blank"
              )
            }
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoRoute} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Tracking Link
            </div>
          </div>

          <div
            className="row m-0 opc-drop border"
            data-toggle="modal"
            data-target="#metadata"
          >
            <div className="col-md-4 d-flex align-items-center">
              <img src={IconoData} height="20" alt="icono" />
            </div>
            <div className="col-md-8 pl-0 txt-carta-name d-flex align-items-center">
              Metadata
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="container pt-5 px-0">
        <div className="row m-0 px-0 mb-3">
          <AssignmentIcon fontSize="large" style={{ marginTop: 10 }} />
          <h1 className="title-text ml-2">Lista de Tareas</h1>
        </div>

        <div className="row m-0 mb-2 w-100">
          <div className="col p-0">
            <FormControl
              variant="outlined"
              size="small"
              className="form-height"
              style={{ width: "30ch", height: "36.19px !important" }}
            >
              <OutlinedInput
                type="text"
                name="tareas"
                placeholder="Buscar tareas"
                className="input-search-options"
                value={this.state.tareas}
                onChange={(e) => this.handleChange(e)}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              className="form-height"
              style={{ width: "30ch", height: "36.19px !important" }}
            >
              <Select
                className="select-search-options"
                label="Age"
                name="selectTareas"
                value={this.state.selectTareas}
                onChange={(e) => this.handleChange(e)}
              >
                <MenuItem value="all">Todos los campos</MenuItem>
                <MenuItem value="orderIdTask">Order ID</MenuItem>
                <MenuItem value="contactNameTask">Nombre de contacto</MenuItem>
                <MenuItem value="addressTask">Dirección</MenuItem>
              </Select>
            </FormControl>
            <Button
              className="p-0 button-search-options"
              onClick={() => this.filterOrderId()}
            >
              <img src={IconoLupa} alt="IconoLupa" width="18" />
            </Button>

            <Button
              aria-controls="fade-menu"
              className="select-filter-estado ml-4"
              aria-describedby={idFiltrosEstados}
              onClick={(e) =>
                this.handleState("anchorElEstados", e.currentTarget)
              }
              endIcon={<img src={IconoFlechaBlanca} height="24" alt="flecha" />}
            >
              {"Estado " + this.state.nombreEstado}
            </Button>

            <Popover
              id={idFiltrosEstados}
              open={openEstados}
              anchorEl={this.state.anchorElEstados}
              onClose={() => this.handleState("anchorElEstados", null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
            >
              <div
                className="row m-0 opc-drop border-bottom-0"
                onClick={() => this.handleEstados("todos")}
              >
                <div className="col-12 pl-0 txt-carta-name d-flex align-items-center justify-content-center">
                  Todos los estados
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_NO_ASIGNADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.state.filters[ID_NO_ASIGNADO]}
                    onChange={() => this.handleEstados(ID_NO_ASIGNADO)}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot back-medium-grey"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  No Asignado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_ASIGNADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={this.state.filters[ID_ASIGNADO]}
                    onChange={() => this.handleEstados(ID_ASIGNADO)}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-asignado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Asignado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_ACEPTADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_ACEPTADO)}
                    checked={this.state.filters[ID_ACEPTADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-aceptado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Aceptado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_INICIADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_INICIADO)}
                    checked={this.state.filters[ID_INICIADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-iniciado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Iniciado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_EN_PROGRESO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_EN_PROGRESO)}
                    checked={this.state.filters[ID_EN_PROGRESO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-en-progreso"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  En Progreso
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_EXITOSO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_EXITOSO)}
                    checked={this.state.filters[ID_EXITOSO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-exitoso"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Exitoso
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_FALLIDO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_FALLIDO)}
                    checked={this.state.filters[ID_FALLIDO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-haFallado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Fallido
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RECHAZADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RECHAZADO)}
                    checked={this.state.filters[ID_RECHAZADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-rechazado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Rechazado
                </div>
              </div>
              <div className="row m-0 opc-drop border-bottom-0">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_CANCELADO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_CANCELADO)}
                    checked={this.state.filters[ID_CANCELADO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-cancelado"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Cancelado
                </div>
              </div>
              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_DEVUELTO}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_DEVUELTO)}
                    checked={this.state.filters[ID_DEVUELTO]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-devuelto"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Devuelto
                </div>
              </div>

              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RETIROCLIENTE}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RETIROCLIENTE)}
                    checked={this.state.filters[ID_RETIROCLIENTE]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-retirocliente"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Retiro Bodega Cliente
                </div>
              </div>

              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_RECEPCIONBODEGA}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_RECEPCIONBODEGA)}
                    checked={this.state.filters[ID_RECEPCIONBODEGA]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-devuelto"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Retiro Bodega Cliente
                </div>
              </div>

              <div className="row m-0 opc-drop border">
                <div className="col-2 p-0 d-flex align-items-center">
                  <Checkbox
                    name={ID_BLOQUEADOCLIENTE}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => this.handleEstados(ID_BLOQUEADOCLIENTE)}
                    checked={this.state.filters[ID_BLOQUEADOCLIENTE]}
                  />
                </div>
                <div className="col-3 d-flex align-items-center">
                  <div className={"dot dot-devuelto"}></div>
                </div>
                <div className="col-7 pl-0 txt-carta-name d-flex align-items-center">
                  Retiro Bodega Cliente
                </div>
              </div>



            </Popover>

            <Button
              variant="contained"
              className="position-absolute px-1 shadow-0"
              style={{ right: 60, height: 40 }}
              onClick={() => {
                this.cargarTareasIniciales(false);
                this.setState({ estadoSelected: "0", tareas: "" });
              }}
            >
              <img src={IconoRecargar} height="30" alt="actualizar" />
            </Button>

            <Button
              variant="contained"
              className="position-absolute px-1 shadow-0"
              style={{ right: 0, height: 40 }}
              onClick={() => $("#modalDescarga").modal("show")}
            >
              <img src={IconoDocumento} height="30" alt="descargar" />
            </Button>
          </div>
        </div>

        {this.props.grupoTareas.tareas === null ? (
          <div className="w-100 p-5 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </div>
        ) : (
          <ListaTareas
            columns={columns}
            tareas={this.props.grupoTareas.tareas}
            grupoTareas={this.props.grupoTareas}
            rayos={this.props.rayos.rayos}
            page={page}
            rowsPerPage={rowsPerPage}
            openFiltros={false}
            filtroTareas={this.state.tareas}
            estadoSelected={this.state.estadoSelected}
            anchorElFiltros={null}
            cleanSelectedT={this.cleanSelectedT}
            checkAll={this.checkAll}
            handleCheck={this.handleCheck}
            handleState={this.handleState}
            getTasksGroupById={this.getTasksGroupById}
            propsData={this.props.data}
          />
        )}

        <div className="w-100 mb-3">
          <TablePagination
            // rowsPerPageOptions={[10, 25, 100, { value: -1, label: 'Todas' }]}
            rowsPerPageOptions={[10]}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => {
              return from + " - " + to + " de " + count + " tareas";
            }}
            component="div"
            // ===================>>>>> DESCOMENTAR-PAGINADOR-API START <<<<<===================
            count={this.props.grupoTareas.countTasks}
            // ===================>>>>> DESCOMENTAR-PAGINADOR-API END <<<<<===================
            // count={this.props.grupoTareas.tareas === null ? 0 : this.props.grupoTareas.tareas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>

        <div
          className="modal fade"
          aria-labelledby="modal"
          data-backdrop="static"
          aria-hidden="true"
          scrollable="true"
          role="dialog"
          id="modalDescarga"
        >
          <DescargarCSV
            disabledPersonalizado={this.props.grupoTareas.seleccionadoT}
            cantidadTareas={this.state.csvCantidadTareas}
            incluirOrder={this.state.csvIncluirOrder}
            descargar={this.downloadTasksCSV}
            handleChange={this.handleChange}
            handleState={this.handleState}
            openCalendar={this.state.openCalendar}
            positionCalendar={this.state.positionCalendar}
            selectedHours={this.state.selectedHours}
            selectedDays={this.state.selectedDays}
            selectedDaysOrder={this.state.selectedDaysOrder}
            from={this.state.from}
            to={this.state.to}
            handleDayClick={this.handleDayClick}
            handleHoursClick={this.handleHoursClick}
            vista={"customer"}
          />
        </div>

        <Drawer
          anchor={"left"}
          open={this.state.openGT}
          onClose={() => {
            this.closeWatchTaskGroup();
            this.handleDrawerGT(false);
          }}
        >
          <div
            className="panel-detalle"
            role="presentation"
            onKeyDown={() => this.handleDrawerGT(false)}
          >
            <DetalleGrupoTareas
              cerrarDetalle={this.handleDrawerGT}
              handleModal={this.handleModalAccion}
              data={this.props.data}
              grupoTareas={this.state.grupoTareas}
              handleContextMenu={this.handleContextMenu}
              vista={"customer"}
              handleState={this.handleState}
              historialState={this.state.historial}
              tituloHistorial={this.state.tituloHistorial}
              dataHistorial={this.state.dataHistorial}
              rayos={this.props.rayos.rayos}
              template={this.state.template}
            />
          </div>
          <MenuContext />
        </Drawer>

        <div
          className="modal fade"
          id="metadata"
          data-backdrop="static"
          data-focus="false"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="metadata"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content modal-white">
              <div className="modal-header">
                <h4 className="h4-modal pl-4 mb-0 text-center w-100">
                  Metadata
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => $("#metadata").modal("hide")}
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="title-text-white">
                    <img src={IconoCerrar} height="30" alt="Logo Rayo" />
                  </span>
                </button>
              </div>

              {this.state.taskMetadata == null ? (
                false
              ) : (
                <div className="modal-body py-0">
                  <MetadataJson
                    metadata={this.state.taskMetadata.metadata_task}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <Cargando cargando={this.state.cargando} />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    rayos: store.rayos,
    grupoTareas: store.grupoTareas,
  };
};

export default connect(mapStateToProps)(VistaLista);
